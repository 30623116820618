import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { HotspotSubType } from '@app/core/interfaces/hotspot-sub-type-interface';
import { HotspotType } from '@app/core/interfaces/hotspot-type-interface';
import { Hotspot, HotspotDto } from '@app/core/interfaces/hotspot.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { QueryParamService } from '@app/core/query-param/query-param.service';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { removePropFromObject } from '@app/shared/utils/utils';
import { filter, finalize, first, take, tap } from 'rxjs/operators';
import { HotspotsService } from '../../hotspots.service';
import { HotspotGroupsService } from '../hotspot-groups/hotspot-groups.service';
import { ShownComponentEnum } from '../hotspots-sidedrawer-showncomponent.enum';
import {
  HotspotDetailsPresentationComponent,
  SaveHotspotEvent,
} from './hotspot-details-presentation/hotspot-details-presentation.component';
import { HotspotDetailsService } from './hotspot-details.service';
import { filterNullish } from '@app/shared/operators';

@Component({
  selector: 'app-hotspot-details',
  templateUrl: './hotspot-details.component.html',
  styleUrls: ['./hotspot-details.component.scss'],
  standalone: false,
})
export class HotspotDetailsComponent implements OnInit, OnDestroy {
  public readonly selectedFarms$ = this.hotspotDetailsService.selectedFarms$.pipe(filter((farms) => !!farms && farms.length > 0));
  private readonly HOTSPOT_TRANSLATION_KEY = 'main.fieldmap.hotspotInfo.hotspot';

  private _hotspot!: HotspotDto;
  public get hotspot(): HotspotDto {
    return this._hotspot;
  }

  @Input()
  public set hotspot(hotspot: HotspotDto) {
    this._hotspot = hotspot;

    this.updateSelectedHotspotType(hotspot);
  }

  @Input() public hotspotTypes: HotspotType[] = [];
  @Input() public hotspotSubTypes: HotspotSubType[] = [];
  @Output() public deleted = new EventEmitter<HotspotDto>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public close = new EventEmitter<boolean>();
  @Output() public update = new EventEmitter<HotspotDto>();
  @Output() public created = new EventEmitter<HotspotDto>();

  @ViewChild('hotspotDetailsPresentation', { static: false })
  public hotspotDetailsPresentation?: HotspotDetailsPresentationComponent;

  private readonly hotspotUrlId = 'hotspot';

  public saving = false;
  public selectedHotspotType!: HotspotType;

  constructor(
    private hotspotGroupsService: HotspotGroupsService,
    private hotspotDetailsService: HotspotDetailsService,
    private hotspotsMapSideDrawerService: HotspotsService,
    private drawerOverlayService: SideDrawerOverlayService,
    private queryParamService: QueryParamService,
    private notificationService: NotificationService
  ) {}

  private updateSelectedHotspotType(hotspot: HotspotDto) {
    if (!this.hotspotTypes || !hotspot) {
      return;
    }

    const type = this.hotspotTypes.find((type) => type.id === hotspot.hotspotTypeId);

    if (type) this.selectedHotspotType = type;
  }

  ///////////////////////////////// Event handlers /////////////////////////////////

  public ngOnInit(): void {
    this.hotspotsMapSideDrawerService.setHotspotDrawing(false);
  }

  public ngOnDestroy() {
    this.hotspotsMapSideDrawerService.setShownComponentState(ShownComponentEnum.createHotspotComponent);
    this.close.emit();
    this.queryParamService.removeQueryParam(this.hotspotUrlId);
  }

  public hideDrawer() {
    this.drawerOverlayService.hideAllSideDrawers();
  }

  public footerClicked(event: any) {
    const methodName: string = event.methodName;
    const presentationObject = this.hotspotDetailsPresentation as any;
    const presentation = presentationObject[methodName];

    if (presentation) {
      if (event.hasOwnProperty('param1')) {
        presentation(event.param1);
      } else {
        presentation();
      }
    }
  }

  public deleteClick(hotspot: HotspotDto): void {
    this.hotspotDetailsService.openConfirmDeleteDialog().onDialogConfirmed?.(() => {
      this.deleteHotspot(hotspot);
    });
  }

  public onCloseClick() {
    this.tryClose();
  }

  public onCancelClicked($event: any): void {
    this.close.emit(this.isNewHotspot);
  }

  public saveClick(hotspot: SaveHotspotEvent): void {
    if (this.hotspotsMapSideDrawerService.isDirty || this.isNewHotspot) {
      this.saveHotspot(hotspot);
    } else {
      this.tryClose();
    }
  }

  public get isNewHotspot() {
    return this.hotspot && !this.hotspot.id;
  }

  private tryClose(): void {
    this.hotspotDetailsService
      .onSideDrawerChangeRequest(this.hotspotsMapSideDrawerService.isDirty)
      .pipe(
        first(),
        filter((isCloseAllowed) => isCloseAllowed),
        tap(() => {
          this.hotspotsMapSideDrawerService.setShownComponentState(ShownComponentEnum.createHotspotComponent);
          this.close.emit();
          this.queryParamService.removeQueryParam(this.hotspotUrlId);
        })
      )
      .subscribe();
  }

  private saveHotspot(hotspot: SaveHotspotEvent): void {
    this.saving = true;

    // For now, the backend throws error if the relations array is pressent in the patch request
    // therefore, we must filter it out with the new util method i just added - you're welcome ;)
    const hotspotWithoutRelations = removePropFromObject(hotspot.hotspot, 'hotspotGroupRelations');

    this.hotspotDetailsService
      .saveHotspot(hotspotWithoutRelations, hotspot.imagesToBeDeleted, hotspot.imagesToBeSaved)
      .pipe(
        take(1),
        finalize(() => (this.saving = false)),
        filterNullish()
      )
      .subscribe((savedHotspot) => {
        if (this.isNewHotspot) {
          this.notificationService.showCreated(this.HOTSPOT_TRANSLATION_KEY);
          this.created.emit(savedHotspot);
        } else {
          this.notificationService.showUpdated(this.HOTSPOT_TRANSLATION_KEY);
          this.update.emit(savedHotspot);
        }
        this.hotspotsMapSideDrawerService.setShownComponentState(ShownComponentEnum.createHotspotComponent);
        this.queryParamService.removeQueryParam(this.hotspotUrlId);

        if (savedHotspot.id && hotspot.hotspotGroups && hotspot.hotspotGroups?.length >= 0) {
          const toUpdate = this.hotspotGroupsService.getGroupsUpdateRequest(savedHotspot, hotspot.hotspotGroups);
          if (toUpdate) {
            this.hotspotDetailsService
              .updateRelations(savedHotspot as Hotspot, toUpdate)
              .pipe(
                take(1),
                finalize(() => (this.saving = false))
              )
              .subscribe();
          }
        }
      });
  }

  private deleteHotspot(hotspot: HotspotDto) {
    this.saving = true;
    const deleteObservable = this.hotspotDetailsService.deleteHotspot(hotspot);

    deleteObservable &&
      deleteObservable.pipe(finalize(() => (this.saving = false))).subscribe((status) => {
        this.notificationService.showDeleted(this.HOTSPOT_TRANSLATION_KEY);
        this.deleted.emit(hotspot);
        this.hotspotsMapSideDrawerService.setShownComponentState(ShownComponentEnum.createHotspotComponent);
        this.queryParamService.removeQueryParam(this.hotspotUrlId);
      });
  }
}
