import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AppLayoutService } from '@app/core/layout/app-layout.service';
import { SideDrawerComponent } from '@app/shared/side-drawer/side-drawer.component';

export enum SideDrawerHeaderCloseEventTypes {
  Cancel = 'cancel',
  CallToAction = 'call-to-action',
  Hide = 'hide',
}

export interface SideDrawerHeaderCloseEvent {
  type: SideDrawerHeaderCloseEventTypes;
}

@Component({
  selector: 'app-side-drawer-header',
  templateUrl: './side-drawer-header.component.html',
  styleUrls: ['./side-drawer-header.component.scss'],
  standalone: false,
})
export class SideDrawerHeaderComponent {
  @Input() public title = '';
  @Input() public cancelIconName = 'Luk';
  @Input() public hideIconName = 'minimize';
  @Input() public showInfoHoverButton?: boolean;
  @Input() public showMinimizeButton = false;
  @Output() public action = new EventEmitter<SideDrawerHeaderCloseEvent>();
  @Output() public onHide = new EventEmitter<SideDrawerHeaderCloseEvent>();

  constructor(
    private injector: Injector,
    private layoutService: AppLayoutService
  ) {}

  public callToAction() {
    this.action.emit({
      type: SideDrawerHeaderCloseEventTypes.CallToAction,
    });
  }

  public hide() {
    this.layoutService.toggleMenuMimized(); // ensures available real estate is correct in mobile mode
    this.onHide.emit({
      type: SideDrawerHeaderCloseEventTypes.Hide,
    });
    this.layoutService.toggleMenuMimized(); // toggle back to current menu state
  }

  public cancel() {
    this.layoutService.toggleMenuMimized(); // ensures available real estate is correct in mobile mode
    this.action.emit({
      type: SideDrawerHeaderCloseEventTypes.Cancel,
    });

    let sideDrawerComponent = null;
    try {
      sideDrawerComponent = this.injector.get(SideDrawerComponent); // check if viewContent of SideDrawer
    } catch (error) {}

    if (sideDrawerComponent) {
      sideDrawerComponent.close();
    }
    this.layoutService.toggleMenuMimized(); // toggle back to current menu state
  }
}
