import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-minimal-empty-card',
  template: `
    <mat-card appearance="outlined">
      <mat-card-header> </mat-card-header>
    </mat-card>
  `,
  styleUrls: ['minimal-empty-card.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MinimalEmptyCardComponent {
  constructor() {}
}
