import { Component, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Month } from '@app/core/enums/month.enum';
import { NToolsTaskStatus } from '@app/core/enums/n-tools-task-Status.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { NumberValidators } from '@app/helpers/validators/forms/number-validators';
import { ScopeItem } from '@app/map/features/cultivation-journal/filter/scope-item';
import { ColumnSorter } from '@app/map/features/cultivation-journal/n-tools/n-tools-table/util/ColumnSorter';
import { CropNutrientAvailability } from '@app/shared/n-tool/CropNutrientAvailabilityGraphDataPointDTO';
import { DateFormatPipe } from '@app/shared/pipes/date-format/date-format.pipe';
import { TreelistTable } from '@app/shared/treelist-table/treelist-table';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { CellClickEvent, CellCloseEvent, SelectableSettings, SelectionChangeEvent } from '@progress/kendo-angular-treelist';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subscription, combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { first, map } from 'rxjs/operators';
import { ColumnKeyNTool } from '../../filter/column/column-key-n-tool';
import { NToolsCalcService } from '../n-tools-calc-dialog/n-tools-calc.service';
import { NToolsTableLine } from './models/n-tools-table-line';
import { NToolsTableSubLine } from './models/n-tools-table-sub-line';
import { NToolsTableService } from './n-tools-table.service';

@Component({
  selector: 'app-n-tools-table',
  templateUrl: './n-tools-table.component.html',
  styleUrls: ['./n-tools-table.component.scss'],
  providers: [NToolsTableService, NToolsCalcService],
  standalone: false,
})
export class NToolsTableComponent extends TreelistTable implements OnInit, OnDestroy {
  @Input() public initialField$!: Observable<Field>;

  @ViewChild('treeList', { static: false }) public treeList: any;
  @ViewChild('dropdownlist', { static: false }) public dropdownlist: any;
  public readonly CROP_NUTRIENT_AVAILABILITY = CropNutrientAvailability;
  public readonly N_TOOLS_TASK_STATUS = NToolsTaskStatus;
  public readonly COLUMN_KEY = ColumnKeyNTool;
  public readonly NO_DATA = '';
  public readonly SCOPE = ScopeItem.NToolTableComponent;
  public expandedIds$!: Observable<number[]>;
  public settings: SelectableSettings = {
    mode: 'row',
    multiple: false,
    drag: false,
    readonly: false,
    enabled: true,
  };
  public selectedRow: number | null = null;
  public formGroup!: UntypedFormGroup;
  public displayData$!: Observable<NToolsTableLine[]> | null;
  public selectableNorms$!: Observable<{ name: string; number: string }[]>;
  public loading = true;
  public maxDate!: Date;
  public minDate!: Date;
  private datePipe!: DateFormatPipe;
  private subscriptions = new Subscription();
  private normsFilter = new BehaviorSubject<string>('');

  constructor(
    private nToolsTableService: NToolsTableService,
    private formBuilder: UntypedFormBuilder,
    private harvestYearStateService: HarvestYearStateService,
    private languageService: LanguageService,
    public intlService: IntlService
  ) {
    super();
  }

  public getColumnSorterByKey(columnKey: ColumnKeyNTool) {
    return this.nToolsTableService.getColumnSorterByKey(columnKey);
  }

  public getAvailableFilterablesForColumn$(columnKey: ColumnKeyNTool) {
    return this.nToolsTableService.getAvailableFilterablesForColumn$(columnKey);
  }

  public hasChildren = (item: NToolsTableLine | NToolsTableSubLine): boolean => {
    return item instanceof NToolsTableLine;
  };

  public fetchChilren = (item: NToolsTableLine): NToolsTableSubLine[] => {
    return item.subLines;
  };

  public ngOnInit(): void {
    (<CldrIntlService>this.intlService).localeId = this.languageService.currentLanguage.shortKey;
    this.datePipe = new DateFormatPipe(this.languageService);
    this.subscriptions.add(
      this.harvestYearStateService.harvestYear$.subscribe((harvestYear) => {
        this.maxDate = DateTime.fromObject({ year: harvestYear! + 2, month: Month.December, day: 31 }).toJSDate();
        this.minDate = DateTime.fromObject({ year: harvestYear! - 3, month: Month.January, day: 1 }).toJSDate();
      })
    );

    this.expandedIds$ = this.nToolsTableService.expandedIds$;
    this.displayData$ = this.nToolsTableService.tableData$;

    this.subscriptions.add(
      this.displayData$.subscribe(() => {
        if (NToolsTableService.refresh === true) {
          this.loading = true;
          this.refreshData();
        } else {
          this.loading = false;
        }
      })
    );

    this.selectableNorms$ = combineLatest([
      this.normsFilter,
      this.nToolsTableService.nProduceNorm$.pipe(map((norms) => norms.map((n) => ({ name: n.name, number: n.number })))),
    ]).pipe(
      map(([filterString, norms]) => {
        return norms.filter((norm) => norm.name.toLowerCase().includes(filterString.toLowerCase()));
      })
    );
    this.setFocusedRow();
    this.createFormGroup();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public createFormGroup(nToolsTableSubLine?: NToolsTableSubLine): UntypedFormGroup {
    return (this.formGroup = this.formBuilder.group({
      date: [nToolsTableSubLine?.date ? nToolsTableSubLine?.date : DateTime.now().toJSDate(), [Validators.required]],
      product: [nToolsTableSubLine?.product ? { name: nToolsTableSubLine?.product, number: nToolsTableSubLine?.product } : ''],
      amount: [nToolsTableSubLine?.amount ?? '', [Validators.required, NumberValidators.numeric, NumberValidators.greaterThan(0)]],
      status: [nToolsTableSubLine?.status ?? ''],
    }));
  }

  public cellCloseHandler(e: CellCloseEvent): void {
    const { formGroup, dataItem, column } = e;
    const columnIndexSymbol = ColumnKeyNTool.getColumnKeySymbolFrom(column.leafIndex);
    if (!formGroup.valid) {
      e.preventDefault();
    } else if (formGroup.dirty && this.nToolsTableService.changesHasBeenMade(formGroup, dataItem, columnIndexSymbol!)) {
      Object.assign(dataItem, {
        ...formGroup.value,
        product: formGroup.value.product.name,
        productNormNumber: formGroup.value.product.number,
      });

      this.nToolsTableService.handleUpdate(dataItem, columnIndexSymbol!);
    }
  }

  public rowCallback = (context: RowClassArgs) => {
    const hasChildren = !!context.dataItem?.subLines;
    return {
      disabledRow: this.loading && !hasChildren,
      updatingRow: context.dataItem?.editable !== undefined && !context.dataItem?.editable,
      childRow: !hasChildren,
      parentRow: hasChildren,
    };
  };

  public isSelected = (dataItem: NToolsTableLine | NToolsTableSubLine) => {
    if (dataItem instanceof NToolsTableLine) {
      return dataItem.field.id === this.selectedRow;
    }
    return false;
  };

  public isExpanded$(dataItem: NToolsTableLine | NToolsTableSubLine): Observable<boolean> {
    if (dataItem instanceof NToolsTableLine) {
      return this.expandedIds$.pipe(map((expandedIds) => expandedIds.includes(dataItem.field.id)));
    }
    return of(false);
  }

  public getNBalanceStyleClass(cropNutrientAvailability: CropNutrientAvailability) {
    switch (cropNutrientAvailability) {
      case CropNutrientAvailability.None:
        return 'n-balance-none';
      case CropNutrientAvailability.Green:
        return 'n-balance-green';
      case CropNutrientAvailability.Yellow:
        return 'n-balance-yellow';
      case CropNutrientAvailability.Red:
        return 'n-balance-red';
      default:
        return 'n-balance-none';
    }
  }
  public onSelectionChange($event: SelectionChangeEvent) {
    if ($event.action === 'select') {
      this.SelectionChange($event?.items[0].dataItem);
    }
  }

  /**
   * Handles edit stage state of cells when clicked based on the cell type
   */
  public cellClick({ sender, rowIndex, dataItem, isEdited, columnIndex }: CellClickEvent) {
    // When a parent is clicked, the selection should change instead of cell editing.
    // If editing on parent cells needs to be implemented, this is the place.
    if (!this.loading) {
      this.normsFilter.next('');
      if (dataItem instanceof NToolsTableLine) {
        this.SelectionChange(dataItem);
      } else if (this.columnHasSpecificHandling(columnIndex)) {
        this.handleSpecificColumnLogic(columnIndex, dataItem);
      } else if (this.isColumnEditable(columnIndex)) {
        if (!isEdited) {
          sender.editCell(dataItem, columnIndex, this.createFormGroup(dataItem));
        } else {
          sender.closeCell();
          sender.cancelCell();
        }
      }
    }
  }
  public handleSpecificColumnLogic(columnIndex: number, dataItem: any) {
    const columnIndexSymbol = ColumnKeyNTool.getColumnKeySymbolFrom(columnIndex);
    if (columnIndexSymbol === ColumnKeyNTool.status) {
      const newStatus = dataItem.status === NToolsTaskStatus.done ? NToolsTaskStatus.planned : NToolsTaskStatus.done;
      Object.assign(dataItem, { status: newStatus });
      this.nToolsTableService.handleUpdate(dataItem, columnIndexSymbol);
    }
  }
  public columnHasSpecificHandling(columnIndex: number) {
    const columnIndexSymbol = ColumnKeyNTool.getColumnKeySymbolFrom(columnIndex);
    return [ColumnKeyNTool.status].includes(columnIndexSymbol!);
  }

  public isColumnEditable(columnIndex: number) {
    const columnIndexSymbol = ColumnKeyNTool.getColumnKeySymbolFrom(columnIndex);
    return [ColumnKeyNTool.productAmount, ColumnKeyNTool.productName, ColumnKeyNTool.taskDate].includes(columnIndexSymbol!);
  }

  public refreshData() {
    this.displayData$ = null;
    this.nToolsTableService.loadTableData();
    this.displayData$ = this.nToolsTableService.tableData$;
  }

  public onExpandedIdsChange() {
    this.expandedIds$.pipe(first()).subscribe((expandedIds) => {
      if (expandedIds.length > 1) {
        this.nToolsTableService.expandedIds = expandedIds.slice(-1);
      }
    });
  }

  public sortChange(customColumnSorter: ColumnSorter<NToolsTableLine>) {
    this.nToolsTableService.sortChange(customColumnSorter);
  }

  public onFilterChange(nToolsTableLine: NToolsTableLine, columnKey: ColumnKeyNTool) {
    this.nToolsTableService.toggleFilterItem(nToolsTableLine, columnKey);
  }

  public onFocus(dropdown: { toggle: (arg0: boolean) => void }) {
    dropdown.toggle(true);
  }

  public deleteButtonClicked(dataItem: NToolsTableSubLine) {
    this.nToolsTableService.deleteTask(dataItem);
  }

  public editButtonClicked(dataItem: NToolsTableSubLine) {
    this.nToolsTableService.editTask(dataItem);
  }

  public getTemplateMapForNBalance(templateRef: TemplateRef<any>) {
    return new Map<ColumnKeyNTool, TemplateRef<any>>().set(ColumnKeyNTool.nBalance, templateRef);
  }

  public shouldNBalanceShowWarning$(nToolsTableLine: NToolsTableLine) {
    return of(nToolsTableLine.subLines.some((subLine) => subLine.cropNutrientAvailability === CropNutrientAvailability.Warning));
  }

  public getFormGroupErrorTranslatables(formGroup: UntypedFormGroup, columnKey: ColumnKeyNTool): string[] {
    const errors: string[] = [];
    switch (columnKey) {
      case ColumnKeyNTool.productAmount:
        {
          const control = formGroup.get('amount');

          if (control?.hasError('required')) {
            errors.push('editTask.messages.quantityRequired');
          }

          if (control?.hasError('greaterThan')) {
            errors.push('editTask.messages.quantityGreaterThanZero');
          }

          if (control?.hasError('number')) {
            errors.push('editTask.messages.quantityNumbersOnly');
          }
        }
        break;
      case ColumnKeyNTool.taskDate:
        {
          const control = formGroup.get('date');

          if (control?.hasError('required')) {
            errors.push(this.languageService.getText('editTask.messages.dateRequired'));
          }

          if (control?.hasError('maxError') || control?.hasError('minError')) {
            errors.push(
              this.languageService.getText('editTask.messages.dateError', {
                minDate: this.datePipe.transform(DateTime.fromJSDate(this.minDate)),
                maxDate: this.datePipe.transform(DateTime.fromJSDate(this.maxDate)),
              })
            );
          }
        }
        break;
    }
    return errors;
  }

  @HostListener('document:keydown.enter', ['$event'])
  public onEnterClick() {
    this.treeList.closeCell();
  }

  private setFocusedRow() {
    combineLatest([this.initialField$, this.nToolsTableService.tableData$])
      .pipe(first())
      .subscribe(([field, tableData]) => {
        this.selectedRow = field.id;
        if (tableData.length && !tableData.find((nToolsTableLine) => nToolsTableLine.field.id === this.selectedRow)) {
          this.selectedRow = tableData[0].field.id;
          this.nToolsTableService.setCultivationJournalField(tableData[0].field);
        }

        this.nToolsTableService.expandedIds = [this.selectedRow];
      });
  }

  private SelectionChange(dataItem: NToolsTableLine) {
    this.expandedIds$.pipe(first()).subscribe((expandedIds) => {
      // Selection change is only used if the given dataItem is a NToolsTableLine
      if (dataItem instanceof NToolsTableLine) {
        // When the selected row is clicked, it is expanded if collapsed, and collapsed if expanded
        if (dataItem.field.id === this.selectedRow) {
          this.nToolsTableService.expandedIds = expandedIds.length === 0 ? [this.selectedRow] : [];
        } else {
          // When a new row is clicked, it is selected and expanded

          this.selectedRow = dataItem.field.id;

          this.nToolsTableService.expandedIds = [this.selectedRow];
        }
        this.nToolsTableService.setCultivationJournalField(dataItem.field);
      }
    });
  }

  public filterNormList(filterString: string) {
    this.normsFilter.next(filterString);
  }
}
