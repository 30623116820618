import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartService } from '@app/core/chart/chart.service';
import { NdviHistory } from '@app/core/interfaces/ndvi-history.interface';
import { GlobalStateService } from '@app/state/services/global/global-state.service';
import { NdviStateService } from '@app/state/services/ndvi/ndvi-state.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CategoryAxis, RenderEvent, ValueAxis } from '@progress/kendo-angular-charts';
import { Group } from '@progress/kendo-drawing';
import { DateTime, Interval } from 'luxon';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { FieldAnalysisService } from '../../../field-analysis.service';

@Component({
  selector: 'app-growth-curve',
  templateUrl: './growth-curve.component.html',
  styleUrls: ['./growth-curve.component.scss'],
  standalone: false,
})
export class GrowthCurveComponent implements OnInit, OnDestroy {
  public selectedNdviHistory$ = this.ndviStateService.selectedNdviHistory$;
  public selectedNdviField$ = this.ndviStateService.selectedNdviField$;
  public isLoading$ = this.globalStateService.isLoading$;

  public directorateCropNormName: string | undefined = '';
  public benchmarkCropCountAverage = 0;
  public dataSeries: any[] = [];
  public categoryAxis: CategoryAxis = {
    categories: [],
    visible: false,
  };
  public valueAxis: ValueAxis = {
    min: 0,
    max: 1,
  };

  private subscriptions: Subscription[] = [];
  private sowingDate?: Date | string;
  private sowingDateString?: string;
  private harvestDate?: Date | string;
  private harvestDateString?: string;

  constructor(
    private ndviStateService: NdviStateService,
    private globalStateService: GlobalStateService,
    private translateService: TranslateService,
    private chartService: ChartService,
    private fieldAnalysisService: FieldAnalysisService
  ) {}

  public ngOnInit() {
    this.getTextTranslation();

    this.subscriptions.push(
      this.selectedNdviHistory$
        .pipe(
          tap(() => {
            this.directorateCropNormName = '';
            this.categoryAxis.categories = [];
            this.dataSeries = [];
          }),
          filter((ndviHistory) => !!ndviHistory)
        )
        .subscribe((ndviHistory) => {
          this.fieldAnalysisService.setOnFieldClicked(true);
          return this.onNdviHistoryChange(ndviHistory);
        }),
      this.translateService.onLangChange.subscribe((_event: LangChangeEvent) => {
        this.getTextTranslation();
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private onNdviHistoryChange(ndviHistory: NdviHistory | null) {
    if (!ndviHistory) return;

    const { sowingDate, harvestDate, directorateCropNormName, timeseries } = ndviHistory;

    this.directorateCropNormName = directorateCropNormName;
    this.sowingDate = sowingDate && new Date(sowingDate);
    this.harvestDate = harvestDate && new Date(harvestDate);

    this.dataSeries = [
      {
        name: 'mapFieldInspectorItem.ndviInfo.legendText2',
        data: [],
      },
      {
        name: 'mapFieldInspectorItem.ndviInfo.legendText1',
        data: [],
      },
    ];

    // Maybe a flatmap to handle non-null assertion
    this.dataSeries[0].data = timeseries.map((timeserie) => {
      return {
        date: new Date(timeserie.sampleDate!),
        ndvi: timeserie.benchMarkNdviPercent,
      };
    });

    // Maybe a flatmap to handle non-null assertion
    this.dataSeries[1].data = timeseries.map((timeserie) => {
      return {
        date: new Date(timeserie.sampleDate!),
        ndvi: timeserie.averageNdviPercent,
      };
    });

    const averageCount = timeseries.reduce((a, b) => a + b.benchmarkFieldCnt!, 0)! / timeseries.length;
    this.benchmarkCropCountAverage = Math.round(averageCount);
  }

  public onRender(e: RenderEvent) {
    const chartElement = (e.sender.getPlotArea().backgroundVisual as any).chartElement;
    const totalMin = chartElement.axisX.totalMin;
    const totalMax = chartElement.axisX.totalMax;
    const group = new Group();

    if (!this.sowingDate || !this.harvestDate) return;

    const sowingDate = this.sowingDate instanceof Date ? DateTime.fromJSDate(this.sowingDate) : DateTime.fromISO(this.sowingDate);

    const harvestDate = this.harvestDate instanceof Date ? DateTime.fromJSDate(this.harvestDate) : DateTime.fromISO(this.harvestDate);

    const totalMinDate = DateTime.fromMillis(totalMin);
    const totalMaxDate = DateTime.fromMillis(totalMax);

    const interval = Interval.fromDateTimes(totalMinDate, totalMaxDate);

    const sowingIsBetween = interval.contains(sowingDate);
    const harvestIsBetween = interval.contains(harvestDate);

    if (this.sowingDateString && sowingIsBetween) {
      const sowingDateLine = this.chartService.createLine(sowingDate, chartElement, totalMin, totalMax);
      const sowingDateLabel = this.chartService.createLineLabel(this.sowingDateString, sowingDateLine);

      group.append(sowingDateLine, sowingDateLabel);
    }

    if (this.harvestDateString && harvestIsBetween) {
      const harvestDateLine = this.chartService.createLine(harvestDate, chartElement, totalMin, totalMax);
      const harvestDateLabel = this.chartService.createLineLabel(this.harvestDateString, harvestDateLine);

      group.append(harvestDateLine, harvestDateLabel);
    }

    e.sender.findPaneByIndex(0).visual.insert(0, group);
  }

  private getTextTranslation() {
    this.translateService
      .get(['mapFieldInspectorItem.ndviInfo.sowingDate', 'mapFieldInspectorItem.ndviInfo.harvestDate'])
      .subscribe((translations) => {
        this.sowingDateString = translations['mapFieldInspectorItem.ndviInfo.sowingDate'];
        this.harvestDateString = translations['mapFieldInspectorItem.ndviInfo.harvestDate'];
      });
  }
}
