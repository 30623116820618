import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';

@Component({
  selector: 'app-farm-selector',
  templateUrl: './farm-selector.component.html',
  styleUrls: ['./farm-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FarmSelectorComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public selectedFarms$ = this.farmStateService.selectedFarms$;
  public farmSelectorControl = new UntypedFormControl();
  @Output() public farmSelected = new EventEmitter<Farm>();

  constructor(private farmStateService: FarmStateService) {}

  public ngOnInit() {
    this.subscriptions.add(
      this.farmSelectorControl.valueChanges.subscribe((farm) => {
        this.farmSelected.emit(farm);
      })
    );

    this.subscriptions.add(
      this.selectedFarms$.pipe(first()).subscribe((farms) => {
        this.farmSelectorControl.setValue(farms[0]);
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public trackByFn(index, item) {
    return index;
  }
}
