import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { Subscription } from 'rxjs';
import { GrowthRegulationVM } from '../growth-regulation-vm.class';
import { GrowthRegulationService } from '../growth-regulation.service';
import { GrowthRegulation } from '../interfaces/growth-regulation.interface';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss'],
  standalone: false,
})
export class FieldsComponent implements OnDestroy, OnChanges, OnInit {
  @Input() public growthRegulations!: GrowthRegulationVM;
  private subscriptions = new Subscription();
  public selectableLayers = [MapLayerId.GROWTHREGULATION];
  public isLayerReady = false;

  constructor(
    private _layerSerive: OlLayerService,
    private _mapService: OlMapService,
    private growthRegService: GrowthRegulationService
  ) {}
  ngOnInit(): void {
    this._layerSerive.createFeatureLayer(LayerId.GROWTH_REGULATION, []);
  }

  public ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['growthRegulations'] && simpleChanges['growthRegulations'].currentValue) {
      const { growthRegulations, fields } = simpleChanges['growthRegulations'].currentValue;
      this.onGrowthRegulationsChange(fields, growthRegulations);
    }
  }

  public ngOnDestroy(): void {
    this._layerSerive.removeLayers(LayerId.GROWTH_REGULATION);
    this._mapService.disableSelectInteraction();
    this.subscriptions.unsubscribe();
  }

  public onGrowthRegulationsChange(fields: Field[], growthRegulations: GrowthRegulation[]) {
    this._mapService.enableSelectInteraction();
    this.isLayerReady = false;
    const growthRegFeatures = this.growthRegService.getAndSetFieldFeatures(fields, growthRegulations, LayerId.GROWTH_REGULATION);
    growthRegFeatures.forEach((feat) => {
      this._layerSerive.addFeature(feat, LayerId.GROWTH_REGULATION);
    });
    this._layerSerive.setLayerVisibility(LayerId.GROWTH_REGULATION, true);

    this._mapService.magicReRender();
    this.isLayerReady = true;
  }
}
