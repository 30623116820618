import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['search-field.component.scss'],
  standalone: false,
})
export class SearchFieldComponent implements OnInit, OnDestroy {
  @Output() public searchFor = new EventEmitter<string>();
  @Input() public debounchTime = 400;
  @Input() public initValue = '';
  public term = new UntypedFormControl();

  private _sub = new Subscription();

  constructor() {}

  @HostListener('document:keydown.tab', ['$event'])
  onKeydownHandlerTab(event: KeyboardEvent) {
    event.preventDefault();
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandlerShiftTab(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
    }
  }

  public ngOnDestroy() {
    this._sub.unsubscribe();
  }

  public ngOnInit() {
    const sub = this.term.valueChanges.pipe(debounceTime(this.debounchTime), distinctUntilChanged()).subscribe((result: string) => {
      this.searchFor.emit(result);
    });

    this._sub.add(sub);
  }
}
