import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FieldService } from '@app/core/field/field.service';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { CropNormDTO } from '@app/core/repositories/crops/crop-norm.dto';
import { FieldsRepo } from '@app/core/repositories/fields/fields-repo.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { LoadingState } from '@app/helpers/loading-state';
import { WKTUtil } from '@app/map/helpers/utils/WKT-util';
import { LayerId } from '@app/map/services/layer/layer.store';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { FarmLockedService } from '@app/shared/farm-locked/farm-locked.service';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { CellClickEvent, CellCloseEvent, GridComponent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { isEqual } from 'lodash';
import { cloneDeep } from 'lodash-es';
import { Feature } from 'ol';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { catchError, finalize, first, take } from 'rxjs/operators';
import { FieldPlanSideDrawerService } from '../../../field-plan-side-drawer.service';
import { UpdateFieldDTO } from '../../../interfaces/update-field-dto.interface';
import { ShownComponentEnum } from '../../../shown-component-in-side-drawer.enum';
import { FieldPlanContentComponent } from '../../field-plan-content.component';

@Component({
  selector: 'app-field-list',
  templateUrl: './field-list.component.html',
  styleUrls: ['./field-list.component.scss'],
  standalone: false,
})
export class FieldListComponent implements OnInit, OnDestroy, AfterViewInit {
  private static currentFieldsListState: Field[] | null;
  private static currentHarvestYear: number | undefined;
  private static currentFarms: Farm[];
  private static selectedField: number[] = [];
  private gridData!: Field[];
  public cropsData!: CropNormDTO[];
  private loadingState = new LoadingState();
  private subscriptions: Subscription[] = [];
  private editSubScription: Subscription = new Subscription();
  private fieldNumberValidationPattern = Validators.pattern(/^([1-9]|[1-9][0-9]{1,2})[-+]([0-9]{1,2})$/);
  @ViewChild(GridComponent) public fieldList!: GridComponent;
  @ViewChild('fieldListWrapper') public fieldListWrapper!: ElementRef;
  public selectedFarms$!: Observable<Farm[]>;
  public readonly harvestYear$: Observable<number | undefined> = this.harvestYearStateService.harvestYear$;

  public gridView!: GridDataResult;
  public gridHeight!: number;
  public errorMessage = '';
  public sort: SortDescriptor[] = [
    {
      field: 'number',
      dir: 'asc',
    },
  ];
  public loading$ = this.loadingState.changes$;
  public isSaving: boolean = false;
  public sideDrawerRef!: SideDrawerRef<FieldPlanContentComponent, void, void>;
  private sideDrawerWidth = new BehaviorSubject(SideDrawerConfig.widthAsClosed);
  public get sideDrawerWidth$(): Observable<string> {
    return this.sideDrawerWidth.asObservable();
  }
  public formGroup!: UntypedFormGroup;

  get selectedField() {
    return FieldListComponent.selectedField;
  }

  set selectedField(id: number[]) {
    FieldListComponent.selectedField = id;
  }

  constructor(
    private fieldService: FieldService,
    private languageService: LanguageService,
    private notificationService: NotificationService,
    private fieldPlanSideDrawerService: FieldPlanSideDrawerService,
    private farmLockedService: FarmLockedService,
    private ngZone: NgZone,
    private formBuilder: UntypedFormBuilder,
    private fieldsRepo: FieldsRepo,
    private mapService: OlMapService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService
  ) {}

  @HostListener('window:resize', ['$event'])
  public onResize($event: { currentTarget: { innerHeight: number } }) {
    if (!$event || !$event.currentTarget || !$event.currentTarget.innerHeight) {
      return;
    }

    this.fitGrid();
  }

  public ngOnInit(): void {
    this.selectedFarms$ = this.farmStateService.selectedFarms$;
    this.createFormGroup = this.createFormGroup.bind(this);
    this.formGroup = new UntypedFormGroup({
      number: new UntypedFormControl('', [Validators.required, this.fieldNumberValidationPattern]),
      name: new UntypedFormControl(''),
    });

    this.subscriptions.push(
      combineLatest([this.selectedFarms$, this.harvestYear$]).subscribe(([farms, harvestYear]) => {
        if (
          FieldListComponent.currentFieldsListState &&
          !this.fieldPlanSideDrawerService.editedFieldCUD &&
          harvestYear === FieldListComponent.currentHarvestYear &&
          isEqual(farms, FieldListComponent.currentFarms)
        ) {
          this.gridData = FieldListComponent.currentFieldsListState;
          this.loadFields();
        } else {
          FieldListComponent.currentHarvestYear = harvestYear;
          FieldListComponent.currentFarms = farms;
          this.getFields(harvestYear, farms).subscribe(
            (fields: Field[] | null) => {
              this.fieldService.setMainCropsForFields(fields);
              this.fieldService.setIsReadOnlyFields(fields);
              this.gridData = fields ?? [];
              FieldListComponent.currentFieldsListState = fields;
              this.loadFields();
            },
            (error: Error) => (this.errorMessage = this.languageService.getText('messages.fields.getError') + '. ' + error.message)
          );
        }
      })
    );
  }

  public ngAfterViewInit(): void {
    document.querySelector('.k-grid .k-grid-content')?.addEventListener('scroll', this.scroll, false);

    this.scrollToSelectedRow();
    this.fitGrid();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    document.querySelector('.k-grid .k-grid-content')?.removeEventListener('scroll', this.scroll, false);
  }

  public editHandler({ sender, rowIndex, dataItem, isEdited, columnIndex }: CellClickEvent): void {
    this.selectedFieldClick(dataItem, false);
    if (!isEdited && dataItem.isReadOnly === false) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup({ dataItem }));
    } else {
      sender.closeCell();
      sender.cancelCell();
    }
  }

  public cancelCurrentCellInput(): void {
    this.fieldList.cancelCell();
  }

  public cellCloseHandler({ sender, dataItem }: CellCloseEvent): void {
    if (!this.formGroup.valid) {
      sender.cancelCell();
    } else if (this.formGroup.dirty) {
      const field = dataItem as Field;
      const clonedFieldValueRow = cloneDeep(this.gridData?.filter((x: Field) => x.id === field.id)[0] as Field);
      const fieldFeatures = this.getFieldFeatures(field);
      if (fieldFeatures && fieldFeatures.length > 0) {
        this.fieldsRepo
          .updateField(this.getUpdatedFieldDTO(field, fieldFeatures[0]), false)
          .pipe(first())
          .subscribe(
            (updatedField) => {
              this.fieldPlanSideDrawerService.updateFieldInStore(updatedField!);
            },
            () => {
              // revert
              const rowIndex = this.gridView.data.findIndex((gridViewField: Field) => gridViewField.id === field.id) + 1;
              sender.focus();
              sender.cancelCell();
              this.selectedField[0] = field.id;
              this.selectedFieldClick(field, false);
              sender.focusCell(rowIndex, 0);
              sender.activeRow.dataItem.number = clonedFieldValueRow.number;
              sender.activeRow.dataItem.name = clonedFieldValueRow.name;
              this.notificationService.showError(this.languageService.getText('messages.fields.updateError'));
            }
          );
      }
    }
    this.editSubScription.remove(this.editSubScription);
  }

  public get isInvalidFieldNumber() {
    return this.formGroup.controls['number'].getError('pattern');
  }

  public get isFieldNumberInUse() {
    return this.formGroup.controls['number'].getError('fieldNumberInUse');
  }

  public get isValidating() {
    return this.formGroup.controls['number'].getError('validating');
  }

  public createFormGroup(args: any): UntypedFormGroup {
    const field = args.dataItem as Field;

    this.formGroup = this.formBuilder.group({
      number: [field.number, this.fieldNumberValidationPattern],
      name: [field.name],
    });

    if (field.isReadOnly === true) {
      this.fieldList.closeCell();
      this.fieldList.cancelCell();
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
      this.editSubScription.add(
        this.formGroup.controls['number'].valueChanges.subscribe((inputNumber) => {
          if (this.formGroup.controls['number'].valid) {
            this.formGroup.controls['number'].setErrors({ validating: true });
            this.fieldsRepo
              .validateFieldNumber(field.farmId, field.harvestYear, inputNumber, field.id)
              .pipe(first())
              .subscribe((validNumber: boolean) => {
                if (validNumber === true) {
                  this.formGroup.controls['number'].setErrors(null);
                } else {
                  this.formGroup.controls['number'].setErrors({ fieldNumberInUse: true });
                }
              });
          }
        })
      );
    }

    return this.formGroup;
  }

  public onKeyDown(pressed: KeyboardEvent) {
    setTimeout(() => {
      if (
        this.fieldList.activeRow &&
        (pressed.key.toUpperCase() === 'ArrowDown'.toUpperCase() ||
          pressed.key.toUpperCase() === 'ArrowUp'.toUpperCase() ||
          pressed.key.toUpperCase() === 'Tab'.toUpperCase()) &&
        this.fieldList.activeRow.dataItem
      ) {
        this.selectedField = [this.fieldList.activeRow.dataItem.id];
        this.selectedFieldClick(this.fieldList.activeRow.dataItem, false);
      }
    });
  }

  public selectedFieldClick(field: Field, showEditFieldDetails: boolean) {
    if (field) {
      const fieldFeatures = this.getFieldFeatures(field);
      if (fieldFeatures && fieldFeatures.length > 0) {
        // show as selected and slide to
        this.fieldPlanSideDrawerService.olMapService.selectFeatures(fieldFeatures);
        this.fieldPlanSideDrawerService.olMapService.fitMapToExtent(fieldFeatures[0].getGeometry()?.getExtent());

        if (showEditFieldDetails) {
          if (!this.farmLockedService.isFieldsFarmLockedInSelectedYear(field)) {
            if (this.formGroup.get('name')?.value) field.name = this.formGroup.get('name')?.value;
            if (this.formGroup.get('number')?.value) field.number = this.formGroup.get('number')?.value;
            fieldFeatures[0].set('field', field);
            this.fieldPlanSideDrawerService.editedField = field;
            this.fieldPlanSideDrawerService.setShownComponentState(ShownComponentEnum.createFieldFormComponent, fieldFeatures[0]);
            this.sideDrawerWidth.next(SideDrawerConfig.widthAsOpened);
          } else {
            this.farmLockedService.showLockedHarvestYearErrorForFarm(field.farmId);
          }
        }
      } else {
        this.notificationService.showInfo(this.languageService.getText('main.fieldAdministration.fieldList.infoText.noFieldPolygon'));
      }
    }
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadFields();
    this.scrollToSelectedRow();
  }

  private loadFields(): void {
    const ascendingFieldNumberFn = (fieldA: { number: string | undefined }, fieldB: { number: string | undefined }) =>
      CompareHelper.compareFieldNumbers(fieldA.number, fieldB.number, true);

    this.gridData?.sort(ascendingFieldNumberFn);
    for (let i = 0; i < (this.gridData?.length ?? 0); i++) {
      this.gridData[i].fieldNumberSortOrder = i;
    }

    const numericFieldNumberSort = cloneDeep(this.sort);
    numericFieldNumberSort.map((obj) => {
      if (obj.field === 'number') {
        obj.field = 'fieldNumberSortOrder';
      }
    });

    if (numericFieldNumberSort) {
      this.gridView = {
        data: orderBy(this.gridData, numericFieldNumberSort),
        total: this.gridData.length,
      };
    } else {
      this.gridView = {
        data: this.gridData,
        total: this.gridData.length,
      };
    }
  }

  private getFields(harvestYear: number | undefined, farms: Farm[]): Observable<Field[] | null> {
    return this.fieldService
      .getAllFields(
        farms.map((farm) => farm.id),
        harvestYear
      )
      .pipe(
        finalize(() => this.loadingState.stop()),
        catchError((error: HttpErrorResponse) => {
          return this.observableThrowGenericErrorFields(error);
        })
      ) as Observable<Field[] | null>;
  }

  private getFieldFeatures(field: Field): Feature[] {
    const allFieldFeatures = this.fieldPlanSideDrawerService.olMapService.getFeaturesFromLayer(LayerId.FIELDS);
    // find features where field id = selected field id
    return allFieldFeatures.filter((feature: Feature) => feature.get('field').id === field.id);
  }

  private getFieldBlockId(currentfield: Field, feature: Feature) {
    const fieldText: string = feature.get('text');
    if (currentfield.fieldBlocks?.length ?? 0 > 0) {
      const subDivision = fieldText.replace(currentfield.number, '');
      return currentfield.fieldBlocks?.find((fieldBlock) => fieldBlock?.subDivision === subDivision)?.id;
    }
    return undefined;
  }

  private getUpdatedFieldDTO(currentfield: Field, fieldFeature: Feature): UpdateFieldDTO {
    const field: UpdateFieldDTO = {
      id: currentfield.id,
      cropNormNumber: currentfield.mainCropNormNumber,
      farmId: currentfield.farmId,
      geometry: WKTUtil.getWktFromFeature(fieldFeature),
      fieldBlockId: currentfield.fieldBlocks?.length ? this.getFieldBlockId(currentfield, fieldFeature) : undefined,
      harvestYear: currentfield.harvestYear,
      jb: currentfield.jb,
      name: this.formGroup.get('name')?.value,
      number: this.formGroup.get('number')?.value,
      cropId: currentfield.mainCropId,
      featureId: currentfield.featureId,
    };

    return field;
  }

  private fitGrid(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.setGridSize();
      });
  }

  private setGridSize() {
    let domRect: DOMRect;
    domRect = this.fieldListWrapper.nativeElement.getBoundingClientRect();

    if (window.innerHeight > domRect.top) {
      this.gridHeight = window.innerHeight - domRect.top;
    } else {
      this.gridHeight = 0;
    }
  }

  private scrollToSelectedRow() {
    // Scroll if any selected
    if (document.querySelector('.k-state-selected')) {
      setTimeout(() => document.querySelector('.k-state-selected')?.scrollIntoView());
    }
  }

  private scroll = (): void => {
    // if is editing amd invalid, then stay
    if (this.fieldList.isEditingCell() && !this.formGroup.valid) {
      this.scrollToSelectedRow();
    }
  };

  private observableThrowGenericErrorFields = (error: HttpErrorResponse): any => {
    throw new Error(error.message);
  };

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.isReadOnly) {
      return { disabledRow: true };
    } else {
      return {};
    }
  };
}
