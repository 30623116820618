import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'app-field-number-input',
  templateUrl: './field-number-input.component.html',
  styleUrls: ['./field-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldNumberInputComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class FieldNumberInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public set value(fieldNo: string) {
    this._value = fieldNo;
  }

  public leftFormControl = new UntypedFormControl(null, [
    Validators.maxLength(3),
    Validators.required,
    Validators.pattern(/^\d+$/),
    Validators.min(1),
  ]);

  public rightFormControl = new UntypedFormControl(null, [Validators.maxLength(3), Validators.required, Validators.pattern(/^\d+$/)]);

  public changes = combineLatest([this.leftFormControl.valueChanges, this.rightFormControl.valueChanges]);

  private _value = '0-0';
  public disabled = false;
  private subscriptions = new Subscription();

  public touched = this.leftFormControl.touched || this.rightFormControl.touched;

  constructor() {}

  public onChange: any = (_: any) => {};
  public onTouched: any = (_: any) => {};

  public ngOnInit() {
    this.subscriptions.add(this.changes.subscribe((value) => this.onChange(`${value[0]}-${value[1]}`)));
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public validateAndSetValue(fieldNo: string) {
    const isValidFieldNo = new RegExp(/^([1-9]|[1-9][0-9]{1,2})[-+]([0-9]{1,2})$/).test(fieldNo);

    if (!isValidFieldNo) {
      this.leftFormControl.setValue(null);
      this.rightFormControl.setValue(null);
      return;
    }

    const numbers = fieldNo.split('-');

    this.leftFormControl.setValue(parseInt(numbers[0], 10));
    this.rightFormControl.setValue(parseInt(numbers[1], 10));
  }

  public onBlur() {
    this.onTouched();
  }

  /**
   * Triggered when the parent component sets a new value.
   * @param fieldNo Field number in the format xxx-xxx
   */
  public writeValue(fieldNo: string): void {
    this.validateAndSetValue(fieldNo);
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.leftFormControl.disable();
      this.rightFormControl.disable();
    } else {
      this.leftFormControl.enable();
      this.rightFormControl.enable();
    }
  }
}
