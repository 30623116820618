import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ScreenSize } from '@app/core/enums/screen-size.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { filterNullish } from '@app/shared/operators';
import { WindowStateService } from '@app/state/services/window/window-state.service';
import { CategoryAxis, SeriesMarkers, ValueAxis } from '@progress/kendo-angular-charts';
import { Group } from '@progress/kendo-drawing';
import { DateTime } from 'luxon';
import { Observable, ReplaySubject, Subject, Subscription, combineLatest, of } from 'rxjs';
import { catchError, filter, finalize } from 'rxjs/operators';
import { CornHarvestPrognosisSettings } from '../repository/corn-harvest-prognosis-settings.class';
import { CornHarvestPrognosis } from '../repository/corn-harvest-prognosis.interface';
import { CornHarvestPrognosisChartService } from './corn-harvest-prognosis-chart.service';
import { CornHarvestPrognosisSettingsComponent } from './corn-harvest-prognosis-settings/corn-harvest-prognosis-settings.component';

@Component({
  selector: 'app-corn-harvest-prognosis-chart',
  templateUrl: './corn-harvest-prognosis-chart.component.html',
  styleUrls: ['./corn-harvest-prognosis-chart.component.scss'],
  providers: [CornHarvestPrognosisChartService],
  standalone: false,
})
export class CornHarvestPrognosisChartComponent implements OnInit, OnChanges, OnDestroy {
  private screenSize$!: Observable<ScreenSize>;

  @Input() public field!: Field;

  public isLoading = false;
  public field$ = new ReplaySubject<Field>();
  public prognosis$ = new Subject<CornHarvestPrognosis>();
  public prognosisTitle!: string;
  public prognosisSeries!: string[];
  public categoryAxis: CategoryAxis = {
    categories: [],
    visible: true,
    baseUnit: 'days',
    type: 'date',
    labels: {
      dateFormats: {
        days: LanguageConstants.getDateFormat(this.languageService.currentLanguage.shortKey).l,
      },
      step: 10,
      rotation: 45,
      position: 'onAxis',
    },
  };
  public valueAxis: ValueAxis = {
    min: 20,
    max: 40,
    title: {
      text: '',
    },
    labels: {
      format: '{0}%',
      position: 'onAxis',
    },
    plotBands: [],
  };
  public tooltip!: { x: Date; y: number };
  public tooltipUnit!: string;
  public firstHarvestDate!: DateTime;
  public prognosisMarkers: SeriesMarkers = {
    visible: false,
  };
  public errorMessages: string[] = [];

  private settings = new CornHarvestPrognosisSettings();
  private prognosis: CornHarvestPrognosis = null as any;
  private dataSubscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private cornHarvestPrognosisChartService: CornHarvestPrognosisChartService,
    private sideDrawerService: SideDrawerOverlayService,
    private languageService: LanguageService,
    private windowStateService: WindowStateService
  ) {}

  public ngOnInit() {
    this.screenSize$ = this.windowStateService.screenSize$;
    const prognosisSubscription = combineLatest([this.prognosis$, this.screenSize$]).subscribe(([prognosis, screenSize]) =>
      this.handlePrognosisChange(prognosis, screenSize)
    );

    this.getAndSetPrognosisAndSettings();

    this.subscriptions.add(prognosisSubscription);
    this.subscriptions.add(this.dataSubscription);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['field'] && changes['field'].currentValue) {
      this.field$.next(changes['field'].currentValue);
    }
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public toggleInfo() {
    this.cornHarvestPrognosisChartService.showInfoDialog(this.prognosis);
  }

  public onSettingsClick() {
    this.openSettings()
      .onClose.pipe(
        filter((settings) => !!settings),
        filter((settings) => !settings.equals(this.settings))
      )
      .subscribe((settings: CornHarvestPrognosisSettings) => this.onInfoDialogClosed(settings));
  }

  public onRender(e: any) {
    const chartElement = e.sender.getPlotArea().backgroundVisual.chartElement;
    const totalMin = chartElement.categoryAxis.dataRange.displayStart ? chartElement.categoryAxis.dataRange.displayStart.getTime() : null;
    const totalMax = chartElement.categoryAxis.dataRange.displayEnd ? chartElement.categoryAxis.dataRange.displayEnd.getTime() : null;
    const group = new Group();

    if (this.firstHarvestDate && chartElement.categoryAxis.dataRange.displayStart) {
      const harvestDateLine = this.cornHarvestPrognosisChartService.createLine(this.firstHarvestDate, chartElement, totalMin, totalMax);
      const harvestDateLabel = this.cornHarvestPrognosisChartService.createLineLabel(
        harvestDateLine,
        this.firstHarvestDate,
        this.prognosis
      );
      group.append(harvestDateLine, harvestDateLabel);
    }

    e.sender.findPaneByIndex(0).visual.insert(0, group);
  }

  private openSettings() {
    return this.sideDrawerService.openCustomSideDrawer<
      CornHarvestPrognosisSettingsComponent,
      CornHarvestPrognosisSettings,
      CornHarvestPrognosisSettings
    >(CornHarvestPrognosisSettingsComponent, {
      panelClass: 'corn-harvest-prognosis-side-drawer',
      data: this.settings,
      width: '300px',
      maxWidth: '100vw',
    });
  }

  private onInfoDialogClosed(settings: CornHarvestPrognosisSettings) {
    this.settings = settings;
    this.getPrognosis();
  }

  private handlePrognosisChange(prognosis: CornHarvestPrognosis, screenSize: ScreenSize) {
    if (!prognosis || !prognosis.harvestPrognosis || !prognosis.harvestPrognosis.length) {
      this.isLoading = false;
      return;
    }

    this.prognosis = prognosis;

    this.tooltipUnit = this.cornHarvestPrognosisChartService.findTooltipTitle(prognosis);

    const { yAxis, xAxis } = this.cornHarvestPrognosisChartService.findAxesData(prognosis)!;

    this.firstHarvestDate = DateTime.fromISO(this.cornHarvestPrognosisChartService.findFirstHarvestDate(prognosis));

    this.valueAxis.plotBands = this.cornHarvestPrognosisChartService.findYAxisPlotBands(prognosis);

    this.valueAxis.max = this.cornHarvestPrognosisChartService.findYAxisMaxValue(prognosis.dryMatterRanges);
    this.valueAxis.min = this.cornHarvestPrognosisChartService.findYAxisMinValue(prognosis.dryMatterRanges);

    this.valueAxis.title!.text = this.cornHarvestPrognosisChartService.findYAxisTitle(prognosis);
    this.prognosisTitle = this.languageService.getText(`cornHarvestPrognosis.prognosisTitle.${prognosis.directorateCropNumber}`);
    this.prognosisSeries = yAxis;
    this.categoryAxis.categories = xAxis;
    this.categoryAxis = this.cornHarvestPrognosisChartService.findXAxisSteps(screenSize, xAxis, this.categoryAxis);

    this.isLoading = false;
  }

  private getAndSetPrognosisAndSettings() {
    this.dataSubscription.unsubscribe();

    // @ts-ignore - TS2339 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    // @ts-ignore - TS2339 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.dataSubscription = this.getPrognosisAndSettings().subscribe(({ prognosis, settings }) => {
      this.settings = settings;
      if (prognosis) this.prognosis$.next(prognosis);
    });
  }

  private getPrognosisAndSettings() {
    this.isLoading = true;

    return this.cornHarvestPrognosisChartService
      .getPrognosisAndSettings(this.field.farmId, this.field.harvestYear, this.field.multiYearId)
      .pipe(this.cleanUp(), filterNullish());
  }

  private getPrognosis() {
    this.isLoading = true;

    this.cornHarvestPrognosisChartService
      .getPrognosis(this.field.farmId, this.field.harvestYear, this.field.multiYearId, this.settings)
      .pipe(this.cleanUp())
      .subscribe((prognosis) => this.prognosis$.next(prognosis!));
  }

  private handlePrognosisError(err: string[]) {
    this.errorMessages = err;

    this.isLoading = false;
    return of(undefined);
  }

  private cleanUp =
    () =>
    <T>(source: Observable<T>) => {
      return source.pipe(
        catchError((error) => this.handlePrognosisError(error)),
        finalize(() => (this.isLoading = false))
      );
    };
}
