import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IWindow } from '@app/core/interfaces/window.interface';
import { WindowRefService } from '@app/core/window/window-ref.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { isEqual } from 'lodash-es';
import { Subscription, combineLatest, filter, first, map, of, switchMap } from 'rxjs';
import { PriceModels } from '../price-model-ids.enum';
import { ProductInfoDialogComponent } from '../product-info-dialog/product-info-dialog.component';
import { SubscriptionsDialogGenericComponent } from '../subscriptions-dialog-confirm/subscriptions-dialog-generic.component';
import { SubscriptionProduct, SubscriptionProducts } from '../subscriptions.interface';
import { SubscriptionsService } from '../subscriptions.service';
import { TermsDialogComponent } from '../terms-dialog/terms-dialog.component';

@Component({
  selector: 'app-subscriptions-dialog',
  templateUrl: './subscriptions-dialog.component.html',
  styleUrls: ['./subscriptions-dialog.component.scss'],
  standalone: false,
})
export class SubscriptionsDialogComponent implements OnInit, OnDestroy {
  public window: IWindow;
  // public products?: SubscriptionProducts;
  public totalPrice = 0;
  public basicAndPremiumStartFee = 1350;
  public priceModels = PriceModels;
  public hasAcceptedTerms = false;

  private readonly freeProductModuleId = 0;
  private readonly premiumProductModuleId = 15;
  private readonly dlbrSubscriberProductModuleId = 46;
  protected readonly alertPackProductModuleId = 45;
  // get the subscriptions of the user from the API
  protected isDlbrSubcribed$ = this.subscriptionsService.getSubscriptions().pipe(
    // the response contains the list of extras the user has bought
    map((res) =>
      res.extras.some((extra) => {
        // check if the user has bought the DLBR extra
        return extra.productModule === this.dlbrSubscriberProductModuleId && extra.bought;
      })
    )
  );

  public products$ = this.prepareProducts();

  protected totalPrice$ = this.products$.pipe(
    map((products) => {
      return this.calculateTotal(products);
    })
  );

  protected basicAndPremiumStartFee$ = this.products$.pipe(
    map((products) => this.subscriptionsService.getBasicAndPremiumStartFee(products))
  );

  protected totalHa$ = this.products$.pipe(
    map((products) => {
      return products?.cropManager.reduce((cur, _prev) => cur).areaHa || 0;
    })
  );

  protected isDlbrSubcribed = false;

  private initProducts?: SubscriptionProducts;
  private subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<SubscriptionsDialogComponent>,
    private subscriptionsService: SubscriptionsService,
    private dialogService: DialogService,
    private windowRefService: WindowRefService
  ) {
    this.window = this.windowRefService.nativeWindow;
  }

  private prepareProducts() {
    return combineLatest([this.isDlbrSubcribed$, this.subscriptionsService.getSubscriptions()]).pipe(
      map(([isDlbrSubcribed, subscriptions]) => {
        // If the user has subscription from a DLBR consulting business then we change the crop manager subscription to premium
        // and we set all packs as bought
        if (isDlbrSubcribed) {
          // Update the bought status of all if DLBR subscribed
          this.isDlbrSubcribed = true;
          subscriptions.extras.forEach((extra) => (extra.bought = true));
          subscriptions.cropManager.forEach((cropManager) => {
            cropManager.productModule === this.premiumProductModuleId ? (cropManager.bought = true) : (cropManager.bought = false);
          });
        } else {
          // don´t show 'CropManager adgang for tilmeldte DLBR virksomheder'/'CropManager access for registered DLBR companies'
          subscriptions.extras = subscriptions.extras.filter((extra) => extra.productModule !== this.dlbrSubscriberProductModuleId);
          this.initProducts = structuredClone(subscriptions);
        }

        return subscriptions;
      })
    );
  }

  public ngOnInit() {
    this.dialogRef.backdropClick().subscribe(() => this.tryToClose()),
      this.dialogRef.keydownEvents().subscribe((event) => {
        if (event.code === 'Escape') {
          this.tryToClose();
        }
      });
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public close() {
    this.tryToClose();
  }

  public onConfirmClicked() {
    if (!this.hasAcceptedTerms) return;

    this.products$.pipe(first()).subscribe((products) => {
      if (isEqual(products, this.initProducts)) {
        this.dialogRef.close();
        return;
      }

      const dialog = this.createDialog('updateSubscription', null, 'yesAccept', 'noCancel', true);

      dialog
        .afterClosed()
        .pipe(
          filter((shouldUpdateSubscriptions: boolean) => shouldUpdateSubscriptions),
          switchMap(() => this.products$)
        )
        .subscribe((products) => {
          this.subscriptionsService.updateSubscriptions(products)?.subscribe((_) => {
            // TODO: add gtag event

            const dialog2 = this.createDialog('successSubscription', 'successBody', 'ok', null);

            dialog2.afterClosed().subscribe(() => {
              setTimeout(() => this.window.location.reload());
            });
          });
        });
    });
  }

  public onInfoClicked(product: SubscriptionProduct, $event: Event) {
    $event.stopPropagation();

    this.dialogService.openCustomDialog(ProductInfoDialogComponent, {
      data: product.info,
    });
  }

  public changeSubscriptionLevel(id: string) {
    if (this.isDlbrSubcribed) return;

    this.products$ = this.products$.pipe(
      map((products) => {
        products.cropManager.forEach((prod) => {
          prod.bought = prod.id === id;
          // If the user has selected the free subscription, we need to set the alertPack to false
          if (prod.productModule === this.freeProductModuleId && prod.bought) {
            // Find the alertPack product in the list of extras
            const alertPack = products.extras.filter((e) => e.productModule === this.alertPackProductModuleId);

            // If we find it, set its bought property to false becasuse the free subscription doesn't include the alertPack
            if (alertPack.length > 0) {
              alertPack[0].bought = false;
            }
          }
        });
        this.totalPrice$ = of(this.calculateTotal(products));
        return products;
      })
    );
  }

  public toggleExtraSubscription(id: string, $event: Event) {
    $event.stopPropagation();
    if (this.isDlbrSubcribed) return;

    this.products$ = this.products$.pipe(
      map((products) => {
        const extra = products?.extras.find((e) => e.id === id);
        if (extra) extra.bought = !extra.bought;
        this.totalPrice$ = of(this.calculateTotal(products));
        return products;
      })
    );
  }

  public openTerms() {
    const dialogRef = this.dialogService.openCustomDialog(TermsDialogComponent, {
      panelClass: ['terms-dialog', 'terms-dialog-backdrop'],
    });

    return dialogRef;
  }

  public onTermsChange(value: boolean) {
    this.hasAcceptedTerms = value;
  }

  private calculateTotal(products: SubscriptionProducts) {
    const cropManagerPrice =
      products?.cropManager
        .filter((extra) => extra.priceModel === PriceModels.AreaBased || extra.priceModel === PriceModels.AreaBased2)
        .reduce((count, el) => {
          return el.bought ? count + el.subscriptionPrice : count;
        }, 0) ?? 0;

    const extraPrice =
      products?.extras
        ?.filter((extra) => extra.priceModel === PriceModels.AreaBased || extra.priceModel === PriceModels.AreaBased2)
        .reduce((count, el) => {
          return el.bought ? count + el.subscriptionPrice : count;
        }, 0) ?? 0;

    return cropManagerPrice + extraPrice;
  }

  private createDialog(title: string, body: string | null, ok: string, cancel: string | null, disableClose = false) {
    const dialogRef = this.dialogService.openCustomDialog(SubscriptionsDialogGenericComponent, {
      data: {
        title: 'subscriptions.' + title,
        body: body ? 'subscriptions.' + body : null,
        ok: 'subscriptions.' + ok,
        cancel: cancel ? 'subscriptions.' + cancel : null,
      },
      panelClass: ['subscriptions-generic-dialog', 'subscriptions-generic-dialog-backdrop'],
      disableClose: disableClose,
      minWidth: '30vw',
    });

    return dialogRef;
  }

  private tryToClose() {
    this.products$.pipe(first()).subscribe((products) => {
      if (isEqual(products, this.initProducts)) {
        this.dialogRef.close();
        return;
      }

      const dialog = this.createDialog('cancelChanges', null, 'yesCancel', 'cancelCancel', true);

      dialog
        .afterClosed()
        .pipe(filter((cancelAccepted: boolean) => cancelAccepted))
        .subscribe(() => {
          this.dialogRef.close();
        });
    });
  }

  private findNewPuchases(products: SubscriptionProducts) {
    const newPurchases = { cropManager: [] as SubscriptionProduct[], extras: [] as SubscriptionProduct[] };

    products?.cropManager.forEach((product) => {
      const initProduct = this.initProducts?.cropManager.find((p) => p.id === product.id);

      if (initProduct && product.bought && !initProduct.bought) {
        newPurchases.cropManager.push(product);
      }
    });

    products?.extras.forEach((product) => {
      const initProduct = this.initProducts?.extras.find((p) => p.id === product.id);

      if (initProduct && product.bought && !initProduct.bought) {
        newPurchases.extras.push(product);
      }
    });

    return newPurchases;
  }
}
