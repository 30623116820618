import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { fadeInNgIf } from '@app/core/animations/fade-in-ng-if.fn';
import { ActionDefinition } from '@app/shared/cards-or-table/interfaces/action-definition.interface';
import { ActionEvent } from '@app/shared/cards-or-table/interfaces/action-event.interface';
import { FieldDefinition } from '@app/shared/cards-or-table/interfaces/field-definition.interface';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { delay, first, map } from 'rxjs/operators';

export const BASE_CARD_HEIGHT = 160;
export const MAT_LIST_ITEM_HEIGHT = 43;

@Component({
  selector: 'app-cards-presentation',
  templateUrl: './cards-presentation.component.html',
  styleUrls: ['./cards-presentation.component.scss'],
  animations: [fadeInNgIf('reveal')],
  standalone: false,
})
export class CardsPresentationComponent implements AfterViewInit, OnDestroy {
  public cardTitleFieldProperty!: string;
  public fieldDefinitions$ = new BehaviorSubject<FieldDefinition<any>[]>([]);
  public actionDefinitions$ = new BehaviorSubject<ActionDefinition[]>([]);
  public data$ = new BehaviorSubject<any[]>([]);
  public maxHeight!: string;

  public loaded$ = combineLatest(this.fieldDefinitions$, this.data$, this.actionDefinitions$).pipe(
    first(),
    map(([fieldDefinitions, data, actionDefinitions]) => fieldDefinitions.length > 0 && data.length > 0 && actionDefinitions.length > 0)
  );

  @Output() public readonly action = new EventEmitter<ActionEvent<any>>();

  @Input()
  public set actionDefinitions(value: ActionDefinition[]) {
    this.actionDefinitions$.next(value);
  }

  @Input() public cardRef!: TemplateRef<any>;

  @Input()
  public set data(value: any[]) {
    this.data$.next(value);
  }

  @Input()
  public set fieldDefinitions(value: FieldDefinition<any>[]) {
    this.fieldDefinitions$.next(value);
  }

  private subscriptions: Subscription[] = [];

  constructor() {}

  public ngAfterViewInit() {
    this.subscriptions.push(
      this.fieldDefinitions$
        .pipe(
          delay(250),
          map((fieldDefinitions) => this.calculateMaximumHeight(fieldDefinitions))
        )
        .subscribe((minHeight) => (this.maxHeight = minHeight))
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public forwardActionEvent(event: ActionEvent<any>) {
    this.action.emit(event);
  }

  public calculateMaximumHeight(fieldDefinitions: FieldDefinition<any>[]): string {
    const lines = fieldDefinitions.filter(
      (fieldDefinition) =>
        !fieldDefinition.hide && !fieldDefinition.isCardTitle && !fieldDefinition.isCardSubTitle && !fieldDefinition.isCardAvatar
    );
    const height = BASE_CARD_HEIGHT + lines.length * MAT_LIST_ITEM_HEIGHT;

    return `${height}px`;
  }
}
