import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-john-deere-form',
  templateUrl: './john-deere-form.component.html',
  styleUrls: ['./john-deere-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class JohnDeereFormComponent {
  @Output() public linkClick = new EventEmitter<void>();

  public onLinkClick() {
    this.linkClick.emit();
  }
}
