import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { GrowthRegulationFieldTableVM, GrowthRegulationTableField } from '../growth-regulation-field-table-vm.interface';

@Component({
  selector: 'app-check-out-table',
  templateUrl: './check-out-table.component.html',
  styleUrls: ['./check-out-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CheckOutTableComponent implements OnInit {
  @Input() public growthRegulationFieldTableVM: GrowthRegulationFieldTableVM[] = [];
  public fields!: GrowthRegulationTableField[];
  public totalPrice!: number;
  public totalArea!: number;

  constructor() {}

  public ngOnInit() {
    this.fields = this.growthRegulationFieldTableVM
      .reduce((prev, cur) => [...prev, ...cur.table.fields], [] as GrowthRegulationTableField[])
      .filter((field) => field.selected && !field.registered);

    this.totalArea = this.fields.reduce((prev, curr) => prev + curr.area, 0);

    this.totalPrice = this.fields.reduce((prev, curr) => prev + curr.price, 0);
  }
}
