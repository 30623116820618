import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';

interface CheckableField {
  field: Field;
  checked$: BehaviorSubject<boolean>;
}

@Component({
  selector: 'app-export-fields-list',
  templateUrl: './export-fields-list.component.html',
  styleUrls: ['./export-fields-list.component.scss'],
  standalone: false,
})
export class ExportFieldsListComponent implements OnInit, OnDestroy {
  constructor() {}

  @Input() fields$: Observable<Field[]> = of([]);

  public checkableFields: CheckableField[] = [];
  private _subs = new SubscriptionArray();

  ngOnInit(): void {
    this._subs.add(
      this.fields$.subscribe((fields) => {
        this.checkableFields = fields.map((field) => ({
          field,
          checked$: new BehaviorSubject<boolean>(false),
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this.checkableFields.forEach((checkable) => checkable.checked$.unsubscribe());
    this._subs.unsubscribe();
  }

  public toggle(event: Event, toggled: CheckableField): void {
    const modifiedCheckbox = this.checkableFields.find((checkable) => checkable.field.id === toggled.field.id);

    modifiedCheckbox?.checked$.next((event.target as HTMLInputElement).checked);
  }

  public toggleAll(event: Event) {
    const chooseAllBoxIsChecked = (event.target as HTMLInputElement).checked;

    this.setAllCheckablesTo(chooseAllBoxIsChecked);
  }

  public setAllCheckablesTo(checked: boolean) {
    this.checkableFields.forEach((field) => field.field.geometry && field.checked$.next(checked));
  }
}
