import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BlightOverviewStateService } from '@app/map/features/cultivation-journal/blight/blight-overview-state.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { CellClickEvent, SelectableSettings, SelectionChangeEvent } from '@progress/kendo-angular-treelist';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ScopeItem } from '../../filter/scope-item';
import { ColumnSorter } from '../../n-tools/n-tools-table/util/ColumnSorter';
import { BlightTableSubLine, BlightTreatment } from '../models/blight-table-sub-line';
import { ColumnKeyBlight } from './../../filter/column/column-key-blight';
import { BlightTableInfoComponent } from './blight-table-info/blight-table-info.component';
import { BlightTableService } from './blight-table.service';
import { BlightTaskStatus } from './util/blight-task-status';

@Component({
  selector: 'app-blight-table',
  templateUrl: './blight-table.component.html',
  styleUrls: ['./blight-table.component.scss'],
  providers: [BlightTableService],
  standalone: false,
})
export class BlightTableComponent implements OnInit, OnDestroy {
  public readonly NO_DATA = '';
  public readonly COLUMN_WIDTH = 100;
  public SCOPE = ScopeItem.BlightTableComponent;
  public COLUMN_KEY = ColumnKeyBlight;
  public formGroup!: UntypedFormGroup;
  public selectedRow: number | null = null;
  public BLIGHT_TASK_STATUS = BlightTaskStatus;
  private taskDateHolder = null;
  private secondaryTask = false;

  public displayData$!: Observable<BlightTableSubLine[]>;
  public expandedIds$!: Observable<number[]>;

  public settings: SelectableSettings = {
    mode: 'row',
    multiple: false,
    drag: false,
    readonly: false,
    enabled: true,
  };

  constructor(
    private blightTableService: BlightTableService,
    private dialogService: DialogService,
    private blightOverviewStateService: BlightOverviewStateService
  ) {}

  public ngOnInit(): void {
    this.expandedIds$ = this.blightTableService.expandedIds$;
    this.displayData$ = this.blightTableService.tableData$;
    this.setInitialRow();
  }

  public ngOnDestroy(): void {
    this.blightOverviewStateService.clearSelectedFieldCrop();
  }

  private setInitialRow() {
    this.displayData$.pipe(first()).subscribe((displayData) => {
      const firstDisplayItem = displayData.find((d) => !!d);
      if (firstDisplayItem) {
        this.setSelectedRow(firstDisplayItem);
      }
    });
  }

  public sortChange(customColumnSorter: ColumnSorter<BlightTableSubLine>) {
    this.blightTableService.sortChange(customColumnSorter);
  }

  public onFilterChange(tableLine: BlightTableSubLine, columnKey: ColumnKeyBlight) {
    this.blightTableService.toggleFilterItem(tableLine, columnKey);
  }

  public getColumnSorterByKey(columnKey: ColumnKeyBlight) {
    return this.blightTableService.getColumnSorterByKey(columnKey);
  }

  public getAvailableFilterablesForColumn$(columnKey: ColumnKeyBlight) {
    return this.blightTableService.getAvailableFilterablesForColumn$(columnKey);
  }

  public rowCallback = (context: RowClassArgs) => {
    const hasChildren = !!context.dataItem?.treatments;
    let dateChange = null;
    if (!hasChildren) {
      dateChange = this.taskDateHolder !== context.dataItem?.date;
      this.taskDateHolder = context.dataItem?.date;
      if (dateChange) {
        this.secondaryTask = !this.secondaryTask;
      }
    }

    return {
      childRow1: !hasChildren && this.secondaryTask,
      childRow2: !hasChildren && !this.secondaryTask,
      parentRow: hasChildren,
    };
  };
  public cellClick({ sender, rowIndex, dataItem, isEdited, columnIndex }: CellClickEvent) {
    // When a parent is clicked, the selection should change instead of cell editing.
    // If editing on parent cells needs to be implemented, this is the place.
    if (dataItem instanceof BlightTableSubLine) {
      this.SelectionChange(dataItem);
    }
  }
  public hasChildren = (item: BlightTableSubLine | BlightTreatment): boolean => {
    return item instanceof BlightTableSubLine;
  };

  public fetchChilren = (item: BlightTableSubLine): BlightTreatment[] => {
    return item.treatments;
  };

  public isSelected = (dataItem: BlightTableSubLine | BlightTreatment) => {
    if (dataItem instanceof BlightTableSubLine) {
      return dataItem.id === this.selectedRow;
    }
    return false;
  };

  public onSelectionChange($event: SelectionChangeEvent) {
    if ($event.action === 'select') {
      this.SelectionChange($event?.items[0].dataItem);
    }
  }

  public isExpanded$(dataItem: BlightTableSubLine | BlightTreatment): Observable<boolean> {
    if (dataItem instanceof BlightTableSubLine) {
      return this.expandedIds$.pipe(map((expandedIds) => expandedIds.includes(dataItem.id)));
    }
    return of(false);
  }

  public onExpandedIdsChange() {
    this.expandedIds$.pipe(first()).subscribe((expandedIds) => {
      if (expandedIds.length > 1) {
        this.blightTableService.expandedIds = expandedIds.slice(-1);
      }
    });
  }

  public onBlightTableInfoClick($event: MouseEvent) {
    this.dialogService.openMd(BlightTableInfoComponent);
    $event.stopPropagation();
  }

  private SelectionChange(dataItem: BlightTableSubLine) {
    this.expandedIds$.pipe(first()).subscribe((expandedIds) => {
      // Selection change is only used if the given dataItem is a NToolsTableLine
      if (dataItem instanceof BlightTableSubLine) {
        // When the selected row is clicked, it is expanded if collapsed, and collapsed if expanded
        if (dataItem.id === this.selectedRow) {
          this.blightTableService.expandedIds = expandedIds.length === 0 ? [this.selectedRow] : [];
        } else {
          // When a new row is clicked, it is selected and expanded
          this.setSelectedRow(dataItem);
        }
      }
    });
  }

  private setSelectedRow(dataItem: BlightTableSubLine) {
    this.selectedRow = dataItem.id;
    this.blightTableService.expandedIds = [this.selectedRow];
    this.blightOverviewStateService.selectedFieldCrop = { fieldId: dataItem.id, cropId: dataItem.cropId, farmId: dataItem.farmId };
  }
}
