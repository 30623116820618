import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { catchError, Observable, of } from 'rxjs';
import { FieldEntryDto } from '../interfaces/field-entry';

@Injectable({
  providedIn: 'root',
})
export class FieldEntryRepositoryService {
  constructor(
    private http: HttpClient,
    private endpoints: EndpointsService,
    private notificationService: NotificationService
  ) {}

  public getFieldEntries(farmIds: number[], harvestYear: number, language: string): Observable<FieldEntryDto[]> {
    const url = `${this.endpoints.cmApi}/FieldEntry?farmIds=${farmIds.join(',')}&harvestYear=${harvestYear}&language=${language}`;

    return this.http.get<FieldEntryDto[]>(url).pipe(
      catchError((error) => {
        this.notificationService.showError(`Error fetching field entries`);
        return of([]);
      })
    );
  }
}
