import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Unit } from '@app/core/interfaces/unit.type';
import { LanguageService } from '@app/core/language/language.service';
import { DecimalService } from '../decimal-separator/decimal.service';

@Directive({
  selector: '[appDecimalSeparator]',
  standalone: false,
})
export class InputFormatDirective implements OnInit {
  @Input('appDecimalSeparator') decimalPlaces: string | number | null = null; // Default to 2 decimal places if null and no unit
  @Input() decimalSeparatorOverride: string | null = null;
  @Input() maxLength: number | null = 7;
  @Input() unit?: Unit | null; // Optional unit to determine decimal places, overrides decimalPlaces

  constructor(
    private el: ElementRef,
    private control: NgControl,
    private languageService: LanguageService,
    private decimalService: DecimalService
  ) {}

  ngOnInit() {
    this.transform(this.el.nativeElement.value); // Initial transformation
  }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    this.transform(this.el.nativeElement.value);
  }

  private setDecimalPlaces() {
    if (this.unit) {
      // remove trailing , . and spaces
      const cleanValue = this.el.nativeElement.value.replace(/[,.\s]+$/, '');
      this.decimalPlaces = this.decimalService.getNumberOfDecimals(cleanValue, this.unit) ?? 2;
    }
    this.decimalPlaces = this.parseDecimalPlaces(this.decimalPlaces);

    if (this.decimalPlaces === null || this.decimalPlaces === undefined) {
      this.decimalPlaces = 2; // Fallback value if not set
    }
  }

  public transform(value: string) {
    this.setDecimalPlaces();
    // Check if the input value is empty
    if (value === '' || value === null || value === undefined) {
      this.control.control?.setValue('', { emitEvent: true });
      return;
    }

    // Allow digits, minus sign, periods, and commas
    let cleanValue = value.replace(/[^0-9.,-]/g, '').replace(',', '.');

    // Allow only one minus sign at the start
    if (cleanValue.startsWith('-')) {
      cleanValue = '-' + cleanValue.replace(/-/g, '');
    }

    // Early return if value is not a number and not just a minus sign
    if (isNaN(parseFloat(cleanValue)) && cleanValue !== '-') {
      this.control.control?.setValue(cleanValue, { emitEvent: true });
      return;
    }

    let parts = cleanValue.split('.');

    // If there are multiple dots, keep the first one
    if (parts.length > 2) {
      cleanValue = parts.shift() + '.' + parts.join('');
    }

    // Limit decimal places based on the input
    if (parts.length === 2 && parts[1].length > (this.decimalPlaces as number)) {
      parts[1] = parts[1].substring(0, this.decimalPlaces as number);
      cleanValue = parts.join('.');
    }

    // Handle negative numbers (allow only one minus sign at the start)
    if (cleanValue.includes('-')) {
      cleanValue = '-' + cleanValue.replace(/-/g, '');
    }

    // Handle the case where the input is just a minus sign
    if (cleanValue === '-') {
      this.control.control?.setValue(cleanValue, { emitEvent: true });
      this.el.nativeElement.value = cleanValue;
      return;
    }

    // Determine decimal separator based on language setting
    let decimalSeparator = this.decimalSeparatorOverride;
    if (!decimalSeparator) {
      decimalSeparator = this.languageService.currentLanguage.shortKey === 'en' ? '.' : ',';
    }

    // Enforce maximum length (excluding decimal point and minus sign)
    if (this.maxLength !== null) {
      let nonDecimalLength = cleanValue.replace(/[-.]/g, '').length;
      if (nonDecimalLength > this.maxLength) {
        cleanValue = this.truncateToMaxLength(cleanValue, this.maxLength);
      }
    }

    // If decimalPlaces is set to 0, restrict to whole numbers only, allowing negative numbers
    if (this.decimalPlaces === 0) {
      cleanValue = cleanValue.replace(/[^\d-]/g, '');
      cleanValue = cleanValue.replace(/[.,]/g, ''); // Remove any commas or periods
    }

    // Update the native element and control value
    const transformedValue = cleanValue.replace('.', decimalSeparator);
    this.el.nativeElement.value = transformedValue;
    this.control.control?.setValue(transformedValue, { emitEvent: false });
  }

  private truncateToMaxLength(value: string, maxLength: number): string {
    let isNegative = value.startsWith('-');
    let parts = value.split('.');
    let integerPart = isNegative ? parts[0].substring(1) : parts[0];
    let decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    // Truncate the integer part to the maxLength
    integerPart = integerPart.substring(0, maxLength);

    // Reassemble the value
    return (isNegative ? '-' : '') + integerPart + decimalPart;
  }

  private parseDecimalPlaces(value: string | number | null): number {
    if (value === null || value === undefined) {
      return 2; // Default fallback value
    }

    if (typeof value === 'string') {
      value = parseInt(value, 10); // Parse string input
    }

    // Check for NaN and ensure 0 is a valid return value
    if (isNaN(value)) {
      return 2; // Default fallback value
    }

    return value;
  }
}
