import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataConnectionsService } from '@app/core/data-connections/data-connections.service';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { filterNullOrEmpty } from '@app/shared/operators';
import { first } from 'rxjs';

@Component({
  selector: 'app-select-jd-organization-dialog',
  templateUrl: './select-jd-organization-dialog.component.html',
  styleUrl: './select-jd-organization-dialog.component.scss',
  standalone: false,
})
export class SelectJdOrganizationDialogComponent implements OnInit {
  public organizations: JdOrganization[] = [];
  public loading = false;

  public form: FormGroup<OrganizationFormData> = this.formBuilder.group({
    jdOrganization: new FormControl<JdOrganization | null>(null, Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataConnectionSettingDTO,
    private dialogRef: MatDialogRef<SelectJdOrganizationDialogComponent>,
    private dataConnectionsService: DataConnectionsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.dataConnectionsService
      .getJdOrganizations()
      .pipe(filterNullOrEmpty(), first())
      .subscribe((organizations) => {
        this.organizations = organizations;
        this.form.controls.jdOrganization.setValue(organizations[0]);
      });
  }

  public save() {
    const id = this.form?.controls.jdOrganization.value?.id;
    if (!id) return;

    this.loading = true;
    this.form.controls.jdOrganization.disable();

    this.dataConnectionsService
      .saveJdOrganization(id)
      .pipe(first())
      .subscribe((result) => {
        this.loading = false;
        this.form.controls.jdOrganization.enable();
        if (result) {
          this.dialogRef.close();
          this.dataConnectionsService.refreshBehaviourSubject.next(true);
        }
      });
  }
}

export interface JdOrganization {
  id: number;
  name: string;
}

export interface OrganizationFormData {
  jdOrganization: FormControl<JdOrganization | null>;
}
