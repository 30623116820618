import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Month } from '@app/core/enums/month.enum';
import { Farm } from '@app/core/interfaces/farm.interface';
import { HotspotSubType } from '@app/core/interfaces/hotspot-sub-type-interface';
import { HotspotType } from '@app/core/interfaces/hotspot-type-interface';
import { HotspotDto } from '@app/core/interfaces/hotspot.interface';
import { ImageItem } from '@app/core/interfaces/image-item.interface';
import { DateValidators } from '@app/helpers/validators/forms/date-validators';
import { ImageSliderComponent } from '@app/shared/image-slider/image-slider.component';
import { isNullOrUndefined } from '@app/shared/utils/utils';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subscription } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, finalize, take } from 'rxjs/operators';
import { HotspotsService } from '../../../hotspots.service';
import { HotspotGroupsService } from '../../hotspot-groups/hotspot-groups.service';
import { HotspotGroupWithRelations } from '../../interfaces/hotspot-group.interface';
import { NotificationRanges } from '../notification-ranges';
import { HotspotDetailsPresentationService } from './hotspot-details-presentation.service';

export interface SaveHotspotEvent {
  hotspot: HotspotDto;
  hotspotGroups: HotspotGroupWithRelations[] | undefined;
  imagesToBeDeleted: ImageItem[];
  imagesToBeSaved: File[];
}

const validImageTypes = ['image/jpg', 'image/png', 'image/jpeg'];

@Component({
  selector: 'app-hotspot-details-presentation',
  templateUrl: './hotspot-details-presentation.component.html',
  styleUrls: ['./hotspot-details-presentation.component.scss'],
  standalone: false,
})
export class HotspotDetailsPresentationComponent implements OnDestroy, OnInit {
  public hotspotGroupOptions?: HotspotGroupWithRelations[];
  public selectedFarms$ = new BehaviorSubject<Farm[]>([]);
  public today = DateTime.now();
  private _selectedFarms: Farm[] = [];
  public get selectedFarms(): Farm[] {
    return this._selectedFarms;
  }
  @Input()
  public set selectedFarms(farms: Farm[]) {
    this._selectedFarms = farms;

    this.selectedFarms$.next(farms);
  }

  private _hotspot!: HotspotDto;
  public get hotspot(): HotspotDto {
    return this._hotspot;
  }
  @Input()
  public set hotspot(hSpot: HotspotDto) {
    if (!hSpot) {
      this._hotspot = hSpot;
      this.doCleanup();
      return;
    }
    this._hotspot = hSpot;
    this.onHotspotChange(hSpot);
  }

  @Input() public hotspotTypes: HotspotType[] = [];
  @Input() public hotspotSubTypes: HotspotSubType[] = [];

  @Output() public delete = new EventEmitter<HotspotDto>();
  @Output() public save = new EventEmitter<SaveHotspotEvent>();

  @ViewChild('fileUploadInput', { static: false }) public fileUploadInput?: ElementRef;
  @ViewChild('imageSlider', { static: false }) public imageSlider?: ImageSliderComponent;

  private subscriptions: Subscription[] = [];
  // for images
  private originalImages: ImageItem[] = [];
  private imagesToBeDeleted: ImageItem[] = [];
  private imagesToBeSaved: File[] = [];
  private newImagesMap: Map<string, File> = new Map();
  public imageUrls: string[] = [];
  public fileUploadError = false;

  public loading = true;
  public imagesLoading?: boolean;
  public selectedHotspotType?: HotspotType;
  public selectedGroups?: HotspotGroupWithRelations[];

  public hotspotFormGroup = new UntypedFormGroup({
    hotspotTypeId: this.formBuilder.control(null),
    description: this.formBuilder.control(null, Validators.maxLength(999)),
    imageUrls: this.formBuilder.control([]),
    farmId: this.formBuilder.control(null),
    registeredDate: this.formBuilder.control(null, [
      Validators.required,
      DateValidators.maxDate(this.today),
      DateValidators.minDate(DateTime.fromObject({ year: 1900, month: Month.January, day: 1 })),
    ]),
    hotspotGroups: this.formBuilder.control([]),
    notificationRangeType: this.formBuilder.control(null),
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private hotspotDetailsPresentationService: HotspotDetailsPresentationService,
    private hotspotsMapSideDrawerService: HotspotsService,
    private hotspotGroupsService: HotspotGroupsService
  ) {}

  public ngOnInit() {
    this.hotspotSubTypes = this.hotspotSubTypes.filter((t) => t.hotspotTypeId === this._hotspot.hotspotTypeId);

    this.subscriptions = [
      ...this.subscriptions,
      this.hotspotFormGroup.valueChanges
        .pipe(
          filter(() => !!this.hotspot),
          distinctUntilChanged()
        )
        .subscribe(() => {
          setTimeout(() => {
            if (this.hotspotFormGroup.dirty) {
              this.hotspotsMapSideDrawerService.isDirty = true;
            }
          });
        }),

      this.farmIdControl!.valueChanges.pipe(filter((v) => !isNullOrUndefined(v) && !!this.hotspot)).subscribe((value) => {
        if (this.hotspot.id) this.populateHotspotGroups(value, this.hotspot.id);
      }),

      this.hotspotGroupsControl!.valueChanges.pipe(filter((v) => !isNullOrUndefined(v) && !!this.hotspot)).subscribe(
        (selectedGroups: HotspotGroupWithRelations[]) => {
          this.selectedGroups = selectedGroups;
        }
      ),
    ];
  }

  public ngOnDestroy() {
    this.doCleanup();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public onHotspotChange(hotspot: HotspotDto) {
    this.reloadFormGroup(hotspot);
    this.initHotspotImages();
  }

  private initHotspotImages(): void {
    this.imagesLoading = true;
    this.imageUrls = [];
    this.originalImages = [];
    this.imagesToBeDeleted = [];

    const imageUrls = this.hotspot.imageUrls;

    if (imageUrls)
      this.hotspotDetailsPresentationService
        .getImagesForUrls(imageUrls, 400)
        .pipe(
          concatMap((urls) => urls),
          finalize(() => (this.imagesLoading = false))
        )
        .subscribe((urls) => this.populateHotspotImages(urls.originalUrl, urls.sanitizedUrl));
  }

  private populateHotspotImages(originalUrl: string, sanitizedUrl: string) {
    const safeUrl = this.sanitizer.bypassSecurityTrustStyle(`url(${sanitizedUrl})`);
    this.imageUrls.push(safeUrl as string);

    this.originalImages.push({ originalUrl: originalUrl, sanitizedUrl: safeUrl });
    if (this.imageSlider) {
      this.imageSlider.gotoSlide(0);
    }
  }

  private populateHotspotGroups(farmId: number, hotspotId: number) {
    this.hotspotGroupsService
      .getGroups(farmId)
      .pipe(take(1))
      .subscribe((options) => {
        // options is not of type HotspotGroupWithRelations[] but it should be
        this.hotspotGroupOptions = options as unknown as HotspotGroupWithRelations[];
        const groups = this.hotspotGroupOptions.filter((hsg) => hsg.hotspotGroupRelations.map((r) => r.hotspotId).includes(hotspotId));
        if (groups && groups.length > 0) {
          setTimeout(() => {
            this.hotspotGroupsControl?.setValue(groups);
            this.hotspotFormGroup.markAsPristine();
          });
        }
      });
  }

  ///////////////////////////////// Event handlers /////////////////////////////////

  public onUploadPhotoClicked(): void {
    this.fileUploadInput?.nativeElement.click();
    this.hotspotFormGroup.markAsDirty();
    this.hotspotsMapSideDrawerService.isDirty = true;
  }

  public onPhotoFileInputChange($event: any) {
    if (!$event.target.files) {
      return;
    }

    for (const file of $event.target.files) {
      if (!validImageTypes.includes(file.type)) {
        this.fileUploadError = true;
        return;
      }
      this.fileUploadError = false;

      const reader = new FileReader();
      reader.onloadend = (e) => {
        const result = reader.result as string;

        this.imageUrls.push(`url(${result})`);
        this.imagesToBeSaved.push(file);

        this.newImagesMap.set(result, file);
        this.imageSlider?.gotoSlide(this.imageUrls.length - 1);
        if (this.fileUploadInput) this.fileUploadInput.nativeElement.value = null;
      };
      reader.readAsDataURL(file);
    }
  }

  public onDeleteImage(index: number) {
    if (this.imageUrls.length < index + 1) {
      return;
    }

    const urlToDelete = this.imageUrls[index];
    this.imageUrls.splice(index, 1);

    const img = this.originalImages.find((img) => img.sanitizedUrl === urlToDelete);

    if (img) this.imagesToBeDeleted.push(img);

    this.imagesToBeDeleted = this.imagesToBeDeleted.filter((img) => img !== undefined);
    this.imagesToBeSaved = this.imagesToBeSaved.filter((img) => img !== this.newImagesMap.get(urlToDelete));
    this.hotspotFormGroup.markAsDirty();
    this.newImagesMap.delete(urlToDelete);
  }

  public onDeleteClicked($event: any): void {
    this.delete.emit(this.hotspot);
  }

  public onHotspotSubTypeChange(hotspotSubTypeIds: number[]) {
    this.hotspot.hotspotSubTypeIds = hotspotSubTypeIds;
    this.hotspotFormGroup.markAsDirty();
    this.hotspotsMapSideDrawerService.isDirty = true;
  }

  // This has been recreated as an arrow function, because "this" is different in this context
  public saveClick = ($event: MouseEvent) => {
    if (!this.isFormValid) return;

    this.hotspot.farmId = this.farmIdControl?.value;
    this.hotspot.description = this.descriptionControl?.value;
    this.hotspot.hotspotTypeId = this.hotspotTypeIdControl?.value;
    this.hotspot.notificationRangeType = this.notificationRangeTypeControl?.value;
    this.hotspot.registeredDate = DateTime.fromISO(this.registeredDateControl?.value).setZone('utc').toISO();

    this.save.emit({
      hotspot: this.hotspot,
      hotspotGroups: this.selectedGroups,
      imagesToBeDeleted: this.imagesToBeDeleted,
      imagesToBeSaved: this.imagesToBeSaved,
    });
  };

  /**
   * Display function passed to the autocomplete input.
   */
  public autoCompleteDisplay(hotspotSubType: HotspotSubType) {
    return hotspotSubType ? hotspotSubType.name : '';
  }

  public get isNewHotspot() {
    return this.hotspot && !this.hotspot.id;
  }

  public getIsNewHotspot(hotspot: HotspotDto) {
    return hotspot && !hotspot.id;
  }

  public get isSubTypeAllowed() {
    return this.hotspotDetailsPresentationService.isSubTypeAllowed(this.hotspotTypeIdControl?.value);
  }

  public get isFormValid(): boolean {
    return this.hotspotFormGroup ? this.hotspotFormGroup.valid : false;
  }

  public get notificationRanges() {
    return NotificationRanges.ranges;
  }

  public get descriptionControl() {
    return this.hotspotFormGroup.get('description');
  }

  private get hotspotTypeIdControl() {
    return this.hotspotFormGroup.get('hotspotTypeId');
  }

  private get notificationRangeTypeControl() {
    return this.hotspotFormGroup.get('notificationRangeType');
  }

  private get farmIdControl() {
    return this.hotspotFormGroup.get('farmId');
  }

  public get registeredDateControl() {
    return this.hotspotFormGroup.get('registeredDate');
  }

  private get hotspotGroupsControl() {
    return this.hotspotFormGroup.get('hotspotGroups');
  }

  public trackByFn(index: any, item: any) {
    return index;
  }

  ///////////////////////////////// Populating view /////////////////////////////////

  private reloadFormGroup(hotspot: HotspotDto): void {
    this.hotspotFormGroup.reset();
    this.imageUrls = [];
    this.imagesToBeDeleted = [];
    this.imagesToBeSaved = [];

    this.hotspotFormGroup.setValue({
      description: hotspot.description ?? '',
      notificationRangeType: hotspot.notificationRangeType ?? null,
      hotspotTypeId: hotspot.hotspotTypeId,
      farmId: hotspot.farmId ?? -1, // Potentially a bug here. farmId er undefined på dette tidspunkt, men det skaber et runtime problem, at den er det.
      registeredDate: hotspot.registeredDate ?? this.today.setZone('utc').toISO(),
      hotspotGroups: hotspot.hotspotGroupRelations ?? [],
      imageUrls: hotspot.imageUrls,
    });

    this.selectedFarms$.pipe(take(1)).subscribe((farms: Farm[]) => {
      if (farms.length >= 1) {
        // hotspot.farmId is potentially undefined or -1 at this point. This is a probably bug.
        if (hotspot.farmId != null) {
          this.farmIdControl!.setValue(hotspot.farmId);
        } else {
          const selectedFarm = farms[0];
          this.farmIdControl!.setValue(selectedFarm.id);
        }
      }
      this.selectedFarms = farms;
      this.populateHotspotGroups(this.farmIdControl!.value, hotspot.id!);
    });

    this.hotspotFormGroup.markAsPristine();
  }

  private doCleanup(): void {
    this.hotspotFormGroup.reset();
    this.hotspotFormGroup.markAsPristine();
    this.imagesToBeDeleted = [];
    this.imagesToBeSaved = [];
    this.imageUrls = [];
    this.newImagesMap = new Map();
    this.hotspotsMapSideDrawerService.isDirty = false;
  }
}
