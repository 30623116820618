import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from '@app/shared/dialog/confirm-dialog-portal/confirm-dialog-data';

@Component({
  selector: 'app-confirm-dialog-portal',
  templateUrl: './confirm-dialog-portal.component.html',
  styleUrls: ['./confirm-dialog-portal.component.scss'],
  standalone: false,
})
export class ConfirmDialogPortalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogPortalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {}

  /**
   * Close the dialog
   */

  public close(val?: any): void {
    this.dialogRef.close(val);
  }

  /**
   * Function for handling "cancel" pressed in modal
   * @param  event from calling element
   */

  public onCancelClicked(ev: MouseEvent): void {
    this.close();
  }

  /**
   * Function for handling "OK" pressed in modal
   * @param  event from calling element
   */

  public onConfirmClicked(ev: MouseEvent): void {
    this.close(true);
  }
}
