import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-map-pdf-papersize-dialog',
  templateUrl: './map-pdf-papersize-dialog.component.html',
  styleUrls: ['./map-pdf-papersize-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MapPdfPapersizeDialogComponent {
  constructor(private dialogRef: MatDialogRef<MapPdfPapersizeDialogComponent>) {}

  public onSelectA4() {
    this.dialogRef.close(true);
  }

  public onSelectA3() {
    this.dialogRef.close(false);
  }

  public close() {
    this.dialogRef.close();
  }
}
