import { Component, Input, OnInit } from '@angular/core';
import { ButtonParentComponent } from '../button-parent-component';

export type SquareButtonTypes = 'Primary' | 'Secondary' | 'Transparent' | 'CTA';

@Component({
  selector: 'app-square-button',
  templateUrl: './square-button.component.html',
  styleUrls: ['./square-button.component.scss'],
  standalone: false,
})
export class SquareButtonComponent extends ButtonParentComponent<SquareButtonTypes> implements OnInit {
  @Input() public override buttonType?: SquareButtonTypes | undefined;

  constructor() {
    super();
  }

  public ngOnInit() {}
}
