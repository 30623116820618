import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '@app/core/language/language.service';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { AsAppliedOperationTypeGroup } from '../as-applied-group.class';
import { AsAppliedTask } from '../as-applied-task.class';

@Component({
  selector: 'app-as-applied-tasks',
  templateUrl: './as-applied-tasks.component.html',
  styleUrls: ['./as-applied-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AsAppliedTasksComponent implements OnInit {
  @Input() public asAppliedTaskGroups!: AsAppliedOperationTypeGroup[];
  @Input() public unspecifiedTaskGroup!: AsAppliedOperationTypeGroup;
  @Output() public taskSelect = new EventEmitter<AsAppliedTask>();

  public asAppliedGroupTableColumns = {
    displayDate: this.languageService.getText('main.fieldmap.vra.date'),
    productName: this.languageService.getText('main.fieldmap.vra.product'),
    fieldName: this.languageService.getText('main.fieldmap.vra.fieldName'),
    cropName: this.languageService.getText('main.fieldmap.vra.crop'),
  };

  public unspecifiedGroupTableColumn = {
    displayDate: this.languageService.getText('main.fieldmap.vra.date'),
    fieldName: this.languageService.getText('common.field'),
    logFileCount: this.languageService.getText('common.logFiles'),
  };

  public readonly tableStyles = {
    productName: {
      color: '#4e808d',
      padding: '0.7rem 0.5rem 0.7rem 0',
      textAlign: 'left',
    },
    cropName: {
      padding: '0.7rem 1.5rem 0.7rem 0',
      textAlign: 'left',
    },
    fieldName: {
      padding: '0.7rem 1.5rem 0.7rem 0',
      textAlign: 'left',
    },
    logFileCount: {
      whiteSpace: 'nowrap',
      padding: '0.7rem 0.5rem 0.7rem 0',
      textAlign: 'left',
    },
    displayDate: {
      whiteSpace: 'nowrap',
      padding: '0.7rem 0.5rem',
      textAlign: 'left',
    },
    name: {
      padding: '0.7rem 0.5rem 0.7rem 0',
      textAlign: 'left',
      overflow: 'hidden',
    },
  };

  constructor(
    private languageService: LanguageService,
    private mapService: OlMapService
  ) {}

  public ngOnInit(): void {
    this.mapService.enableSelectInteraction();
  }

  public onTaskSelect(task: AsAppliedTask) {
    this.taskSelect.emit(task);
  }

  public taskInitSortFn = (a: AsAppliedTask, b: AsAppliedTask) => CompareHelper.compare(a.date, b.date);

  public readonly sortTaskFn = (item: any, property: any) => {
    switch (property) {
      case 'displayDate':
        return item.date;
      case 'logFileCount':
        return item.logFileCount;
      case 'fieldName':
        return item.fieldName;
      default:
        return item[property];
    }
  };
}
