import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HotspotSubType } from '@app/core/interfaces/hotspot-sub-type-interface';
import { LanguageService } from '@app/core/language/language.service';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-hotspot-subtype-select',
  templateUrl: './hotspot-subtype-select.component.html',
  styleUrls: ['./hotspot-subtype-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HotspotSubtypeSelectComponent implements OnInit, OnChanges {
  @Input() public hotspotSubTypes: HotspotSubType[] = [];
  @Input() public selectedHotspotSubTypeIds?: number[] = [];
  @Output() public hotspotSubtypesChange = new EventEmitter<number[]>();
  public selectedHotspotSubTypes: HotspotSubType[] = [];

  public hotspotSubTypeIdsControl = this.fb.control(null);

  protected isInputFocused: boolean = false;

  filteredOptions: Observable<HotspotSubType[]> | undefined;
  constructor(
    private fb: UntypedFormBuilder,
    private languageService: LanguageService
  ) {}

  public ngOnInit() {
    this.onSelectedHotspotIdsChange();
    this.setSubtypeNamesBasedOnLanguage();

    this.hotspotSubTypeIdsControl.setValidators([HotspotValidator(this.hotspotSubTypes)]);
    this.filteredOptions = this.hotspotSubTypeIdsControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.hotspotSubTypes.slice();
      })
    );
  }

  private _filter(name: string): HotspotSubType[] {
    const filterValue = name.toLowerCase();

    return this.hotspotSubTypes.filter((option) => option.name.toLowerCase().includes(filterValue));
  }

  public ngOnChanges(changes: SimpleChanges) {
    const Ids = changes['selectedHotspotSubTypeIds'];

    if (Ids && Ids.currentValue !== Ids.previousValue) {
      this.onSelectedHotspotIdsChange();
    }
  }

  protected onFocus() {
    this.isInputFocused = true;
  }

  protected onBlur() {
    this.isInputFocused = false;
  }
  public onAddHotspotSubType(event: MatAutocompleteSelectedEvent): void {
    const selected = event.option.value as HotspotSubType;

    // if subtype is already added, just ignore it.
    if (!selected || this.selectedHotspotSubTypes.includes(selected)) {
      this.hotspotSubTypeIdsControl.setValue(null, { emitEvent: false });
      return;
    }

    this.hotspotSubTypeIdsControl.markAsDirty();
    this.selectedHotspotSubTypes.push(selected);
    this.hotspotSubTypeIdsControl.setValue(null, { emitEvent: false });
    this.hotspotSubtypesChange.emit(this.selectedHotspotSubTypes.map((type) => type!.id));
  }

  public onRemoveSubType(subType: HotspotSubType) {
    const index = this.selectedHotspotSubTypes.findIndex((type) => type?.id === subType.id);

    if (index === -1) {
      return;
    }

    this.hotspotSubTypeIdsControl.markAsDirty();
    this.selectedHotspotSubTypes.splice(index, 1);

    this.hotspotSubtypesChange.emit(this.selectedHotspotSubTypes.map((type) => type!.id));
  }

  /**
   * Display function passed to the autocomplete input.
   */
  public autoCompleteDisplay = (hotspotSubType: HotspotSubType) => (hotspotSubType ? hotspotSubType.name : '');

  public get isAnySubTypeSelected() {
    return this.selectedHotspotSubTypes.length > 0;
  }

  public trackByFn(index: number, value: HotspotSubType) {
    return value ? value.name : index;
  }

  private onSelectedHotspotIdsChange() {
    if (this.selectedHotspotSubTypeIds)
      this.selectedHotspotSubTypes = this.selectedHotspotSubTypeIds
        .map((id) => this.hotspotSubTypes.find((subType) => subType.id === id))
        .filter<HotspotSubType>((subType): subType is HotspotSubType => subType !== undefined);
  }

  private setSubtypeNamesBasedOnLanguage() {
    const currentLanguageShortKey = this.languageService.currentLanguage.shortKey;
    switch (currentLanguageShortKey) {
      case 'da':
        this.hotspotSubTypes = this.hotspotSubTypes.map((subType) => ({
          ...subType,
          name: subType.nameDa,
        }));
        break;
      case 'de':
        this.hotspotSubTypes = this.hotspotSubTypes.map((subType) => ({
          ...subType,
          name: subType.nameDe,
        }));
        break;
      default:
        this.hotspotSubTypes = this.hotspotSubTypes.map((subType) => ({
          ...subType,
          name: subType.nameEn,
        }));
        break;
    }
  }
}

export function HotspotValidator(types: HotspotSubType[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // Check if the input is empty
    if (!value) {
      return null;
    }

    // Validate against the crop options
    const isValid = types.some((type) => type.name === value.name);

    return isValid ? null : { invalidHotspot: true }; // Return an error if not valid
  };
}
