import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@app/core/notification/notification.service';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { GTagEventTrackerService } from '@app/shared/pipes/gTagEventTracker/gTagEventTracker.service';
import { CultivationJournalStateService } from '@app/state/services/cultivation-journal/cultivation-journal-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { CultivationJournalComponentEnum } from '../../CultivationJournalComponentEnum.enum';
import { DecisionSupportService } from '../../map-features/decision-support/decision-support.service';
import { NToolsCalcDialogComponent } from '../n-tools-calc-dialog/n-tools-calc-dialog.component';

@Component({
  selector: 'app-n-tool-wrapper',
  templateUrl: './n-tool-wrapper.component.html',
  styleUrls: ['./n-tool-wrapper.component.scss'],
  standalone: false,
})
export class NToolWrapperComponent {
  public field$ = this.decisionSupportService.field$;

  public components = CultivationJournalComponentEnum;
  public selectedValue = this.components.NTools;
  public isCurrentHarvestYear$ = this.harvestYearStateService.isCurrentHarvestYear$;

  constructor(
    private decisionSupportService: DecisionSupportService,
    private cultivationJournalStateService: CultivationJournalStateService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private screenSizeService: ScreenSizeService,
    private harvestYearStateService: HarvestYearStateService,
    private gTagEventTrackerService: GTagEventTrackerService
  ) {}

  public onCalcNNeedsClick(): void {
    this.screenSizeService
      .isMobile()
      .pipe(first())
      .subscribe((isMobile) => {
        if (isMobile) {
          this.notificationService.showInfo(this.translateService.instant('main.fieldAdministration.copyField.notifications.mobile'));
          return;
        }
        this.dialogService
          .openCustomDialog(NToolsCalcDialogComponent, {
            width: '85vw',
            maxWidth: '85vw',
            disableClose: true,
            maxHeight: '85vh',
            panelClass: 'dialog-container-p-0',
          })
          .afterClosed()
          .pipe(first());
      });
  }

  public valueChange(selectedComponent: CultivationJournalComponentEnum) {
    const componentStateName = CultivationJournalComponentEnum[selectedComponent];
    this.gTagEventTrackerService.trackEvent(`${componentStateName} Selector (click)`, 'Field Plan').catch((error) => {
      console.error('GTagEventTrackerService ~ trackEvent ~ error', error);
    });

    this.cultivationJournalStateService.setShownComponentState(selectedComponent);
  }
}
