import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-map-zoom',
  templateUrl: './map-zoom.component.html',
  styleUrls: ['./map-zoom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MapZoomComponent {
  @Output() public zoomIn = new EventEmitter();
  @Output() public zoomOut = new EventEmitter();

  public onZoomIn() {
    this.zoomIn.emit();
  }

  public onZoomOut() {
    this.zoomOut.emit();
  }
}
