import { OperationTypes } from '@app/core/enums/operation-types.enum';
import { Unit } from '@app/core/interfaces/unit.type';
import { ProductEntryDto } from '../interfaces/product-entry';

export class ProductEntry {
  private _id: number;
  private _label: string;
  private _unit: Unit;
  private _avgQuantity: number;
  private _totalQuantity: number;
  private _priority: number;
  private _type: OperationTypes;
  public enabled: boolean = true;

  constructor(dto: ProductEntryDto) {
    this._id = dto.normNumber;
    this._label = dto.label;
    this._unit = dto.unit;
    this._avgQuantity = dto.quantity;
    this._totalQuantity = dto.totalQuantity;
    this._priority = dto.priority;
    this._type = dto.operationType;
  }

  public get type(): OperationTypes {
    return this._type;
  }

  public get priority(): number {
    return this._priority;
  }

  public get id(): number {
    return this._id;
  }

  public get label(): string | undefined {
    return this._label;
  }

  public withLabel(label: string): ProductEntry {
    return { ...this, label: label };
  }

  public get unit(): Unit | undefined {
    return this._unit;
  }

  public withUnit(unit: Unit): ProductEntry {
    return { ...this, unit: unit };
  }

  public get avgQuantity(): number | undefined {
    return this._avgQuantity;
  }

  public withQuantity(avgQuantity: number, totalQuantity: number): ProductEntry {
    return { ...this, avgQuantity: avgQuantity, totalQuantity: totalQuantity };
  }

  public get totalQuantity(): number | undefined {
    return this._totalQuantity;
  }

  public withType(type: OperationTypes): ProductEntry {
    return { ...this, type: type };
  }

  public withpriority(priority: number): ProductEntry {
    const obj = this.toDto();
    obj.priority = priority;
    return new ProductEntry(obj);
  }

  public toDto(): ProductEntryDto {
    return {
      normNumber: this._id,
      label: this._label,
      unit: this._unit,
      quantity: this._avgQuantity,
      totalQuantity: this._totalQuantity,
      priority: this._priority,
      operationType: this._type,
    };
  }
}
