import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';
import { ActionDefinition } from '@app/shared/cards-or-table/interfaces/action-definition.interface';
import { FieldDefinition } from '@app/shared/cards-or-table/interfaces/field-definition.interface';
import { CardsOrTableService } from '@app/shared/cards-or-table/services/cards-or-table.service';
import { DateTime } from 'luxon';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-task-table',
  templateUrl: './task-table.component.html',
  styleUrls: ['./task-table.component.scss'],
  standalone: false,
})
export class TaskTableComponent implements OnInit {
  @Input() public disable: boolean = false;

  public get tasks(): SimpleTask[] {
    return this._tasks;
  }

  @Input()
  public set tasks(v: SimpleTask[]) {
    this._tasks = v;
    this.subscriptions.push(this.setDataStream());
  }

  public set fieldDefinitions(value: FieldDefinition<any>[]) {
    this.fieldDefinitions$.next(value);
  }

  public set actionDefinitions(value: ActionDefinition[]) {
    this.actionDefinitions$.next(value);
  }

  private _tasks!: SimpleTask[];
  public fieldDefinitions$ = new BehaviorSubject<FieldDefinition<any>[]>([]);
  public actionDefinitions$ = new BehaviorSubject<ActionDefinition[]>([]);
  public displayedColumns$ = this.cardsOrTableService.mapDisplayedColumns$(this.fieldDefinitions$, this.actionDefinitions$);
  public dataSource: MatTableDataSource<SimpleTask> = new MatTableDataSource<SimpleTask>();
  private subscriptions: Subscription[] = [];
  @Output() public itemEdit = new EventEmitter<SimpleTask>();
  @Output() public itemDelete = new EventEmitter<SimpleTask>();
  @Output() public itemComplete = new EventEmitter<SimpleTask>();
  @Output() public itemSchedule = new EventEmitter<SimpleTask>();

  constructor(private cardsOrTableService: CardsOrTableService) {}

  public ngOnInit() {
    this.fieldDefinitions = [
      {
        name: 'taskCards.date',
        property: 'date',
        type: 'date',
        tableCellWidth: '10rem',
        sortable: true,
      },
      {
        name: 'taskCards.taskStatus',
        property: 'registered',
        type: 'statusText',
        icon: {
          name: 'check',
          backgroundColor: '#4f734a',
          color: '#fff',
          condition: true,
        },
        tableCellWidth: '7rem',
        sortable: true,
      },
      {
        name: 'taskCards.operationTypeGroupName',
        property: 'operationTypeGroupNames',
        type: 'text',
        tableCellWidth: '20%',
        sortable: true,
      },
      {
        name: 'taskCards.operations',
        property: 'produceNormNames',
        type: 'text',
        sortable: true,
      },
    ];

    this.actionDefinitions = [
      {
        title: 'taskCards.tooltips.editTask',
        icon: 'mode_edit',
        type: 'edit',
      },
      {
        title: 'taskCards.tooltips.deleteTask',
        icon: 'delete',
        type: 'delete',
      },
    ];
  }

  /**
   * editButtonClicked
   */
  public editButtonClicked(task: SimpleTask) {
    this.itemEdit.emit(task);
  }

  /**
   * deleteBUttonClicked
   */
  public deleteButtonClicked(task: SimpleTask) {
    this.itemDelete.emit(task);
  }

  public completeButtonClicked(task: SimpleTask) {
    this.itemComplete.emit(task);
  }

  public scheduleButtonClicked(task: SimpleTask) {
    this.itemSchedule.emit(task);
  }

  public sortData(sort: Sort) {
    this.dataSource.data = this.dataSource.data.sort((a: SimpleTask, b: SimpleTask) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return this.compareDate(a.date, b.date, isAsc);
        case 'registered':
          return this.compareDateBool(a, b, isAsc);
        case 'operationTypeGroupNames':
          return this.compareStrings(a.operationTypeGroupNames ?? '', b.operationTypeGroupNames ?? '', isAsc);
        case 'produceNormNames':
          return this.compareStrings(a.produceNormNames ?? '', b.produceNormNames ?? '', isAsc);
        default:
          return 0;
      }
    });
  }

  private setDataStream() {
    return of(this.tasks)
      .pipe(filter((data) => data !== undefined))
      .subscribe((data) => {
        // Sort the data by date ascending as default..
        this.dataSource.data = data.sort((a, b) => this.compareDate(a.date, b.date, true));
      });
  }

  private compareDate(a: DateTime, b: DateTime, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // Compares bools, and then makes sure to order by date ascending...
  private compareDateBool(a: SimpleTask, b: SimpleTask, isAsc: boolean) {
    if (a.registered !== b.registered) {
      return (a.registered < b.registered ? -1 : 1) * (isAsc ? 1 : -1);
    } else {
      return this.compareDate(a.date, b.date, true);
    }
  }

  // Not used, using compareDateBool instead...
  private compareBool(a: boolean, b: boolean, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private compareStrings(a: string, b: string, isAsc: boolean) {
    if (isAsc) {
      return a.localeCompare(b);
    } else {
      return b.localeCompare(a);
    }
  }
}
