import { Component, ElementRef, Input } from '@angular/core';
import { OlMapComponent } from '@app/map/ol-map/ol-map.component';
import { HarvestYear } from '@app/state/services/harvest-year/harvest-year';
import { map } from 'rxjs';
import { AccessControlService } from '../access-control/services/access-control.service';
import { OpenLayersMapComponent } from '../openlayers-map/openlayers-map.component';

@Component({
  selector: 'app-map-controls',
  templateUrl: './map-controls.component.html',
  styleUrls: ['./map-controls.component.scss'],
  standalone: false,
})
export class MapControlsComponent {
  public disableHarvestYearPicker$ = this.accessControlService.hasAccessTo('map_harvestyear_picker').pipe(map((hasAccess) => !hasAccess));
  @Input() map!: OpenLayersMapComponent | OlMapComponent;
  @Input() hostElement!: ElementRef;
  @Input() showLayerControls = false;

  constructor(private accessControlService: AccessControlService) {}

  protected HarvestYear = HarvestYear;
}
