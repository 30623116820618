import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { LoadingState } from '@app/helpers/loading-state';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Subscription } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { GrowthRegulationCropsEnum } from '../growth-regulation-crops-enum.enum';
import { GrowthRegulationFieldTableVM, GrowthRegulationTableField } from '../growth-regulation-field-table-vm.interface';
import { GrowthRegulationVM } from '../growth-regulation-vm.class';
import { GrowthRegulationService } from '../growth-regulation.service';
import { GrowthRegulation, scoreColor } from '../interfaces/growth-regulation.interface';
import { FieldTableState } from './field-table-state.class';

@Component({
  selector: 'app-field-table',
  templateUrl: './field-table.component.html',
  styleUrls: ['./field-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FieldTableComponent implements OnChanges, OnInit, OnDestroy {
  private subscriptions = new Subscription();
  @Input() public growthRegulation!: GrowthRegulationVM;
  @Input() public farm!: Farm;
  public fieldTablesState = new FieldTableState();
  public fieldTables$ = this.fieldTablesState.changes$;
  public loadingState = new LoadingState();
  public scoreColor = scoreColor;

  constructor(
    private growthRegulationService: GrowthRegulationService,
    private harvestYearStateService: HarvestYearStateService
  ) {}

  public ngOnInit() {
    this.subscriptions.add(
      this.fieldTables$.subscribe((growthRegulationFieldTableVM) => {
        this.growthRegulationService.setGrowthRegulationFieldTableVM(growthRegulationFieldTableVM);
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges(simpleChanges: SimpleChanges) {
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    if (simpleChanges.growthRegulation && simpleChanges.growthRegulation.currentValue) {
      // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      const growthRegulationVm: GrowthRegulationVM = simpleChanges.growthRegulation.currentValue;

      // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      const farm: Farm = simpleChanges.farm.currentValue;

      this.onGrowthRegulationVmChange(growthRegulationVm, farm);
      return;
    }

    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    if (simpleChanges.farm && simpleChanges.farm.currentValue) {
      // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      const farm: Farm = simpleChanges.farm.currentValue;
      this.onFarmChange(farm);
      return;
    }
  }

  public onCheckAllClick(checked: boolean, model: GrowthRegulationFieldTableVM) {
    this.fieldTablesState.checkOrUncheckAll(checked, model);
  }

  public onCheckClick(checked: boolean, field: GrowthRegulationTableField, model: GrowthRegulationFieldTableVM) {
    this.fieldTablesState.checkOrUncheckField(checked, field, model);
  }

  public trackByFn(index: number, item: any) {
    return index;
  }

  private getFieldTableVM(growthRegulationVm: GrowthRegulationVM) {
    return this.getInsurance(this.farm, growthRegulationVm.growthRegulations).pipe(
      map((insuredFields) => {
        return [
          ...this.getWinterWheatTableModel(growthRegulationVm.winterWheat, growthRegulationVm.fields, insuredFields),
          ...this.getWinterRyeTableModel(growthRegulationVm.winterRye, growthRegulationVm.fields, insuredFields),
        ].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      })
    );
  }

  private getWinterWheatTableModel(growthRegulationWinterWheat: GrowthRegulation[], fields: Field[] | null, insuredFields: number[]) {
    return growthRegulationWinterWheat.filter((gr) => gr.farmId === this.farm.id).length
      ? [this.getTableModels(growthRegulationWinterWheat, fields!, GrowthRegulationCropsEnum.WinterWheat, insuredFields)]
      : [];
  }

  private getWinterRyeTableModel(growthRegulationWinterRye: GrowthRegulation[], fields: Field[] | null, insuredFields: number[]) {
    return growthRegulationWinterRye.filter((gr) => gr.farmId === this.farm.id).length
      ? [this.getTableModels(growthRegulationWinterRye, fields!, GrowthRegulationCropsEnum.WinterRye, insuredFields)]
      : [];
  }

  private getTableModels(
    growthRegulations: GrowthRegulation[],
    fields: Field[],
    crop: GrowthRegulationCropsEnum,
    insuredFields: number[]
  ): GrowthRegulationFieldTableVM {
    const tableFields = growthRegulations
      .filter((gr) => gr.farmId === this.farm.id)
      .map((gr) => {
        const field = fields.find((f) => gr.fieldYearId === f.id);

        // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        const isRegistered = insuredFields.includes(field.id);
        return {
          cropName: crop,
          farmId: gr.farmId,
          fieldNumber: gr.fieldNumber,
          fieldYearId: gr.fieldYearId,
          area: field ? field.area : 0,
          crop: this.getCrop(crop),
          price: gr.getScorePrice()! * field!.area,
          risk: gr.getScoreColorName(),
          registered: isRegistered,
          selected: isRegistered,
          directorateCropNormNumber: gr.directorateCropNormNumber,
        };
      })
      .sort((a, b) => {
        // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        if (a.fieldNumber < b.fieldNumber) {
          return -1;
        }

        // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        if (a.fieldNumber > b.fieldNumber) {
          return 1;
        }
        return 0;
      });

    const tableModel = {
      name: growthRegulations[0].getCropName(),
      isAllSelected: false,
      table: {
        totalPrice: tableFields.reduce((prev, curr) => (curr ? prev + curr.price : prev + 0), 0),
        totalArea: tableFields.reduce((prev, curr) => (curr ? prev + curr.area : prev + 0), 0),
        fields: tableFields,
      },
      farm: this.farm,
    };

    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return tableModel;
  }

  private onFarmChange(farm: Farm) {
    this.getFieldTableVM(this.growthRegulation)
      .pipe(
        take(1),
        map((fieldTableVm) => {
          return fieldTableVm.map((vm) => {
            return {
              ...vm,
              table: {
                ...vm.table,
                fields: vm.table.fields.filter((field) => field.farmId === farm.id),
              },
            };
          });
        })
      )
      .subscribe((fieldTableVm) => {
        this.fieldTablesState.setState(fieldTableVm);
      });
  }

  private onGrowthRegulationVmChange(growthRegulationVm: GrowthRegulationVM, farm: Farm) {
    if (!farm) {
      this.getFieldTableVM(growthRegulationVm)
        .pipe(take(1))
        .subscribe((fieldTableVm) => {
          this.fieldTablesState.setState(fieldTableVm);
        });
    } else {
      this.filterTableByFarmId(growthRegulationVm, farm.id)
        .pipe(take(1))
        .subscribe((fieldTableVm) => {
          this.fieldTablesState.setState(fieldTableVm);
        });
    }
  }

  private filterTableByFarmId(growthRegulationVm: GrowthRegulationVM, farmId: number) {
    return this.getFieldTableVM(growthRegulationVm).pipe(
      take(1),
      map((growthRegulationFieldTableVm) => {
        return growthRegulationFieldTableVm.map((vm) => {
          return {
            ...vm,
            table: {
              ...vm.table,
              fields: vm.table.fields.filter((field) => field.farmId === farmId),
            },
          };
        });
      })
    );
  }

  private getCrop(crop: GrowthRegulationCropsEnum): string {
    return crop === GrowthRegulationCropsEnum.WinterWheat
      ? 'main.growthRegulation.insurance.winterWheat'
      : 'main.growthRegulation.insurance.winterRye';
  }

  private getInsurance(farm: Farm, growthRegulations: GrowthRegulation[]) {
    const fieldIds = growthRegulations.filter((gr) => gr.farmId === farm.id).map((gr) => gr.fieldYearId);
    this.loadingState.start('main.growthRegulation.insurance.loadingInsuredFields');

    return this.harvestYearStateService.harvestYear$.pipe(
      take(1),
      switchMap((harvestYear) => {
        // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        return this.growthRegulationService.getInsuredFields([farm], harvestYear, fieldIds);
      }),
      finalize(() => this.loadingState.stop())
    );
  }
}
