import { CdkDragDrop, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FieldEntry } from '@app/farm-tasks-overview/class/field-entry';
import { TaskEntry } from '@app/farm-tasks-overview/class/task-entry';
import { FieldEntryQuery } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.query';
import { CropFilterType, SortState } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.store';
import { filterNullOrEmpty } from '@app/shared/operators';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, shareReplay, tap } from 'rxjs/operators';
import { ProductSubType } from '../product-filter/product-filter.service';
import { DataTableUtils, memoize } from './data-table.utils';
import { tableAnimations } from './table-animations';
import { TableCacheService, TableRow, TableState } from './table-cache.service';

interface DragState {
  fieldId: string;
  date: string;
}

export const _MINIMUM_DATES = 12;
export const _MINIMUM_DAYS_AFTER_TODAY = 6;
export const _DATE_CACHE = new Set<string>();

// Cached computations
const fieldEntryCache = new Map<string, FieldEntry[]>();
const sortedDataCache = new Map<string, FieldEntry[]>();
const requiredDatesCache = new Map<string, string[]>();
const tableRowsCache = new Map<string, TableRow[]>();

interface dateClass {
  date: string;
  class: string;
}
@Component({
  selector: 'app-farm-tasks-overview-table',
  templateUrl: './farm-tasks-overview-table.component.html',
  styleUrls: ['./farm-tasks-overview-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [tableAnimations.tableFade, tableAnimations.rowReorder],
  standalone: false,
})
export class FarmTasksOverviewTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tableContainer') tableContainer!: ElementRef<HTMLElement>;
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;

  protected readonly dataSource = new MatTableDataSource<TableRow>([]);
  protected displayedColumns: readonly string[] = ['field'];
  protected uniqueDates: dateClass[] = [];

  protected readonly trackByDate: TrackByFunction<string> = (_, date) => date;

  private readonly _tableState$: Observable<TableState> = combineLatest({
    data: this._fieldEntryQuery.fieldEntries$.pipe(filterNullOrEmpty(), distinctUntilChanged()),
    sort: this._fieldEntryQuery.sortState$.pipe(distinctUntilChanged()),
    cropFilter: this._fieldEntryQuery.cropFilter$.pipe(distinctUntilChanged()),
    productAndMachineEventFilter: this._fieldEntryQuery.ProductAndMachineEventFilter$.pipe(distinctUntilChanged()),
  }).pipe(
    tap(() => this._isProcessingSubject.next(true)),
    debounceTime(100),
    map(({ data, sort, cropFilter, productAndMachineEventFilter: productAndMachineEventFilter }) => {
      this._isLoadingSubject.next(true);
      const cacheKey = this.generateCacheKey(data, sort, cropFilter, productAndMachineEventFilter);
      const cachedState = this._tableCacheService.getCachedData(cacheKey);

      if (cachedState) {
        this._isProcessingSubject.next(false);
        return cachedState;
      }

      const filteredData = this.getFilteredEntries(data, cropFilter, productAndMachineEventFilter, cacheKey);
      const sortedData = this.getSortedEntries(filteredData, sort, cacheKey);
      const dates = this.getRequiredDates(sortedData, cacheKey);
      const rows = this.getTableRows(sortedData, dates, productAndMachineEventFilter, cacheKey);

      const state = { rows, dates };
      this._tableCacheService.setCachedData(cacheKey, state);
      this._isProcessingSubject.next(false);
      return state;
    }),
    shareReplay(1)
  );

  protected readonly hasEntries$ = this._tableState$.pipe(
    map((state) => state.rows.length > 0 && state.dates.length > 0),
    distinctUntilChanged()
  );

  protected readonly isCurrentYear$ = this._harvestYearStateService.isCurrentHarvestYear$;

  private readonly _isInitializedSubject = new BehaviorSubject<boolean>(false);
  protected readonly isInitialized$ = this._isInitializedSubject.asObservable();

  private readonly _isProcessingSubject = new BehaviorSubject<boolean>(false);
  protected readonly isProcessing$ = this._isProcessingSubject.asObservable();

  private readonly _isLoadingSubject = new BehaviorSubject<boolean>(false);
  protected readonly isLoading$ = this._isLoadingSubject.asObservable();

  protected readonly showLoading$ = combineLatest([
    this.isInitialized$,
    this.isLoading$,
    this.isProcessing$,
    this._fieldEntryQuery.storeReady$,
  ]).pipe(
    map(([isInitialized, isLoading, isProcessing, storeIsReady]) => {
      return !isInitialized || isLoading || isProcessing || !storeIsReady;
    }),
    distinctUntilChanged()
  );

  private _currentDragFieldId: string | null = null;
  private readonly _dragStateMap = new Map<string, DragState>();

  private _isScrolling = false;
  private readonly _SCROLL_THRESHOLD = 200;
  private readonly _SCROLL_SPEED = 12;
  private _currentMouseX = 0;
  private _lastScrollTime = 0;
  private _animationFrameId: number | null = null;

  private readonly _subs = new SubscriptionArray();

  constructor(
    private readonly _fieldEntryQuery: FieldEntryQuery,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _tableCacheService: TableCacheService,
    private readonly _harvestYearStateService: HarvestYearStateService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._tableState$.subscribe((state) => {
        this._isLoadingSubject.next(false);
        this.updateTableState(state);
        requestAnimationFrame(() => this.scrollToToday());
      })
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._isInitializedSubject.next(true);
      this.scrollToToday();
    }, 0);
  }

  ngOnDestroy(): void {
    this.stopAutoScroll();
    this._subs.unsubscribe();
  }
  protected getHeaderClass(date: string, uniqueDates: string[]): string {
    return DataTableUtils.getHeaderClass(date, uniqueDates);
  }

  @memoize()
  protected isToday(date: string): boolean {
    return DataTableUtils.isToday(date);
  }

  @memoize()
  protected generateDropListId(fieldId: string, date: string): string {
    return DataTableUtils.generateDropListId(fieldId, date);
  }

  protected isFirstDateOfMonth(date: string, index: number): boolean {
    const previousDate = index > 0 ? this.uniqueDates[index - 1] : null;
    return DataTableUtils.isFirstDateOfMonth(date, previousDate?.date!);
  }

  protected isLastDateOfMonth(date: string, index: number): boolean {
    const nextDate = index < this.uniqueDates.length - 1 ? this.uniqueDates[index + 1] : null;
    return DataTableUtils.isLastDateOfMonth(date, nextDate?.date!);
  }

  protected dragStarted(event: CdkDragStart, fieldId: string): void {
    this._currentDragFieldId = fieldId;
    this.startAutoScroll();
  }

  protected dragEnded(): void {
    this._currentDragFieldId = null;
    this.stopAutoScroll();
  }

  protected dragMoved(event: CdkDragMove): void {
    this._currentMouseX = event.pointerPosition.x;
  }

  protected isValidDropTarget(fieldId: string): boolean {
    return this._currentDragFieldId === fieldId;
  }

  protected getConnectedLists(row: TableRow): string[] {
    return this.uniqueDates.map((date) => this.generateDropListId(row.fieldEntry.id.toString(), date.date!));
  }

  protected isDragHovering(fieldId: string, date: string): boolean {
    // needs a rewrite after optimizations to add back '+' styling
    return false;
  }

  protected dragEntered(fieldId: string, date: string): void {
    this._dragStateMap.set(`${fieldId}-${date}`, { fieldId, date });
  }

  protected dragExited(): void {
    this._dragStateMap.clear();
  }

  protected onTaskDrop(event: CdkDragDrop<TaskEntry[][]>, fieldEntry: FieldEntry, targetDate: string): void {
    this._dragStateMap.clear();

    if (!event.container.id.includes(fieldEntry.id.toString())) {
      return;
    }

    const sourceDate = DataTableUtils.getDateFromDropListId(event.previousContainer.id);
    const tasksToMove = event.item.data as TaskEntry[];

    if (sourceDate === targetDate) {
      return;
    }

    this.updateTasksDate(fieldEntry, tasksToMove, targetDate);
  }

  protected addDatesAfter(date: string, numberOfDates: number): void {
    if (!this.wouldAddDatesAfter(date, numberOfDates)) return;

    const newDates = this.generateDatesAfter(date, numberOfDates);
    this.updateDatesAndDataSource(newDates);
  }

  protected addDatesBefore(date: string, numberOfDates: number): void {
    if (!this.wouldAddDatesBefore(date, numberOfDates)) return;

    const newDates = this.generateDatesBefore(date, numberOfDates);
    this.updateDatesAndDataSource(newDates);
  }

  protected wouldAddDatesAfter(date: string, numberOfDates: number): boolean {
    return DataTableUtils.wouldAddDatesAfter(
      this.uniqueDates.map((ud) => ud.date!),
      date,
      numberOfDates
    );
  }

  protected wouldAddDatesBefore(date: string, numberOfDates: number): boolean {
    return DataTableUtils.wouldAddDatesBefore(
      this.uniqueDates.map((ud) => ud.date!),
      date,
      numberOfDates
    );
  }

  private getEarliestTaskDate(tasks: TaskEntry[]): DateTime | null {
    if (!tasks.length) return null;

    return tasks.reduce(
      (earliest, task) => {
        if (!task.date) return earliest;
        const taskDate = DateTime.fromISO(task.date);
        return !earliest || taskDate < earliest ? taskDate : earliest;
      },
      null as DateTime | null
    );
  }

  private updateTableState(state: TableState): void {
    this.uniqueDates = state.dates.map((date) => ({ date: date, class: this.getHeaderClass(date, state.dates) }));
    this.displayedColumns = ['field', ...state.dates];
    this.dataSource.data = state.rows;
    this._cdr.markForCheck();
  }

  private scrollToToday(): void {
    if (!this.tableContainer?.nativeElement || !this.viewport) return;

    const today = DataTableUtils.formatDate(DateTime.local().startOf('day'));
    const headerCell = this.tableContainer.nativeElement.querySelector(`[data-date="${today}"]`);

    if (headerCell) {
      // Get the viewport element and its dimensions
      const viewportElement = this.viewport.elementRef.nativeElement;
      const containerWidth = viewportElement.clientWidth;

      // Get the cell's position
      const cellLeft = (headerCell as HTMLElement).offsetLeft;
      const cellWidth = (headerCell as HTMLElement).offsetWidth;

      // Calculate the target scroll position (centered)
      const targetScroll = Math.max(0, cellLeft - containerWidth / 2 + cellWidth / 2);

      // Scroll smoothly to the target position
      this.viewport.scrollTo({
        left: targetScroll,
        behavior: 'smooth',
      });
    }
  }

  private startAutoScroll(): void {
    if (this._isScrolling) return;
    this._isScrolling = true;
    this._lastScrollTime = performance.now();
    this.autoScroll();
  }

  private stopAutoScroll(): void {
    this._isScrolling = false;
    if (this._animationFrameId !== null) {
      cancelAnimationFrame(this._animationFrameId);
      this._animationFrameId = null;
    }
  }

  private autoScroll = (): void => {
    if (!this._isScrolling || !this.tableContainer) {
      return;
    }

    const now = performance.now();
    if (now - this._lastScrollTime < 16) {
      // ~60fps
      this._animationFrameId = requestAnimationFrame(this.autoScroll);
      return;
    }
    this._lastScrollTime = now;

    const container = this.tableContainer.nativeElement;
    const rect = container.getBoundingClientRect();

    if (this._currentMouseX - rect.left < this._SCROLL_THRESHOLD) {
      container.scrollLeft -= this._SCROLL_SPEED;
    } else if (rect.right - this._currentMouseX < this._SCROLL_THRESHOLD) {
      container.scrollLeft += this._SCROLL_SPEED;
    }

    this._animationFrameId = requestAnimationFrame(this.autoScroll);
  };

  private generateDatesAfter(date: string, numberOfDates: number): string[] {
    const startDate = DateTime.fromISO(date);
    const newDates: string[] = [];

    for (let i = 1; i <= numberOfDates; i++) {
      const newDate = startDate.plus({ days: i }).startOf('day');
      const newDateStr = DataTableUtils.formatDate(newDate);

      if (!_DATE_CACHE.has(newDateStr)) {
        newDates.push(newDateStr);
        _DATE_CACHE.add(newDateStr); // Fixed syntax
      }
    }

    return newDates;
  }

  private generateDatesBefore(date: string, numberOfDates: number): string[] {
    const startDate = DateTime.fromISO(date);
    const newDates: string[] = [];

    for (let i = 1; i <= numberOfDates; i++) {
      const newDate = startDate.minus({ days: i }).startOf('day');
      const newDateStr = DataTableUtils.formatDate(newDate);

      if (!_DATE_CACHE.has(newDateStr)) {
        newDates.push(newDateStr);
        _DATE_CACHE.add(newDateStr); // Fixed syntax
      }
    }

    return newDates;
  }

  private updateDatesAndDataSource(newDates: string[]): void {
    if (!newDates?.length) return;

    // Use Set to ensure unique dates
    const uniqueDatesSet = new Set([...this.uniqueDates.map((ud) => ud.date!), ...newDates]);
    const allDates = Array.from(uniqueDatesSet).sort((a, b) => DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis());

    const updatedRows = this.dataSource.data.map((row) => ({
      ...row,
      tasksByDate: {
        ...row.tasksByDate,
        ...Object.fromEntries(newDates.map((date) => [date, [] as TaskEntry[]])),
      },
    }));

    this.updateTableState({
      rows: updatedRows,
      dates: allDates,
    });
  }

  private updateTasksDate(fieldEntry: FieldEntry, tasks: TaskEntry[], newDate: string): void {
    return;
  }

  private getFilteredEntries(
    entries: FieldEntry[],
    cropFilter: readonly CropFilterType[],
    productFilter: readonly ProductSubType[],
    cacheKey: string
  ): FieldEntry[] {
    const filterCacheKey = `${cacheKey}_filter`;
    if (fieldEntryCache.has(filterCacheKey)) {
      return fieldEntryCache.get(filterCacheKey)!;
    }

    const productsToShowTaskFor = productFilter.map((p) => p.productId);

    // Optimize filtering with Sets and Maps
    const productsSet = new Set(productFilter.map((p) => p.productId));
    const cropFilterMap = new Map(cropFilter.map((f) => [`${f.cropName}-${f.term}`, true]));

    const filtered = entries.filter((entry) => {
      if (!cropFilterMap.has(`${entry.cropName}-${entry.successionNo}`)) return false;
      return true;
    });

    fieldEntryCache.set(filterCacheKey, filtered);

    return filtered.map((entry) => entry.getFieldEntryWithProductTasks(productsToShowTaskFor));
  }

  private getSortedEntries(entries: FieldEntry[], sort: SortState, cacheKey: string): FieldEntry[] {
    const sortCacheKey = `${cacheKey}_${sort.sortType}_${sort.sortDirection}`;

    const sorted = [...entries].sort((a, b) => {
      const direction = sort.sortDirection === 'asc' ? 1 : -1;

      switch (sort.sortType) {
        case 'fieldLabel':
          return direction * this.compareFieldLabels(a.label ?? '', b.label ?? '');

        case 'crop':
          return direction * (a.cropName ?? '').localeCompare(b.cropName ?? '');

        case 'taskDate':
          const aDate = this.getEarliestTaskDate(a.tasks)?.toMillis() ?? -Infinity;
          const bDate = this.getEarliestTaskDate(b.tasks)?.toMillis() ?? -Infinity;

          // If either entry has no date, place it at the bottom
          if (aDate === -Infinity && bDate !== -Infinity) return 1;
          if (bDate === -Infinity && aDate !== -Infinity) return -1;

          return direction * (aDate - bDate);

        case 'fieldArea':
          return direction * ((a.area ?? 0) - (b.area ?? 0));

        default:
          return 0;
      }
    });

    sortedDataCache.set(sortCacheKey, sorted);

    return sorted;
  }

  // Custom comparison for field labels
  private compareFieldLabels(labelA: string, labelB: string): number {
    // Split labels into numeric parts
    const partsA = labelA.split('-').map((part) => parseInt(part, 10));
    const partsB = labelB.split('-').map((part) => parseInt(part, 10));

    // Compare first part
    if (partsA[0] !== partsB[0]) {
      return partsA[0] - partsB[0];
    }

    // If first parts are equal, compare second part
    // Use 0 if no second part exists
    const secondA = partsA[1] ?? 0;
    const secondB = partsB[1] ?? 0;

    return secondA - secondB;
  }

  private getRequiredDates(entries: FieldEntry[], cacheKey: string): string[] {
    if (requiredDatesCache.has(cacheKey)) {
      return requiredDatesCache.get(cacheKey)!;
    }

    const dateSet = new Set<string>();

    // Process task dates in bulk first
    entries.forEach((field) => {
      field.tasks.forEach((task) => {
        if (task.date) {
          dateSet.add(DataTableUtils.formatDate(DateTime.fromISO(task.date).startOf('day')));
        }
      });
    });

    // Only add today and future dates if it's current year
    this.isCurrentYear$.pipe(first()).subscribe((isCurrentYear) => {
      if (isCurrentYear) {
        const requiredDates = DataTableUtils.getRequiredDates();
        requiredDates.forEach((date) => dateSet.add(date));

        // Ensure minimum dates efficiently only for current year
        const today = DateTime.local().startOf('day');
        let currentDate = today;
        while (dateSet.size < 12) {
          dateSet.add(DataTableUtils.formatDate(currentDate));
          currentDate = currentDate.plus({ days: 1 });
        }

        // Ensure we have enough days after today
        DataTableUtils.ensureDaysAfterToday(dateSet, 12, isCurrentYear);
      }
    });

    // If there is a task on the last date, add one more day
    const lastDate = Array.from(dateSet).sort().pop();
    const lastDateTasks = entries.flatMap((f) => f.getTasks()).filter((t) => DateTime.fromISO(t.date!).equals(DateTime.fromISO(lastDate!)));
    if (lastDate && lastDateTasks.length) {
      const nextDate = DateTime.fromISO(lastDate).plus({ days: 1 });
      dateSet.add(DataTableUtils.formatDate(nextDate));
    }

    const dates = Array.from(dateSet).sort();
    requiredDatesCache.set(cacheKey, dates);
    return dates;
  }

  private getTableRows(entries: FieldEntry[], dates: string[], productFilter: ProductSubType[], cacheKey: string): TableRow[] {
    if (tableRowsCache.has(cacheKey)) {
      return tableRowsCache.get(cacheKey)!;
    }

    // Pre-compute task date map for faster lookup
    const taskDateMap = new Map<string, Map<string, TaskEntry[]>>();
    entries.forEach((field) => {
      const fieldMap = new Map<string, TaskEntry[]>();
      field.tasks.forEach((task) => {
        if (task.date) {
          const dateKey = DataTableUtils.formatDate(DateTime.fromISO(task.date).startOf('day'));
          const existing = fieldMap.get(dateKey) || [];
          existing.push(task);
          fieldMap.set(dateKey, existing);
        }
      });
      taskDateMap.set(field.id.toString(), fieldMap);
    });

    const rows = entries.map((field) => ({
      fieldEntry: field,
      tasksByDate: Object.fromEntries(dates.map((date) => [date, taskDateMap.get(field.id.toString())?.get(date) || []])),
    }));

    rows.forEach((row) => {
      const tasksByDate = row.tasksByDate;
      const filteredTasksByDate = Object.fromEntries(
        Object.entries(tasksByDate).map(([date, tasks]) => {
          const filteredTasks = tasks.filter((task) => task.products.some((p) => productFilter.some((pf) => pf.productId === p.id)));
          return [date, filteredTasks];
        })
      );
      row.tasksByDate = filteredTasksByDate;
    });

    tableRowsCache.set(cacheKey, rows);
    return rows;
  }

  private generateCacheKey(
    data: FieldEntry[],
    sort: SortState,
    cropFilter: readonly CropFilterType[],
    productFilter: readonly ProductSubType[]
  ): string {
    const dataNames = data.map((d) => d.id).join(',');
    const cropFilterNames = cropFilter.map((f) => `${f.cropName}-${f.term}`).join(',');
    const productFilterNames = productFilter.map((f) => f.productId).join(',');
    return `${dataNames}_${sort.sortType}_${sort.sortDirection}_${cropFilterNames}_${productFilterNames}`;
  }
}
