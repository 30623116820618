import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { Position, bbox, projection } from '@turf/turf';

export type Coordinate2D = [number, number] | Position;
type BBox2D = [number, number, number, number];

@Component({
  selector: 'app-geojson-icon',
  template: '<canvas #canvas></canvas>',
  standalone: false,
})

//* Can only handle polygons for now, can be extended.
export class GeojsonIconComponent implements OnChanges {
  @Input() geojson?: GeoJSON.Polygon | null;
  @Input() color: string = '#900';
  @Input() size: number = 25;
  @Input() strokeWidth: number = 2;

  // Define the regex pattern for valid hex color codes
  private readonly hexColorPattern = /^#(?:[0-9a-fA-F]{3,4}){1,2}$/;

  @ViewChild('canvas', { static: true }) canvas!: ElementRef<HTMLCanvasElement>;

  ngOnChanges(): void {
    this.canvas.nativeElement.width = this.size;
    this.canvas.nativeElement.height = this.size;

    this.drawIcon();
  }

  private drawIcon(): void {
    if (!this.geojson || !this.canvas) {
      return;
    }

    const ctx = this.canvas.nativeElement.getContext('2d');
    if (!ctx) {
      return;
    }

    // Map to mercator projection for easier comparison between the canvas icon and map field
    this.geojson = projection.toMercator(this.geojson);

    // Clear the canvas before drawing a new icon
    ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);

    const polygons = this.geojson.coordinates;

    // Draw the outer boundaries
    this.drawBoundary(ctx, polygons[0], true);

    // Draw the inner boundaries (holes)
    for (let i = 1; i < polygons.length; i++) {
      this.drawBoundary(ctx, polygons[i], false);
    }
  }

  private drawBoundary(crc: CanvasRenderingContext2D, boundary: Coordinate2D[], isOuter: boolean): void {
    crc.beginPath();

    for (const [index, point] of boundary.entries()) {
      const [x, y] = this.scaleAndTranslatePoint(point);
      if (index === 0) {
        crc.moveTo(x, y);
      } else {
        crc.lineTo(x, y);
      }
    }

    crc.closePath();

    // Draw the outer boundary with fill and stroke
    if (isOuter) {
      crc.fillStyle = this.colorWithTransparency(this.color, 0.3);
      crc.lineWidth = this.strokeWidth;
      crc.globalCompositeOperation = 'source-over'; // default behavior
      // Draw the inner boundary (hole) the same way as the outer boundary
    } else {
      crc.fillStyle = this.color;
      crc.lineWidth = this.strokeWidth / 2;
      crc.globalCompositeOperation = 'destination-out'; // remove the overlapping area, leaving a transparent hole
    }
    crc.fill();

    // Reset globalCompositeOperation to the default value and stroke the path...
    // to draw the outline of the hole
    if (!isOuter) {
      crc.globalCompositeOperation = 'source-over';
    }
    crc.strokeStyle = this.darkerStrokeColor(this.color);
    crc.stroke();
  }

  // Parse the hexColor string and convert it to an rgba string with the specified alpha value
  private colorWithTransparency(hexColor: string, alpha: number): string {
    if (!this.hexColorPattern.test(hexColor)) {
      throw new Error('Invalid hex color code');
    }

    // If the hex color has 4 characters (3-digit shorthand), double each character
    const hex = hexColor.length === 4 ? hexColor.replace(/./g, '$&$&') : hexColor;

    // Extract the RGB values from the hex color and convert to integers
    const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));

    // Return the RGBA color string with the specified transparency
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  // Parse the hexColor string and convert it to a darker rgba string
  private darkerStrokeColor(hexColor: string): string {
    if (!this.hexColorPattern.test(hexColor)) {
      throw new Error('Invalid hex color code');
    }

    // If the hex color has 4 characters (3-digit shorthand), double each character
    const hex = hexColor.length === 4 ? hexColor.replace(/./g, '$&$&') : hexColor;

    // Extract the RGBA values from the hex color and convert to integers
    const rgbaValues = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));

    // If the input hex color has an alpha value, extract it; otherwise, set alpha to 1
    const [r, g, b, a] = rgbaValues.length === 4 ? rgbaValues : [...rgbaValues, 1];

    // Return the darker RGBA color string by multiplying each RGB value by 0.8 and maintaining the alpha value
    return `rgba(${r * 0.8}, ${g * 0.8}, ${b * 0.8}, ${a})`;
  }

  /**
   * This function scales and translates a point from the GeoJSON object...
   * to fit inside the canvas element, taking into account the stroke width
   * Because the canvas size can be changed by the user, the transformation...
   * also needs to be updated based on the new size of the canvas.
   */
  private scaleAndTranslatePoint([x, y]: Coordinate2D): Coordinate2D {
    if (!this.geojson || !this.canvas) {
      return [0, 0];
    }

    // Calculate the bounding box of the GeoJSON object if it hasn't been calculated yet
    if (!this.geojson.bbox) {
      this.geojson.bbox = bbox(this.geojson) as BBox2D;
    }

    // Get the current width and height of the canvas element
    const canvasWidth = this.canvas.nativeElement.width;
    const canvasHeight = this.canvas.nativeElement.height;

    // Calculate the width and height of the bounding box of the GeoJSON object
    const bboxWidth = this.geojson.bbox[2] - this.geojson.bbox![0];
    const bboxHeight = this.geojson.bbox![3] - this.geojson.bbox![1];

    // Calculate half the stroke width to adjust for the stroke being drawn outside the path
    const halfStrokeWidth = this.strokeWidth / 2;

    // Calculate the scale needed to fit the GeoJSON object inside the canvas element
    const scaleX = (canvasWidth - halfStrokeWidth * 2) / bboxWidth;
    const scaleY = (canvasHeight - halfStrokeWidth * 2) / bboxHeight;

    // Maintain aspect ratio by selecting the smaller scale factor
    const scale = Math.min(scaleX, scaleY);

    // Calculate the translation needed to move the GeoJSON object to the top-left of the canvas element
    const translateX = -this.geojson.bbox[0];
    const translateY = -this.geojson.bbox[1];

    // Calculate the offset to center the geometry within the canvas
    const offsetX = (canvasWidth - bboxWidth * scale) / 2;
    const offsetY = (canvasHeight - bboxHeight * scale) / 2;

    // Scale, translate, and offset the x and y coordinates of the point
    const scaledX = (x + translateX) * scale + offsetX;
    const scaledY = (y + translateY) * scale + offsetY;

    // Invert the y-axis to match the coordinate system of the canvas element
    return [scaledX, canvasHeight - scaledY];
  }
}
