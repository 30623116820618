import { Pipe, PipeTransform } from '@angular/core';
import { NToolTask } from '@app/shared/n-tool/n-tool-data';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { DateTime } from 'luxon';

@Pipe({
  name: 'futureTasks',
  standalone: false,
})
export class FutureTasksPipe implements PipeTransform {
  public transform(nToolTasks: NToolTask[]): any {
    const today = DateTime.now();
    return nToolTasks.filter((nToolTask) => CompareHelper.compare(nToolTask.date, today) === -1);
  }
}
