import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { flyInOut } from '@app/core/animations/fly-in-out.fn';
import { FieldFeatures } from '@app/core/feature/field-features.interface';
import { FieldService } from '@app/core/field/field.service';
import { Field } from '@app/core/interfaces/field.interface';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { LoadingState } from '@app/helpers/loading-state';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { finalize, first, switchMap, withLatestFrom } from 'rxjs/operators';
import { FieldSelectorSideDrawerData } from './field-selector-side-drawer-data.interface';
import { FieldSelectorService } from './field-selector.service';
import { TaskCropVM } from './task-crop-vm';

@Component({
  selector: 'app-field-selector',
  templateUrl: './field-selector.component.html',
  styleUrls: ['./field-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut],
  standalone: false,
})
export class FieldSelectorComponent implements OnInit, OnDestroy {
  public task!: SimpleTask;
  public field!: Field;
  public selectedCrop!: TaskCropVM;

  public cropsWithSimilarTask: TaskCropVM[] = [];
  public cropsWithSimilarCrop: TaskCropVM[] = [];
  public newlyAdded: TaskCropVM[] = [];
  public newlyDeleted: TaskCropVM[] = [];
  public otherCrops: TaskCropVM[] = [];

  public readonly loadingState = new LoadingState();
  public loading$ = this.loadingState.changes$;

  private fieldsWithSimilarCrop!: Field[];
  private allFieldFeatures!: FieldFeatures;
  private subscriptions = new Subscription();

  constructor(
    private fieldService: FieldService,
    private fieldSelectorService: FieldSelectorService,
    private changeDetectorRef: ChangeDetectorRef,
    private sideDrawerRef: SideDrawerRef<
      FieldSelectorComponent,
      FieldSelectorSideDrawerData,
      { newAddedItems: TaskCropVM[]; newlyDeletedItems: TaskCropVM[] }
    >,
    public farmStateService: FarmStateService
  ) {}

  public ngOnInit() {
    this.task = this.sideDrawerRef.data.task;
    this.selectedCrop = this.sideDrawerRef.data.crop;

    this.newlyAdded = this.sideDrawerRef.data.selectedCrops;

    this.getAllFields();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public select(item: TaskCropVM) {
    if (this.newlyAdded.includes(item)) {
      return;
    }
    this.newlyAdded = this.addAndSort(this.newlyAdded, item);

    switch (item.type) {
      case 'similar-crop':
        this.cropsWithSimilarCrop = this.removeAndSort(this.cropsWithSimilarCrop, item);
        break;
      case 'similar-task':
        this.cropsWithSimilarTask = this.removeAndSort(this.cropsWithSimilarTask, item);
        break;
      default:
        this.otherCrops = this.removeAndSort(this.otherCrops, item);
    }
  }

  public deselect(item: TaskCropVM) {
    this.newlyAdded = this.removeAndSort(this.newlyAdded, item);

    this.cropsWithSimilarCrop = this.fieldSelectorService.filterCropsWithSimilarCrop(
      this.fieldsWithSimilarCrop,
      this.cropsWithSimilarTask,
      this.newlyAdded
    );

    this.otherCrops = this.fieldSelectorService.filterOtherCrops(
      this.allFieldFeatures as FieldFeatures,
      this.cropsWithSimilarCrop,
      this.cropsWithSimilarTask,
      this.newlyAdded,
      this.selectedCrop
    );
  }

  public deselectFromFieldsWithSimilarTask(item: TaskCropVM) {
    this.cropsWithSimilarTask = this.removeAndSort(this.cropsWithSimilarTask, item);
    this.newlyDeleted = this.addAndSort(this.newlyDeleted, item);

    this.cropsWithSimilarCrop = this.fieldSelectorService.filterCropsWithSimilarCrop(
      this.fieldsWithSimilarCrop,
      this.cropsWithSimilarTask,
      this.newlyAdded
    );

    this.otherCrops = this.fieldSelectorService.filterOtherCrops(
      this.allFieldFeatures,
      this.cropsWithSimilarCrop,
      this.cropsWithSimilarTask,
      this.newlyAdded,
      this.selectedCrop
    );
  }

  public deselectAll() {
    this.newlyAdded.forEach((item) => this.deselect(item));
    this.newlyAdded = [];
  }

  public onCloseClick() {
    this.sideDrawerRef.close({ newAddedItems: [], newlyDeletedItems: [] });
  }

  public onSaveClick() {
    this.sideDrawerRef.close({ newAddedItems: this.newlyAdded, newlyDeletedItems: this.newlyDeleted });
  }

  private addAndSort(array: TaskCropVM[], item: TaskCropVM) {
    return [...array, item].sort((a, b) => a.crop.id - b.crop.id);
  }

  private removeAndSort(array: TaskCropVM[], item: TaskCropVM) {
    return array.filter((f) => f.crop.id !== item.crop.id).sort((a, b) => a.crop.id - b.crop.id);
  }

  private getAllFields() {
    this.loadingState.start('fieldSelector.loading');
    this.farmStateService.selectedFarmIds$
      .pipe(
        first(),
        switchMap((farmIds) =>
          forkJoin([this.getFieldsWithSimilarCrop(farmIds), this.getFieldsWithSimilarTask(farmIds)]).pipe(
            withLatestFrom(this.farmStateService.fieldFeatures$),
            finalize(() => this.loadingState.stop())
          )
        )
      )
      .subscribe(([[withSimilarCrop, withSimilarTask], allFieldFeatures]) => {
        this.cropsWithSimilarTask = this.fieldSelectorService.mapCropsWithSimilarTask(withSimilarTask, this.newlyAdded);
        this.fieldsWithSimilarCrop = withSimilarCrop;
        this.allFieldFeatures = allFieldFeatures as FieldFeatures;
        this.cropsWithSimilarCrop = this.fieldSelectorService.filterCropsWithSimilarCrop(
          withSimilarCrop,
          this.cropsWithSimilarTask,
          this.newlyAdded
        );

        this.otherCrops = this.fieldSelectorService.filterOtherCrops(
          allFieldFeatures as FieldFeatures,
          this.cropsWithSimilarCrop,
          this.cropsWithSimilarTask,
          this.newlyAdded,
          this.selectedCrop
        );

        this.changeDetectorRef.detectChanges();
      });
  }

  private getFieldsWithSimilarCrop(farmIds: number[]) {
    return this.fieldService.getFieldsWithSimilarCrop(
      farmIds,
      this.selectedCrop.crop.harvestYear,
      this.selectedCrop.crop.fieldId,
      this.selectedCrop.crop.cropNormNumber.toString()
    );
  }

  private getFieldsWithSimilarTask(farmIds: number[]): Observable<Field[]> {
    return this.task.id ? this.fieldSelectorService.getFieldsWithSimilarTask(farmIds, this.task.harvestYear, this.task.id) : of([]);
  }
}
