import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { SimpleTaskService } from '@app/core/task/simple-task/simple-task.service';
import { TaskService } from '@app/core/task/task.service';
import { LoadingState } from '@app/helpers/loading-state';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { ActionDefinition } from '@app/shared/cards-or-table/interfaces/action-definition.interface';
import { ActionEvent } from '@app/shared/cards-or-table/interfaces/action-event.interface';
import { FieldDefinition } from '@app/shared/cards-or-table/interfaces/field-definition.interface';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { FarmLockedService } from '@app/shared/farm-locked/farm-locked.service';
import { negate } from '@app/shared/operators/negate';
import { merge } from 'lodash-es';
import { Subscription } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { DecisionSupportService } from '../../decision-support/decision-support.service';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-tasks-overview',
  templateUrl: './tasks-overview.component.html',
  styleUrls: ['./tasks-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TasksOverviewComponent implements OnInit, OnDestroy {
  @Input() public field!: Field;

  public operationTypeGroupId!: number;
  public cropId!: number;
  private tasks: SimpleTask[] = [];
  public filteredTasks: SimpleTask[] = [];

  private loadingState = new LoadingState();
  public loading$ = this.loadingState.changes$;

  public actionDefinitions: ActionDefinition[] = [];
  private subscriptions = new Subscription();

  public fieldDefinitions!: FieldDefinition<any>[];

  protected noWriteAccess$ = this.accessControlService.hasAccessTo('field_plan_cultivation_journal_write').pipe(negate());

  @ViewChild('editTask', { static: false }) public editTask: TaskComponent | undefined;

  constructor(
    private dialogService: DialogService,
    private sideDrawerOverlayService: SideDrawerOverlayService,
    private simpleTaskService: SimpleTaskService,
    private taskService: TaskService,
    private farmLockedService: FarmLockedService,
    private changeDetectorRef: ChangeDetectorRef,
    private decisionSupportService: DecisionSupportService,
    private accessControlService: AccessControlService
  ) {}

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit() {
    this.decisionSupportService.field$.subscribe((currentField: Field | undefined) => {
      if (currentField !== undefined) {
        this.field = currentField;
      }
    });

    this.cropId = this.getCurrentCrop().id;

    this.subscriptions.add(this.getAndSetTasks());

    this.fieldDefinitions = [
      {
        name: 'taskCards.date',
        property: 'date',
        type: 'date',
        tableCellWidth: '10rem',
        sortable: true,
      },
      {
        name: 'taskCards.taskStatus',
        property: 'registered',
        type: 'statusText',
        icon: {
          name: 'check',
          backgroundColor: '#4f734a',
          color: '#fff',
          condition: true,
        },
        tableCellWidth: '7rem',
        sortable: true,
      },
      {
        name: 'taskCards.operationTypeGroupName',
        property: 'operationTypeGroupNames',
        type: 'text',
        tableCellWidth: '20%',
      },
      {
        name: 'taskCards.operations',
        property: 'produceNormNames',
        type: 'text',
      },
    ];

    this.actionDefinitions = [
      {
        title: 'taskCards.tooltips.editTask',
        icon: 'mode_edit',
        type: 'edit',
      },
      {
        title: 'taskCards.tooltips.deleteTask',
        icon: 'delete',
        type: 'delete',
      },
    ];
  }

  public onCropIdSelect(cropId: number) {
    this.cropId = cropId;

    this.filteredTasks = this.filterTasks([...this.tasks], this.cropId, this.operationTypeGroupId);
  }

  public onOperationTypeSelect(operationTypeGroupId: number) {
    this.operationTypeGroupId = operationTypeGroupId;

    this.filteredTasks = this.filterTasks([...this.tasks], this.cropId, this.operationTypeGroupId);
  }

  public onDelete(task: SimpleTask) {
    const data = {
      title: 'editOperationLine.removeTaskConfirmationTitle',
      text: 'editOperationLine.removeTaskConfirmationText',
      confirmText: 'editOperationLine.confirmText',
    };

    event?.stopPropagation();
    this.dialogService.openConfirmDialog(data).onDialogConfirmed?.(() => this.modifyTask(task, 'delete'));
  }

  public completeTask(task: SimpleTask) {
    if (!this.farmLockedService.isFarmLockedInSelectedHarvestYear(this.field.farmId)) {
      task.registered = true;
      this.modifyTask(task, 'update');
    } else {
      this.farmLockedService.showCurrentHarvestYearLockedErrorForFarmId(this.field.farmId);
    }
  }

  public scheduleTask(task: SimpleTask) {
    if (!this.farmLockedService.isFarmLockedInSelectedHarvestYear(this.field.farmId)) {
      task.registered = false;
      this.modifyTask(task, 'update');
    } else {
      this.farmLockedService.showCurrentHarvestYearLockedErrorForFarmId(this.field.farmId);
    }
  }

  public onTableAction($event: ActionEvent<SimpleTask>) {
    switch ($event.type) {
      case 'edit':
        this.beginCreatingOrEditingTask($event.dataItem);
        return;
      case 'delete':
        this.onDelete($event.dataItem);
        return;
    }
  }

  public onTaskEditClick(task: SimpleTask) {
    if (!task) {
      return;
    }
    this.beginCreatingOrEditingTask(task);
  }

  public onAddTaskClick() {
    if (!this.farmLockedService.isFarmLockedInSelectedHarvestYear(this.field.farmId)) {
      this.beginCreatingOrEditingTask(new SimpleTask(this.field, this.cropId), true);
    } else {
      this.farmLockedService.showCurrentHarvestYearLockedErrorForFarmId(this.field.farmId);
    }
  }

  public beginCreatingOrEditingTask(data: SimpleTask, creating?: boolean) {
    const sideDrawerRef = this.sideDrawerOverlayService.openCustomSideDrawer<
      TaskComponent,
      { field: Field; creating?: boolean; task: SimpleTask },
      SimpleTask
    >(TaskComponent, {
      width: '500px',
      maxWidth: '100vw',
      panelClass: 'edit-task-side-drawer',
      data: {
        field: this.field,
        creating: creating,
        task: data,
      },
    });
    this.subscriptions.add(
      sideDrawerRef.backdropClicked.subscribe(
        () => {
          sideDrawerRef.component.onCloseClick();
        },
        sideDrawerRef.onClose.subscribe((task: SimpleTask) => {
          if (task) {
            this.tasks = this.sortTasks([...this.tasks.filter((t) => t.id !== task.id), task]);
            this.filteredTasks = this.filterTasks(this.tasks, this.cropId, this.operationTypeGroupId);
            this.changeDetectorRef.detectChanges();
          }
        })
      )
    );
  }

  private modifyTask(task: SimpleTask, action: 'update' | 'delete') {
    if (!this.farmLockedService.isFarmLockedInSelectedHarvestYear(this.field.farmId)) {
      switch (action) {
        case 'update':
          this.updateTask(task);
          break;
        case 'delete':
          this.deleteTask(task);
      }
    } else {
      this.farmLockedService.showCurrentHarvestYearLockedErrorForFarmId(this.field.farmId);
    }
  }

  private deleteTask(task: SimpleTask) {
    this.loadingState.start('main.cultivationJournal.fieldTasks.deletingTask');

    this.taskService
      .deleteTask(task.id)
      .pipe(finalize(() => this.loadingState.stop()))
      .subscribe(() => {
        this.tasks = this.tasks.filter((t) => t.id !== task.id);
        this.filteredTasks = this.filteredTasks.filter((t) => t.id !== task.id);
      });
  }

  private updateTask(task: SimpleTask) {
    this.loadingState.start('main.cultivationJournal.fieldTasks.updatingTask');

    this.simpleTaskService
      .updateSimpleTask(task)
      .pipe(finalize(() => this.loadingState.stop()))
      .subscribe((newTask) => {
        this.tasks = this.sortTasks([
          ...this.tasks.filter((t) => t.id !== newTask.id),
          merge(new SimpleTask(this.field, task.cropId), newTask),
        ]);
        this.filteredTasks = this.filterTasks(this.tasks, this.cropId, this.operationTypeGroupId);
      });
  }

  private getAndSetTasks() {
    this.loadingState.start('main.cultivationJournal.fieldTasks.gettingTasks');

    this.simpleTaskService
      .getTasksByField(this.field, this.cropId, { bustCache: true })
      .pipe(
        take(1),
        finalize(() => this.loadingState.stop()),
        map((tasks) => tasks.map((task) => merge(new SimpleTask(this.field, this.cropId), task)))
      )
      .subscribe((tasks) => {
        this.tasks = this.sortTasks(tasks);
        this.filteredTasks = this.filterTasks(this.sortTasks(tasks), this.cropId, this.operationTypeGroupId);
      });
  }

  private getCurrentCrop() {
    return this.field.crops.reduce((prev, curr) => (prev.successionNo < curr.successionNo ? prev : curr), { id: null } as unknown as Crop);
  }

  private sortTasks(tasks: SimpleTask[]) {
    return tasks.sort((a, b) => a.date.valueOf() - b.date.valueOf());
  }

  private filterTasks(tasks: SimpleTask[], selectedCropId: number, selectedOperationTypeGroupId: number) {
    return tasks
      .filter((task) => task.cropId === selectedCropId)
      .filter((task) => {
        if (selectedOperationTypeGroupId === undefined || selectedOperationTypeGroupId === -1) {
          return true;
        }

        return task.operations.some((operation) => operation.operationTypeGroupId === selectedOperationTypeGroupId);
      });
  }
}
