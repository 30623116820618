import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { HotspotGroup } from '../../interfaces/hotspot-group.interface';

@Component({
  selector: 'app-group-item',
  templateUrl: './group-item.component.html',
  styleUrls: ['./group-item.component.scss'],
  standalone: false,
})
export class GroupItemComponent {
  @ViewChild('input', { static: false }) private input?: ElementRef;
  @Output() public nameChanged = new EventEmitter<string>();
  @Output() public deleteGroup = new EventEmitter<void>();
  @Input() public group!: HotspotGroup;
  public nameControl = new UntypedFormControl();
  public editing = false;

  constructor() {
    this.nameControl.setValidators([Validators.required, Validators.maxLength(254)]);
  }

  public focusInput() {
    this.editing = true;
    this.nameControl.setValue(this.group.name);
    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.focus();
        this.input.nativeElement.select();
      }
    });
  }

  public emitName() {
    if (this.nameControl.valid) {
      this.editing = false;
      if (this.nameControl.dirty) {
        this.nameChanged.emit(this.nameControl.value);
      }
    }
  }
}
