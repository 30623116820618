import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { driver } from 'driver.js';

@Component({
  selector: 'app-task-overview-tour',
  templateUrl: './task-overview-tour.component.html',
  styleUrls: ['./task-overview-tour.component.scss'],
  standalone: false,
})
export class TaskOverviewTourComponent implements OnInit {
  constructor(private _translateService: TranslateService) {}

  ngOnInit() {
    // Check if tour has already been shown
    const tourShown = localStorage.getItem('tour-task-overview');

    // Only proceed if tour hasn't been shown before
    if (!tourShown) {
      const driverObj = driver({
        showProgress: true,
        showButtons: ['next', 'previous', 'close'],
        nextBtnText: this._translateService.instant('tour.btn.next'),
        prevBtnText: this._translateService.instant('tour.btn.prev'),
        doneBtnText: this._translateService.instant('tour.btn.end'),
        progressText: this._translateService.instant('tour.btn.progress'),
        steps: [
          {
            popover: {
              title: this._translateService.instant('tour.farm-tasks-overview.step1.title'),
              description: this._translateService.instant('tour.farm-tasks-overview.step1.description'),
            },
          },
          {
            element: '.tableOverview',
            popover: {
              title: this._translateService.instant('tour.farm-tasks-overview.step2.title'),
              description: this._translateService.instant('tour.farm-tasks-overview.step2.description'),
              side: 'left',
              align: 'start',
            },
          },
          {
            element: '.top-section',
            popover: {
              title: this._translateService.instant('tour.farm-tasks-overview.step3.title'),
              description: this._translateService.instant('tour.farm-tasks-overview.step3.description'),
              side: 'left',
              align: 'start',
            },
          },
          {
            element: '.cropFilter',
            popover: {
              title: this._translateService.instant('tour.farm-tasks-overview.step4.title'),
              description: this._translateService.instant('tour.farm-tasks-overview.step4.description'),
              side: 'left',
              align: 'start',
            },
          },
          {
            element: '.productFilter',
            popover: {
              title: this._translateService.instant('tour.farm-tasks-overview.step5.title'),
              description: this._translateService.instant('tour.farm-tasks-overview.step5.description'),
              side: 'left',
              align: 'start',
            },
          },
          {
            element: '.machineEventFilter',
            popover: {
              title: this._translateService.instant('tour.farm-tasks-overview.step6.title'),
              description: this._translateService.instant('tour.farm-tasks-overview.step6.description'),
              side: 'left',
              align: 'start',
            },
          },
          {
            popover: {
              title: this._translateService.instant('tour.farm-tasks-overview.step7.title'),
              description: this._translateService.instant('tour.farm-tasks-overview.step7.description'),
            },
          },
        ],
        onCloseClick: () => {
          // Mark the tour as shown
          localStorage.setItem('tour-task-overview', 'true');
          driverObj.destroy();
        },
        onNextClick: () => {
          // Mark the tour as shown
          localStorage.setItem('tour-task-overview', 'true');
          driverObj.moveNext();
        },
      });

      // Start the tour
      driverObj.drive();
    }
  }
}
