import { TDateISO } from '@app/core/types/iso-date.type';
import { Status } from '../enums/status.enum';
import { FieldEntryDto } from '../interfaces/field-entry';
import { TaskEntryDto } from '../interfaces/task-entry';
import { TaskEntry } from './task-entry';

export class FieldEntry {
  private static _idCounter = 1;
  private _id: number;
  private _fieldYearId: number;
  private _farmId: number;
  private _label: string;
  private _name?: string;
  private _polygon?: string;
  private _cropNormNumber: number;
  private _cropName: string;
  private _cropColor?: string;
  private _area: number;
  private _successionNo: number;
  private _tasks: TaskEntry[];

  constructor(dto: FieldEntryDto) {
    this._fieldYearId = dto.fieldYearId;
    this._id = FieldEntry._idCounter++;
    this._farmId = dto.farmId;
    this._label = dto.label;
    this._name = dto.name;
    this._polygon = dto.polygon;
    this._cropNormNumber = dto.cropNormNumber;
    this._cropName = dto.cropName;
    this._cropColor = dto.cropColor;
    this._area = dto.area;
    this._successionNo = dto.successionNo;
    this._tasks = dto?.taskEntries.map((task) => new TaskEntry(task));
  }

  public get id(): number {
    return this._id;
  }

  public get farmId(): number {
    return this._farmId;
  }

  public get label(): string | undefined {
    return this._label;
  }

  public withLabel(label: string): FieldEntry {
    return { ...this, label: label };
  }

  public get name(): string | undefined {
    return this._name;
  }

  public withName(name: string): FieldEntry {
    return { ...this, name: name };
  }

  public get polygon(): string | undefined {
    return this._polygon;
  }

  public withPolygon(polygon: string): FieldEntry {
    return { ...this, polygon: polygon };
  }

  public get cropNormNumber(): number | undefined {
    return this._cropNormNumber;
  }

  public withCropNormNumber(cropNormNumber: number): FieldEntry {
    return { ...this, cropNormNumber: cropNormNumber };
  }

  public get cropName(): string | undefined {
    return this._cropName;
  }

  public withCropName(cropName: string): FieldEntry {
    return { ...this, cropName: cropName };
  }

  public get cropColor(): string | undefined {
    return this._cropColor;
  }

  public withCropColor(cropColor: string): FieldEntry {
    return { ...this, cropColor: cropColor };
  }

  public get area(): number | undefined {
    return this._area;
  }

  public withArea(area: number): FieldEntry {
    return { ...this, area: area };
  }

  public get isMainCrop(): boolean {
    return this.successionNo === 1;
  }

  public get successionNo(): number {
    return this._successionNo;
  }

  public withSuccessionNo(successionNo: number): FieldEntry {
    return { ...this, successionNo: successionNo };
  }

  public get tasks(): TaskEntry[] {
    return this._tasks;
  }

  /**
   * ONLY USE ON A CLONED INSTANCE
   */
  public set tasks(tasks: TaskEntry[]) {
    this._tasks = tasks;
  }

  public withTasks(tasks: TaskEntry[] | TaskEntryDto[]): FieldEntry {
    const updatedTasks = tasks.map((task) => (task instanceof TaskEntry ? task : new TaskEntry(task)));

    // Create a new FieldEntryDto from the current instance properties
    const updatedDto = this.toDto();
    updatedDto.taskEntries = updatedTasks.map((task) => task.toDto());

    // Return a new FieldEntry instance with the updated DTO
    return new FieldEntry(updatedDto);
  }

  public getTasks(): TaskEntry[] {
    return this._tasks;
  }

  public getTaskBetweenDates(startDate: TDateISO, endDate: TDateISO): TaskEntry[] {
    return this._tasks.filter((task) => task.date && task.date >= startDate && task.date <= endDate);
  }

  public getTasksWithCropNormNumber(cropNormNumber: number): TaskEntry[] {
    return this._tasks.filter((task) => task.area && task.area === cropNormNumber);
  }

  public getTasksWithStatus(status: Status): TaskEntry[] {
    return this._tasks.filter((task) => task.status === status);
  }

  public getFieldEntryWithProductTasks(productIds: number[]): FieldEntry {
    let result = this.withTasks(this._tasks.filter((task) => task.hasProduct(productIds)));
    result = result.withTasks(result.tasks.map((task) => task.moveProductsInPriorityOrder(productIds)));
    return result;
  }

  public hasFieldEntryWithProductTasks(productIds: number[]): boolean {
    const result = this._tasks.some((task) => task.hasProduct(productIds));
    return result;
  }

  public toDto(): FieldEntryDto {
    return {
      fieldYearId: this._id,
      farmId: this._farmId,
      label: this._label,
      name: this._name,
      polygon: this._polygon,
      cropNormNumber: this._cropNormNumber,
      cropName: this._cropName,
      cropColor: this._cropColor,
      area: this._area,
      isMainCrop: this.isMainCrop,
      successionNo: this._successionNo,
      taskEntries: this._tasks.map((task) => task.toDto()), // Assuming TaskEntry has its own toDto method
    };
  }
}
