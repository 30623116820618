import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Farm } from '@app/core/interfaces/farm.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { MapPdfPapersizeDialogComponent } from '@app/map/map-pdf-papersize-dialog/map-pdf-papersize-dialog.component';
import { MapSelector } from '@app/map/map.selector';
import { OlMapComponent } from '@app/map/ol-map/ol-map.component';
import { MapPdfService } from '@app/map/services/map-pdf/map-pdf.service';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { OpenLayersMapComponent } from '@app/shared/openlayers-map/openlayers-map.component';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { Subscription, map } from 'rxjs';

@Component({
  selector: 'app-pdf-map-print',
  templateUrl: './pdf-map-print.component.html',
  styleUrls: ['./pdf-map-print.component.scss'],
  standalone: false,
})
export class PdfMapPrintComponent implements OnInit {
  @Input() map!: OpenLayersMapComponent | OlMapComponent;
  @Input() hostElement!: ElementRef;
  protected disablePDFButton$ = this.accessControlService.hasAccessTo('map_generate_pdf').pipe(map((x) => !x));
  private harvestYear!: number;
  private currentFarms!: Farm[];

  private subscriptions: Subscription[] = [];
  public onClickPDF() {
    if (this.currentFarms.length === 0) {
      this.notificationService.showInfo('pdf.noFarmSelectet');
      return;
    }

    const dialogRef = this.dialogService.openCustomDialog<MapPdfPapersizeDialogComponent>(MapPdfPapersizeDialogComponent);

    dialogRef.afterClosed().subscribe((isA4) => {
      if (isA4 !== undefined) {
        this.mapPdfService.generatePdf(this.map, this.hostElement, this.currentFarms, this.harvestYear, isA4);
      }
    });
  }

  constructor(
    private accessControlService: AccessControlService,
    private mapPdfService: MapPdfService,
    private dialogService: DialogService,
    private mapSelector: MapSelector,
    private notificationService: NotificationService,
    private farmStateService: FarmStateService
  ) {}

  ngOnInit() {
    this.subscriptions.push(this.mapSelector.harvestYear$.subscribe((harvestYear) => (this.harvestYear = harvestYear)));
    this.subscriptions.push(this.farmStateService.selectedFarms$.subscribe((currentFarms) => (this.currentFarms = currentFarms)));
  }
}
