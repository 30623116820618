import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CropModels } from '@app/core/enums/crop-models.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { QueryParamService } from '@app/core/query-param/query-param.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { SubscriptionsValidatorService } from '@app/core/subcriptions-validator/subscriptions-validator.service';
import { fieldIdQueryParamKey } from '@app/map/features/cultivation-journal/cultivation-journal.variables.ts';
import { GTagEventTrackerService } from '@app/shared/pipes/gTagEventTracker/gTagEventTracker.service';
import { CultivationJournalStateService } from '@app/state/services/cultivation-journal/cultivation-journal-state.service';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CultivationJournalComponentEnum } from '../CultivationJournalComponentEnum.enum';
import { DecisionSupportService } from './decision-support/decision-support.service';

const ANIMATION_DELAY = 250;

@Component({
  selector: 'app-cultivation-journal-map-feature',
  templateUrl: './cultivation-journal-map-feature.component.html',
  styleUrls: ['./cultivation-journal-map-feature.component.scss'],
  animations: [
    trigger('contentVisibility', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate(`${ANIMATION_DELAY}ms ease`, style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate(`${ANIMATION_DELAY}ms ease`, style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
  standalone: false,
})
export class CultivationJournalMapFeatureComponent implements OnDestroy, OnInit {
  public field!: Field;

  public shownComponentState$ = this.cultivationJournalStateService.shownComponentState$;

  public components = CultivationJournalComponentEnum;

  public subscription = new Subscription();

  public selectedValue: CultivationJournalComponentEnum = this.components.CultivationJournal;

  @HostListener('document:keydown.tab', ['$event'])
  onKeydownHandlerTab(event: KeyboardEvent) {
    event.preventDefault();
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandlerShiftTab(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
    }
  }

  constructor(
    private sideDrawerRef: SideDrawerRef<CultivationJournalMapFeatureComponent, Field, void>,
    private queryParamService: QueryParamService,
    private cultivationJournalStateService: CultivationJournalStateService,
    private decisionSupportService: DecisionSupportService,
    private subscriptionValidator: SubscriptionsValidatorService,
    private gTagEventTrackerService: GTagEventTrackerService
  ) {}

  public ngOnInit() {
    this.gTagEventTrackerService.trackEvent('Field Plan Overview (view)', 'Field Plan').catch((error) => {
      console.error('GTagEventTrackerService ~ trackEvent ~ error', error);
    });

    this.field = this.sideDrawerRef.data;
    this.subscription.add(
      this.cultivationJournalStateService.shownComponentState$
        .pipe(filter((componentState) => componentState !== null))
        .subscribe((componentStateChange) => {
          this.selectedValue = componentStateChange;
        })
    );
  }

  public ngOnDestroy(): void {
    this.queryParamService.removeQueryParam(fieldIdQueryParamKey);
  }
  public onClose() {
    this.cultivationJournalStateService.cultivationJournalBtnShown = false;
    this.sideDrawerRef.close();
  }

  public onMinimize() {
    this.cultivationJournalStateService.cultivationJournalBtnShown = true;
    this.sideDrawerRef.hide();
  }

  public valueChange($event: CultivationJournalComponentEnum) {
    const componentStateName = CultivationJournalComponentEnum[$event];
    this.gTagEventTrackerService.trackEvent(`${componentStateName} Selector (click)`, 'Field Plan').catch((error) => {
      console.error('GTagEventTrackerService ~ trackEvent ~ error', error);
    });

    this.cultivationJournalStateService.setShownComponentState($event);
  }

  public get hasNRequirement$(): Observable<boolean> {
    return combineLatest([
      this.decisionSupportService.fieldHasModel(CropModels.NRequirement),
      this.subscriptionValidator.isCropManagerBasicOrPremium$,
    ]).pipe(map(([hasNRequirement, isBasicOrPremium]) => hasNRequirement && isBasicOrPremium));
  }
}
