import { Component } from '@angular/core';

@Component({
  selector: 'app-harvest-year-title',
  template: '<ng-content></ng-content>',
  standalone: false,
})
export class HarvestYearTitleComponent {}

@Component({
  selector: 'app-harvest-year-extras',
  template: '<ng-content></ng-content>',
  standalone: false,
})
export class HarvestYearExtrasComponent {}

@Component({
  selector: 'app-harvest-year-header',
  templateUrl: './harvest-year-header.component.html',
  styleUrls: ['./harvest-year-header.component.scss'],
  standalone: false,
})
export class HarvestYearHeaderComponent {}
