import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Farm } from '@app/core/interfaces/farm.interface';
import { fileExtensionValidator } from '@app/helpers/file/file-extension.validator';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AsAppliedFileUploadService } from '../services/as-applied-file-upload.service';

@Component({
  selector: 'app-as-applied-file-picker',
  templateUrl: './as-applied-file-picker.component.html',
  styleUrls: ['./as-applied-file-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AsAppliedFilePickerComponent implements OnInit, OnDestroy {
  public form!: UntypedFormGroup;
  public selectableFarms$!: Observable<Farm[]>;
  public selectableHarvestYears$!: Observable<number[]>;
  public selectedHarvestYear$ = this.harvestYearStateService.harvestYear$;

  private subscriptions = new Subscription();

  @ViewChild('asAppliedFilePicker', { static: false }) public filePicker!: ElementRef;

  constructor(
    private asAppliedFileUploadService: AsAppliedFileUploadService,
    private formBuilder: UntypedFormBuilder,
    private harvestYearStateService: HarvestYearStateService
  ) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      farm: ['', [Validators.required]],
      files: [[], [fileExtensionValidator(['zip']), Validators.required]],
    });
    this.selectableFarms$ = this.asAppliedFileUploadService.selectableFarms$;
    this.selectableHarvestYears$ = this.asAppliedFileUploadService.selectableHarvestYears$;
    this.subscriptions.add(
      this.selectableFarms$.subscribe((farms) => {
        if (farms.length === 1) {
          this.form.controls['farm'].setValue(farms[0]);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onFileInputChange(event: Event) {
    const filesControl = this.form.get('files');
    const filesList = (event.target as HTMLInputElement).files;
    if (filesList) {
      const files = Array.from(filesList) as File[];
      if (files?.length > 0) {
        filesControl?.setValue(files);
      }
    }
    // Resets the file inputs internal value, so the same files can be picked more than once.
    this.filePicker.nativeElement.value = null;
    filesControl?.markAsTouched();
  }

  public trackByFn(index: number) {
    return index;
  }

  public uploadClicked() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.selectedHarvestYear$.pipe(first()).subscribe((harvestYear) => {
        this.asAppliedFileUploadService.uploadFile(this.form.get('files')?.value[0], this.form.get('farm')?.value, harvestYear);
      });
    }
  }
}
