import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { distinctUntilChanged, map, skip } from 'rxjs';
import { CropFilterService } from './crop-filter.service';
import { FilterCrop } from './crop-filter.store';

@Component({
  selector: 'app-map-crop-filter-control',
  templateUrl: './map-crop-filter-control.component.html',
  styleUrls: ['./map-crop-filter-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MapCropFilterControlComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private _accessControlService: AccessControlService,
    private _harvestYearService: HarvestYearStateService,
    private _farmStateService: FarmStateService,
    private _cropFilterService: CropFilterService
  ) {}

  protected cropTotalArea = {
    first: 0.0,
    second: 0.0,
    third: 0.0,
  };
  protected crops$ = this._cropFilterService.allCrops$;

  protected form!: FormGroup;

  private _subs = new SubscriptionArray();
  protected hasAccess$ = this._accessControlService.policies$.pipe(
    map((policy) => {
      return policy.includes('basic');
    })
  );

  ngOnInit() {
    this._subs.add(
      this.crops$.pipe().subscribe((crops) => {
        this.form = this.fb.group({
          crops: this.fb.array(
            crops.map((crop) => {
              return this.createGroup(crop);
            })
          ),
        });
        this.calculcateTotalAreaCrop();
      })
    );

    this._subs.add(
      this._farmStateService.selectedFarms$
        .pipe(skip(1), distinctUntilChanged())
        .subscribe((_) => this._cropFilterService.resetCropFilter())
    );
    this._subs.add(
      this._harvestYearService.harvestYear$
        .pipe(skip(1), distinctUntilChanged())
        .subscribe((_) => this._cropFilterService.resetCropFilter())
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private createGroup(crop: FilterCrop) {
    const group = this.fb.group({
      id: [crop.id],
      name: [crop.name],
      color: [crop.color],
      totalArea: [crop.totalArea],
      enabled: [crop.enabled],
      fieldIds: [crop.fieldIds],
      succession: [crop.succession],
    });
    return group;
  }
  protected selectAll() {
    this.crops.controls.forEach((control) => {
      control.patchValue({ enabled: true });
    });
    this._cropFilterService.resetCropFilter();
    this.calculcateTotalAreaCrop();
  }

  protected deselectAll() {
    this.crops.controls.forEach((control) => {
      control.patchValue({ enabled: false });
    });

    const allCrops = this.crops.controls.map((control) => {
      return {
        id: control.get('id')?.value,
        fieldIds: control.get('fieldIds')?.value,
        color: control.get('color')?.value,
        name: control.get('name')?.value,
        totalArea: control.get('totalArea')?.value,
        succession: control.get('succession')?.value,
        enabled: control.get('enabled')?.value,
      };
    });
    this._cropFilterService.updateCropFilters(allCrops);
    this.cropTotalArea = {
      first: 0.0,
      second: 0.0,
      third: 0.0,
    };
  }
  protected isAllSelected() {
    return this.crops.controls.every((control) => {
      const enabled = control.get('enabled')?.value;
      return enabled;
    });
  }

  toggleCrop(crop: AbstractControl, event: MouseEvent) {
    this._cropFilterService.updateCropFilter({
      id: crop.get('id')?.value,
      fieldIds: crop.get('fieldIds')?.value,
      color: crop.get('color')?.value,
      name: crop.get('name')?.value,
      totalArea: crop.get('totalArea')?.value,
      succession: crop.get('succession')?.value,
      enabled: crop.get('enabled')?.value,
    });
    this.calculcateTotalAreaCrop();
  }

  private calculcateTotalAreaCrop() {
    const totalAreaFirst = this.firstTermCrops.controls
      .filter((cropsControl) => cropsControl.get('enabled')?.value === true)
      .map((cropsControl) => cropsControl.get('totalArea')?.value)
      .reduce((acc, area) => acc + (area || 0), 0);
    const totalAreaSecond = this.secondTermCrops.controls
      .filter((cropsControl) => cropsControl.get('enabled')?.value === true)
      .map((cropsControl) => cropsControl.get('totalArea')?.value)
      .reduce((acc, area) => acc + (area || 0), 0);
    const totalAreaThird = this.thirdTermCrops.controls
      .filter((cropsControl) => cropsControl.get('enabled')?.value === true)
      .map((cropsControl) => cropsControl.get('totalArea')?.value)
      .reduce((acc, area) => acc + (area || 0), 0);
    this.cropTotalArea = this.cropTotalArea = {
      first: totalAreaFirst,
      second: totalAreaSecond,
      third: totalAreaThird,
    };
  }

  get crops(): FormArray {
    return this.form.get('crops') as FormArray;
  }

  get firstTermCrops(): FormArray {
    const filteredControls = this.crops.controls.filter((control) => control.get('succession')?.value === 1);

    return new FormArray(filteredControls);
  }

  get secondTermCrops(): FormArray {
    const filteredControls = this.crops.controls.filter((control) => control.get('succession')?.value === 2);

    return new FormArray(filteredControls);
  }

  get thirdTermCrops(): FormArray {
    const filteredControls = this.crops.controls.filter((control) => control.get('succession')?.value === 3);

    return new FormArray(filteredControls);
  }
}
