import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-wet-hours-chart-info',
  templateUrl: './wet-hours-chart-info.component.html',
  styleUrls: ['./wet-hours-chart-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class WetHoursChartInfoComponent {
  constructor(private dialogRef: MatDialogRef<WetHoursChartInfoComponent>) {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
