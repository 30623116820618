import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InfoDialogContentComponent } from '@app/map/features/prognosis/growth-regulation/insurance-info/info-dialog-content/info-dialog-content.component';
import { DialogService } from '@app/shared/dialog/dialog.service';

@Component({
  selector: 'app-new-in-cropmanager-dialog',
  templateUrl: './new-in-cropmanager-dialog.component.html',
  styleUrls: ['./new-in-cropmanager-dialog.component.scss'],
  standalone: false,
})
export class NewInCropmanagerDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<NewInCropmanagerDialogComponent>,
    public dialogService: DialogService
  ) {}

  public close() {
    this.dialogRef.close();
  }

  public showInfoDialog() {
    this.dialogRef.updatePosition().addPanelClass('cdk-global-overlay-wrapper');

    this.dialogService
      .openCustomDialog(InfoDialogContentComponent, {})
      .afterClosed()
      .subscribe(() => {
        this.dialogRef.updatePosition().addPanelClass('buy-extra-dialog-backdrop');
      });
  }
}
