import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoarseFodderDataSource } from '@app/core/economy/enum/coarseFodderDataSource.enum';
import { CoarseFodderEconomyDataSource } from '@app/core/economy/interface/coarsefodder';
import { LanguageService } from '@app/core/language/language.service';
import { SourceUtil } from './../sourceUtil';

@Component({
  selector: 'app-sourcepicker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sourcepicker.component.html',
  styleUrls: ['./sourcepicker.component.scss'],
  standalone: false,
})
export class SourcepickerComponent implements OnInit {
  public sources: CoarseFodderEconomyDataSource[] = [];
  public selectedSource: CoarseFodderDataSource = CoarseFodderDataSource.Manual;
  public fieldName!: string;
  public title = '';
  constructor(
    private languageService: LanguageService,
    private dialogRef: MatDialogRef<SourcepickerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { sources: CoarseFodderEconomyDataSource[]; selectedSource: number; fieldName: string; columnName: string }
  ) {}

  public ngOnInit() {
    this.sources = this.data.sources;
    this.selectedSource = this.data.selectedSource;
    this.fieldName = this.data.fieldName;

    this.title = this.languageService.getText('main.economy.sourcePicker.subtitle', {
      source: this.data.columnName,
      fieldName: this.data.fieldName,
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public onSourceClick(source: number) {
    this.dialogRef.close({ selectedSource: source });
  }

  public getStyleForSource(source: CoarseFodderEconomyDataSource) {
    const color = SourceUtil.getTabColorFromSource(source.dataSource);
    return `border-top: 15px solid ${color}`;
  }
}
