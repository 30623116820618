import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { DateTime } from 'luxon';
import { BehaviorSubject, debounceTime, of } from 'rxjs';

@Component({
  selector: 'app-as-applied-task-log-list',
  templateUrl: './as-applied-task-log-list.component.html',
  styleUrls: ['./as-applied-task-log-list.component.scss'],
  standalone: false,
})
export class AsAppliedTaskLogListComponent implements OnInit {
  @Input() public form!: FormGroup;
  @Input() public date!: DateTime | undefined;
  @Input() public field!: string | undefined;
  public logCount: number = 0;
  private _allSelectedSubject = new BehaviorSubject(true);
  protected allSelected$ = this._allSelectedSubject.asObservable();

  private _subs = new SubscriptionArray();

  constructor() {}

  ngOnInit() {
    this.logCount = this.logArray.length;
    this._subs.add(
      this.logArray!.valueChanges.pipe(debounceTime(10)).subscribe(() => {
        this._allSelectedSubject.next(this.isAllSelected());
        this.logCount = this.logArray.length;
      })
    );
  }

  get logArray() {
    return this.form.get('logs') as FormArray;
  }

  protected selectAll() {
    this.logArray.controls.forEach((control) => {
      control.patchValue({ selected: true });
    });
  }

  protected deselectAll() {
    this.logArray.controls.forEach((control) => {
      control.patchValue({ selected: false });
    });
  }
  protected isAllSelected() {
    return this.logArray.controls.every((control) => {
      const selected = control.get('selected')?.value;
      return selected;
    });
  }
}
