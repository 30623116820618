import { Component } from '@angular/core';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { OperationTypes } from '@app/core/enums/operation-types.enum';
import { OperationType } from '@app/core/interfaces/operation.type';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';
import { OperationTypeGroupsService } from '@app/core/operation-type-groups/operation-type-groups.service';
import { OperationTypeGroup } from '@app/core/repositories/operation-type-groups/operation-type-groups.interface';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { negate } from '@app/shared/operators/negate';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

interface OperationTypeGroupItem extends OperationTypeGroup {
  exists: boolean;
  disabled: boolean;
}

@Component({
  selector: 'app-operation-selector',
  templateUrl: './operation-selector.component.html',
  styleUrls: ['./operation-selector.component.scss'],
  standalone: false,
})
export class OperationSelectorComponent {
  private _task = this.sideDrawerRef.data;

  private _filteredOperationTypes$ = this.operationTypeGroupService.get().pipe(
    // Lime shouldn't be a selectable operation type
    map((operationTypes) => operationTypes.filter((operationType) => operationType.id !== OperationTypeGroupEnum.Lime))
  );

  protected _noEdit$ = this.accessControlService.hasAccessTo('field_plan_cultivation_journal_write').pipe(negate());

  protected operationTypes$ = combineLatest([this._filteredOperationTypes$, this._noEdit$]).pipe(
    map(([operationTypes, disable]) =>
      operationTypes.map<OperationTypeGroupItem>((operationType) => ({
        ...operationType,
        exists: this.taskHasOperation(operationType),
        disabled: this.shouldDisable(operationType, disable),
      }))
    )
  );

  constructor(
    private operationTypeGroupService: OperationTypeGroupsService,
    private sideDrawerRef: SideDrawerRef<OperationSelectorComponent, SimpleTask, OperationType>,
    private accessControlService: AccessControlService
  ) {}

  public onCloseClick() {
    this.sideDrawerRef.close();
  }

  public onOperationTypeSelect(operationTypeGroup: OperationTypeGroupItem) {
    if (operationTypeGroup.exists || operationTypeGroup.disabled) return;

    this.sideDrawerRef.close(operationTypeGroup);
  }

  // disable everything BUT seeding and harvesting operations if user has no edit access
  private shouldDisable = (operationTypeGroup: OperationTypeGroup, disable: boolean) =>
    disable && !operationTypeGroup.operationTypeIds.some((x) => x === OperationTypes.Yield);

  private taskHasOperation = (operationTypeGroup: OperationTypeGroup) =>
    !!this._task.operations.find((operation) => operation.operationTypeGroupId === operationTypeGroup.id);
}
