import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GrassPrognosisSampleValue } from '@app/core/repositories/grass-prognosis/grass-prognosis-sample-value.interface';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { DateTime } from 'luxon';
import { finalize } from 'rxjs/operators';
import { GrassPrognosisService } from '../grass-prognosis.service';
import { GrassPrognosisOptions } from './grass-prognosis-settings.interface';
@Component({
  selector: 'app-grass-prognosis-settings',
  templateUrl: './grass-prognosis-settings.component.html',
  styleUrls: ['./grass-prognosis-settings.component.scss'],
  standalone: false,
})
export class GrassPrognosisSettingsComponent implements OnInit {
  public formGroup = new UntypedFormGroup({
    cutNumber: new UntypedFormControl(null),
    previousCutYield: new UntypedFormControl(null),
    sampleFKOrg: new UntypedFormControl(null),
    sampleRp: new UntypedFormControl(null),
    sampleSugar: new UntypedFormControl(null),
  });

  public cutOptions: GrassPrognosisSampleValue[] = [];
  public yieldOptions: GrassPrognosisSampleValue[] = [];
  public fkOrgOptions: GrassPrognosisSampleValue[] = [];
  public proteinOptions: GrassPrognosisSampleValue[] = [];
  public sugarOptions: GrassPrognosisSampleValue[] = [];

  public isLoading = false;
  public loadingMessage = this.grassPrognosisService.settingsLoadingMessage;
  public options!: GrassPrognosisOptions;
  public previousCut: GrassPrognosisSampleValue = {
    text: '',
    value: 0,
  };

  private previousOptions!: GrassPrognosisOptions;

  constructor(
    private grassPrognosisService: GrassPrognosisService,
    private sideDrawerRef: SideDrawerRef<GrassPrognosisSettingsComponent, GrassPrognosisOptions, GrassPrognosisOptions>
  ) {}

  public ngOnInit() {
    this.getSampleValues();

    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.cutNumberControl.valueChanges.subscribe((value: number) => {
      this.previousCut = this.findPreviousCut(value);
      this.togglePreviousCutInputStates(value);
    });

    this.formGroup.valueChanges.subscribe((value: Partial<GrassPrognosisOptions>) => {
      this.options = { ...this.options, ...value };
    });

    this.setOptions(this.sideDrawerRef.data);
  }

  public onCloseClick() {
    this.sideDrawerRef.close(this.previousOptions);
  }

  public onPreviousCutDateChange(date: DateTime) {
    this.options.previousCutDate = date.setZone('utc');
  }

  public onSampleDateChange(date: DateTime) {
    this.options.sampleDate = date.setZone('utc');
  }

  public onSampleChange(isEnabled: boolean) {
    this.toggleSampleInputStates(isEnabled);

    this.options.useSampleData = isEnabled;
  }

  public onDifferentStartDateChange(date: DateTime) {
    this.options.testRunDate = date.setZone('utc');
  }

  public onDifferentStartClick(isEnabled: boolean) {
    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    isEnabled ? (this.options.testRunDate = DateTime.now()) : (this.options.testRunDate = null);
  }

  public get cutNumberControl() {
    return this.formGroup.get('cutNumber');
  }

  public onSaveClick() {
    this.sideDrawerRef.close(this.options);
  }

  private setOptions(options: GrassPrognosisOptions) {
    this.options = { ...options };
    this.previousOptions = { ...options };

    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.formGroup.controls.cutNumber.setValue(options.cutNumber);

    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.formGroup.controls.previousCutYield.setValue(options.previousCutYield);

    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.formGroup.controls.sampleFKOrg.setValue(options.sampleFKOrg);

    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.formGroup.controls.sampleRp.setValue(options.sampleRp);

    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.formGroup.controls.sampleSugar.setValue(options.sampleSugar);
    this.toggleSampleInputStates(options.useSampleData);
    this.togglePreviousCutInputStates(options.cutNumber);
  }

  private toggleSampleInputStates(isEnabled: boolean) {
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    isEnabled ? this.formGroup.controls.sampleFKOrg.enable() : this.formGroup.controls.sampleFKOrg.disable();

    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    isEnabled ? this.formGroup.controls.sampleRp.enable() : this.formGroup.controls.sampleRp.disable();

    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    isEnabled ? this.formGroup.controls.sampleSugar.enable() : this.formGroup.controls.sampleSugar.disable();
  }

  private togglePreviousCutInputStates(currentCut: number) {
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    currentCut > 1 ? this.formGroup.controls.previousCutYield.enable() : this.formGroup.controls.previousCutYield.disable();
  }

  private findPreviousCut(currentCut: number) {
    const previousCut = this.cutOptions.find((option) => option.value === currentCut - 1);
    return previousCut ? previousCut : this.previousCut;
  }

  private getSampleValues() {
    this.isLoading = true;
    this.grassPrognosisService
      .getSampleValues()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(([cutNumbers, fkOrg, rp, sugar, previousCutYields]) => {
        this.cutOptions = cutNumbers;
        this.fkOrgOptions = fkOrg;
        this.proteinOptions = rp;
        this.sugarOptions = sugar;
        this.yieldOptions = previousCutYields;

        // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        this.previousCut = this.findPreviousCut(this.cutNumberControl.value);
      });
  }
}
