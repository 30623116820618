import { Injectable } from '@angular/core';
import { LanguageService } from '@app/core/language/language.service';
import { FieldEntry } from '@app/farm-tasks-overview/class/field-entry';
import { ProductSubType } from '@app/farm-tasks-overview/components/product-filter/product-filter.service';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { setEntities } from '@ngneat/elf-entities';
import { uniqBy } from 'lodash-es';
import { combineLatest, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs';
import { FieldEntryRepositoryService } from '../../field-entry-repository.service';
import { MACHINEEVENTOPERATIONTYPES } from './field-entry.query';
import { FieldEntryStore, SortDirection, SortType } from './field-entry.store';

// Define the CropFilterType interface
export interface CropFilterType {
  cropName: string;
  term: number;
}

@Injectable({
  providedIn: 'root',
})
export class FieldEntryService {
  constructor(
    private _store: FieldEntryStore,
    private _harvestYearService: HarvestYearStateService,
    private _farmService: FarmStateService,
    private _fieldEntryRepository: FieldEntryRepositoryService,
    private _languageService: LanguageService
  ) {}
  private _subscriptions = new SubscriptionArray();

  public init() {
    this._subscriptions.unsubscribe();
    this._subscriptions.push(this.fieldEntryFetchOnFarmOrYearChange());
    this._store.loadState();
  }

  public shutdown() {
    this._subscriptions.unsubscribe();
  }

  public resetStoreReadyState() {
    this._store.store.update((state) => ({ ...state, isReady: false }));
  }
  private fieldEntryFetchOnFarmOrYearChange() {
    let isFirstCall = true; // Flag to track the first call

    return combineLatest([this._harvestYearService.harvestYear$, this._farmService.selectedFarmIds$])
      .pipe(
        distinctUntilChanged(
          (prev, curr) =>
            prev[0] === curr[0] && // Compare harvestYear
            JSON.stringify(prev[1]) === JSON.stringify(curr[1]) // Compare farmIds
        ),
        debounceTime(50),
        switchMap(([harvestYear, farmIds]) => {
          const language = this._languageService.currentLanguage.shortKey;
          if (!harvestYear || !farmIds || farmIds.length === 0) {
            console.warn('Harvest year or farm IDs are missing');
            return [];
          }
          return this._fieldEntryRepository.getFieldEntries(farmIds, harvestYear, language);
        }),
        tap((fieldEntries) => {
          const fieldEntryEntities = fieldEntries.map((f) => new FieldEntry(f));
          if (!isFirstCall) {
            // Only update crops after the first call

            const crops = Object.values(fieldEntryEntities)
              .map((f) => ({ cropName: f.cropName ?? '', term: f.successionNo ?? 0 })) // Include term
              .filter((entry) => entry.cropName !== '') // Remove empty crop names
              .filter(
                (entry, index, self) => self.findIndex((e) => e.cropName === entry.cropName && e.term === entry.term) === index // Remove duplicates
              );

            const rawProducts = fieldEntryEntities.flatMap((fe) => fe.getTasks().flatMap((t) => t.getProducts()));

            const products = uniqBy(
              rawProducts.filter((p) => !MACHINEEVENTOPERATIONTYPES.includes(+p.type)),
              'id'
            ).map((p) => this.createSubType(p));

            const machineEvents = uniqBy(
              rawProducts.filter((p) => MACHINEEVENTOPERATIONTYPES.includes(+p.type)).map(this.createSubType),
              'id'
            );
            this._store.store.update((state) => ({
              ...state,
              crops: crops,
              products: products,
              machineEvents: machineEvents,
            }));
          }

          // Update entities regardless of the first call
          this._store.store.update(setEntities(fieldEntryEntities));

          isFirstCall = false; // Set the flag to false after the first call
        })
      )
      .subscribe({
        error: (err) => console.error('Error fetching field entries:', err),
      });
  }

  private createSubType(product: { id: number; label?: string }): ProductSubType {
    return {
      id: product.id,
      productId: product.id,
      label: product.label ?? '',
      enabled: true,
    };
  }
  public destroy() {
    this._subscriptions.unsubscribe();
  }

  setSort(sortType: SortType, sortDirection: SortDirection) {
    this._store.store.update((state) => ({
      ...state,
      sortType,
      sortDirection,
    }));
  }

  resetCropFilter() {
    setTimeout(() => {
      this._store.store.update((state) => ({
        ...state,
        crops: Object.values(state.entities)
          .map((f) => ({ cropName: f.cropName ?? '', term: f.successionNo ?? 0 })) // Include term
          .filter((entry) => entry.cropName !== '') // Remove empty crop names
          .filter(
            (entry, index, self) => self.findIndex((e) => e.cropName === entry.cropName && e.term === entry.term) === index // Remove duplicates
          ),
      }));
    }, 200);
  }

  clearCropFilter() {
    this._store.store.update((state) => ({
      ...state,
      crops: [],
    }));
  }

  updateCropFilter(cropFilter: CropFilterType) {
    this._store.store.update((state) => {
      const cropFilters = state.crops;
      const index = cropFilters.findIndex((c) => c.cropName === cropFilter.cropName && c.term === cropFilter.term);
      if (index === -1) {
        cropFilters.push(cropFilter);
      } else {
        cropFilters.splice(index, 1);
      }

      return {
        ...state,
        crops: cropFilters,
      };
    });
  }
}
