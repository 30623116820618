import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DataConnectionTypes } from '@app/core/data-connections/data-connection-types.enum';
import { DataConnectionsService } from '@app/core/data-connections/data-connections.service';
import { DatamanagementService } from '@app/core/datamanagement/datamanagement.service';
import { TransferStatus } from '@app/core/enums/transfer-status.enum';
import { ClaasWork } from '@app/core/interfaces/claas-work.interface';
import { LanguageService } from '@app/core/language/language.service';
import { MessageService } from '@app/core/messages/messages.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { TransferTasksDataSource } from '@app/settings/datamanagement/transfer-tasks/helpers/transfer-tasks-data-source';
import { TransferTaskService } from '@app/settings/datamanagement/transfer-tasks/service/transfer-task.service';
import { IntervalPickerComponent } from '@app/shared/interval-picker/interval-picker.component';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, Subscription, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-transfer-tasks',
  templateUrl: './transfer-tasks.component.html',
  styleUrls: ['./transfer-tasks.component.scss'],
  standalone: false,
})
export class TransferTasksComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) public paginator!: MatPaginator;

  public startDate: DateTime = DateTime.now().minus({ days: 14 });
  public endDate: DateTime = DateTime.now().plus({ years: 1 });
  public displayedColumns = [
    'farmName',
    'fieldNumber',
    'fieldArea',
    'area',
    'productName',
    'startTime',
    'quantity',
    'dryMatter',
    'transfer',
  ];
  public numberOfTasks = 0;
  public isTasksLoading = new BehaviorSubject<boolean>(false);
  public hasDataConnections = new BehaviorSubject<boolean>(false);
  public isTransferingAllTasks = new BehaviorSubject<boolean>(false);
  public transferStatus = TransferStatus;
  public languageShortKey!: string;
  public works: ClaasWork[] = [];
  public dataSource: Observable<TransferTasksDataSource>;
  private intervalPickerModalRef!: MatDialogRef<IntervalPickerComponent>;
  private intervalSubject = new BehaviorSubject<{ startDate: DateTime; endDate: DateTime }>({
    startDate: this.startDate,
    endDate: this.endDate,
  });
  private subscriptions: Subscription = new Subscription();

  constructor(
    private notificationService: NotificationService,
    private messageService: MessageService,
    private datamanagementService: DatamanagementService,
    private mdDialog: MatDialog,
    private languageService: LanguageService,
    private transferTaskService: TransferTaskService,
    private farmStateService: FarmStateService,
    private translateService: TranslateService,
    private dataConnectionsService: DataConnectionsService,
    private cdr: ChangeDetectorRef
  ) {
    this.dataSource = this.dataSourceWithEnrichedClaasWorks$;
  }

  public ngOnInit() {
    this.paginator.pageSize = 10;

    this.languageShortKey = this.languageService.currentLanguage.shortKey;
    this.subscriptions.add(
      this.languageService.languageChanged.subscribe(() => {
        this.languageShortKey = this.languageService.currentLanguage.shortKey;
      })
    );
  }

  protected dataSourceWithEnrichedClaasWorks$ = combineLatest([
    this.intervalSubject.asObservable(),
    this.dataConnectionsService.getSettings(),
  ]).pipe(
    map(([, connections]) => connections?.filter((connection) => connection.connectionTypeId === DataConnectionTypes.Claas)),
    tap((claasConnections) => {
      const hasConnections = claasConnections && claasConnections.length > 0;
      this.hasDataConnections.next(hasConnections);
      this.toggleLoadingState(hasConnections);
    }),
    filter((claasConnections) => claasConnections && claasConnections.length > 0),
    delay(500),
    withLatestFrom(this.farmStateService.farms$, this.farmStateService.selectedFarms$, this.transferTaskService.getTransferedWorks()),
    switchMap(([claasConnections, farms, selectedFarms, claasWorkLogs]) => {
      const selectedOrFarms = selectedFarms || farms;
      return forkJoin([
        this.transferTaskService.getFieldsForFarmsAndHarvestYear(selectedOrFarms, this.startDate),
        this.transferTaskService.getProduceNormsForFarmsAndHarvestYear(selectedOrFarms, this.startDate),
      ]).pipe(
        switchMap(() => this.transferTaskService.getClaasWorksForConnections(claasConnections, this.startDate, this.endDate)),
        map((works) => this.transferTaskService.enrichWorks(works)),
        map((works) => this.transferTaskService.filterClaasTasksForCurrentUserFarms(works, selectedOrFarms)),
        map((works) => this.transferTaskService.filterClaasWorksToNotImportedWorks(works, claasWorkLogs)),
        tap((works) => (this.works = works)),
        map((works) => this.ClaasWorksToTransferTasksDataSource(works)),
        catchError((err) => {
          this.toggleLoadingState(false);
          throw err;
        })
      );
    })
  );

  private toggleLoadingState(isLoading: boolean) {
    this.isTasksLoading.next(isLoading);
    this.cdr.detectChanges();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public isTransferable = (task: ClaasWork) => this.datamanagementService.isWorkTransferable(task);

  public isAnyTransferable = () => this.works.some((work) => this.isTransferable(work) && !this.hasTransferError(work));

  public isTransferAllDisabled = () =>
    this.isTransferingAllTasks.getValue() || !this.hasDataConnections.getValue() || !this.isAnyTransferable();

  public isTransferAllHidden = () => this.isTasksLoading.getValue();

  public shouldShowTransferButton = (work: ClaasWork) => this.isNewWork(work) && this.isTransferable(work);

  public shouldShowNoConnectionsMsg = () => !this.isTasksLoading.getValue() && !this.hasDataConnections.getValue();

  public transferAllWorks() {
    this.subscriptions.add(this.transferTaskService.transferAllWorks(this.works).subscribe((works) => this.onTransferAllWorks(works)));
  }

  public transferWork = (work: ClaasWork) =>
    this.subscriptions.add(this.transferTaskService.transferWork(work).subscribe(() => this.setTransferStatusDoneAndNotify(work)));

  public isNewWork = (work: ClaasWork) => this.transferTaskService.isNewWork(work);

  public isTransfered = (work: ClaasWork) => this.transferTaskService.isTransfered(work);

  public isTransfering = (work: ClaasWork) => this.transferTaskService.isTransfering(work);

  public hasTransferError = (work: ClaasWork) => work.transferStatus === this.transferStatus.ERROR;

  public displayErrorMessage = (work: ClaasWork) => this.notificationService.showError(work.transferError);

  public onTransferAllWorks(works: ClaasWork[]) {
    works = works.map((work) => this.setTransferStatusDone(work));
    this.works = [...this.works.filter((work) => !works.find((w) => w.internalId === work.internalId)), ...works];

    this.dataSource = of(this.ClaasWorksToTransferTasksDataSource(this.works));

    works.some((work) => this.hasTransferError(work))
      ? this.notificationService.showError(`${this.messageService.getDataExchangeMsg().saveAllTasksError}`)
      : this.notificationService.showInfo(`${this.messageService.getDataExchangeMsg().saveAllTasksSuccess}`);
  }

  public setTransferStatusDone(work: ClaasWork) {
    if (work.transferStatus === this.transferStatus.ERROR) {
      return work;
    }

    work.transferStatus = TransferStatus.DONE;
    return work;
  }

  public setTransferStatusDoneAndNotify(work: ClaasWork) {
    this.setTransferStatusDone(work);
    this.notificationService.showInfo(this.messageService.getDataExchangeMsg().saveTaskSuccess);
  }

  public onPickIntervalClicked() {
    this.intervalPickerModalRef = this.mdDialog.open(IntervalPickerComponent, {
      data: {
        startDate: this.startDate,
        endDate: this.endDate,
        title: this.translateService.instant('main.datamanagement.fieldTransfer.datePicker'),
      },
    });
    this.intervalPickerModalRef.componentInstance.dialogRef = this.intervalPickerModalRef;
    this.intervalPickerModalRef.disableClose = true;

    this.intervalPickerModalRef
      .afterClosed()
      .pipe(filter((dates: { startDate: DateTime; endDate: DateTime }) => this.isDateChanged(this.startDate, this.endDate, dates)))
      .subscribe((dates: { startDate: DateTime; endDate: DateTime }) => this.onDateChange(dates));
  }

  public onDateChange(dates: { startDate: DateTime; endDate: DateTime }) {
    this.startDate = dates.startDate;
    this.endDate = dates.endDate;
    this.intervalSubject.next(dates);
  }

  private ClaasWorksToTransferTasksDataSource(works: ClaasWork[]) {
    const worksAsBehavSubj = new BehaviorSubject<ClaasWork[]>(works);
    this.isTasksLoading.next(false);
    if (this.paginator) {
      this.paginator.length = works.length;
    }
    return new TransferTasksDataSource(worksAsBehavSubj, this.paginator);
  }

  private isDateChanged = (startDate: DateTime, endDate: DateTime, result: { startDate: DateTime; endDate: DateTime }) =>
    !this.startDate.hasSame(result.startDate, 'day') || !this.endDate.hasSame(result.endDate, 'day');
}
