import { Component, OnInit } from '@angular/core';
import { ConsentStateService } from '@app/core/consent/consent-state.service';
import { IWindow } from '@app/core/interfaces/window.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { QueryParamService } from '@app/core/query-param/query-param.service';
import { ConsentRepo } from '@app/core/repositories/consent/consent-repo.service';
import { Consent, ConsentTypes } from '@app/core/repositories/consent/consent.interface';
import { WindowRefService } from '@app/core/window/window-ref.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-no-consent',
  templateUrl: './no-consent.component.html',
  styleUrls: ['./no-consent.component.scss'],
  standalone: false,
})
export class NoConsentComponent implements OnInit {
  public consents!: Consent[];
  private window: IWindow;
  public consentTypes = ConsentTypes;

  constructor(
    windowRefService: WindowRefService,
    private queryParamService: QueryParamService,
    private consentStateService: ConsentStateService,
    private consentRepo: ConsentRepo,
    private notificationService: NotificationService
  ) {
    this.window = windowRefService.nativeWindow;
  }

  public ngOnInit() {
    this.consentStateService.consents$.subscribe((consents: Consent[]) => {
      this.onConsentReady(consents);
    });
  }

  public onConsentReady(consents: Consent[]): void {
    this.consents = consents;
  }

  public onGetConsentClicked(consent: Consent): void {
    consent.statusId = this.consentTypes.Given;
    this.queryParamService.getQueryParamNullable('returnUrl', false).subscribe((returnUrl) => {
      window.open(this.getConsentBaseUrl(consent.decisionUrl, encodeURIComponent(returnUrl)), '_blank');
    });
  }
  private getConsentBaseUrl(decisionUrl: string, returnUrl: string): string {
    return decisionUrl.replace('{0}', returnUrl);
  }

  protected checkAccepted() {
    this.consentRepo
      .get(ConsentRepo.consentParam)
      .pipe(first())
      .subscribe((consents) => {
        if (consents.every((consent) => consent.statusId === ConsentTypes.Given)) {
          window.location.href = '/';
        } else {
          this.notificationService.showError('consent.missingConsent');
        }
      });
  }
}
