import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ScreenSize } from '@app/core/enums/screen-size.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { WeatherData } from '@app/core/repositories/weather/weather-data.interface';
import { getChartLegendStyle } from '@app/helpers/chart-legend-style/chart-legend-style.fn';
import { RainChartVM } from '@app/shared/rain-chart/rain-chart-vm.interface';
import { RainChartService } from '@app/shared/rain-chart/rain-chart.service';
import { CategoryAxis, SeriesMarkers, ValueAxis } from '@progress/kendo-angular-charts';
import { Subscription } from 'rxjs';
import { finalize, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-rain-chart',
  templateUrl: './rain-chart.component.html',
  styleUrls: ['./rain-chart.component.scss'],
  standalone: false,
})
export class RainChartComponent implements OnInit, OnDestroy {
  @Input() public field: Field = {} as Field;
  private readonly screenSize$ = this.rainChartService.screenSize$;
  public observed: WeatherData[] = [];
  public forecast: WeatherData[] = [];
  public isLoading = false;

  public categoryAxis: CategoryAxis = {
    baseUnit: 'hours',
    type: 'date',
    labels: {
      dateFormats: {
        hours: LanguageConstants.getDateFormat(this.languageService.currentLanguage.shortKey).l,
      },
      step: 10,
      rotation: 45,
      position: 'onAxis',
    },
  };

  public valueAxis: ValueAxis = {
    max: 15,
    title: {
      text: this.rainChartService.findYAxisTitle(),
    },
  };

  public seriesMarkers: SeriesMarkers = {
    visible: false,
  };

  private subscriptions: Subscription[] = [];
  private aggregated: WeatherData[] = [];

  constructor(
    private rainChartService: RainChartService,
    private languageService: LanguageService
  ) {}

  public ngOnInit() {
    this.subscriptions.push(
      this.screenSize$.subscribe((size) => this.calculateCategoryAxis(size)),
      this.getWeatherData()
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public showInfoDialog() {
    this.rainChartService.showInfoModal();
  }

  public legendItemVisual = (args: any) => getChartLegendStyle(args);

  private getWeatherData() {
    this.isLoading = true;
    return this.rainChartService
      .getRainData(this.field.farmId, this.field.harvestYear, this.field.featureId)
      .pipe(
        withLatestFrom(this.screenSize$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(([weatherData, screenSize]) => this.onRainChartDataReceived(weatherData, screenSize));
  }

  private onRainChartDataReceived(weatherData: RainChartVM, screenSize: ScreenSize) {
    this.observed = weatherData.observed;
    this.forecast = weatherData.forecast;
    this.aggregated = weatherData.aggregated;
    this.calculateCategoryAxis(screenSize);
  }

  private calculateCategoryAxis(screenSize: ScreenSize) {
    this.categoryAxis = this.rainChartService.calculateNumberOfStepsByScreenSize(screenSize, this.aggregated, this.categoryAxis);
  }
}
