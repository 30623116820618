import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BasisLayerCategory } from '@app/core/interfaces/basis-layer/basis-layer-category';
import { BasisLayerLogicService } from '@app/map/features/basis-layer/basis-layer-logic.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { map, withLatestFrom } from 'rxjs/operators';
import { BasisLayerStateService } from '../../basis-layer-state.service';
import { BasisLayerInformationDialogComponent } from './basis-layer-information-dialog/basis-layer-information-dialog.component';

@Component({
  selector: 'app-category-picker',
  templateUrl: './category-picker.component.html',
  styleUrls: ['./category-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CategoryPickerComponent {
  public loading$ = this.basisLayerStateService.loading$;
  public categoryTypes = this.basisLayerStateService.basisLayerCategories$.pipe(
    withLatestFrom(this.farmStateService.selectedFarms$),
    map(([basisLayerCategories, farmIds]) => basisLayerCategories.filter((category) => category.farmId === farmIds[0].id))
  );

  constructor(
    private basisLayerLogicService: BasisLayerLogicService,
    private basisLayerStateService: BasisLayerStateService,
    private farmStateService: FarmStateService,
    private dialogService: DialogService
  ) {}

  public onCategoryTypeClick(category: BasisLayerCategory) {
    this.basisLayerLogicService.onCategorySelect(category);
  }

  public onCloseClick() {
    this.basisLayerLogicService.hideSideDrawer();
  }

  public onHideClick() {
    this.basisLayerLogicService.hideSideDrawer();
  }

  public onBasisLayerInfoClick($event: Event) {
    this.dialogService.openMd(BasisLayerInformationDialogComponent);
    $event.stopPropagation();
  }
}
