import { Component } from '@angular/core';

@Component({
  selector: 'app-side-drawer-backdrop',
  template: '',
  styleUrls: ['./side-drawer-backdrop.component.scss'],
  host: { 'collision-id': 'SideDrawerBackdropComponent' },
  standalone: false,
})
export class SideDrawerBackdropComponent {}
