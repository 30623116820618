import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { DateTime } from 'luxon';
import { CornHarvestPrognosisSettings } from '../../repository/corn-harvest-prognosis-settings.class';

@Component({
  selector: 'app-corn-harvest-prognosis-settings',
  templateUrl: './corn-harvest-prognosis-settings.component.html',
  styleUrls: ['./corn-harvest-prognosis-settings.component.scss'],
  standalone: false,
})
export class CornHarvestPrognosisSettingsComponent implements OnInit {
  public settings = new CornHarvestPrognosisSettings();
  private previousSettings!: CornHarvestPrognosisSettings;

  public dryMatterPctControl = new UntypedFormControl(0, Validators.max(100));

  constructor(
    private sideDrawerRef: SideDrawerRef<CornHarvestPrognosisSettingsComponent, CornHarvestPrognosisSettings, CornHarvestPrognosisSettings>
  ) {}

  public ngOnInit() {
    this.settings = new CornHarvestPrognosisSettings(this.sideDrawerRef.data);
    this.previousSettings = new CornHarvestPrognosisSettings(this.sideDrawerRef.data);

    this.dryMatterPctControl.setValue(this.sideDrawerRef.data.drymatterPct);

    this.dryMatterPctControl.valueChanges.subscribe((value: string) => {
      this.settings.drymatterPct = parseInt(value.toString(), 0);
    });
  }

  public onSampleDateChange(date: DateTime) {
    this.settings.sampleDate = date;
  }

  public onCloseClick() {
    this.sideDrawerRef.close(this.previousSettings);
  }

  public onSaveClick() {
    if (this.dryMatterPctControl.invalid) {
      return;
    }
    this.sideDrawerRef.close(this.settings);
  }
}
