import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { INSURANCE_PRICES } from '../../growth-regulation-prices';

@Component({
  selector: 'app-info-dialog-content',
  templateUrl: './info-dialog-content.component.html',
  styleUrls: ['./info-dialog-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InfoDialogContentComponent {
  public prices = INSURANCE_PRICES;

  constructor(private dialogRef: MatDialogRef<InfoDialogContentComponent>) {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
