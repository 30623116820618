import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { DateRange } from '@app/shared/n-tool/date-range';
import { NToolData } from '@app/shared/n-tool/n-tool-data';
import { NToolService } from '@app/shared/n-tool/n-tool.service';
import { AxisLabelVisualArgs, CategoryAxis, ValueAxis } from '@progress/kendo-angular-charts';
import { DateTime } from 'luxon';
import { Observable, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { filterNullish } from '../operators';

@Component({
  selector: 'app-n-tool',
  templateUrl: './n-tool.component.html',
  styleUrls: ['./n-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NToolComponent implements OnInit, OnDestroy {
  @Input() public field$?: Observable<Field | undefined>;

  public isLoading$?: Observable<boolean>;
  public nToolData$ = new Subject<NToolData>();

  public colorGreen?: '#B4C5B0';
  public colorYellow?: '#FDF4C9';
  public colorRed?: '#FDCBBE';
  public readonly colorBrightGreen = '#25BD59';
  public readonly colorGrey = '#969696';
  public readonly colorLightGrey = '#bdbdbd';
  public readonly colorTooltipGrey = '#EEEEEE';
  public readonly columnGap = 999;
  public readonly lineWidth = 3;

  private subscriptions = new Subscription();
  private dateRange$?: Observable<DateRange>;

  public categoryAxis: CategoryAxis = {
    name: 'category',
    baseUnit: 'days',
    type: 'date',
    majorGridLines: {
      visible: false,
    },
    majorTicks: {
      visible: false,
    },
    // Vertical line that marks today's date
    notes: {
      line: {
        width: 2,
        length: this.columnGap,
      },
      data: [{ value: DateTime.now().toJSDate() }],
    },
    labels: {
      visual: (args) => this.createLabelVisual(args)!,
      dateFormats: {
        days: LanguageConstants.getDateFormat(this.languageService.currentLanguage.shortKey).l,
      },
    },
  };

  public valueAxis: ValueAxis = {
    name: 'value',
    min: 0,
    max: 250,
    title: {
      text: this.nToolService.getValueAxisTitle(),
      rotation: -90,
    },
  };

  public hidden = {
    visible: false,
  };

  constructor(
    private nToolService: NToolService,
    private languageService: LanguageService
  ) {}

  public labelContent = (e: any) => {
    if (!!e?.dataItem?.totalNutrientAmount) {
      return this.nToolService.getLabelContent(e.dataItem);
    }
    return e.value ?? '';
  };

  /**
   * Creates label visual if the date property of the given args value property equals 1 or 15, or 29 if month is may.
   * Otherwise, no label is created.
   */
  private createLabelVisual = (args: AxisLabelVisualArgs) => {
    const date = args.value.getDate();
    if (date === 1 || date === 15) {
      return args.createVisual();
    } else if (date === 29 && args.value.getMonth() === 4) {
      return args.createVisual();
    } else return undefined;
  };

  public ngOnInit(): void {
    this.initializeObservables();
    this.setColors();
    this.subscriptions.add(
      this.field$
        ?.pipe(
          filterNullish(),
          switchMap((field) => this.nToolService.getChartData(field))
        )
        .subscribe((nToolData) => this.nToolData$.next(nToolData))
    );

    this.subscriptions.add(this.dateRange$?.subscribe((dateRange) => this.handleDateRangeChange(dateRange)));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public toggleInfo() {
    this.nToolService.showInfoModal();
  }

  private handleDateRangeChange(dateRange: DateRange) {
    this.categoryAxis = { ...this.categoryAxis, min: dateRange.start.toJSDate(), max: dateRange.end.toJSDate() };
  }

  private initializeObservables() {
    this.dateRange$ = this.nToolService.dateRange$;
    this.isLoading$ = this.nToolService.isLoading$;
  }

  private setColors() {
    this.colorGreen = this.nToolService.colorGreen;
    this.colorYellow = this.nToolService.colorYellow;
    this.colorRed = this.nToolService.colorRed;
  }
}
