import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { SelectOptionComponent } from '@app/shared/select/select-option/select-option.component';

@Component({
  selector: 'app-select-option-group',
  templateUrl: './select-option-group.component.html',
  styleUrls: ['./select-option-group.component.scss'],
  standalone: false,
})
export class SelectOptionGroupComponent {
  @ContentChildren(SelectOptionComponent) public selectOptions?: QueryList<SelectOptionComponent>;

  @Input() public label?: string;
}
