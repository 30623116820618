import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Month } from '@app/core/enums/month.enum';
import { ScreenSize } from '@app/core/enums/screen-size.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { WetHourDto } from '@app/core/repositories/weather/leaf-wet-hours.interface';
import { getChartLegendStyle } from '@app/helpers/chart-legend-style/chart-legend-style.fn';
import { WetHoursChartVM } from '@app/shared/wet-hours-chart/wet-hours-chart-vm.interface';
import { WetHoursChartService } from '@app/shared/wet-hours-chart/wet-hours-chart.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { CategoryAxis, PlotBand, SeriesMarkers, ValueAxis } from '@progress/kendo-angular-charts';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { finalize, take, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-wet-hours-chart',
  templateUrl: './wet-hours-chart.component.html',
  styleUrls: ['./wet-hours-chart.component.scss'],
  standalone: false,
})
export class WetHoursChartComponent implements OnInit, OnDestroy {
  @Input() public field: Field = {} as Field;
  public plotBands: PlotBand[] = [];
  public observed: WetHourDto[] = [];
  public forecast: WetHourDto[] = [];
  public isLoading = false;

  public categoryAxis: CategoryAxis = {
    name: 'category',
    baseUnit: 'hours',
    type: 'date',
    majorGridLines: {
      visible: false,
    },
    majorTicks: {
      visible: false,
    },
    labels: {
      dateFormats: {
        hours: LanguageConstants.getDateFormat(this.languageService.currentLanguage.shortKey).l,
      },
      rotation: 45,
      position: 'onAxis',
    },
  };

  public valueAxis: ValueAxis = {
    plotBands: [],
    max: 50,
    title: {
      text: this.wetHoursChartService.getValueAxistitle(),
      margin: {
        right: 6,
      },
      rotation: -90,
    },
  };

  public seriesMarkers: SeriesMarkers = {
    visible: false,
  };

  public aggregated: WetHourDto[] = [];
  private readonly screenSize$ = this.wetHoursChartService.screenSize$;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private wetHoursChartService: WetHoursChartService,
    private languageService: LanguageService,
    private harvestYearStateService: HarvestYearStateService
  ) {}

  public ngOnInit() {
    this.subscriptions.add(this.screenSize$.subscribe((size) => this.calculateCategoryAxis(size)));
    this.subscriptions.add(this.getLeafWetHoursData());
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public legendItemVisual = (args: any) => getChartLegendStyle(args);

  public toggleInfo() {
    this.wetHoursChartService.showInfoModal();
  }

  private getLeafWetHoursData() {
    this.isLoading = true;
    return this.wetHoursChartService
      .getLeafWetHours(this.field.farmId, this.field.harvestYear, this.field.id)
      .pipe(
        withLatestFrom(this.screenSize$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(([data, screenSize]) => this.onChartDataReceived(data, screenSize));
  }

  private onChartDataReceived(data: WetHoursChartVM, screenSize: ScreenSize) {
    this.forecast = data.forecast;
    this.observed = data.observed;
    this.valueAxis.plotBands = data.plotBands;
    this.aggregated = [...data.forecast, ...data.observed];

    this.setMinAndMaxCategoryValue();
    this.calculateCategoryAxis(screenSize);
  }

  private setMinAndMaxCategoryValue() {
    this.harvestYearStateService.harvestYear$.pipe(take(1)).subscribe((year) => {
      const startDate = DateTime.fromObject({ year: year, month: Month.June, day: 1 }).toJSDate();

      const endDate = DateTime.fromObject({ year: year, month: Month.September, day: 15 }).toJSDate();

      // If only forecast data is available, set start date of the chart to the earliest dateDay in forecast, and end date to 10 days later
      if (!this.observed.length && this.forecast.length) {
        const firstForecast = this.forecast.reduce((prev, current) => (prev.dateDay < current.dateDay ? prev : current));
        this.categoryAxis.min = DateTime.fromISO(firstForecast.dateDay).toJSDate();
        this.categoryAxis.max = DateTime.fromJSDate(this.categoryAxis.min)?.plus({ days: 10 }).toJSDate();
      } else {
        this.categoryAxis.min = startDate;
        this.categoryAxis.max = endDate;
      }
    });
  }

  private calculateCategoryAxis(screenSize: ScreenSize) {
    this.categoryAxis = this.wetHoursChartService.calculateNumberOfStepsByScreenSize(screenSize, this.aggregated, this.categoryAxis);
  }
}
