import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SoilSampleGroup } from '@app/core/repositories/soil-samples/soil-sample-group.class';
import { SoilSampleTableComponent } from '@app/map/features/field-analysis/features/soil-sample-feature/soil-sample-side-drawer/soil-sample-table/soil-sample-table.component';
import { SoilSampleParameterIds } from '@app/map/features/field-analysis/features/soil-sample-feature/soil-sample-side-drawer/soil-samples-analysis-parameter-ids.enum';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { map } from 'rxjs';
import { MapControlSoilsampleService } from './map-control-soilsample.service';

@Component({
  selector: 'app-map-layer-control-soilsample',
  templateUrl: './map-layer-control-soilsample.component.html',
  styleUrls: ['./map-layer-control-soilsample.component.scss'],
  standalone: false,
})
export class MapLayerControlSoilsampleComponent implements OnInit, OnDestroy {
  protected soilsampleGroups$ = this.soilsampleService.soilSampleGroups$;
  protected soilSamples$ = this.soilsampleService.soilSamples$;
  protected hasActiveSoilSamples$ = this.soilsampleService.hasSelectedSoilSamples$;
  protected selectableMeasurementType$ = this.soilsampleService.selectableMeasurementType$;
  protected hasAccess$ = this._accessControlService.policies$.pipe(
    map((policy) => {
      return policy.includes('basic');
    })
  );

  protected form: FormGroup;

  private _subs = new SubscriptionArray();

  constructor(
    private fb: FormBuilder,
    private soilsampleService: MapControlSoilsampleService,
    private layerService: OlLayerService,
    private _accessControlService: AccessControlService,
    private _dialogService: DialogService
  ) {
    this.form = this.fb.group({
      selectedGroups: [[]],
      selectedType: [undefined],
    });
  }

  ngOnInit() {
    this.soilsampleService.getSoilSampleGroups();
    this._subscribeToFormChanges();
    this._subscribeToValidSelectCheck();
    this._subs.add(this._subscribeToFormResetCheck());
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private _subscribeToFormChanges() {
    this.form.get('selectedGroups')?.valueChanges.subscribe((groups) => {
      this.OnGroupsChange(groups);
    });

    this.form.get('selectedType')?.valueChanges.subscribe((type) => {
      this.OnTypeChange(type);
    });
  }

  private _subscribeToValidSelectCheck() {
    this._subs.add(
      this.soilsampleService.selectableMeasurementType$.subscribe((types) => {
        const selectedType = this.form.get('selectedType')?.value;
        if (types.some((type) => type.parameterId === selectedType && type.enabled)) {
          return;
        }
        this.form.patchValue({
          selectedType: undefined,
        });
      })
    );
  }

  private _subscribeToFormResetCheck() {
    return this.soilsampleService.resetForm$.subscribe((reset) => {
      if (reset) {
        this.onClearClick();
      }
    });
  }

  OnTypeChange(type: SoilSampleParameterIds) {
    this.soilsampleService.setSelectedType(type);

    if (type === undefined || type === SoilSampleParameterIds.None) {
      this.layerService.setLayerVisibility(LayerId.SOILSAMPLE, false);
    } else {
      this.layerService.setLayerVisibility(LayerId.SOILSAMPLE, true);
    }
  }

  OnGroupsChange(groups: SoilSampleGroup[]) {
    this.soilsampleService.setSelectedSampleGroups(groups);
    this.soilsampleService.getSoilSamples();
    if (groups.length === 0) {
      this.form.patchValue({
        selectedType: undefined,
      });
    }
  }

  onClearClick() {
    this.form.patchValue({
      selectedGroups: [],
      selectedType: undefined,
    });
  }

  openInfo() {
    this._dialogService.openCustomDialog(SoilSampleTableComponent);
  }
}
