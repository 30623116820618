import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Hoverable } from '@app/shared/buttons/hoverable';

@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AddButtonComponent extends Hoverable {
  @Input() public color = '#43b05c';
  @Input() public disabled: boolean | null = false;
  @Input() public invert = false;
  @Input() public size: 'lg' | 'sm' = 'lg';
  @Output() public clicked = new EventEmitter<void>();

  public onClick() {
    if (this.disabled) {
      return;
    }
    this.clicked.emit();
  }
}
