import { ComponentType } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BffAuthService } from '@app/core/authentication/bff-auth.service';
import { LanguagePickerService } from '@app/core/language-picker/language-picker.service';
import { ILanguage, LanguageService } from '@app/core/language/language.service';
import { PartnersComponent } from '@app/layouts/footer/partners/partners.component';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { LanguageStateService } from '@app/state/services/language/language-state.service';
import { first, Observable } from 'rxjs';
import { AppLayoutService } from '../../core/layout/app-layout.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.scss'],
  standalone: false,
})
export class FooterComponent implements OnInit, OnDestroy {
  public modalRef?: MatDialogRef<PartnersComponent>;
  public menuMinimized$ = this.layoutService.menuMinimized$;
  public hideMenuToggle$ = this.layoutService.hideMenuToggle$;
  public readonly isNaesgaard$ = this.bffAuthService.isCurrentUserFromNaesgaard$;

  private _subscriptions = new SubscriptionArray();
  private _selectedLanguage: ILanguage | undefined;

  constructor(
    private dialog: MatDialog,
    private layoutService: AppLayoutService,
    private bffAuthService: BffAuthService,
    private languageService: LanguageService,
    private languageStateService: LanguageStateService,
    private languagePickerService: LanguagePickerService
  ) {}

  public ngOnInit() {
    this._setLanguageForNaesgaard();
  }

  public ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  public openSponsorModal() {
    this.openModal(PartnersComponent);
  }

  public openModal(component: ComponentType<PartnersComponent>) {
    this.modalRef = this.dialog.open(component, { panelClass: 'partners-pane' });

    this.modalRef.afterClosed().subscribe(() => (this.modalRef = undefined));
  }

  public goToPage(link: string) {
    window.open(link, '_blank');
  }

  public toggleMenu() {
    this.layoutService.toggleMenuMimized();
  }

  //! This logic should really not be contained in the footer or in the user profile component...
  //! Quick fix to get Naesgaard language working.
  private _setLanguageForNaesgaard() {
    this.isNaesgaard$.pipe(first()).subscribe((isNaesgaard) => {
      if (isNaesgaard) {
        this._subscriptions.push(
          (this.languageStateService.currentLanguage$ as Observable<string | null>).subscribe((language) => {
            if (language) {
              this._selectedLanguage = this.languageService.getLanguage(language);
            } else {
              this.languagePickerService.getLangShortCode$().subscribe((lang) => {
                this._selectedLanguage = this.languageService.getLanguage(lang);
              });
            }
          })
        );
        this.languagePickerService.setLanguage(this._selectedLanguage!.shortKey);
      }
    });
  }
}
