import { Injectable } from '@angular/core';
import { OperationTypes } from '@app/core/enums/operation-types.enum';
import { FieldEntry } from '@app/farm-tasks-overview/class/field-entry';
import { ProductEntry } from '@app/farm-tasks-overview/class/product-entry';
import { ProductSubType } from '@app/farm-tasks-overview/components/product-filter/product-filter.service';
import { FilterCrop } from '@app/shared/map-layer-controls/map-crop-filter-control/crop-filter.store';
import { selectAllEntities, selectEntity } from '@ngneat/elf-entities';
import { map, Observable, shareReplay, withLatestFrom } from 'rxjs';
import { FieldEntryStore } from './field-entry.store';

export const MACHINEEVENTOPERATIONTYPES = [
  OperationTypes.OwnSoilTreatment,
  OperationTypes.SoilTreatment,
  OperationTypes.Sowing,
  OperationTypes.Fertilization,
  OperationTypes.PlantProtection,
  OperationTypes.OwnFertilization,
  OperationTypes.OwnPlantProtection,
  OperationTypes.OwnHarvest,
  OperationTypes.OwnFieldTasks,
  OperationTypes.Harvest,
  OperationTypes.Forestry,
  OperationTypes.OtherMachine,
];

@Injectable({
  providedIn: 'root',
})
export class FieldEntryQuery {
  constructor(private _store: FieldEntryStore) {}

  public sortState$ = this._store.store.pipe(
    map((state) => ({
      sortType: state.sortType,
      sortDirection: state.sortDirection,
    })),
    shareReplay(1)
  );

  public cropFilter$ = this._store.store.pipe(map((state) => state.crops));

  public ProductFilter$ = this._store.store.pipe(
    map((state) => state.products),
    shareReplay(1)
  );

  public ProductAndMachineEventFilter$ = this._store.store.pipe(
    map((state) => [...state.products, ...state.machineEvents]),
    shareReplay(1)
  );

  public storeReady$ = this._store.store.pipe(map((state) => state.isReady));

  public MachineEventFilter$ = this._store.store.pipe(
    map((state) => state.machineEvents),
    shareReplay(1)
  );
  
  public fieldEntries$ = this._store.store.pipe(selectAllEntities(), shareReplay(1));

  public operationTypesAndProducts$ = this.fieldEntries$.pipe(
    withLatestFrom(this.ProductFilter$),
    map(([fieldEntries, productFilter]: [FieldEntry[], ProductSubType[]]) => {
      const allProducts: ProductEntry[] = fieldEntries
        .flatMap((fe) => fe.getTasks().flatMap((t) => t.getProducts()))
        .filter((p) => !MACHINEEVENTOPERATIONTYPES.includes(+p.type));

      const uniqueProductsById = new Map<number, ProductEntry>();
      allProducts.forEach((product) => {
        uniqueProductsById.set(product.id, product);
      });

      const productIdToInclude = productFilter.map((pf) => pf.productId);
      const groupedByOperationType = Array.from(uniqueProductsById.values()).reduce(
        (acc, product) => {
          const type = product.type;
          if (!acc[type]) {
            acc[type] = [];
          }
          product.enabled = productIdToInclude.includes(product.id);
          acc[type].push(product);
          return acc;
        },
        {} as Record<OperationTypes, ProductEntry[]>
      );
      return groupedByOperationType;
    })
  );

  public machineEvents$ = this.fieldEntries$.pipe(
    withLatestFrom(this.MachineEventFilter$),
    map(([fieldEntries, machineEventFilter]: [FieldEntry[], ProductSubType[]]) => {
      const allProducts: ProductEntry[] = fieldEntries.flatMap((fe) => fe.getTasks().flatMap((t) => t.getProducts()));

      const productIdToEnable = machineEventFilter.map((pf) => pf.productId);

      const machineProducts = allProducts
        .filter((p) => MACHINEEVENTOPERATIONTYPES.includes(+p.type))
        .map((p) => {
          p.enabled = productIdToEnable.includes(p.id);
          return p;
        });

      // Ensure unique machineProducts by id
      const uniqueMachineProducts = Array.from(new Map(machineProducts.map((p) => [p.id, p])).values());
      uniqueMachineProducts.sort((a, b) => a.label!.localeCompare(b.label!));
      return uniqueMachineProducts;
    })
  );

  public crops$: Observable<FilterCrop[]> = this.fieldEntries$.pipe(
    withLatestFrom(this.cropFilter$),
    map(([fieldEntries, cropFilter]) => {
      // Group fields by crop name to combine fields with the same crop
      const cropGroups = new Map<string, FilterCrop>();

      const cropFilterName = cropFilter.map((crop) => crop.cropName);
      fieldEntries.forEach((field) => {
        const cropId = `${field.cropName}-${field.successionNo}`;

        if (!cropGroups.has(cropId)) {
          cropGroups.set(cropId, {
            id: cropId,
            fieldIds: [],
            color: field.cropColor || '#000000', // Default color if none provided
            name: field.cropName || 'unknown',
            totalArea: 0,
            succession: field.successionNo,
            enabled: cropFilterName.includes(field.cropName || 'unknown'),
          });
        }

        const crop = cropGroups.get(cropId)!;
        crop.fieldIds.push(field.id);
        crop.totalArea += field.area || 0;
      });

      return Array.from(cropGroups.values());
    }),
    shareReplay(1)
  );

  public taskEntries$ = this.fieldEntries$.pipe(
    map((fieldEntries: FieldEntry[]) => fieldEntries.flatMap((field) => field.tasks || [])),
    shareReplay(1)
  );

  public getFieldById$(fieldId: number) {
    return this._store.store.pipe(selectEntity(fieldId));
  }

  public getTasksByFieldId$(fieldId: number) {
    return this._store.store.pipe(
      selectEntity(fieldId),
      map((field) => field?.tasks || [])
    );
  }

  public getTasksByTaskId$(taskId: number) {
    return this.taskEntries$.pipe(map((taskEntries) => taskEntries.find((taskEntry) => taskEntry.id === taskId)));
  }
}
