import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss'],
  standalone: false,
})
export class MoreInfoComponent {
  public isInfoVisible = false;
  public zIndex = 0;
  @Input() public buttonText = '';
  @Input() public height = '225px';
  @Input() public width = '300px';

  public mouseEnter() {
    this.isInfoVisible = true;
    this.zIndex = 3;
  }
  public mouseLeave() {
    this.isInfoVisible = false;
    // wait for fade animation to finish to prevent blinking
    setTimeout(() => (this.zIndex = 0), 200);
  }
}
