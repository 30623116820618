import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReminderWindowComponent } from '@app/shared/reminder-window/reminder-window.component';
import { ReminderWindowService } from '@app/shared/reminder-window/reminder-window.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reminder-button',
  templateUrl: './reminder-button.component.html',
  styleUrls: ['reminder-button.component.scss'],
  standalone: false,
})
export class ReminderButtonComponent implements OnInit, OnDestroy {
  private reminderCountSubscription!: Subscription;
  private isLoadingSubscribtion!: Subscription;
  public reminderCount?: number | null;
  public noReminders!: boolean;
  public isLoadingReminders!: boolean;

  @Input() public minimized = false;

  constructor(
    private reminderWindowService: ReminderWindowService,
    public dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.collectRemindersAndSubscribe();
  }

  public openReminders() {
    this.dialog.open(ReminderWindowComponent, {
      hasBackdrop: true,
    });
  }

  public ngOnDestroy(): void {
    this.reminderCountSubscription.unsubscribe();
  }

  private collectRemindersAndSubscribe() {
    this.reminderCountSubscription = this.reminderWindowService.getReminderCount().subscribe((count) => {
      if (count) {
        this.reminderCount = count;
      } else {
        // clears reminder count
        this.reminderCount = null;
      }
    });

    this.isLoadingSubscribtion = this.reminderWindowService.isLoading().subscribe((isLoading) => {
      this.isLoadingReminders = isLoading;
      if (!isLoading) {
        // set true if no reminders
        this.noReminders = !this.reminderWindowService.allReminders || this.reminderWindowService.allReminders.length === 0;
        this.isLoadingSubscribtion.unsubscribe();
      }
    });

    // Get and activate observables
    this.reminderWindowService.getAllReminders();
  }
}
