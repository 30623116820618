import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HotspotGroup } from '../../interfaces/hotspot-group.interface';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss'],
  standalone: false,
})
export class GroupListComponent {
  @Output() public deleteGroup = new EventEmitter<number>();
  @Output() public nameChanged = new EventEmitter<{ name: string; index: number }>();
  @Input() public groups!: HotspotGroup[];
}
