import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Farm } from '@app/core/interfaces/farm.interface';
import { InsuranceOrder } from '@app/core/interfaces/insurance-order.interface';
import { IsNotIntegerValidator } from '@app/helpers/validators/forms/is-not-integer.validator';
import { NoWhitespaceValidator } from '@app/helpers/validators/forms/no-whitespace.validator';
import { DialogService } from '@app/shared/dialog/dialog.service';

@Component({
  selector: 'app-check-out-form',
  templateUrl: './check-out-form.component.html',
  styleUrls: ['./check-out-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CheckOutFormComponent implements OnInit {
  private email!: string;
  private insuranceOrder!: InsuranceOrder;
  @Input() public farm!: Farm;
  @Output() public infoChange = new EventEmitter<UntypedFormGroup>();
  public insuranceBuyerFormGroup!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogService: DialogService
  ) {}

  public ngOnInit() {
    this.insuranceBuyerFormGroup = this.initFormGroup();
    this.insuranceBuyerFormGroup.valueChanges.subscribe((values: Partial<any>) => {
      this.insuranceOrder = { ...this.insuranceOrder, ...values };

      this.infoChange.emit(this.insuranceBuyerFormGroup);
    });
  }

  private initFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      farmName: [this.farm.name, Validators.required],
      farmAddress: [this.farm.address1, Validators.required],
      email: [this.email, [Validators.email, Validators.required]],
      telephoneNo: [this.farm.telephoneNo, [Validators.required, IsNotIntegerValidator]],
      cvrNumber: [
        this.farm.cvr,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(8),
          Validators.min(1),
          NoWhitespaceValidator,
          IsNotIntegerValidator,
        ],
      ],
      termsAccepted: [false, [Validators.requiredTrue]],
    });
  }
}
