import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileService } from '@app/core/file/file.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  standalone: false,
})
export class FileUploadComponent {
  @Input() public placeholderText = '';
  @Input() public labelText = '';
  @Input() public allowMultipleFiles = false;
  @Input() public acceptFileExtensions: string[] = [];
  @Input() public invalidFileTypesMessage = '';
  @Input() public disabled = false;
  @Output() public fileInputChange = new EventEmitter<File[]>();
  public fileName?: string;

  public areFileTypesValid = true;

  private filesToSave: File[] = [];

  public onFileInputChange(e: any) {
    this.areFileTypesValid = true;

    if (!e.target.files.length || this.disabled) {
      return;
    }

    this.filesToSave = [];

    for (const file of e.target.files) {
      this.filesToSave.push(file);
    }

    if (!FileService.isValidFormats(this.acceptFileExtensions, this.filesToSave)) {
      this.areFileTypesValid = false;
      return;
    }

    this.fileName = Object.keys(e.target.files)
      .map((key) => e.target.files[key].name)
      .join(', ');

    this.fileInputChange.emit(this.filesToSave);
  }

  public clearFiles() {
    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.fileName = null;
    this.fileInputChange.emit([]);
  }
}
