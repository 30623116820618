import { Component, OnInit } from '@angular/core';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { negate } from '@app/shared/operators/negate';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { EconomyStateService } from './economy-state.service';

@Component({
  selector: 'app-economy',
  templateUrl: './economy.component.html',
  styleUrls: ['./economy.component.scss'],
  standalone: false,
})
export class EconomyComponent implements OnInit {
  public useFieldScore = false;
  public showSources = false;
  public showEconomySubject = new BehaviorSubject<boolean>(false);
  public selectableHarvestYears$ = this.economyStateService.selectableHarvestYears$;
  public selectedHarvestYear!: number;

  public readonly harvestYear$: Observable<number | undefined> = this.harvestYearStateService.harvestYear$;
  public isPortable$ = this.screenSizeService.isPortable();
  public showEconomyButtonClicked$ = this.showEconomySubject.asObservable();
  public showEconomy$ = combineLatest([this.showEconomyButtonClicked$, this.isPortable$]).pipe(
    map(([showEconomyClicked, portable]) => (portable ? showEconomyClicked : true))
  );
  public disableSourcesCheckbox$ = this.accessControlService.hasAccessTo('economy_price_suggestion').pipe(negate());

  constructor(
    private economyStateService: EconomyStateService,
    private screenSizeService: ScreenSizeService,
    private harvestYearStateService: HarvestYearStateService,
    private accessControlService: AccessControlService
  ) {}

  public ngOnInit() {
    this.economyStateService.showSources$.pipe(first()).subscribe((showSources) => {
      this.showSources = showSources;
    });

    this.economyStateService.useFieldScore$.pipe(first()).subscribe((useFieldScore) => {
      this.useFieldScore = useFieldScore;
    });

    this.harvestYear$
      .pipe(
        first(),
        withLatestFrom(this.selectableHarvestYears$),
        map(([harvestYear, selectableHarvestYears]) =>
          selectableHarvestYears.includes(harvestYear!) ? harvestYear : selectableHarvestYears[0]
        )
      )
      .subscribe((harvestYear) => {
        this.selectedHarvestYear = harvestYear!;
      });
  }

  public showSourcesChange(showSources: boolean) {
    this.economyStateService.showSources = showSources;
  }

  public useFieldScoreChange(useFieldScore: boolean) {
    this.economyStateService.useFieldScore = useFieldScore;
  }

  public harvestYearChange(harvestYear: number) {
    this.economyStateService.selectedHarvestYear = harvestYear;
  }

  public onShowEconomyClick() {
    this.showEconomySubject.next(true);
  }
}
