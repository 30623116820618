import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { OperationTypes } from '@app/core/enums/operation-types.enum';
import { ProductEntry } from '@app/farm-tasks-overview/class/product-entry';
import { FieldEntryQuery } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.query';
import { FieldEntryStore } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.store';
import { ChipUtil } from '@app/farm-tasks-overview/util/chip-util';
import { latest } from '@app/shared/constants/rxjs-constants';
import { BehaviorSubject, Subject, combineLatest, filter, map, shareReplay, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductFilterService implements OnDestroy {
  public operationTypesAndProducts$ = this._ftoQuery.operationTypesAndProducts$;
  public selectAllproductsSubject = new BehaviorSubject<boolean>(false);
  public selectSpecificProductSubject = new BehaviorSubject<{ operationType: number; productIds: number[] | undefined } | null>(null);
  public filterProduct$ = this._ftoQuery.ProductFilter$;
  public isMenuOpenSubject = new BehaviorSubject<boolean>(false);
  public isMenuOpen$ = this.isMenuOpenSubject.asObservable().pipe(shareReplay(latest));

  private _pendingProducts = new Set<ProductSubType>();
  private _productUpdates$ = new Subject<Set<ProductSubType>>();
  private _destroy$ = new Subject<void>();
  private _updateVersion = 1;
  private _priorUpdateVersion = -1;
  private _allAvailableProducts = new Set<number>();

  constructor(
    private _ftoQuery: FieldEntryQuery,
    private _fb: FormBuilder,
    private _ftoStore: FieldEntryStore
  ) {
    this.setupProductUpdates();
    this.setupAvailableProductsTracking();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public setSelectedProducts(products: ProductSubType[]): void {
    this._pendingProducts.clear();

    products.forEach((product) => {
      if (product && product.id) {
        this._pendingProducts.add(product);
      }
    });

    if (this.isMenuOpenSubject.value) {
      this._updateVersion++;
    }

    // Emit the current state of pending products
    this._productUpdates$.next(this._pendingProducts);
  }

  private setupAvailableProductsTracking(): void {
    this.operationTypesAndProducts$.pipe(takeUntil(this._destroy$)).subscribe((operationTypesAndProducts) => {
      this._allAvailableProducts.clear();
      Object.values(operationTypesAndProducts).forEach((products) => {
        products.forEach((product) => {
          this._allAvailableProducts.add(product.id);
        });
      });
    });
  }

  public mapToMenuData(operationTypesAndProducts: Record<OperationTypes, ProductEntry[]>): ProductMenuDataItem[] {
    return Object.entries(operationTypesAndProducts).map(([operationType, productEntries]) => {
      const productMap = new Map<string, ProductSubType[]>();

      productEntries.forEach((productEntry) => {
        const product = {
          id: productEntry.id,
          productId: productEntry.id,
          label: productEntry.label,
          enabled: productEntry.enabled,
        } as ProductSubType;

        const key = product.label;
        const existing = productMap.get(key) || [];
        productMap.set(key, [...existing, product]);
      });

      const processedProducts = Array.from(productMap.entries()).flatMap(([label, products]) => {
        if (products.length > 1) {
          return products.map((product) => ({
            ...product,
            label: `${label} (${product.id})`,
          }));
        }
        return products;
      });

      return {
        id: Number(operationType),
        type: Number(operationType) as OperationTypes,
        products: processedProducts.sort((a, b) => a.label.localeCompare(b.label)),
      } as ProductMenuDataItem;
    });
  }

  public createGroups(data: ProductMenuDataItem[]): FormGroup {
    return this._fb.group({
      anySelected: [true],
      hoveredItem: [null],
      groups: this._fb.array(
        data.map((item) => {
          return this._fb.group({
            id: [item.id],
            type: this._fb.group({
              id: [item.id],
              name: [this.getNameBasedOnLanguage(item.type)],
              type: [item.type],
              selected: [
                () => {
                  item.products.every((p) => p.enabled);
                },
              ],
              partiallySelected: [item.products.some((p) => p.enabled)],
              color: [ChipUtil.getOperationTypeColor(item.type)],
            }),
            products: this._fb.array(
              item.products.map((product) => {
                return this._fb.group({
                  id: [product.id],
                  name: [product.label],
                  product: [product],
                  type: [item.type],
                  selected: [product.enabled],
                });
              })
            ),
          });
        })
      ),
    });
  }

  public updateParentTypeStatus(typeGroup: FormGroup, productArray: FormArray): void {
    const subTypeStates = productArray.controls.map((control) => control.get('selected')?.value);
    const allSelected = subTypeStates.every((state) => state === true);
    const parentTypeIdOfSubTypes = productArray.controls[0]?.get('type')?.value;
    const typeIdOfType = typeGroup.get('id')?.value;
    const someSelected = subTypeStates.some((state) => state === true) && parentTypeIdOfSubTypes === typeIdOfType;

    typeGroup.patchValue(
      {
        selected: allSelected,
        partiallySelected: !allSelected && someSelected,
      },
      { emitEvent: false }
    );
  }

  private getNameBasedOnLanguage(type: OperationTypes): string {
    return 'farm-tasks-overview.filter-product.operation-groups.' + type;
  }

  private setupProductUpdates(): void {
    combineLatest([this.isMenuOpen$, this._productUpdates$])
      .pipe(
        takeUntil(this._destroy$),
        filter(([isMenuOpen]) => !isMenuOpen),
        map(([, updates]) => updates)
      )
      .subscribe((products) => {
        if (this._priorUpdateVersion === this._updateVersion) {
          return;
        }
        this._priorUpdateVersion = this._updateVersion;

        // Commit the accumulated changes to the store
        this._ftoStore.store.update((state) => ({
          ...state,
          products: Array.from(products),
        }));

        const allProductsSelected = Array.from(this._allAvailableProducts).every((id) =>
          Array.from(products).some((product) => product.id === id)
        );
        this.selectAllproductsSubject.next(allProductsSelected);
      });
  }
}

export interface ProductSubType {
  id: number;
  productId: number;
  label: string;
  enabled: boolean;
}

export interface ProductMenuDataItem {
  id: number;
  type: OperationTypes;
  products: ProductSubType[];
  color: string;
}
