import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaxDecimals } from '@app/shared/directives/validation/value/max-decimal.validator';

interface ScoreFormData {
  averageCostPreCorrection: FormControl<number | null>;
  fieldName: FormControl<string | null>;
  fieldArea: FormControl<number | null>;
  fieldDistance: FormControl<number | null>;
  transportationCosts: FormControl<number | null>;
  correction: FormControl<number | null>;
  manualCorrection: FormControl<number | null>;
}

@Component({
  selector: 'app-score-dialog',
  templateUrl: './score-dialog.component.html',
  styleUrls: ['./score-dialog.component.scss'],
  standalone: false,
})
export class ScoreDialogComponent {
  public initialValue!: number | null;

  public correction!: number;
  public totalValue!: number;
  public form: FormGroup<ScoreFormData>;

  public get fieldName() {
    return this.form.controls.fieldName.value ?? '';
  }

  public get fieldArea() {
    return this.form.controls.fieldArea.value ?? 0;
  }

  public get fieldDistance(): number {
    return this.form.controls.fieldDistance?.value ?? 0;
  }

  public get transportationCosts(): number {
    return this.form.controls.transportationCosts?.value ?? 0;
  }

  public get manualCorrection(): number {
    return this.form.controls.manualCorrection.value ?? 0;
  }

  constructor(
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      averageCostPreCorrection: number | null;
      fieldName: string;
      area: number;
      fieldDistance: number | null;
      transportationCosts: number | null;
      correction: number;
      manualCorrection: number;
    },
    private dialogRef: MatDialogRef<ScoreDialogComponent>
  ) {
    this.form = this.formBuilder.group<ScoreFormData>({
      averageCostPreCorrection: new FormControl(this.data.averageCostPreCorrection),
      fieldName: new FormControl({ value: this.data.fieldName, disabled: true }),
      fieldArea: new FormControl({ value: this.data.area, disabled: true }),
      fieldDistance: new FormControl(this.data.fieldDistance, [
        Validators.min(0),
        Validators.required,
        Validators.max(99.99),
        MaxDecimals(2),
      ]),
      transportationCosts: new FormControl(this.data.transportationCosts, [
        Validators.min(0),
        Validators.required,
        Validators.max(9999),
        MaxDecimals(2),
      ]),
      correction: new FormControl(this.data.correction),
      manualCorrection: new FormControl(this.data.manualCorrection),
    });
    this.initialValue = this.data.averageCostPreCorrection;
    this.correction = this.data.correction;
    this.recalculateTotal();
  }

  displayLabel(value: number) {
    switch (value) {
      case 1:
        return '85%';
      case 2:
        return '95%';
      case 3:
        return '100%';
      case 4:
        return '115%';
      case 5:
        return '130%';
      default:
        return '85%';
    }
  }

  public close() {
    this.dialogRef.close();
  }

  public recalculateTotal() {
    if (this.initialValue && this.fieldDistance && this.transportationCosts) {
      this.totalValue =
        (+this.initialValue * +this.correction + +this.fieldDistance * +this.transportationCosts) * (0.7 + this.manualCorrection * 0.1);
    } else {
      this.totalValue = 0;
    }
  }

  public onCancelClick() {
    this.dialogRef.close();
  }

  public onConfirmClick() {
    if (this.form.valid) {
      this.dialogRef.close({
        transportationCost: +this.transportationCosts,
        fieldDistance: +this.fieldDistance,
        manualCorrection: +this.manualCorrection,
      });
    }
  }
}
