import { Component, OnDestroy, OnInit } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { CornField } from '@app/core/interfaces/corn-field.interface';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { MapLayerControlService } from '@app/shared/map-layer-controls/map-layer-control.service';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { Subscription } from 'rxjs';
import { PrognosisShownComponent } from '../prognosis-side-drawer/prognosis-shown-component.enum';
import { PrognosisSideDrawerComponent } from '../prognosis-side-drawer/prognosis-side-drawer.component';
import { PrognosisService } from '../prognosis.service';
import { CornPrognosisService } from './service/corn-prognosis.service';

@Component({
  selector: 'app-corn-harvest-prognosis',
  templateUrl: './corn-harvest-prognosis.component.html',
  styleUrls: ['./corn-harvest-prognosis.component.scss'],
  standalone: false,
})
export class CornHarvestPrognosisComponent implements OnInit, OnDestroy {
  public isLayerReady = false;
  public selectableLayers = [MapLayerId.CORNPROGNOSIS];

  private subscriptions = new Subscription();

  constructor(
    private mapService: OlMapService,
    private layerService: OlLayerService,
    private cornPrognosisService: CornPrognosisService,
    private prognosisService: PrognosisService,
    private sideDrawerRef: SideDrawerRef<PrognosisSideDrawerComponent, any, any>,
    private _mapLayerControlService: MapLayerControlService
  ) {}

  public ngOnInit() {
    this.layerService.setLayerVisibility(LayerId.GROWTH_REGULATION, false);
    this.layerService.setLayerVisibility(LayerId.FIELDS, false);
    this.layerService.setLayerVisibility(LayerId.FIELD_FILL, false);
    this.layerService.setLayerVisibility(LayerId.FIELD_LABELS, false);
    this.layerService.createFeatureLayer(LayerId.CORN_PROGNOSIS, []);

    this.subscriptions.add(
      this.cornPrognosisService.cornFieldsWithColor$.subscribe((fields) => {
        return this.onFieldsReceived(fields);
      })
    );

    this.layerService.setActivePage('prognosis_corn');
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.mapService.deselectFeatures();
    this.layerService.removeLayers(LayerId.CORN_PROGNOSIS);
    this.layerService.setLayerVisibility(LayerId.FIELDS, true);
    this.layerService.setLayerVisibility(LayerId.FIELD_FILL, true);
    this.layerService.setLayerVisibility(LayerId.FIELD_LABELS, true);
  }

  public onCloseClick() {
    this.prognosisService.setShownComponent(PrognosisShownComponent.PrognosisPicker);
  }

  public onHideClick() {
    this._mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
    this.sideDrawerRef.hide();
  }

  private onFieldsReceived(fields: CornField[]) {
    this.addFieldsToMap(fields);
  }

  private addFieldsToMap(fields: CornField[]) {
    this.layerService.setLayerVisibility(LayerId.CORN_PROGNOSIS, true);
    this.isLayerReady = false;

    const features = this.cornPrognosisService.getCornPrognosisFieldFeatures(fields);

    features.forEach((feature) => {
      this.layerService.addFeature(feature, LayerId.CORN_PROGNOSIS);
      this.mapService.magicReRender();
    });

    this.isLayerReady = true;
  }
}
