import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BffAuthService } from '@app/core/authentication/bff-auth.service';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { DateTime } from 'luxon';
import { combineLatest, filter, first, map, tap } from 'rxjs';
import { BiomassImageSource, MapControlBiomassService } from './map-control-biomass.service';

@Component({
  selector: 'app-map-layer-control-biomass',
  templateUrl: './map-layer-control-biomass.component.html',
  styleUrls: ['./map-layer-control-biomass.component.scss'],
  standalone: false,
})
export class MapLayerControlBiomassComponent implements OnInit, OnDestroy {
  protected sourceType = BiomassImageSource;
  protected form: FormGroup;

  protected imagesource$ = this.biomassService.imagesource$;
  protected years$ = this.biomassService.year$;
  protected months$ = this.biomassService.months$;
  protected dates$ = this.biomassService.dates$.pipe(
    map((dates) => dates?.map((date) => ({ date: date.date, imgsrc: this._getCloudImageSource(date.cloudCoveragePct) })))
  );
  protected isNaesgaard$ = this.authService.isCurrentUserFromNaesgaard$;
  private _subs = new SubscriptionArray();

  constructor(
    private fb: FormBuilder,
    private biomassService: MapControlBiomassService,
    private layerService: OlLayerService,
    private authService: BffAuthService
  ) {
    this.form = this.fb.group({
      year: [undefined],
      month: [undefined],
      date: [undefined],
    });
  }
  ngOnInit() {
    this._subs.add(this._initFormSubscription(), this._resetFormSubscription());
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private initForm() {
    combineLatest([this.years$, this.months$])
      .pipe(
        filter(([years, months]) => !!years && !!months),
        first(),
        tap(([years, months]) => {
          this.form.patchValue({
            year: years?.first(),
            month: months?.first(),
          });
        })
      )
      .subscribe();
  }

  private subscribeToFormChanges() {
    this.form.get('year')?.valueChanges.subscribe((year) => {
      this.onYearChange(year);
    });

    this.form.get('month')?.valueChanges.subscribe((month) => {
      this.onMonthChange(month);
    });

    this.form.get('date')?.valueChanges.subscribe((date) => {
      this.onDateChange(date);
    });
  }
  onYearChange(year: string) {
    this.biomassService.updateMonths(year);
    this.form.patchValue({
      month: undefined,
      date: undefined,
    });
  }

  onMonthChange(month: string) {
    const year = this.form.get('year')!.value;
    this.biomassService.updateDates(year, month);
    this.form.patchValue({
      date: undefined,
    });
  }

  onDateChange(date: DateTime | undefined) {
    this.biomassService.setSelectedDate(date);
    if (date === undefined) {
      this.layerService.setLayerVisibility(LayerId.BIOMASS, false);
    } else {
      this.layerService.setLayerVisibility(LayerId.BIOMASS, true);
    }
  }

  onStandardBtnClick() {
    this.biomassService.setImageSource(this.sourceType.standard);
    this.biomassService.getStandardNDVIDates();
    this.initForm();
  }

  onClearSkyBtnClick() {
    this.biomassService.setImageSource(this.sourceType.clearsky);
    this.biomassService.getCloudlessNDVIDates();
    this.initForm();
  }

  onClearClick() {
    this.initForm();
  }

  private _getCloudImageSource(input: number) {
    return MapControlBiomassService.getCloudImageSource(input);
  }

  private _initFormSubscription() {
    return this.isNaesgaard$
      .pipe(
        first(),
        tap((isNaesgaard) => {
          if (isNaesgaard) {
            this.biomassService.setImageSource(BiomassImageSource.standard);
            this.biomassService.getStandardNDVIDates();
          } else {
            this.biomassService.getCloudlessNDVIDates();
          }
          this.initForm();
          this.subscribeToFormChanges();
        })
      )
      .subscribe();
  }

  private _resetFormSubscription() {
    return this.biomassService.resetForm$.subscribe((reset) => {
      if (reset) {
        this.initForm();
      }
    });
  }
}
