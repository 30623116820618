import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round',
  standalone: false,
})
export class RoundPipe implements PipeTransform {
  /**
   * Rounds with standard javascript rounding unlike Numbers pipe
   * @param value
   */
  public transform(value: number): number {
    return +Math.round(+value);
  }
}
