import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ProductEntry } from '@app/farm-tasks-overview/class/product-entry';
import { FieldEntryQuery } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.query';
import { ChipUtil } from '@app/farm-tasks-overview/util/chip-util';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { map } from 'rxjs';
import { ProductSubType } from '../product-filter/product-filter.service';
import { MachineEventFilterService } from './machine-event-filter.service';

@Component({
  selector: 'app-overview-machine-event-filter',
  templateUrl: './machine-event-filter.component.html',
  styleUrls: ['./machine-event-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MachineEventFilterComponent implements OnInit, OnDestroy {
  constructor(
    private _fb: FormBuilder,
    private _accessControlService: AccessControlService,
    private _fieldEntryQuery: FieldEntryQuery,
    private _machineEventFilterService: MachineEventFilterService
  ) {}

  protected machineEvents$ = this._fieldEntryQuery.machineEvents$;
  protected readonly filterStateTranslateKey$ = this._fieldEntryQuery.MachineEventFilter$.pipe(
    map((machineEvents) => {
      return machineEvents.filter((event) => event.enabled);
    }),
    map((machineFilter) => {
      if (machineFilter.length === 0 || this.form.get('machineevents')?.value.length === 0) {
        return 'farm-tasks-overview.filter-state.none';
      }
      if (machineFilter.length === 1) {
        return machineFilter[0].label;
      }
      if (this.machineevents?.controls.some((control) => !control.value.enabled)) {
        return 'farm-tasks-overview.filter-state.some';
      }
      return 'farm-tasks-overview.filter-state.all';
    })
  );

  protected readonly hasAccess$ = this._accessControlService.policies$.pipe(
    map((policy) => {
      return policy.includes('basic');
    })
  );

  protected form!: FormGroup;

  private _subs = new SubscriptionArray();

  ngOnInit() {
    this._subs.add(
      this.machineEvents$.subscribe((machineEvents) => {
        this.form = this._fb.group({
          machineevents: this._fb.array(
            machineEvents.map((machineEvent) => {
              return this.createGroup(machineEvent);
            })
          ),
        });
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  get machineevents(): FormArray {
    return this.form.get('machineevents') as FormArray;
  }

  protected selectAll() {
    this.machineevents.controls.forEach((control) => {
      control.patchValue({ enabled: true });
    });
    this.filterSelected();
  }

  protected deselectAll() {
    this.machineevents.controls.forEach((control) => {
      control.patchValue({ enabled: false });
    });
    this.filterSelected();
  }

  protected filterSelected() {
    let enabledMachineEvents: ProductSubType[] = [];
    this.machineevents.controls.forEach((control) => {
      const isEnabled = control.get('enabled')?.value;
      const label = control.get('name')?.value;
      const id = control.get('id')?.value;
      const color = control.get('color')?.value;
      const machineEvent = { id, label, enabled: isEnabled, productId: id, color: color };
      if (isEnabled) {
        enabledMachineEvents.push(machineEvent);
      }
    });

    this._machineEventFilterService.setSelectedMachineEvents(enabledMachineEvents);
  }

  protected isAllSelected() {
    return this.machineevents.controls.every((control) => {
      const enabled = control.get('enabled')?.value;
      return enabled;
    });
  }

  protected onMenuStateChange(isOpen: boolean) {
    this._machineEventFilterService.isMenuOpenSubject.next(isOpen);
  }

  protected toggleMachineEvent() {
    this.filterSelected();
  }

  private createGroup(machineEvent: ProductEntry) {
    const group = this._fb.group({
      id: [machineEvent.id],
      name: [machineEvent.label],
      enabled: [machineEvent.enabled],
      color: [ChipUtil.getOperationTypeColor(machineEvent.type)],
    });
    return group;
  }
}
