import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rotate',
  standalone: false,
})
export class RotatePipe implements PipeTransform {
  /**
   * Transforms the given number to a rotate string, used with transform
   *
   * Functional example:
   * input = 45
   * output = 'rotate(45deg)'
   *
   * Functional example:
   * input = 90:85:true
   * output = 'rotate(-175deg)'
   *
   *
   * Usage example:
   * <div [ngStyle]="{ transform: blightRisk.windDirection | rotate}">
   *   &uarr;
   * </div>
   */
  public transform(degrees: number, iconRotation = 0, reverse = false): unknown {
    return `rotate(${reverse ? degrees - iconRotation - 180 : degrees - iconRotation}deg)`;
  }
}
