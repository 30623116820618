import { Component, OnDestroy, OnInit } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { MapLayerControlService } from '@app/shared/map-layer-controls/map-layer-control.service';
import { filterNullOrEmpty } from '@app/shared/operators';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Feature } from 'ol';
import { SelectEvent } from 'ol/interaction/Select';
import { ReplaySubject, combineLatest } from 'rxjs';
import { filter, finalize, first, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PrognosisShownComponent } from '../prognosis-side-drawer/prognosis-shown-component.enum';
import { PrognosisSideDrawerComponent } from '../prognosis-side-drawer/prognosis-side-drawer.component';
import { PrognosisService } from '../prognosis.service';
import { YieldPrognosisService } from './yield-prognosis.service';

/**
 * Displays fields relevant for YieldPrognosis on the map
 */
@Component({
  selector: 'app-yield-prognosis',
  templateUrl: './yield-prognosis.component.html',
  styleUrls: ['./yield-prognosis.component.scss'],
  standalone: false,
})
export class YieldPrognosisComponent implements OnInit, OnDestroy {
  public loadingState = this.prognosisService.mapLoadingState;
  public selectableLayers = [LayerId.YIELD_PROGNOSIS];
  public sideDrawerBodyText!: string;
  public sideDrawerWidth = SideDrawerConfig.widthAsOpened;

  public cropsForLegend$ = this.yieldPrognosisService.cropsForLegend$;

  // If the screensize is mobile, and the sidedrawer is not hidden, the legend should not be visible
  public hideLegend$ = combineLatest([
    this.screenSizeService.isMobile(),
    this.sideDrawerRef.onHide.pipe(startWith({ hidden: false })),
  ]).pipe(map(([mobile, hidden]) => (mobile ? !hidden.hidden : false)));

  private destroy$ = new ReplaySubject(1);

  constructor(
    private prognosisService: PrognosisService,
    private sideDrawerRef: SideDrawerRef<PrognosisSideDrawerComponent, any, any>,
    private yieldPrognosisService: YieldPrognosisService,
    private harvestYearStateService: HarvestYearStateService,
    private _mapService: OlMapService,
    private _layerService: OlLayerService,
    private farmStateService: FarmStateService,
    private languageService: LanguageService,
    private screenSizeService: ScreenSizeService,
    private _mapLayerControlService: MapLayerControlService
  ) {}

  public ngOnInit() {
    this.updateOnFarmOrHarvestYearChange();
    this._layerService.setLayerVisibility(LayerId.FIELD_FILL, false);
    this._layerService.setLayerVisibility(LayerId.FIELD_LABELS, false);
    this._layerService.setLayerVisibility(LayerId.FIELDS, false);
    this._layerService.setActivePage('prognosis_yield');
  }

  /**
   * Removes all visual maps components.
   * Unsubscribes from all active subscriptions.
   */
  public ngOnDestroy() {
    this.cleanMap();

    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  /**
   * Initially and when selected harvestYear and farms changes, the map is updated to only show fields relevant to yield prognosis.
   * Other layers are removed from the map.
   */
  private updateOnFarmOrHarvestYearChange() {
    combineLatest([this.harvestYearStateService.harvestYear$.pipe(first()), this.farmStateService.selectedFarms$])
      .pipe(
        filter(([_harvestYear, farms]) => !!farms.length),
        switchMap(() => this.yieldPrognosisService.yieldPrognosisFieldFeatures$),
        takeUntil(this.destroy$)
      )
      .subscribe((features) => {
        this.sideDrawerBodyText = features.length
          ? this.languageService.getText('main.yieldPrognosis.selectField')
          : this.languageService.getText('main.yieldPrognosis.noSelectableFields');

        this.cleanMap();

        this._layerService.setLayerVisibility(LayerId.FIELDS, false);
        this._layerService.setLayerVisibility(LayerId.FIELD_LABELS, false);

        this.addFeaturesToMap(features);
      });
  }

  public onCloseClick() {
    this.prognosisService.setShownComponent(PrognosisShownComponent.PrognosisPicker);
  }

  public onHideClick() {
    this.sideDrawerRef.hide();
    this._mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
  }

  /**
   * Sets the selected field in yieldPrognosisService and opens YieldPrognosisDetails and sidedrawer
   * @param event SelectEvent from OL
   */
  public onFieldSelect(event: SelectEvent) {
    const field: Field = event?.selected[0]?.get('field');

    if (!field) return;

    const directorateCropNormNumber = field.crops[0].lawDK.directorateCropNormNumber;

    this.harvestYearStateService.harvestYear$
      .pipe(
        tap(() => this.prognosisService.startLoading('')),
        switchMap((year) =>
          this.yieldPrognosisService.getYieldPrognosis(field, year!).pipe(finalize(() => this.prognosisService.stopLoading()))
        ),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (_yieldPrognosis) => {
          this.yieldPrognosisService.setSelectedFieldFeature(event.selected[0]);
          this.yieldPrognosisService.selectedDirectorateCropNormNumber = directorateCropNormNumber;
          this._layerService.setLayerVisibility(LayerId.YIELD_PROGNOSIS, false);
          this._layerService.setLayerVisibility(LayerId.FIELD_LABELS, true);
          this._layerService.filterFeaturesInLayerByPredicate(LayerId.FIELDS, (feature) => feature.get('field').id === field.id);
          this._layerService.filterFeaturesInLayerByPredicate(LayerId.FIELD_LABELS, (feature) => feature.get('field').id === field.id);

          this.prognosisService.setShownComponent(PrognosisShownComponent.YieldPrognosisDetails);
          this.sideDrawerRef.show();
        },
        error: (_error) => {
          this.yieldPrognosisService.setSelectedFieldFeature(null as any);
          this._mapService.deselectFeatures();
        },
      });
  }

  /**
   * Removes all possible existing visual components from the map
   */
  private cleanMap() {
    this._mapService.deselectFeatures();
    this._layerService.removeLayers(LayerId.YIELD_PROGNOSIS);
    this._layerService.removeLayers(LayerId.VRA_PROGNOSIS);
    this._layerService.setLayerVisibility(LayerId.GROWTH_REGULATION, false);
  }

  /**
   * Adds all the given features fields to the map, styled with the fields primary crop-color as fill
   * @param features List of features to add
   */
  private addFeaturesToMap(features: Feature[]) {
    this._layerService.createFeatureLayer(LayerId.YIELD_PROGNOSIS, []);

    this.farmStateService.fieldFeatures$
      .pipe(
        filterNullOrEmpty(),
        first(),
        tap((fieldFeatures) => {
          this._mapService.addFieldsToMap(fieldFeatures, LayerId.FIELDS, false);
        })
      )
      .subscribe();

    this._layerService.setLayerVisibility(LayerId.FIELDS, false);
    this._layerService.setLayerVisibility(LayerId.FIELD_LABELS, false);

    features.forEach((feat) => {
      this._layerService.addFeature(feat, LayerId.YIELD_PROGNOSIS);
    });

    this.yieldPrognosisService.fitMapToSelectableFeatures();

    this._layerService.setLayerVisibility(LayerId.YIELD_PROGNOSIS, true);
    this._layerService.setLayerVisibility(LayerId.VRA_PROGNOSIS, true);
  }
}
