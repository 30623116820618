import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldPlanSideDrawerService } from '../field-plan-side-drawer.service';
import { ShownComponentEnum } from '../shown-component-in-side-drawer.enum';

@Component({
  selector: 'app-field-plan-content',
  templateUrl: './field-plan-content.component.html',
  styleUrls: ['./field-plan-content.component.scss'],
  standalone: false,
})
export class FieldPlanContentComponent implements OnInit {
  public shownComponent$!: Observable<ShownComponentEnum>;

  constructor(private fieldPlanSideDrawerService: FieldPlanSideDrawerService) {}

  public ngOnInit() {
    this.shownComponent$ = this.fieldPlanSideDrawerService.shownComponent$.pipe(map((sc) => sc.shownComponent));
  }
}
