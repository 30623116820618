import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NotificationService } from '@app/core/notification/notification.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { MapLayerControlService } from '@app/shared/map-layer-controls/map-layer-control.service';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Observable, Subscription } from 'rxjs';
import { PrognosisService } from '../../prognosis.service';
import { PrognosisSideDrawerComponent } from '../prognosis-side-drawer.component';

@Component({
  selector: 'app-prognosis-picker',
  templateUrl: './prognosis-picker.component.html',
  styleUrls: ['./prognosis-picker.component.scss'],
  standalone: false,
})
export class PrognosisPickerComponent implements OnDestroy, OnInit {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public select = new EventEmitter<MapCoverFlowItem>();
  private subscriptions = new Subscription();
  public mapCoverFlowItems$ = this.prognosisService.getCoverFlowLayersItems();
  public harvestYear$!: Observable<number>;

  constructor(
    private prognosisService: PrognosisService,
    private sideDrawerRef: SideDrawerRef<PrognosisSideDrawerComponent, any, any>,
    private _layerService: OlLayerService,
    private notificationService: NotificationService,
    private harvestYearStateService: HarvestYearStateService,
    private _mapLayerControlService: MapLayerControlService
  ) {}

  public ngOnInit() {
    this.harvestYear$ = this.harvestYearStateService.harvestYear$ as Observable<number>;
    this._layerService.removeLayers([
      LayerId.GROWTH_REGULATION,
      LayerId.CORN_PROGNOSIS,
      LayerId.BLIGHT_FIELDS,
      LayerId.BLIGHT_POLYGONS,
      LayerId.BLIGHT_FIELD_POLYGONS,
      LayerId.VRA_PROGNOSIS,
    ]);
    this._layerService.setLayerVisibility(LayerId.FIELDS, true);
    this._layerService.setLayerVisibility(LayerId.FIELD_LABELS, true);
    this._layerService.setLayerVisibility(LayerId.FIELD_FILL, true);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onCloseClick() {
    this.prognosisService.drawerWidth = SideDrawerConfig.widthAsClosed;
    this._mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
    this.sideDrawerRef.hide();
  }

  public onHideClick() {
    this.prognosisService.drawerWidth = SideDrawerConfig.widthAsClosed;
    this._mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
    this.sideDrawerRef.hide();
  }

  public onSelect(prognosisFeature: MapCoverFlowItem) {
    this.select.emit(prognosisFeature);
  }

  public onDisabledSelect(message: string) {
    this.notificationService.showInfo(message);
  }
}
