import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-as-applied-task-accordion',
  templateUrl: './as-applied-task-accordion.component.html',
  styleUrls: ['./as-applied-task-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AsAppliedTaskAccordionComponent<T extends object> implements OnInit {
  @Input() public id: any;
  @Input() public title!: string;
  @Input() public data!: T[];
  @Input() public noDataMessage!: string;

  @Input() public sortFn: any;

  @Input() public tableColumns: any;
  @Input() public tableStyles: any;

  @Input() public initSortFn!: (arg0: T, arg1: T) => number;
  @Input() public showTooltip!: boolean;
  @Input() public tooltipKey!: string;
  @Input() public charactersBeforeTooltip!: number;

  @Output() public onItemSelect = new EventEmitter<T>();

  public ngOnInit(): void {
    if (!!this.initSortFn) {
      this.data.sort((a, b) => this.initSortFn(a, b));
    }
  }
}
