import { Component } from '@angular/core';

@Component({
  selector: 'app-side-drawer-body',
  templateUrl: './side-drawer-body.component.html',
  styleUrls: ['./side-drawer-body.component.scss'],
  standalone: false,
})
export class SideDrawerBodyComponent {
  constructor() {}
}
