import { Component, Input } from '@angular/core';
import { Feature } from 'ol';

@Component({
  selector: 'app-as-applied-tooltip',
  templateUrl: './as-applied-tooltip.component.html',
  styleUrls: ['./as-applied-tooltip.component.scss'],
  standalone: false,
})
export class AsAppliedTooltipComponent {
  @Input() feature!: Feature;
}
