import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CropModels } from '@app/core/enums/crop-models.enum';
import { CultivationJournalComponentEnum } from '@app/map/features/cultivation-journal/CultivationJournalComponentEnum.enum';
import { DecisionSupportService } from '@app/map/features/cultivation-journal/map-features/decision-support/decision-support.service';
import { Observable, combineLatest, map } from 'rxjs';
import { AccessControlService } from '../access-control/services/access-control.service';

@Component({
  selector: 'app-component-selector',
  templateUrl: './component-selector.component.html',
  styleUrls: ['./component-selector.component.scss'],
  standalone: false,
})
export class ComponentSelectorComponent {
  @Input() public initialComponent = CultivationJournalComponentEnum.CultivationJournal;

  @Output() public onValueChange = new EventEmitter();

  public componentItems$: Observable<ComponentItem[]> = combineLatest([
    this.decisionSupportService.fieldOnSelectedFarmsHasModel(CropModels.PotatoBlight),
    this.accessControlService.hasAccessTo('field_plan_n_tools'),
    this.accessControlService.hasAccessTo('field_plan_potato_blight'),
  ]).pipe(
    map(([farmHasPotatoBlightModel, hasAccessToNTools, hasAccessPotatoBlight]) => [
      {
        text: 'main.cultivationJournal.componentSelector.cultivationJournal',
        value: CultivationJournalComponentEnum.CultivationJournal,
        disabled: false,
      },
      {
        text: 'main.cultivationJournal.componentSelector.nTools',
        value: CultivationJournalComponentEnum.NTools,
        disabled: hasAccessToNTools === false,
      },
      {
        text: 'main.cultivationJournal.componentSelector.blight',
        value: CultivationJournalComponentEnum.Blight,
        disabled: farmHasPotatoBlightModel === false || hasAccessPotatoBlight === false,
      },
    ])
  );

  constructor(
    private decisionSupportService: DecisionSupportService,
    private accessControlService: AccessControlService
  ) {}

  public itemDisabled(itemArgs: { dataItem: ComponentItem; index: number }) {
    return itemArgs.dataItem.disabled;
  }
}

interface ComponentItem {
  text: string;
  value: CultivationJournalComponentEnum;
  disabled: boolean;
}
