import { Component, Input } from '@angular/core';
import { LayerId } from '@app/map/services/layer/layer.store';
import { YieldPrognosis } from '../../interfaces/yield-prognosis';
import { YieldPrognosisService } from '../../yield-prognosis.service';

@Component({
  selector: 'app-expected-yield',
  templateUrl: './expected-yield.component.html',
  styleUrls: ['./expected-yield.component.scss'],
  standalone: false,
})
export class ExpectedYieldComponent {
  @Input() public yieldPrognosis!: YieldPrognosis;

  public selectedCropNormNumber = this.yieldPrognosisService.selectedDirectorateCropNormNumber;

  public hoverMapLayerId = LayerId.VRA_PROGNOSIS;

  constructor(private yieldPrognosisService: YieldPrognosisService) {}

  public openInfoDialog() {
    this.yieldPrognosisService.openExpectedYieldInfoDialog();
  }
}
