import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionsGenericDialogData } from '../subscriptions.interface';

@Component({
  selector: 'app-subscriptions-dialog-generic',
  templateUrl: './subscriptions-dialog-generic.component.html',
  styleUrls: ['./subscriptions-dialog-generic.component.scss'],
  standalone: false,
})
export class SubscriptionsDialogGenericComponent {
  constructor(
    public dialogRef: MatDialogRef<SubscriptionsDialogGenericComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SubscriptionsGenericDialogData
  ) {}

  public close() {
    this.dialogRef.close(false);
  }

  public updateSubscriptions() {
    this.dialogRef.close(true);
  }
}
