import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataConnectionTypes } from '@app/core/data-connections/data-connection-types.enum';
import { DataConnectionsService } from '@app/core/data-connections/data-connections.service';
import { DataConnectionState } from '@app/core/enums/data-connection-state.enum';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { DatamanagementStateService } from '@app/state/services/data-management/datamanagement-state.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { DataConnectionSettingsFormValues } from './form-values.interface';

@Component({
  selector: 'app-add-edit-setting-modal',
  templateUrl: './add-edit-setting-modal.component.html',
  styleUrls: ['./add-edit-setting-modal.component.scss'],
  standalone: false,
})
export class AddEditSettingModalComponent implements OnInit, OnDestroy {
  public readonly dataConnectionTypes$ = this.dataConnectionsService.filteredDataConnectionTypes$;
  private dataConnections!: DataConnectionSettingDTO[];

  public dialogRef!: MatDialogRef<AddEditSettingModalComponent>;
  public isLoading = false;
  public loadingMessage = '';

  public subscriptions = new Subscription();

  public settingFormGroup!: UntypedFormGroup;

  public settingsId = -1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataConnectionSettingDTO,
    private dataConnectionsService: DataConnectionsService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private datamanagementStateService: DatamanagementStateService
  ) {}

  public ngOnInit() {
    this.settingsId = this.data ? this.data.id : -1;

    this.settingFormGroup = this.setupFormGroup();

    this.onConnectionTypeChange(this.settingFormGroup.value);

    this.subscriptions.add(this.connectionTypeId$.subscribe((values) => this.onConnectionTypeChange(values)));

    this.datamanagementStateService.dataConnectionSettings$.pipe(first()).subscribe((settings) => (this.dataConnections = settings));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onConnectionTypeChange(connectionTypeId: number) {
    if (this.dataConnections && this.onlyOneConnectionTypePerUser(connectionTypeId)) {
      const warningMessage1 = this.translateService.instant('main.settings.dataConnections.onlyOneConnectionTypePerUser1');
      const warningMessage2 = this.translateService.instant('main.settings.dataConnections.onlyOneConnectionTypePerUser2');
      this.notificationService.showInfo(`${warningMessage1} ${DataConnectionTypes[connectionTypeId]} ${warningMessage2}`);
      this.settingFormGroup.disable({ emitEvent: false });
      this.settingFormGroup.controls['connectionTypeId'].enable({ emitEvent: false });
      return;
    } else {
      this.settingFormGroup.enable({ emitEvent: false });
    }

    switch (connectionTypeId) {
      case DataConnectionTypes.Claas:
        this.updateClaasFormValidators();
        break;
      case DataConnectionTypes.JohnDeere:
        this.updateJDFormValidators();
        break;
      default:
        return;
    }
  }

  public onFormSubmit(settingFormGroup: UntypedFormGroup) {
    if (!settingFormGroup.valid) return;

    const settingsToSave: DataConnectionSettingDTO = this.getSettings(settingFormGroup.value);
    const initial = this.setupFormGroup();

    // if pristine or if no changes from form init
    if (!this.settingFormGroup.dirty || _.isEqual(initial.value, settingFormGroup.value)) {
      this.dialogRef?.close();
      return this.notificationService.showWarning('messages.dataConnection.noChange');
    }

    this.isLoading = true;

    this.isEditing ? this.updateSettings(settingsToSave) : this.createSettings(settingsToSave);
  }

  public onCancelClicked() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  public onJDLinkClick() {
    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    if (!this.connectionNameControl.value) {
      return;
    }
    this.setLoading(true, 'main.settings.dataConnections.johnDeere.loadingJDPage');
    this.dataConnectionsService

      // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      .createOrUpdateJDSettings(this.connectionNameControl.value)
      .pipe(finalize(() => this.setLoading(false)))

      // @ts-ignore - TS2769 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      .subscribe((jdConnectionUrl: string) => this.dataConnectionsService.goToJDLogin(jdConnectionUrl));
  }

  public onAgriRouterLinkClick() {
    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    if (!this.connectionNameControl.value) {
      return;
    }
    this.setLoading(true, 'main.settings.dataConnections.agriRouter.loadingAgriRouterPage');
    this.dataConnectionsService

      // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      .createOrUpdateAgriRouterSettings(this.connectionNameControl.value)
      .pipe(finalize(() => this.setLoading(false)))

      // @ts-ignore - TS2769 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      .subscribe((agriRouterConnectionUrl: string) => this.dataConnectionsService.goToAgriRouterLogin(agriRouterConnectionUrl));
  }

  public get isEditing() {
    return this.settingsId > -1;
  }

  public get isClaasConnection() {
    return this.settingFormGroup.get('connectionTypeId')?.value === DataConnectionTypes.Claas;
  }

  public get isTrimbleConnection() {
    return this.settingFormGroup.get('connectionTypeId')?.value === DataConnectionTypes.Trimble;
  }

  public get isJDConnection() {
    return this.settingFormGroup.get('connectionTypeId')?.value === DataConnectionTypes.JohnDeere;
  }

  public get isCNHConnection() {
    return this.settingFormGroup.get('connectionTypeId')?.value === DataConnectionTypes.CNH;
  }

  public get isAgriRouterConnection() {
    return this.settingFormGroup.get('connectionTypeId')?.value === DataConnectionTypes.AgriRouter;
  }

  private get connectionTypeId$() {
    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return this.connectionTypeIdControl.valueChanges;
  }

  public get connectionTypeIdControl() {
    return this.settingFormGroup.get('connectionTypeId');
  }

  public get connectionNameControl() {
    return this.settingFormGroup.get('name');
  }

  private get connectionIdControl() {
    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return this.settingFormGroup.get('credentials').get('connectionId');
  }

  private get connectionCodeControl() {
    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return this.settingFormGroup.get('credentials').get('connectionCode');
  }

  private setLoading(isLoading: boolean, message = '') {
    this.isLoading = isLoading;
    this.loadingMessage = message;
  }

  private onSettingReturnedFromServer(setting: DataConnectionSettingDTO): void {
    this.updateDataConnection(setting);
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      this.isLoading = false;
    }
  }

  private updateJDFormValidators() {
    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.connectionIdControl.clearValidators();

    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.connectionIdControl.updateValueAndValidity();

    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.connectionCodeControl.clearValidators();

    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.connectionCodeControl.updateValueAndValidity();
  }

  private updateClaasFormValidators() {
    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.connectionIdControl.setValidators([Validators.required]);

    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.connectionIdControl.updateValueAndValidity();

    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.connectionCodeControl.setValidators([Validators.required]);

    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.connectionCodeControl.updateValueAndValidity();
  }

  private createSettings(settingsToCreate: DataConnectionSettingDTO) {
    this.dataConnectionsService.createSetting(settingsToCreate).subscribe(
      // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      (setting: DataConnectionSettingDTO) => {
        this.notificationService.showCreated('main.dataConnection');
        this.onSettingReturnedFromServer(setting);
      },
      (error) => this.handleError(error)
    );
  }

  private handleError(error: Error) {
    this.isLoading = false;
    this.notificationService.showError(this.translateService.instant(error.message));
  }

  private updateSettings(settingsToUpdate: DataConnectionSettingDTO) {
    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    // @ts-ignore - TS2769 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.dataConnectionsService.updateSetting(settingsToUpdate).subscribe((setting: DataConnectionSettingDTO) => {
      this.notificationService.showUpdated('main.dataConnection');
      this.onSettingReturnedFromServer(setting);
    });
  }

  private setupFormGroup() {
    return new UntypedFormGroup({
      name: new UntypedFormControl(this.data ? this.data.name : '', [
        Validators.required,
        Validators.maxLength(50),
        this.connectionNameIsUsedValidator(this.data ? this.data.name : ''),
      ]),
      connectionTypeId: new UntypedFormControl(this.data ? this.data.connectionTypeId : '', [Validators.required]),
      credentials: new UntypedFormGroup({
        connectionId: new UntypedFormControl(this.data ? this.data.connectionId : ''),
        connectionCode: new UntypedFormControl(this.data ? this.data.connectionCode : ''),
      }),
      dataIsMissing: new UntypedFormControl(this.data ? this.data.dataIsMissing : false),
      additionalInfo: new FormControl({ value: this.data ? this.data.additionalInfo : '', disabled: true }),
    });
  }

  private connectionNameIsUsedValidator(currentDataConnectionName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      const controlValue = control.value;
      if (!controlValue || this.dataConnections === undefined) {
        return null;
      }

      if (
        this.dataConnections.find(
          (dataConnection) => dataConnection.name === controlValue.toString() && dataConnection.name !== currentDataConnectionName
        )
      ) {
        return { nameIsUsed: 'main.settings.dataConnections.validationMsg.dataConnectionNameMustBeUnique' };
      } else {
        return null;
      }
    };
  }

  private getSettings(formValues: DataConnectionSettingsFormValues) {
    return {
      connectionCode: formValues.credentials.connectionCode,
      connectionId: formValues.credentials.connectionId,
      connectionTypeId: formValues.connectionTypeId,
      id: this.settingsId,
      name: formValues.name,
      dataIsMissing: formValues.dataIsMissing,
      additionalInfo: formValues.additionalInfo,
    };
  }

  private updateDataConnection(setting: DataConnectionSettingDTO) {
    this.datamanagementStateService.updateDataConnectionStates({
      dataConnectionId: setting.id,
      dataConnectionState: DataConnectionState.UNTESTED,
    });
    this.datamanagementStateService.updateDataconnection(setting);
  }

  private onlyOneConnectionTypePerUser(connectionTypeId: number): boolean {
    const connectionTypeIds = this.dataConnections.map((dataConnection) => dataConnection.connectionTypeId);
    const connectionTypeIdUsed = connectionTypeIds.includes(connectionTypeId);
    return connectionTypeIdUsed;
  }
}
