import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FieldEntryQuery } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.query';
import { FieldEntryService } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.service';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { latest } from '@app/shared/constants/rxjs-constants';
import { FilterCrop } from '@app/shared/map-layer-controls/map-crop-filter-control/crop-filter.store';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, shareReplay, skip, Subject } from 'rxjs';

@Component({
  selector: 'app-overview-crop-filter',
  templateUrl: './overview-crop-filter.component.html',
  styleUrls: ['./overview-crop-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OverviewCropFilterComponent implements OnInit, OnDestroy {
  private _cropFilterUpdates = new Subject<FilterCrop[]>();
  private _isMenuOpenSubject = new BehaviorSubject<boolean>(false);
  private _isMenuOpen$ = this._isMenuOpenSubject.asObservable().pipe(shareReplay(latest));
  private _pendingUpdates: FilterCrop[] = [];

  private _updateVersion = 1;
  private _priorUpdateVersion = -1;

  constructor(
    private _fb: FormBuilder,
    private _accessControlService: AccessControlService,
    private _harvestYearService: HarvestYearStateService,
    private _fieldEntryQuery: FieldEntryQuery,
    private _fieldEntryService: FieldEntryService
  ) {}

  protected cropTotalArea = {
    first: 0.0,
    second: 0.0,
    third: 0.0,
  };

  protected readonly crops$ = this._fieldEntryQuery.crops$;

  protected readonly filterStateTranslateKey$ = this._fieldEntryQuery.cropFilter$.pipe(
    map((cropFilter) => {
      if (cropFilter.length === 0 || this.form.get('crops')?.value.length === 0) {
        return 'farm-tasks-overview.filter-state.none';
      }
      if (cropFilter.length === 1) {
        return cropFilter[0].cropName;
      }
      if (this.crops?.controls?.some((control) => !control?.value?.enabled)) {
        return 'farm-tasks-overview.filter-state.some';
      }
      return 'farm-tasks-overview.filter-state.all';
    })
  );

  protected readonly hasAccess$ = this._accessControlService.policies$.pipe(
    map((policy) => {
      return policy.includes('basic');
    })
  );

  protected form!: FormGroup;

  private _subs = new SubscriptionArray();

  ngOnInit() {
    this.form = this._fb.group({
      crops: this._fb.array([]),
    });

    this._subs.add(
      combineLatest([this._isMenuOpen$, this._cropFilterUpdates])
        .pipe(
          filter(([isMenuOpen]) => !isMenuOpen),
          map(([isMenuOpen, updates]) => updates)
        )
        .subscribe((updates) => {
          if (this._priorUpdateVersion === this._updateVersion) {
            return;
          }
          this._priorUpdateVersion = this._updateVersion;
          updates.forEach((crop) => {
            this._fieldEntryService.updateCropFilter({
              cropName: crop.name,
              term: crop.succession,
            });
          });
          this._pendingUpdates = [];
        })
    );

    this._subs.add(
      this.crops$.subscribe((crops) => {
        this.form = this._fb.group({
          crops: this._fb.array(
            crops.map((crop) => {
              return this.createGroup(crop);
            })
          ),
        });
        this.calculcateTotalAreaCrop();
      })
    );

    //skip the initial one, as we do not want to reset filter on startup of the module
    this._subs.add(
      this._harvestYearService.harvestYear$.pipe(skip(1), distinctUntilChanged(), skip(1)).subscribe((_) => {
        this.resetCropFilter();
      })
    );
  }

  ngOnDestroy() {
    this._cropFilterUpdates.complete();
    this._subs.unsubscribe();
  }

  protected onMenuStateChange(isOpen: boolean) {
    this._isMenuOpenSubject.next(isOpen);
  }

  private createGroup(crop: FilterCrop) {
    const group = this._fb.group({
      id: [crop.id],
      name: [crop.name],
      color: [crop.color],
      totalArea: [crop.totalArea],
      enabled: [crop.enabled],
      fieldIds: [crop.fieldIds],
      succession: [crop.succession],
    });
    return group;
  }

  protected selectAll() {
    this.crops.controls.forEach((control) => {
      if (!control.get('enabled')?.value) {
        control.patchValue({ enabled: true });
        this._pendingUpdates.push({
          id: control.get('id')?.value,
          fieldIds: control.get('fieldIds')?.value,
          color: control.get('color')?.value,
          name: control.get('name')?.value,
          totalArea: control.get('totalArea')?.value,
          succession: control.get('succession')?.value,
          enabled: true,
        });
      }
    });
    this.calculcateTotalAreaCrop();

    if (this._pendingUpdates.length > 0) {
      this._cropFilterUpdates.next(this._pendingUpdates);
      this._updateVersion++;
    }
  }

  protected deselectAll() {
    this.crops.controls.forEach((control) => {
      if (control.get('enabled')?.value) {
        control.patchValue({ enabled: false });
        this._pendingUpdates.push({
          id: control.get('id')?.value,
          fieldIds: control.get('fieldIds')?.value,
          color: control.get('color')?.value,
          name: control.get('name')?.value,
          totalArea: control.get('totalArea')?.value,
          succession: control.get('succession')?.value,
          enabled: false,
        });
      }
    });
    this.cropTotalArea = {
      first: 0.0,
      second: 0.0,
      third: 0.0,
    };

    if (this._pendingUpdates.length > 0) {
      this._cropFilterUpdates.next(this._pendingUpdates);
      this._updateVersion++;
    }
  }

  protected isAllSelected() {
    return this.crops.controls.every((control) => {
      const enabled = control.get('enabled')?.value;
      return enabled;
    });
  }

  protected toggleCrop(crop: AbstractControl) {
    const cropData: FilterCrop = {
      id: crop.get('id')?.value,
      fieldIds: crop.get('fieldIds')?.value,
      color: crop.get('color')?.value,
      name: crop.get('name')?.value,
      totalArea: crop.get('totalArea')?.value,
      succession: crop.get('succession')?.value,
      enabled: crop.get('enabled')?.value,
    };

    // Find if this crop is already in pending updates
    const existingIndex = this._pendingUpdates.findIndex(
      (update) => update.name === cropData.name && update.succession === cropData.succession
    );

    if (existingIndex !== -1) {
      // Remove the existing update if found
      this._pendingUpdates.splice(existingIndex, 1);
    } else {
      // Add new update if not found
      this._pendingUpdates.push(cropData);
    }

    // Only trigger updates if there are pending changes
    if (this._pendingUpdates.length > 0) {
      this._cropFilterUpdates.next(this._pendingUpdates);
      this._updateVersion++;
    }

    this.calculcateTotalAreaCrop();
  }

  private calculcateTotalAreaCrop() {
    const totalAreaFirst = this.firstTermCrops.controls
      .filter((cropsControl) => cropsControl.get('enabled')?.value === true)
      .map((cropsControl) => cropsControl.get('totalArea')?.value)
      .reduce((acc, area) => acc + (area || 0), 0);
    const totalAreaSecond = this.secondTermCrops.controls
      .filter((cropsControl) => cropsControl.get('enabled')?.value === true)
      .map((cropsControl) => cropsControl.get('totalArea')?.value)
      .reduce((acc, area) => acc + (area || 0), 0);
    const totalAreaThird = this.thirdTermCrops.controls
      .filter((cropsControl) => cropsControl.get('enabled')?.value === true)
      .map((cropsControl) => cropsControl.get('totalArea')?.value)
      .reduce((acc, area) => acc + (area || 0), 0);
    this.cropTotalArea = this.cropTotalArea = {
      first: totalAreaFirst,
      second: totalAreaSecond,
      third: totalAreaThird,
    };
  }

  private resetCropFilter() {
    this._fieldEntryService.resetCropFilter();
  }

  get crops(): FormArray {
    return this.form.get('crops') as FormArray;
  }

  get firstTermCrops(): FormArray {
    const filteredControls = this.crops.controls
      .filter((control) => control.get('succession')?.value === 1)
      .sort((a, b) => {
        const areaA = a.get('totalArea')?.value;
        const areaB = b.get('totalArea')?.value;
        return areaB - areaA;
      });
    return new FormArray(filteredControls);
  }

  get secondTermCrops(): FormArray {
    const filteredControls = this.crops.controls
      .filter((control) => control.get('succession')?.value === 2)
      .sort((a, b) => {
        const areaA = a.get('totalArea')?.value;
        const areaB = b.get('totalArea')?.value;
        return areaB - areaA;
      });
    return new FormArray(filteredControls);
  }

  get thirdTermCrops(): FormArray {
    const filteredControls = this.crops.controls
      .filter((control) => control.get('succession')?.value === 3)
      .sort((a, b) => {
        const areaA = a.get('totalArea')?.value;
        const areaB = b.get('totalArea')?.value;
        return areaB - areaA;
      });
    return new FormArray(filteredControls);
  }
}
