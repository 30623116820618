import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-blight-info',
  templateUrl: './blight-info.component.html',
  styleUrls: ['./blight-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BlightInfoComponent {
  constructor(private dialogRef: MatDialogRef<BlightInfoComponent>) {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
