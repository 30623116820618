import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { YieldCropType } from '../../interfaces/yield-crop-type';

@Component({
  selector: 'app-yield-info-dialog',
  templateUrl: './yield-info-dialog.component.html',
  styleUrls: ['./yield-info-dialog.component.scss'],
  standalone: false,
})
export class YieldInfoDialogComponent {
  public yieldCropType: Observable<YieldCropType>;
  constructor(
    private dialogRef: MatDialogRef<YieldInfoDialogComponent, void>,
    @Inject(MAT_DIALOG_DATA) public data: Observable<YieldCropType>
  ) {
    this.yieldCropType = data;
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
