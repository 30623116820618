import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { HotspotSubType } from '@app/core/interfaces/hotspot-sub-type-interface';
import { HotspotType } from '@app/core/interfaces/hotspot-type-interface';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, distinctUntilChanged, map, merge, switchMap, take, tap } from 'rxjs';
import { MapControlHotspotsService } from './map-layer-control-hotspots.service';

@Component({
  selector: 'app-map-layer-control-hotspots',
  templateUrl: './map-layer-control-hotspots.component.html',
  styleUrls: ['./map-layer-control-hotspots.component.scss'],
  standalone: false,
})
export class MapLayerControlHotspotsComponent implements OnInit {
  @ViewChildren(MatMenuTrigger) menuTriggers?: QueryList<MatMenuTrigger>;
  protected openSubMenuTrigger: MatMenuTrigger | null = null;

  protected hasAccess$ = this._accessControlService.policies$.pipe(
    map((policy) => {
      return policy.includes('basic');
    })
  );
  protected menuData$ = this.mapControlHotspotService.hotspotsAndTypesAndSubtypes$.pipe(
    map(([types, subtypes, hotspots]) => this.mapControlHotspotService.mapToMenuData(types, subtypes, hotspots))
  );

  protected noHotspots$ = new BehaviorSubject<boolean>(true);

  protected formGroup?: FormGroup;
  protected hoveredItem: number | null = null;

  private _subs = new SubscriptionArray();

  constructor(
    private fb: FormBuilder,
    private _accessControlService: AccessControlService,
    private mapControlHotspotService: MapControlHotspotsService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this.menuData$
        .pipe(
          tap((menuData) => {
            this.formGroup = this.mapControlHotspotService.createGroups(menuData);
          }),
          switchMap(() => {
            return merge(
              this.subscribeToFormChanges(),
              this.subscribeToHotspotChanges(),
              this.mapControlHotspotService.selectAllHotspotsSubject.pipe(
                distinctUntilChanged(),
                tap((isSelectAll) => {
                  if (isSelectAll) {
                    this.selectAllTypesAndSubTypes();
                  } else {
                    this.onClearClick();
                  }
                })
              ),
              this.mapControlHotspotService.selectSpecificHotspotSubject.pipe(
                distinctUntilChanged(),
                tap((data) => {
                  if (data) {
                    const { hotspotId, hotspotSubTypeIds } = data;
                    //? For some reason, despite being an array, subTypeIds is always a single-element array
                    this.selectHotspotOrSubType(hotspotId, hotspotSubTypeIds?.[0]);
                  }
                })
              )
            );
          })
        )
        .subscribe(() => {
          this.updateAnySelectedFlag();
        })
    );
  }

  get groups(): FormArray {
    return this.formGroup?.get('groups') as FormArray;
  }

  protected foldOutSubTypes(event: Event, trigger: MatMenuTrigger) {
    // Should only work for touch-screens, so ignore click events.
    if (event.type === 'click') {
      return;
    }
    event.stopPropagation();
    if (this.openSubMenuTrigger === trigger) {
      this.closeSubMenu();
      return;
    }
    setTimeout(() => trigger.openMenu());
    this.openSubMenuTrigger = trigger;
  }

  protected onClearClick() {
    this.groups.controls.forEach((group) => {
      // Deselect the type
      const typeGroup = group.get('type') as FormGroup;
      typeGroup.patchValue({
        selected: false,
        partiallySelected: false,
      });

      // Deselect all subtypes
      const subTypesArray = group.get('subTypes') as FormArray;
      subTypesArray.controls.forEach((subType) => {
        subType.patchValue({ selected: false });
      });
    });

    this.closeSubMenu();
    this.formGroup?.patchValue({ anySelected: false }, { emitEvent: true });
  }

  protected onSelectAllClick() {
    this.selectAllTypesAndSubTypes();
  }

  protected getSubTypesFormArray(control: AbstractControl): AbstractControl[] {
    const subTypesFormArray = control.get('subTypes') as FormArray;
    return subTypesFormArray ? subTypesFormArray.controls : [];
  }

  protected toggleTypeAndSubTypes(type: any, subTypes: any[], event: Event, trigger: MatMenuTrigger) {
    event.stopPropagation();

    // Find the group containing this type
    const groupIndex = this.groups.controls.findIndex((group) => group.get('type')?.get('id')?.value === type.id);

    if (groupIndex === -1) return;

    const group = this.groups.controls[groupIndex];
    const typeGroup = group.get('type') as FormGroup;
    const subTypesArray = group.get('subTypes') as FormArray;

    // Get current selected state
    const isCurrentlySelected = typeGroup.get('selected')?.value;

    // Toggle type selection
    typeGroup.patchValue({
      selected: !isCurrentlySelected,
      partiallySelected: false,
    });

    // Update all subtypes to match type selection
    subTypesArray.controls.forEach((subType) => {
      subType.patchValue({ selected: !isCurrentlySelected });
    });

    // If there are subtypes, open the submenu
    if (subTypes.length > 0) {
      setTimeout(() => trigger.openMenu());
      this.openSubMenuTrigger = trigger;
    }

    this.updateAnySelectedFlag();
  }

  protected toggleSubType(subType: any, event: Event) {
    event.stopPropagation();

    // Find the group containing this subtype
    const groupIndex = this.groups.controls.findIndex((group) => {
      const subTypesArray = group.get('subTypes') as FormArray;
      return subTypesArray.controls.some(
        (control) => control.get('id')?.value === subType.id && control.get('hotspotTypeId')?.value === subType.hotspotTypeId
      );
    });

    if (groupIndex === -1) return;

    const group = this.groups.controls[groupIndex];
    const typeGroup = group.get('type') as FormGroup;
    const subTypesArray = group.get('subTypes') as FormArray;

    // Find and toggle the specific subtype
    const subTypeControl = subTypesArray.controls.find((control) => control.get('id')?.value === subType.id);
    if (!subTypeControl) return;

    // Toggle subtype selection
    const newSelectedState = !subTypeControl.get('selected')?.value;
    subTypeControl.patchValue({ selected: newSelectedState });

    this.mapControlHotspotService.updateParentTypeStatus(typeGroup, subTypesArray);
    this.updateAnySelectedFlag();
  }

  // Keeps track of changes in the form and updates the visibility state
  private subscribeToFormChanges() {
    return this.formGroup!.valueChanges.pipe(
      tap(() => {
        this.noHotspots$.next(this.groups.controls.length === 0);
      }),
      map(() => this.mapControlHotspotService.getVisibilityState(this.groups.controls as FormGroup[])),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      tap((state) => {
        this.mapControlHotspotService.updateVisibility(state);
      })
    );
  }

  // Keeps track of changes under hotspots, i.e. when a new hotspot is added or removed
  private subscribeToHotspotChanges() {
    return this.mapControlHotspotService.hotspotFeaturesChangeSubject.pipe(
      tap((change) => {
        if (!change || !this.formGroup) return;
        const { typeId, subTypeIds, action } = change;
        if (action === 'add') {
          //? For some reason, despite being an array, subTypeIds is always a single-element array
          this.handleHotspotAddition(typeId, subTypeIds?.[0] ?? undefined);
        } else {
          this.handleHotspotRemoval(typeId, subTypeIds?.[0] ?? undefined);
        }
      })
    );
  }

  // Handles the addition of a new hotspot to the form, which may require adding new types and subtypes
  private handleHotspotAddition(typeId: number, subTypeId: number | undefined) {
    const groups = this.groups;
    const existingGroupIndex = groups.controls.findIndex((group) => group.get('type')?.get('id')?.value === typeId);

    if (existingGroupIndex === -1) {
      // Type doesn't exist in form, fetch type data and add it
      this.mapControlHotspotService.hotspotsAndTypesAndSubtypes$
        .pipe(
          map(([types, subtypes]) => ({
            type: types.find((t) => t.id === typeId),
            subtype: subTypeId ? subtypes.find((st) => st.id === subTypeId) : undefined,
          })),
          take(1)
        )
        .subscribe(({ type, subtype }) => {
          if (type) {
            const newGroup = this.createNewGroup(type, subtype);
            groups.push(newGroup);
          }
        });
    }

    // If type exists, consider adding subtype
    const group = groups.at(existingGroupIndex);
    const subTypes = group.get('subTypes') as FormArray;
    if (!subTypeId) {
      // No subtype, add unspecified subtype if it doesn't exist
      const unspecifiedSubType = subTypes.controls.find(
        (control) => control.get('id')?.value === this.mapControlHotspotService.getUnspecifiedId(typeId)
      );
      if (!unspecifiedSubType) {
        subTypes.push(
          this.fb.group({
            id: [this.mapControlHotspotService.getUnspecifiedId(typeId)],
            name: [this.translateService.instant('common.operationTypeGroup.unspecified')],
            hotspotTypeId: [typeId],
            selected: [true],
          })
        );
      }
    }

    // Check if subtype already exists
    const existingSubType = subTypes.controls.find((control) => control.get('id')?.value === subTypeId);
    if (!existingSubType && subTypeId) {
      // Set type to partially selected if it wasn't already selected
      const typeGroup = group.get('type') as FormGroup;
      if (!typeGroup.get('selected')?.value) {
        typeGroup.patchValue({ selected: false, partiallySelected: true });
      }
      // Fetch subtype data and add it
      this.mapControlHotspotService.hotspotsAndTypesAndSubtypes$
        .pipe(
          map(([_, subtypes]) => subtypes.find((st) => st.id === subTypeId)),
          take(1)
        )
        .subscribe((subtype) => {
          if (subtype) {
            subTypes.push(
              this.fb.group({
                id: [subtype.id],
                name: [this.mapControlHotspotService.getNameBasedOnLanguage(subtype)],
                hotspotTypeId: [subtype.hotspotTypeId],
                selected: [true],
              })
            );
          }
        });
    }

    // Sort subtype list alphabetically with unspecified subtype first
    subTypes.controls.sort((a, b) => {
      const aName = a.get('name')?.value;
      const bName = b.get('name')?.value;
      if (aName === this.translateService.instant('common.operationTypeGroup.unspecified')) return -1;
      if (bName === this.translateService.instant('common.operationTypeGroup.unspecified')) return 1;
      return aName.localeCompare(bName);
    });

    this.updateAnySelectedFlag();
  }

  // Handles the removal of a hotspot from the form, which may require removing types and subtypes
  private handleHotspotRemoval(typeId: number, subTypeId: number | undefined) {
    subTypeId = subTypeId ?? this.mapControlHotspotService.getUnspecifiedId(typeId);

    // First, get the current hotspots to check if this was the last one
    this.mapControlHotspotService.hotspotFeatures$.pipe(take(1)).subscribe((hotspots) => {
      const remainingHotspotsOfType = hotspots!.filter((h) => h.get('hotspotTypeId') === typeId);
      const groups = this.groups;
      const groupIndex = groups.controls.findIndex((group) => group.get('type')?.get('id')?.value === typeId);

      if (groupIndex === -1) return;

      if (remainingHotspotsOfType.length <= 1) {
        // This was the last hotspot of this type, remove the entire group
        groups.removeAt(groupIndex);
        return;
      }

      // Check if this was the last hotspot with this subtype
      const remainingHotspotsWithSubtype = remainingHotspotsOfType.filter((h) => h.get('hotspotSubTypeIds')?.includes(subTypeId));
      // If the subtype is unspecified, also match with hotspots that have no subtype
      if (subTypeId === this.mapControlHotspotService.getUnspecifiedId(typeId)) {
        remainingHotspotsWithSubtype.push(...remainingHotspotsOfType.filter((h) => !h.get('hotspotSubTypeIds')?.length));
      }

      if (remainingHotspotsWithSubtype.length <= 1) {
        // This was the last hotspot with this subtype, remove the subtype
        const group = groups.at(groupIndex);
        const subTypes = group.get('subTypes') as FormArray;
        const subTypeIndex = subTypes.controls.findIndex((control) => control.get('id')?.value === subTypeId);

        if (subTypeIndex !== -1) {
          subTypes.removeAt(subTypeIndex);
        }

        // Update the parent type status
        this.mapControlHotspotService.updateParentTypeStatus(group.get('type') as FormGroup, subTypes);
      }
    });
  }

  private createNewGroup(type: HotspotType, subtype?: HotspotSubType) {
    const newGroup = this.fb.group({
      id: [type.id],
      type: this.fb.group({
        id: [type.id],
        name: [this.mapControlHotspotService.getNameBasedOnLanguage(type)],
        icon: [this.mapControlHotspotService.getIconNameById(type.id)],
        selected: [true],
        partiallySelected: [false],
      }),
      subTypes: this.fb.array([]),
    });

    // Add subtype if it exists
    if (subtype) {
      (newGroup.get('subTypes') as FormArray).push(
        this.fb.group({
          id: [subtype.id],
          name: [this.mapControlHotspotService.getNameBasedOnLanguage(subtype)],
          hotspotTypeId: [subtype.hotspotTypeId],
          selected: [true],
        })
      );
    }

    return newGroup;
  }

  private selectAllTypesAndSubTypes() {
    // Get the groups FormArray
    const groupsArray = this.groups;

    // Iterate through each group
    groupsArray.controls.forEach((group) => {
      // Select the type
      const typeGroup = group.get('type') as FormGroup;
      typeGroup.patchValue(
        {
          selected: true,
          partiallySelected: false,
        },
        { emitEvent: true }
      );

      // Select all subtypes
      const subTypesArray = group.get('subTypes') as FormArray;
      subTypesArray.controls.forEach((subType) => {
        subType.patchValue({ selected: true });
      });
    });

    // Update the anySelected flag
    this.formGroup?.get('anySelected')?.setValue(true);
  }

  // Selects a specific hotspot type or subtype
  private selectHotspotOrSubType(hotspotId: number, hotspotSubTypeId?: number) {
    const groupIndex = this.groups.controls.findIndex((group) => group.get('type')?.get('id')?.value === hotspotId);

    if (groupIndex === -1) return;

    const group = this.groups.controls[groupIndex];
    const typeGroup = group.get('type') as FormGroup;
    const subTypesArray = group.get('subTypes') as FormArray;

    if (hotspotSubTypeId !== undefined) {
      // Select the specific sub-hotspot type
      const subTypeControl = subTypesArray.controls.find((control) => control.get('id')?.value === hotspotSubTypeId);
      if (subTypeControl) {
        subTypeControl.patchValue({ selected: true });
        typeGroup.patchValue({ selected: false, partiallySelected: true });
      }
    } else {
      // Select the entire hotspot type
      typeGroup.patchValue({ selected: true, partiallySelected: false });
      subTypesArray.controls.forEach((subType) => subType.patchValue({ selected: true }));
    }

    // Update anySelected flag
    this.updateAnySelectedFlag();
  }

  private updateAnySelectedFlag() {
    const groupsArray = this.groups;
    let anySelected = false;

    // Check if any type or subtype is selected
    for (const group of groupsArray.controls) {
      const typeSelected = group.get('type')?.get('selected')?.value;
      if (typeSelected) {
        anySelected = true;
        break;
      }

      const subTypesArray = group.get('subTypes') as FormArray;
      const anySubTypeSelected = subTypesArray.controls.some((subType) => subType.get('selected')?.value);
      if (anySubTypeSelected) {
        anySelected = true;
        break;
      }
    }

    // Update the form
    this.formGroup?.get('anySelected')?.setValue(anySelected);
  }

  private closeSubMenu() {
    this.openSubMenuTrigger?.closeMenu();
    this.openSubMenuTrigger = null;
  }
}
