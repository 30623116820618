import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Colors } from '@app/core/enums/colors.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { GrowthStageCorrection, GrowthStageDay, GrowthStageResult } from '@app/core/repositories/growthstage/growthstage.interface';
import { CategoryAxis, SeriesClickEvent, SeriesMarkers, ValueAxis } from '@progress/kendo-angular-charts';
import { SeriesOverEvent } from '@progress/kendo-angular-charts/events/series-over-event';
import { DashType } from '@progress/kendo-drawing';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GrowthstageService } from '../growthstage.service';

@Component({
  selector: 'app-growth-stages-chart',
  templateUrl: './growth-stages-chart.component.html',
  styleUrls: ['./growth-stages-chart.component.scss'],
  standalone: false,
})
export class GrowthStagesChartComponent implements OnInit, OnDestroy {
  @Input() public field: Field = {} as Field;
  @Input() public prognosis: GrowthStageResult = {} as GrowthStageResult;
  @Input() public errorMessage = '';

  public cursor = 'default';
  public isGrowthStageSaving = false;
  public isTooltipVisible = true;

  public categoryAxis: CategoryAxis = {
    baseUnit: 'days',
    type: 'date',
    majorGridLines: {
      visible: false,
    },
    min: 0,
    labels: {
      dateFormats: {
        days: LanguageConstants.getKendoDateFormat(this.languageService.currentLanguage.shortKey).L,
      },
      position: 'start',
    },
  };

  public valueAxis: ValueAxis = {
    title: {
      text: this.languageService.getText('growthStage.valueAxis.title'),
      margin: {
        right: 1,
      },
    },
  };

  public prognosisMarkers: SeriesMarkers = {
    visible: false,
  };

  public correctionsMarkers: SeriesMarkers = {
    visible: false,
  };

  public correctionPointMarkers: SeriesMarkers = {
    visible: true,
    size: 10,
    background: Colors.White,
    border: {
      color: Colors.Orange1,
      dashType: 'solid',
      width: 2,
    },
  };

  public prognosisAttributeValue = {
    colors: {
      toolTipBackgroundColor: Colors.White,
      observationLineColor: Colors.Blue2,
    },
    opacities: {
      notTansparent: 1,
    },
    lineWidths: {
      observationLineWidth: 3,
    },
  };

  public correctionsAttributeValue = {
    colors: {
      correctionLineColor: Colors.Orange1,
      correctionLineColorTransparent: Colors.Transparent,
    },
    opacities: {
      notTansparent: 1,
    },
    lineWidths: {
      correctionLineWidth: 3,
    },
  };

  public listPrognosisAttributeValue = {
    colors: {
      toolTipBackgroundColor: Colors.White,
      prognosisLineColor: Colors.Gray1,
    },
    opacities: {
      notTansparent: 1,
    },
    line: {
      prognosisLineWidth: 3,
      dashType: 'dash' as DashType,
    },
  };

  private subscriptions: Subscription[] = [];
  private isPrognosisLoading = false;
  private isOptionsLoading = false;

  constructor(
    private languageService: LanguageService,
    private growthstageService: GrowthstageService
  ) {}

  public ngOnInit(): void {
    this.prognosis = {
      growthStageListObservations: [],
      growthStageListWithCorrections: [],
    } as unknown as GrowthStageResult;

    this.getAndSetPrognosis();
  }

  public setCursorMouseOver(seriesOverEvent: SeriesOverEvent): void {
    if (
      seriesOverEvent.series.name?.toUpperCase() ===
      this.languageService.getText('growthStage.chartSeriesLegend.calculatedGrowthStage').toUpperCase()
    ) {
      this.cursor = 'pointer';
    } else {
      this.cursor = 'default';
    }
  }

  public setCursorMouseLeave(): void {
    this.cursor = 'default';
  }

  public updateGrowthStage(seriesClickEvent: SeriesClickEvent): void {
    if (
      seriesClickEvent.series.name?.toUpperCase() ===
      this.languageService.getText('growthStage.chartSeriesLegend.calculatedGrowthStage').toUpperCase()
    ) {
      this.isTooltipVisible = false;
      // find by date and send to dialog box
      const growthStageDay: GrowthStageDay = this.prognosis.growthStageListObservations.filter(
        (item) => item.date === seriesClickEvent.dataItem.date
      )[0];

      this.growthstageService.openCorrectionDialog(growthStageDay, true)?.subscribe((updatedGrowthStageDay: GrowthStageDay) => {
        this.isTooltipVisible = true;
        if (updatedGrowthStageDay) {
          const updateCorrection: GrowthStageCorrection = {
            dateTime: updatedGrowthStageDay.date!,
            growthStage: updatedGrowthStageDay.growthStage!,
          };

          const growthStageCorrections: GrowthStageCorrection[] = this.growthstageService.getGrowthStageResults?.corrections ?? [];

          const existingCorrection: GrowthStageCorrection = this.growthstageService.isExistingCorrection(updateCorrection);
          if (existingCorrection) {
            existingCorrection.growthStage = updateCorrection.growthStage;
          } else {
            growthStageCorrections.push(updateCorrection);
          }

          this.isGrowthStageSaving = true;

          this.growthstageService
            .setGrowthStageCorrection(this.field, growthStageCorrections)
            .pipe(finalize(() => (this.isGrowthStageSaving = false)))
            .subscribe({
              next: (resultsData) => {
                // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
                this.prognosis = resultsData;
              },
              error: (error: Error) => (this.errorMessage = error.message),
            });
        }
      });
    }
  }

  public get isPrognosisVisible() {
    return !this.isPrognosisLoading && !this.errorMessage && this.prognosis;
  }

  public get isErrorMessageVisible() {
    return !this.isPrognosisLoading && this.errorMessage;
  }

  public get isLoading() {
    return this.isOptionsLoading || this.isPrognosisLoading;
  }

  private getAndSetPrognosis() {
    this.isPrognosisLoading = true;
    this.subscriptions.push(
      this.growthstageService
        .getGrowthStages(this.field)
        .pipe(finalize(() => (this.isPrognosisLoading = false)))
        .subscribe({
          next: (resultsData) => {
            this.prognosis = resultsData;
            if (this.prognosis.growthStageListObservations.length > 0) {
              this.categoryAxis.labels!.step = Math.round(this.prognosis.growthStageListObservations.length / 5);
            } else {
              this.categoryAxis.labels!.visible = false;
            }
          },
          error: (error: Error) => (this.errorMessage = error.message),
        })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
