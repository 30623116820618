import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@app/core/notification/notification.service';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { FieldPlanSideDrawerService } from '@app/map/features/field-plan/field-plan-side-drawer/field-plan-side-drawer.service';
import { cmShortcuts } from '@app/shared/directives/shortcut-key/cm-shortcuts.const';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { ShownComponentEnum } from '../../shown-component-in-side-drawer.enum';
import { DialogService } from './../../../../../../shared/dialog/dialog.service';
import { FieldCopyDialogComponent } from './../../../field-copy/field-copy-dialog/field-copy-dialog.component';

@Component({
  selector: 'app-field-administration',
  templateUrl: './field-administration.component.html',
  styleUrls: ['./field-administration.component.scss'],
  standalone: false,
})
export class FieldAdministrationComponent {
  public shortcuts = cmShortcuts;

  constructor(
    private sideDrawerRef: SideDrawerRef<FieldAdministrationComponent, any, any>,
    private fieldPlanSideDrawerService: FieldPlanSideDrawerService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private screenSizeService: ScreenSizeService
  ) {}

  public onCreateClick() {
    this.fieldPlanSideDrawerService.setShownComponentState(ShownComponentEnum.createFieldComponent);
  }

  public onExportClick() {
    this.fieldPlanSideDrawerService.setShownComponentState(ShownComponentEnum.exportFieldsComponent);
  }

  public onCopyFieldsClick() {
    this.screenSizeService
      .isMobile()
      .pipe(first())
      .subscribe((isMobile) => {
        if (isMobile) {
          this.notificationService.showInfo(this.translateService.instant('main.fieldAdministration.copyField.notifications.mobile'));
          return;
        }
        this.sideDrawerRef.hide();
        this.dialogService
          .openCustomDialog(FieldCopyDialogComponent, {
            panelClass: 'copy-field-dialog',
            width: '85vw',
            maxWidth: '85vw',
            disableClose: true,
            maxHeight: '85vh',
          })
          .afterClosed()
          .pipe(first())
          .subscribe(() => {
            this.sideDrawerRef.show();
          });
      });
  }

  public onHideClick() {
    this.sideDrawerRef.hide();
  }

  public onCloseClick() {
    const animateDelay = 100;
    this.sideDrawerRef.hide();
    setTimeout(() => {
      this.sideDrawerRef.close();
    }, animateDelay);
  }
}
