import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTime } from 'luxon';

interface IntervalPickerData {
  startDate: DateTime;
  endDate: DateTime;
  title: String;
}
@Component({
  selector: 'app-interval-picker',
  templateUrl: './interval-picker.component.html',
  styleUrls: ['./interval-picker.component.scss'],
  standalone: false,
})
export class IntervalPickerComponent {
  // @ts-ignore - TS2564 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public dialogRef: MatDialogRef<IntervalPickerComponent>;
  public startDate: DateTime;
  public endDate: DateTime;
  public startDateInit: DateTime;
  public endDateInit: DateTime;
  public title: String;

  constructor(@Inject(MAT_DIALOG_DATA) data: IntervalPickerData) {
    this.startDateInit = data.startDate;
    this.endDateInit = data.endDate;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.title = data.title;
  }

  public onCancelClicked() {
    this.dialogRef?.close({
      startDate: this.startDateInit,
      endDate: this.endDateInit,
    });
  }

  public onConfirmClicked() {
    this.dialogRef?.close({
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

  public isInvalid() {
    return this.endDate <= this.startDate;
  }

  public handleStartDateChange(date: DateTime) {
    this.startDate = date;
  }

  public handleEndDateChange(date: DateTime) {
    this.endDate = date;
  }
}
