import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldService } from '@app/core/field/field.service';
import { Field, FieldDetails } from '@app/core/interfaces/field.interface';
import { CultivationJournalStateService } from '@app/state/services/cultivation-journal/cultivation-journal-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-journal-side',
  templateUrl: './journal-side.component.html',
  styleUrls: ['./journal-side.component.scss'],
  standalone: false,
})
export class JournalSideComponent implements OnInit, OnDestroy {
  public harvestYear$: Observable<number | undefined> = this.harvestYearStateService.harvestYear$;
  public field$: Observable<Field | undefined> = this.cultivationJournalStateService.field$;

  public field!: Field;
  public fieldDetails!: FieldDetails;
  public preCrops!: Field[];
  public readonly maxHarvestYears = 6;

  private subscriptions: Subscription[] = [];

  constructor(
    private fieldService: FieldService,
    private harvestYearStateService: HarvestYearStateService,
    private cultivationJournalStateService: CultivationJournalStateService
  ) {}

  public ngOnInit() {
    const fetchFieldInfo = this.onDataChange(this.harvestYear$ as Observable<number>, this.field$ as Observable<Field>).subscribe(
      ([field, fieldDetails]) => {
        this.field = field;
        this.fieldDetails = fieldDetails;
      }
    );
    const fetchPreCropData = this.onPreCropDataChange(this.harvestYear$ as Observable<number>, this.field$ as Observable<Field>).subscribe(
      (preCrops) => (this.preCrops = preCrops.filter((preCrop) => !!preCrop).sort((a, b) => b.harvestYear - a.harvestYear))
    );
    this.subscriptions.push(fetchFieldInfo, fetchPreCropData);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public onPreCropDataChange(harvestYear$: Observable<number>, field$: Observable<Field>) {
    return combineLatest([harvestYear$, field$]).pipe(
      filter(([harvestYear, field]) => field !== undefined),
      switchMap(([harvestYear, field]) => this.getFieldPreCrops(field.farmId, harvestYear, field.id, this.maxHarvestYears))
    );
  }

  private getFieldPreCrops(farmId: number, harvestYear: number, fieldMultiYearId: number, maxHarvestYears: number): Observable<Field[]> {
    return this.fieldService
      .getFieldPreCrops(farmId, harvestYear, fieldMultiYearId, maxHarvestYears)
      .pipe(map((fields) => fields.map((field) => this.fieldService.decorateWithMainCrop(field))));
  }

  public onDataChange(harvestYear$: Observable<number>, field$: Observable<Field>) {
    return combineLatest([harvestYear$, field$]).pipe(
      filter(([harvestYear, field]) => field !== undefined),
      switchMap(([harvestYear, field]) => this.getFieldData(field.farmId, harvestYear, field.id))
    );
  }

  private getFieldData(farmId: number, harvestYear: number, fieldId: number): Observable<[Field, FieldDetails]> {
    const field$ = this.fieldService.getField(farmId, harvestYear, fieldId);
    const details$ = this.fieldService.getFieldDetails(farmId, harvestYear, fieldId).pipe(
      map((details) => {
        // Fixing formatting x ,y => x, y
        if (details && details.varietyNames) {
          details.varietyNames = details.varietyNames
            .split(',')
            .map((el) => el.trim())
            .join(', ');
        }
        return details;
      })
    );
    return combineLatest([field$, details$]);
  }
}
