import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { FarmService } from '@app/core/farm/farm.service';
import { Farm } from '@app/core/interfaces/farm.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { IsNotIntegerValidator } from '@app/helpers/validators/forms/is-not-integer.validator';
import { NoWhitespaceValidator } from '@app/helpers/validators/forms/no-whitespace.validator';
import { FarmPickerStateService } from '@app/state/services/farm-picker/farm-picker-state.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';

@Component({
  selector: 'app-farm-picker-modal-create-farm',
  templateUrl: './create.component.html',
  styleUrls: ['create.component.scss'],
  standalone: false,
})
export class FarmPickerModalCreateFarmComponent implements OnInit {
  public cvr!: UntypedFormControl;
  public isLoading!: boolean;

  constructor(
    private farmStateService: FarmStateService,
    private farmPickerStateService: FarmPickerStateService,
    private farmService: FarmService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.isLoading = false;
    this.cvr = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(8),
      // TODO: This is not a ValidatorFn
      NoWhitespaceValidator as ValidatorFn,
      // TODO: This is not a ValidatorFn
      IsNotIntegerValidator as ValidatorFn,
    ]);
  }

  /**
   * Function for handling "cancel" pressed in modal
   */
  public onCancelClicked(): void {
    this.farmPickerStateService.closeAddFarmModal();
  }

  /**
   * Function for handling "opret" pressed in modal
   */
  public onConfirmClicked(): void {
    if (!this.cvr.errors) {
      this.createFarm(this.cvr.value);
    }
  }

  /**
   * Creates a new farm.
   */
  public createFarm(cvr: string): void {
    this.isLoading = true;

    this.farmService.createFarmFromCVR(cvr).subscribe(
      (farm: Farm | null) => {
        this.notificationService.showCreated('common.farm');
        this.farmService.addFarmToState(farm!);
        this.farmPickerStateService.closeAddFarmModal();
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  public isReady(): boolean {
    return !this.isLoading;
  }
}
