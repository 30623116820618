import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { DirtyCheckService } from '@app/core/dirty-check/dirty-check.service';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { VraDirtyCheckService } from '@app/map/features/vra/guards/vra-dirty-check.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { DirtyCheckDialogAction } from '@app/shared/dialog/dirty-check-dialog/dirty-check-actions.class';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { defaultIfEmpty, filter, first, tap, withLatestFrom } from 'rxjs';
import { HarvestYearPickerMapService } from './harvest-year-picker-map.service';

const MAT_MENU_PANEL = '.mat-mdc-menu-panel';
@Component({
  selector: 'app-harvest-year-picker-map',
  templateUrl: './harvest-year-picker-map.component.html',
  styleUrls: ['./harvest-year-picker-map.component.scss'],
  standalone: false,
})
export class HarvestYearPickerMapComponent implements OnInit {
  @Input() public start?: number;
  @Input() public end?: number;
  @Input() public disabled?: boolean;

  public years: number[] = [];
  public harvestYear$ = this.harvestYearStateService.harvestYear$;

  private readonly NO_DIRTY_CHECK_MODULE_NAMES = ['hotspots', 'droneUpload', 'asAppliedUpload'];

  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef }) menu!: ElementRef<HTMLElement>;
  get menuData() {
    return {
      menuWidth: this.menu.nativeElement.clientWidth - 8,
    };
  }
  constructor(
    private harvestYearPickerMapService: HarvestYearPickerMapService,
    private dirtyCheckService: DirtyCheckService,
    private dialogService: DialogService,
    private harvestYearStateService: HarvestYearStateService,
    private dirtyCheckServiceVra: VraDirtyCheckService,
    private overlayContainer: OverlayContainer,
    private screenSizeService: ScreenSizeService
  ) {}

  public ngOnInit() {
    this.years = this.harvestYearPickerMapService.generateYears(this.start!, this.end!);
    this.harvestYear$
      .pipe(
        first(),
        filter((value) => !Boolean(value)),
        tap(() => {
          this.harvestYearStateService.setHarvestYearToCurrent();
        })
      )
      .subscribe();
  }

  public onAutoScrollToCurrentYear() {
    this.harvestYear$
      .pipe(
        first(),
        withLatestFrom(this.screenSizeService.isMobile()),
        tap(([harvestYear, isMobile]) => {
          const delay = isMobile ? 10 : 0;
          const overlayRef = this.overlayContainer.getContainerElement();
          const yearMenu = overlayRef.querySelector(MAT_MENU_PANEL) as HTMLElement;
          if (yearMenu) {
            // this is currently the only way found to get the scrolling to function
            setTimeout(() => {
              const scrollpx = this.harvestYearPickerMapService.getScrollTopForMenu(harvestYear!, this.end!, 48);
              if (scrollpx > 0) {
                // Using touchstart event
                yearMenu.dispatchEvent(new Event('touchstart'));
                yearMenu.scrollTop = scrollpx;
                yearMenu.dispatchEvent(new Event('touchend'));
              }
            }, delay);
          }
        })
      )
      .subscribe();
  }

  // Called when clicking a year
  public selectYear(year: number) {
    if (!this.disabled) {
      // Skip warning if only hotspots or droneUpload are dirty as they do not change with the harvest year.
      if (this.shouldShowDirtyCheck()) {
        this.dialogService
          .openDirtyCheckDialog()
          .pipe(DirtyCheckDialogAction.confirmed())
          .subscribe(() => this.setCurrentYear(year));
      } else {
        // Check new VRA dirty
        this.dirtyCheckServiceVra
          .checkDirtyAndAct()
          .pipe(defaultIfEmpty(true), first())
          .subscribe((isNotDirtyOrUserAccepted) => {
            if (isNotDirtyOrUserAccepted) this.setCurrentYear(year);
          });
      }
    }
  }

  private shouldShowDirtyCheck() {
    return (
      this.dirtyCheckService.isAppDirty &&
      // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      this.dirtyCheckService.getDirtyModuleNames().some((name) => !this.NO_DIRTY_CHECK_MODULE_NAMES.includes(name))
    );
  }

  private setCurrentYear(year: number) {
    const indexOfYear = this.years.indexOf(year);
    this.setCurrent(indexOfYear);
  }

  private setCurrent(index: number) {
    if (index < 0 || index > this.years.length - 1) {
      index = 0;
    }

    const year = this.years[index];

    this.harvestYearStateService.harvestYear = year;
  }
}
