import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { GrowthStageCorrection, GrowthStageDay, GrowthStageResult } from '@app/core/repositories/growthstage/growthstage.interface';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GrowthstageService } from './growthstage.service';

@Component({
  selector: 'app-growthstage',
  templateUrl: './growthstage.component.html',
  styleUrls: ['./growthstage.component.scss'],
  standalone: false,
})
export class GrowthstageComponent implements OnInit, OnDestroy {
  @Input() public field: Field = {} as Field;

  private subscriptions = new Subscription();
  public growthStageResult?: GrowthStageResult;
  public errorMessage = '';
  public isGrowthStageSaving = false;
  public isCorrectionDisabled = false;
  public hasObservations = false;

  constructor(private growthstageService: GrowthstageService) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.growthstageService.hasObservations().subscribe((hasObservations) => {
        this.hasObservations = hasObservations;
      })
    );
  }

  public onGenerateCorrectionClicked() {
    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.growthstageService.openCorrectionDialog(null, false).subscribe((updatedGrowthStageDay: GrowthStageDay) => {
      if (updatedGrowthStageDay) {
        const updateCorrection: GrowthStageCorrection = {
          dateTime: updatedGrowthStageDay.date!,
          growthStage: updatedGrowthStageDay.growthStage!,
        };

        const growthStageCorrections: GrowthStageCorrection[] = this.growthstageService.getGrowthStageResults?.corrections ?? [];

        const existingCorrection: GrowthStageCorrection = this.growthstageService.isExistingCorrection(updateCorrection);
        if (existingCorrection) {
          existingCorrection.growthStage = updateCorrection.growthStage;
        } else {
          growthStageCorrections.push(updateCorrection);
        }

        this.isGrowthStageSaving = true;

        this.growthstageService
          .setGrowthStageCorrection(this.field, growthStageCorrections)
          .pipe(finalize(() => (this.isGrowthStageSaving = false)))
          .subscribe({
            next: (resultsData) => {
              // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
              this.growthStageResult = resultsData;
            },
            error: (error: Error) => (this.errorMessage = error.message),
          });
      }
    });
  }

  public onInfoClick(field: Field) {
    this.growthstageService.showInfoDialog(field);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
