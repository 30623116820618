import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DirtyCheckDialogAction } from './dirty-check-actions.class';

@Component({
  selector: 'app-dirty-check-dialog',
  templateUrl: './dirty-check-dialog.component.html',
  styleUrls: ['./dirty-check-dialog.component.scss'],
  standalone: false,
})
export class DirtyCheckDialogComponent {
  constructor(public dialogRef: MatDialogRef<DirtyCheckDialogComponent, DirtyCheckDialogAction>) {}

  public close(val?: DirtyCheckDialogAction) {
    this.dialogRef.close(val);
  }

  public onCancelClicked() {
    this.close(new DirtyCheckDialogAction(false));
  }

  public onConfirmClicked() {
    this.close(new DirtyCheckDialogAction(true));
  }
}
