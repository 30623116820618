import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from '@app/core/notification/notification.service';
import { WindowRefService } from '@app/core/window/window-ref.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DebugInfoService } from './debug-info.service';
import { DebugInformation } from './models/debug-info.interface';

@Component({
  selector: 'app-debug-info',
  templateUrl: './debug-info.component.html',
  styleUrls: ['./debug-info.component.scss'],
  standalone: false,
})
export class DebugInfoComponent implements OnInit {
  // @ts-ignore - TS2564 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  @ViewChild('table', { static: false }) public table: ElementRef;

  // @ts-ignore - TS2564 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  @ViewChild('selectArea', { static: false }) public selectArea: ElementRef;
  public info$!: Observable<DebugInformation>;

  constructor(
    private translate: TranslateService,
    private dialogRef: MatDialogRef<DebugInfoComponent>,
    private windowRef: WindowRefService,
    private service: DebugInfoService,
    private notification: NotificationService,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnInit() {
    this.info$ = this.service.info$.changes$;
  }

  public async copyInfo() {
    const value = this.tableContent('\n');
    this.selectArea.nativeElement.value = value;
    this.selectArea.nativeElement.select();
    document.execCommand('copy');
    this.notification.showInfo(this.translate.instant('header.debugInfo.copyMessage'));
  }

  public mailInfo() {
    const subject = this.translate.instant('header.debugInfo.emailSubject');
    this.windowRef.nativeWindow.location.href = `mailto:kundecenter@seges.dk?subject=
    ${subject}&body=${this.sanitizer.sanitize(1, this.tableContent('%0D%0A'))}`;
  }

  public close() {
    this.dialogRef.close();
  }

  private tableContent(lineBreak: string): string {
    const rows = this.table.nativeElement.rows as HTMLCollection;
    let content = '';
    if (rows && rows.length) {
      for (let i = 0; i < rows.length; i++) {
        // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        content += `${rows.item(i).firstChild.textContent}: ${rows.item(i).children[1].textContent}${lineBreak}`;
      }
    }
    return content;
  }
}
