import { Component } from '@angular/core';
import { GlobalStateService } from '@app/state/services/global/global-state.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
  standalone: false,
})
export class LoadingScreenComponent {
  public isLoading$ = this.globalStateService.combinedIsLoading$;

  constructor(private globalStateService: GlobalStateService) {}
}
