import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-footer',
  templateUrl: './dialog-footer.component.html',
  styleUrls: ['./dialog-footer.component.scss'],
  standalone: false,
})
export class DialogFooterComponent {
  @Input() public confirmButtonText = 'KOPIER MARKER';

  // @ts-ignore - TS7008 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  @Input() public cancelButtonText;
  @Output() public cancel = new EventEmitter<void>();
  @Output() public confirm = new EventEmitter<void>();

  public onCancel() {
    this.cancel.emit();
  }

  public onConfirm() {
    this.confirm.emit();
  }
}
