import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-management-action',
  templateUrl: './management-action.component.html',
  styleUrls: ['./management-action.component.scss'],
  standalone: false,
})
export class ManagementActionComponent {
  @Input() icon: string = '';
  @Input() tooltip: string = '';
  @Input() minimized: boolean = false;
  @Input() noReminders: boolean = true; // For notification icon logic
  @Input() linksToOffsite: boolean = false;
  @Output() actionClick: EventEmitter<void> = new EventEmitter<void>();

  handleClick() {
    this.actionClick.emit();
  }
}
