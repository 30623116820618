import { Component, ElementRef, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OlMapComponent } from '@app/map/ol-map/ol-map.component';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { Observable, filter, map } from 'rxjs';
import { OpenLayersMapComponent } from '../openlayers-map/openlayers-map.component';

const BENCHMARK_ROUTE = 'yield-benchmark';

@Component({
  selector: 'app-map-button-controls',
  templateUrl: './map-button-controls.component.html',
  styleUrls: ['./map-button-controls.component.scss'],
  standalone: false,
})
export class MapButtonControlsComponent {
  @Input() map!: OpenLayersMapComponent | OlMapComponent;
  @Input() hostElement!: ElementRef;
  public mapControlsHidden$: Observable<boolean> = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.url.includes(BENCHMARK_ROUTE))
  );

  public isNewMapComponent(): boolean {
    return this.map instanceof OlMapComponent;
  }

  /**
   * Handles click events on the zoom in button.
   */
  public onZoomInClick() {
    this.map.zoomIncrementally(0.5);
  }
  /**
   * Handles click events on the zoom out button.
   */
  public onZoomOutClick() {
    this.map.zoomIncrementally(-0.5);
  }

  onFocusFieldsClick() {
    this.mapService.centerMapOnFields();
  }

  constructor(
    private router: Router,
    private mapService: OlMapService
  ) {}
}
