import { Pipe, PipeTransform } from '@angular/core';
import { NToolsTaskStatus } from '@app/core/enums/n-tools-task-Status.enum';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'taskStatus',
  standalone: false,
})
export class TaskStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(status: NToolsTaskStatus): string | undefined {
    switch (status) {
      case NToolsTaskStatus.done:
        return this.translateService.instant('nTool.table.status.done');
      case NToolsTaskStatus.planned:
        return this.translateService.instant('nTool.table.status.planned');
      case NToolsTaskStatus.inProgress:
        return this.translateService.instant('nTool.table.status.inProgress');
    }
    return undefined;
  }
}
