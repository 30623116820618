import { Injectable } from '@angular/core';
import { FieldEntry } from '@app/farm-tasks-overview/class/field-entry';
import { ProductSubType } from '@app/farm-tasks-overview/components/product-filter/product-filter.service';
import { filterNullOrEmpty } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { createStore, withProps } from '@ngneat/elf';
import { selectAllEntities, withEntities } from '@ngneat/elf-entities';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { entitiesStateHistory } from '@ngneat/elf-state-history';
import { uniqBy } from 'lodash-es';
import { filter, first, map, withLatestFrom } from 'rxjs';
import { MACHINEEVENTOPERATIONTYPES } from './field-entry.query';

interface LoadingProps {
  preLoading: boolean;
}

interface SortProps {
  sortType: SortType;
  sortDirection: SortDirection;
}

export interface CropFilterType {
  cropName: string;
  term: number;
}
interface CropFilterProps {
  crops: CropFilterType[];
}

interface ProductFilterProps {
  products: ProductSubType[];
}

interface MachineEventFilterProps {
  machineEvents: ProductSubType[];
}

interface StoreReadyProps {
  isReady: boolean;
}

@Injectable({ providedIn: 'root' })
export class FieldEntryStore {
  constructor(private _farmState: FarmStateService) {}

  public readonly store = createStore(
    { name: 'field-entries' },
    withEntities<FieldEntry>({ initialValue: [] }),
    withProps<LoadingProps>({ preLoading: false }),
    withProps<SortProps>({ sortType: 'taskDate', sortDirection: 'asc' }),
    withProps<ProductFilterProps>({ products: [] }),
    withProps<MachineEventFilterProps>({ machineEvents: [] }),
    withProps<CropFilterProps>({ crops: [] }),
    withProps<StoreReadyProps>({ isReady: false })
  );

  public readonly persist = persistState(this.store, {
    key: 'field-entries-state', // Initial key; will be ignored since we're customizing it with the ID
    storage: localStorageStrategy,
    source: () =>
      this.store.pipe(
        filter((state) => {
          const currentState = state;
          return !!currentState && (currentState.products?.length > 0 || currentState.crops?.length > 0);
        }),
        withLatestFrom(
          this._farmState.selectedFarmIds$.pipe(
            filter((selectedFarmIds) => selectedFarmIds.length > 0),
            map((selectedFarmIds) => selectedFarmIds.join('-'))
          )
        ),
        map(([state, id]) => ({
          key: `field-entries-state-${id}`,
          state: {
            products: state.products,
            machineEvents: state.machineEvents,
            crops: state.crops,
            sortType: state.sortType,
            sortDirection: state.sortDirection,
          },
        })),
        map(({ key, state }) => {
          localStorage.setItem(key, JSON.stringify(state)); // Persist the state manually
          return state;
        })
      ),
  });

  // Track store history for undo/redo
  public readonly history = entitiesStateHistory(this.store);

  // Load persisted state
  public loadState() {
    this._farmState.selectedFarmIds$
      .pipe(
        filterNullOrEmpty(),
        first(),
        map((selectedFarmIds) => selectedFarmIds.join('-'))
      )
      .subscribe((id) => {
        const savedState = localStorage.getItem(`field-entries-state-${id}`);
        if (savedState) {
          const parsedState = JSON.parse(savedState);
          this.store.update((state) => ({
            ...state,
            ...parsedState,
            isReady: true,
          }));
        } else {
          this.enableAll();
        }
      });
  }

  public enableAll() {
    this.store
      .pipe(
        selectAllEntities(),
        filter((entries) => entries.length > 0),
        first()
      )
      .subscribe((fieldEntries) => {
        this.store.update((state) => {
          const fieldEntries = Object.values(state.entities);

          // Get and transform all products
          const products = uniqBy(
            fieldEntries.flatMap((fe) => fe.getTasks().flatMap((t) => t.getProducts())),
            'id'
          );
          const productSubTypes = products.map(this.createSubType);
          // Get and transform machine events
          const machineEvents = uniqBy(products.filter((p) => MACHINEEVENTOPERATIONTYPES.includes(+p.type)).map(this.createSubType), 'id');

          // Get and transform crops
          const crops = uniqBy(
            fieldEntries.flatMap((f) =>
              f.getTasks().map((t) => ({
                cropName: f.cropName ?? '',
                term: f.successionNo ?? 0,
              }))
            ),
            'cropName'
          );

          const parsedState = { products: productSubTypes, machineEvents, crops, sortType: 'taskDate', sortDirection: 'asc' } as any;

          return {
            ...state,
            ...parsedState,
            isReady: true,
          };
        });
      });
  }

  private createSubType(product: { id: number; label?: string }): ProductSubType {
    return {
      id: product.id,
      productId: product.id,
      label: product.label ?? '',
      enabled: true,
    };
  }
}

export type SortType = 'fieldLabel' | 'taskDate' | 'crop' | 'fieldArea';
export type SortDirection = 'asc' | 'desc';
export interface SortState {
  sortType: SortType;
  sortDirection: SortDirection;
}
