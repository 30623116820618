import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { flyInOut } from '@app/core/animations/fly-in-out.fn';
import { FieldsRepo } from '@app/core/repositories/fields/fields-repo.service';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { TranslateService } from '@ngx-translate/core';
import { CellClickEvent, CellCloseEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { first, skip } from 'rxjs/operators';
import { FieldCopyStateService } from '../field-copy-state.service';
import { FieldCopyItem } from '../fieldCopyItem';
import { ItemIncludeCrop } from '../itemIncludeCrop';
import { filterNullish } from '@app/shared/operators';

@Component({
  selector: 'app-field-modify',
  templateUrl: './field-modify.component.html',
  styleUrls: ['./field-modify.component.scss'],
  animations: [flyInOut],
  standalone: false,
})
export class FieldModifyComponent implements OnInit, OnDestroy {
  get selectedRow() {
    return this.tarSelectedRow;
  }

  set selectedRow(id: number[]) {
    this.tarSelectedRow = id;
  }

  public get isInvalidFieldNumber() {
    return this.formGroup.controls['fieldNumber'].getError('pattern');
  }

  public get isFieldNumberInUse() {
    return this.formGroup.controls['fieldNumber'].getError('fieldNumberInUse');
  }

  public get isValidating() {
    return this.formGroup.controls['fieldNumber'].getError('validating');
  }
  public harvestYear$ = this.harvestYearStateService.harvestYear$;
  public selectedFields$ = this.fieldCopyStateService.selectedFields$;
  public gridData!: FieldCopyItem[];
  private fieldNumberValidationPattern = Validators.pattern(/^([1-9]|[1-9][0-9]{1,2})[-+]([0-9]{1,2})$/);
  public crops$ = this.fieldCopyStateService.crops$;
  private subscriptions: Subscription = new Subscription();
  private editSubScription: Subscription = new Subscription();
  @ViewChild('dropdownlist', { static: false }) public dropdownlist: any;

  public gridView!: GridDataResult;
  public formGroup!: UntypedFormGroup;
  public tarSelectedRow: number[] = [];

  constructor(
    public harvestYearStateService: HarvestYearStateService,
    public fieldCopyStateService: FieldCopyStateService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private fieldsRepo: FieldsRepo
  ) {
    this.createFormGroup = this.createFormGroup.bind(this);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      fieldNumber: new UntypedFormControl('', [Validators.required, this.fieldNumberValidationPattern]),
      fieldName: new UntypedFormControl(''),
      fieldCrop: new UntypedFormControl(''),
      fieldCropSort: new UntypedFormControl(''),
      data: new UntypedFormControl(''),
    });

    this.subscriptions.add(this.selectedFields$.pipe(skip(1), first()).subscribe((result) => this.setData(result)));
    this.subscriptions.add(this.selectedFields$.pipe(skip(2)).subscribe((result) => this.updateData(result)));
    this.subscriptions.add(
      this.fieldCopyStateService.includesCrop$.pipe(skip(1), filterNullish()).subscribe((result) => this.includeCrop(result))
    );
    this.createFormGroup = this.createFormGroup.bind(this);
  }
  public includeCrop(itemIncludeCropList: ItemIncludeCrop[]): void {
    itemIncludeCropList.forEach((element) => {
      const targetField = this.gridData.find((field) => field.id === element.id);
      if (targetField) {
        targetField.includeCrop = element.includeCrop;
      }
    });
  }
  public updateData(FieldCopyItems: FieldCopyItem[]): void {
    const newIncludedIds: number[] = FieldCopyItems.map((items) => items.id);
    const previousIncludedIds: number[] = this.gridData.map((items) => items.id);
    const stillExsistingFieldItems = this.gridData.filter((currentItem) => newIncludedIds.some((id) => id === currentItem.id));
    const newFieldItem = FieldCopyItems.find((currentItem) => !previousIncludedIds.includes(currentItem.id));
    let newFieldItems: FieldCopyItem[] = [];
    if (newFieldItem) {
      newFieldItems = [{ ...newFieldItem }];
    }
    this.gridData = [...stillExsistingFieldItems, ...newFieldItems].sort((x, y) =>
      CompareHelper.compareFieldNumbers(x.fieldNumber, y.fieldNumber)
    );

    this.gridData.forEach((x: FieldCopyItem) => {
      if (x.includeCrop == true && x.fieldData.crops[0]) {
        x.fieldCrop = x.fieldData.crops[0];
      } else {
        x.fieldCrop = undefined;
      }
    });

    this.gridView = {
      data: this.gridData,
      total: this.gridData.length,
    };
  }

  public setData(gridData: any[]) {
    this.gridData = gridData.map((a) => ({ ...a }));
    this.gridData.forEach((x: FieldCopyItem) => {
      if (x.includeCrop == false) {
        x.fieldCrop = undefined;
      }
    });

    this.gridView = {
      data: this.gridData,
      total: gridData.length,
    };
  }

  public createFormGroup(args: any): UntypedFormGroup {
    const data = args.dataItem;
    this.formGroup = this.formBuilder.group({
      id: [data.id],
      fieldNumber: [data.fieldNumber, this.fieldNumberValidationPattern],
      fieldName: [data.fieldName],
      fieldCrop: [data.fieldCrop],
      fieldCropSort: [data.fieldCropSort],
      data: [data.fieldData],
    });

    const farmId = data.fieldData.farmId;
    const harvestyear = data.fieldData.harvestYear + 1;
    const fieldId = data.id;
    this.editSubScription.add(
      this.formGroup.controls['fieldNumber'].valueChanges.subscribe((inputNumber) => {
        if (this.formGroup.controls['fieldNumber'].valid) {
          this.formGroup.controls['fieldNumber'].setErrors({ validating: true });
          this.fieldsRepo
            .validateFieldNumber(farmId, harvestyear, inputNumber, fieldId)
            .pipe(first())
            .subscribe((validNumber: boolean) => {
              if (validNumber === true && this.noMoreThanOneOccourance(inputNumber, data.fieldNumber)) {
                this.formGroup.controls['fieldNumber'].setErrors(null);
              } else {
                this.formGroup.controls['fieldNumber'].setErrors({ fieldNumberInUse: true });
              }
            });
        }
      })
    );

    return this.formGroup;
  }

  public editHandler({ sender, rowIndex, dataItem, isEdited, columnIndex }: CellClickEvent): void {
    if (!isEdited) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup({ dataItem }));
    } else {
      sender.closeCell();
      sender.cancelCell();
    }
  }

  public cellCloseHandler({ sender, dataItem }: CellCloseEvent): void {
    if (!this.formGroup.valid) {
      sender.cancelCell();
    }
    this.editSubScription.remove(this.editSubScription);
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.isReadOnly) {
      return { disabledRow: true };
    } else {
      return {};
    }
  };

  public trackByFn(index: number, item: any) {
    return item.number;
  }

  public getDefaultItem(dataItem: any) {
    if (!dataItem.includeCrop) {
      return undefined;
    }

    const defaultItem = { ...dataItem.fieldCrop };

    if (!defaultItem.name) {
      defaultItem.name = dataItem.fieldCrop?.cropName ?? dataItem.fieldCrop?.name;
    }
    return defaultItem;
  }

  public getCropName(dataItem: any) {
    if (dataItem?.fieldCrop?.name) {
      return dataItem.fieldCrop?.name;
    }
    if (dataItem?.includeCrop) {
      return dataItem.fieldCrop?.cropName ? dataItem.fieldCrop?.cropName : dataItem.fieldCrop?.name;
    }

    return this.translateService.instant('main.fieldAdministration.createField.dropDownAdditionalContent.noCrop');
  }

  public onFocus(dropdown: any) {
    dropdown.toggle(true);
  }

  private noMoreThanOneOccourance(inputNumber: string, self: string) {
    if (inputNumber === self) {
      return true;
    }
    const occourance = this.gridData.some((field) => field.fieldNumber === inputNumber);
    return !occourance;
  }
}
