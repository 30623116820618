import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { FieldEntryQuery } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.query';
import { FieldEntryService } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.service';
import { SortDirection, SortType } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.store';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { BehaviorSubject, distinctUntilChanged, filter, first, map, Subject, withLatestFrom } from 'rxjs';

interface SortState {
  sortType: SortType;
  sortDirection: SortDirection;
}

interface SortOption {
  value: SortType;
  labelKey: string;
}

@Component({
  selector: 'app-overview-sort-select',
  templateUrl: './overview-sort-select.component.html',
  styleUrls: ['./overview-sort-select.component.scss'],
  standalone: false,
})
export class OverviewSortSelectComponent implements OnDestroy {
  @ViewChild('selectMenu') selectMenu!: MatSelect;

  protected readonly sortOptions: SortOption[] = [
    {
      value: 'fieldLabel',
      labelKey: 'farm-tasks-overview.sort.field-number',
    },
    {
      value: 'taskDate',
      labelKey: 'farm-tasks-overview.sort.task-date',
    },
    {
      value: 'crop',
      labelKey: 'farm-tasks-overview.sort.crop',
    },
    {
      value: 'fieldArea',
      labelKey: 'farm-tasks-overview.sort.field-area',
    },
  ];

  // Create a local BehaviorSubject to handle immediate UI updates
  private readonly _localSortState = new BehaviorSubject<SortState>({ sortType: 'fieldLabel', sortDirection: 'asc' });
  protected readonly localSortState$ = this._localSortState.asObservable();

  protected readonly currentValue$ = this.localSortState$.pipe(map((state) => state.sortType));

  private readonly _sortChange$ = new Subject<SortType>();
  private _isMenuOpen = new BehaviorSubject<boolean>(false);

  private _subs = new SubscriptionArray();

  constructor(
    private _service: FieldEntryService,
    private _query: FieldEntryQuery
  ) {
    this.initializeStateSync();
    this.initializeSortSubscription();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  protected onSortChange(newSortType: SortType): void {
    // Immediately update local state
    const currentState = this._localSortState.getValue();
    const newDirection: SortDirection =
      newSortType === currentState.sortType ? (currentState.sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';

    this._localSortState.next({
      sortType: newSortType,
      sortDirection: newDirection,
    });

    // Queue the actual state update
    this._sortChange$.next(newSortType);
    this.selectMenu.open();
  }

  private initializeStateSync(): void {
    // Sync the initial state
    this._query.sortState$.pipe(first()).subscribe((state) => {
      this._localSortState.next(state);
    });
  }

  private initializeSortSubscription(): void {
    this._isMenuOpen
      .pipe(
        withLatestFrom(this._sortChange$),
        distinctUntilChanged((a, b) => a[1] === b[1]),
        filter(([isMenuOpen]) => !isMenuOpen),
        map(([isMenuOpen, updates]) => updates)
      )
      .subscribe((newSortType) => {
        const localState = this._localSortState.getValue();
        this._service.setSort(localState.sortType, localState.sortDirection);
      });
  }

  protected onMenuStateChange(isOpen: boolean) {
    this._isMenuOpen.next(isOpen);
  }
}
