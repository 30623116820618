import { Component, Input, OnChanges } from '@angular/core';
import { Unit } from '@app/core/interfaces/unit.type';
import { Feature } from 'ol';

@Component({
  selector: 'app-expected-yield-tooltip',
  templateUrl: './expected-yield-tooltip.component.html',
  styleUrls: ['./expected-yield-tooltip.component.scss'],
  standalone: false,
})
export class ExpectedYieldTooltipComponent implements OnChanges {
  @Input() feature!: Feature;

  public quantity!: number;

  public unit: Unit = 'ton';

  ngOnChanges() {
    this.quantity = this.feature.get('quantity') / 1000;
  }
}
