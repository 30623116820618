import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { flyInOut } from '@app/core/animations/fly-in-out.fn';
import { Field } from '@app/core/interfaces/field.interface';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { LoadingState } from '@app/helpers/loading-state';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldSelectorSideDrawerData } from '../field-selector/field-selector-side-drawer-data.interface';
import { FieldSelectorComponent } from '../field-selector/field-selector.component';
import { FieldSelectorService } from '../field-selector/field-selector.service';
import { TaskCropVM } from '../field-selector/task-crop-vm';

@Component({
  selector: 'app-task-fields',
  templateUrl: './task-fields.component.html',
  styleUrls: ['./task-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut],
  standalone: false,
})
export class TaskFieldsComponent implements OnInit {
  @Input() public task: SimpleTask | undefined;
  @Input() public field: Field | undefined;
  @Input() public disabled: boolean | null = false;
  @Output() public onSave = new EventEmitter<{ cropsWithSimilarTask: TaskCropVM[]; newItems: TaskCropVM[] }>();

  public readonly loadingState = new LoadingState();
  public loading$ = this.loadingState.changes$;

  public selectedCrop!: TaskCropVM;
  public cropsWithSimilarTask: TaskCropVM[] = [];
  public newCropsWithSimilarTask: TaskCropVM[] = [];

  constructor(
    private sideDrawerService: SideDrawerOverlayService,
    public farmStateService: FarmStateService,
    private fieldSelectorService: FieldSelectorService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    const selectedCropWithOutTask = this.field?.crops.find((crop) => crop.id === this.task?.cropId);

    if (selectedCropWithOutTask) {
      selectedCropWithOutTask.tasks = [this.task] as SimpleTask[];
      this.selectedCrop = new TaskCropVM(this.field!, selectedCropWithOutTask, 'none');
    }

    this.getAllFields();
  }

  public openSideDrawer() {
    this.sideDrawerService
      .openCustomSideDrawer<FieldSelectorComponent, FieldSelectorSideDrawerData, Field[]>(FieldSelectorComponent, {
        width: '500px',
        maxWidth: '100vw',
        panelClass: 'edit-task-side-drawer',
        data: {
          task: this.task as SimpleTask,
          crop: this.selectedCrop,
          selectedCrops: this.newCropsWithSimilarTask,
        },
      })
      .onClose.subscribe((deletedAndAdded: { newAddedItems: TaskCropVM[]; newlyDeletedItems: TaskCropVM[] }) => {
        if (!deletedAndAdded) {
          return;
        }
        deletedAndAdded.newlyDeletedItems.forEach((newlyDeletedItem) => {
          const similarTaskIndex = this.cropsWithSimilarTask.findIndex((taskCropVm) => taskCropVm.crop.id === newlyDeletedItem.crop.id);
          if (similarTaskIndex > -1) {
            this.cropsWithSimilarTask.splice(similarTaskIndex, 1);
          }
        });

        this.newCropsWithSimilarTask = deletedAndAdded.newAddedItems;
        this.onSave.emit({ cropsWithSimilarTask: this.cropsWithSimilarTask, newItems: this.newCropsWithSimilarTask });
      });
  }

  private getAllFields() {
    this.loadingState.start('fieldSelector.loading');
    this.farmStateService.selectedFarmIds$.pipe(map((farmId) => this.getFieldsWithSimilarTask(farmId))).subscribe((withSimilarTask) => {
      withSimilarTask.subscribe((withSimilarTask) => {
        this.cropsWithSimilarTask = this.mapCropsWithSimilarTask(withSimilarTask);
        this.onSave.emit({ cropsWithSimilarTask: this.cropsWithSimilarTask, newItems: [] });
        this.changeDetectorRef.detectChanges();
        this.loadingState.stop();
      });
    });
  }
  private getFieldsWithSimilarTask(farmIds: number[]): Observable<Field[]> {
    return this.task?.id ? this.fieldSelectorService.getFieldsWithSimilarTask(farmIds, this.task.harvestYear, this.task.id) : of([]);
  }

  public mapCropsWithSimilarTask(fieldsWithSimilarTask: Field[]) {
    return fieldsWithSimilarTask.reduce(
      (allCrops, field) => [...allCrops, ...field.crops.map((crop) => new TaskCropVM(field, crop, 'similar-task'))],
      [] as TaskCropVM[]
    );
  }
}
