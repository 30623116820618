import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { HotspotType } from '@app/core/interfaces/hotspot-type-interface';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { FeatureToggleService } from '@app/libraries/ng-feature-toggles/services/feature-toggle.service';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { MapLayerControlService } from '@app/shared/map-layer-controls/map-layer-control.service';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { SideDrawerComponent } from '@app/shared/side-drawer/side-drawer.component';
import cloneDeep from 'lodash-es/cloneDeep';
import Feature from 'ol/Feature';
import { Observable, Subscription } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { HotspotsComponent } from '../../hotspots.component';
import { HotspotsService } from '../../hotspots.service';
import { ShownComponentEnum } from '../hotspots-sidedrawer-showncomponent.enum';
import { HotspotTypes } from '../shape-file-import/interfaces/hotspot-types.enum';
import { CreateHotspotService } from './create-hotspot.service';

@Component({
  selector: 'app-create-hotspot',
  templateUrl: './create-hotspot.component.html',
  styleUrls: ['./create-hotspot.component.scss'],
  standalone: false,
})
export class CreateHotspotComponent implements OnDestroy {
  [index: string]: any;
  public toggledHotSpotIds = [9, 10, 11];
  public enabledToggledHotspots$ = this._featureToggleService.get$('pla_cropmanager_new_hotspots');
  public hotspotTypes$ = this.createHotspotsService.hotspotTypes$.pipe(
    withLatestFrom(this.enabledToggledHotspots$),
    map(([hotspots, featureToggle]) => {
      return hotspots.filter((hotspot) => (this.toggledHotSpotIds.includes(hotspot.id) ? featureToggle : true));
    })
  );

  public isDrawing$?: Observable<boolean>;
  private drawSub?: Subscription;

  public loadingMessage$ = this.hotspotsMapSideDrawerService.loadingMessage$;
  public isLoading$ = this.hotspotsMapSideDrawerService.isHotspotsLoading$;

  @ViewChild('createHotspotDrawer', { static: true })
  public hotspotDetailsComponent!: SideDrawerComponent;

  @Output() private newHotspotDrawn = new EventEmitter();
  @Output() private importShapeFileClicked = new EventEmitter();
  @Output() private createHotspotGroupClicked = new EventEmitter();

  public selectedHotspotType?: HotspotType | null;

  private _selectedGeometryType?: GeometryType | null;

  public get selectedGeometryType(): GeometryType | null | undefined {
    return this._selectedGeometryType;
  }
  public set selectedGeometryType(geometryType: GeometryType) {
    this._selectedGeometryType = geometryType;
    this.lastSelectedGeometryType = cloneDeep(geometryType);
  }

  public lastSelectedGeometryType!: GeometryType;

  public get GeometryType() {
    return GeometryType;
  }

  public get geometryTypes() {
    return this.createHotspotsService.geometryTypes;
  }

  constructor(
    private createHotspotsService: CreateHotspotService,
    private hotspotsMapSideDrawerService: HotspotsService,
    private sideDrawerRef: SideDrawerRef<HotspotsComponent, void, void>,
    private mapService: OlMapService,
    private mapLayerControlService: MapLayerControlService,
    private _featureToggleService: FeatureToggleService
  ) {}

  public ngOnDestroy(): void {
    this.removeDrawInteraction();
  }

  public onCloseClick() {
    this.sideDrawerRef.hide();
    this.mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
  }

  public onOpenSideDrawerClicked() {
    this.sideDrawerRef.show();
    this.mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsOpenedPx);
  }

  public hideDrawer() {
    this.sideDrawerRef.hide();
  }

  public openSideDrawerOnNewHotspot() {
    this.sideDrawerRef.show();
  }

  public resetSelectedGeometryType() {
    this._selectedGeometryType = null;
  }

  public getGeometryTypeTooltip(geometryType: GeometryType) {
    const geometryTypeEnabled = this.isGeometryTypeEnabled(geometryType);
    if (!geometryTypeEnabled) {
      return 'main.hotspots.drawToolDisabled';
    }

    switch (geometryType) {
      case GeometryType.POINT:
        return 'main.hotspots.drawPoint';
      case GeometryType.POLYGON:
        return 'main.hotspots.drawPolygon';
      case GeometryType.LINE:
        return 'main.hotspots.drawLines';
      default:
        return;
    }
  }

  public getGeometryTypeIcon(geometryType: GeometryType) {
    switch (geometryType) {
      case GeometryType.POINT:
        return 'Punkt';
      case GeometryType.POLYGON:
        return 'Polygen';
      case GeometryType.LINE:
        return 'Linje';
      default:
        return;
    }
  }

  public isGeometryTypeEnabled(geometryType: GeometryType) {
    const isGeometryTypeEnabled = this.selectedHotspotType?.geometryTypes?.includes(geometryType);
    return isGeometryTypeEnabled;
  }

  public onImportShapeFile() {
    this.selectedHotspotType = null;
    this.hotspotsMapSideDrawerService.setHotspotDrawing(false);
    this.removeDrawInteraction();

    this.importShapeFileClicked.emit();
  }

  public createHotspotGroup() {
    this.removeDrawInteraction();
    this.createHotspotGroupClicked.emit();
  }

  public setHotspotTypeForCreate(hotspotType: HotspotType) {
    if (this.selectedHotspotType && hotspotType.id === this.selectedHotspotType.id) {
      this.selectedHotspotType = null;
      this.hotspotsMapSideDrawerService.setHotspotDrawing(false);
      this.removeDrawInteraction();
      return;
    }

    this.setGeometryTypeForDraw(hotspotType.geometryType, hotspotType.id);
    this.selectedHotspotType = hotspotType;
    this.hotspotsMapSideDrawerService.setHotspotDrawing(true);
  }

  private removeDrawInteraction() {
    this.mapService.removeDrawingInteraction();
    this.mapService.enableSelectInteraction();
  }

  public enableDrawCurrentHotspot() {
    if (this.selectedHotspotType && this.lastSelectedGeometryType) {
      this.setGeometryTypeForDraw(this.lastSelectedGeometryType, this.selectedHotspotType.id);
    }
  }

  public setGeometryTypeForDraw(drawGeometry: GeometryType, hotspotTypeId: HotspotTypes) {
    const isSameHotspot = this.selectedHotspotType && hotspotTypeId === this.selectedHotspotType.id;
    const isSameGeometryType = this.selectedGeometryType === drawGeometry;
    if (isSameHotspot && isSameGeometryType) {
      this.removeDrawInteraction();
      this.resetSelectedGeometryType();
      return;
    }

    this.addHotspotDrawingInteraction(hotspotTypeId, drawGeometry);
    this.selectedGeometryType = drawGeometry;
  }

  private addHotspotDrawingInteraction(typeId: number, geometryType?: GeometryType) {
    if (this.drawSub) {
      this.drawSub.unsubscribe();
    }

    this.drawSub = this.hotspotsMapSideDrawerService
      .addDrawingInteraction(this.createHotspotsService.hotspotTypes, typeId, geometryType)
      ?.pipe(take(1))
      .subscribe((value) => {
        const { event, hotspotTypeId } = value;

        if (event.feature) return this.onNewHotspotDrawn(event.feature, hotspotTypeId);
      });
  }

  private onNewHotspotDrawn(feature: Feature, hotspotTypeId: HotspotTypes) {
    const { drawnFeature, markerFeature } = this.hotspotsMapSideDrawerService.addDrawnHotspotToMap(feature, hotspotTypeId);
    this.hotspotsMapSideDrawerService.updateHotspotMarkerFeatureGeometry(drawnFeature);
    this.removeDrawInteraction();
    this.newHotspotDrawn.emit({ drawnFeature, markerFeature, hotspotTypeId });
    this.hotspotsMapSideDrawerService.setShownComponentState(ShownComponentEnum.hotspotDetailsComponent);
  }
}
