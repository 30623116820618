import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastSegment',
  standalone: false,
})
export class LastSegmentPipe implements PipeTransform {
  transform(value: string): string {
    const segments = value.split('.');
    return segments.length > 0 ? segments[segments.length - 1] : '';
  }
}
