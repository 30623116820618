import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TaskCropVM } from '../task-crop-vm';

@Component({
  selector: 'app-field-collection-item',
  templateUrl: './field-collection-item.component.html',
  styleUrls: ['./field-collection-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FieldCollectionItemComponent {
  @Input() public crop!: TaskCropVM;
  @Input() public canRemove = false;
  @Input() public canAdd = false;
  @Output() public onRemove = new EventEmitter<{ crop: TaskCropVM }>();
  @Output() public onAdd = new EventEmitter<{ crop: TaskCropVM }>();

  public remove() {
    this.onRemove.emit({ crop: this.crop });
  }
  public add() {
    this.onAdd.emit({ crop: this.crop });
  }
}
