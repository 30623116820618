import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from './confirm-dialog-data';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  standalone: false,
})
export class ConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) protected data: ConfirmDialogData) {}
}
