import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import Feature from 'ol/Feature';
import { SelectEvent } from 'ol/interaction/Select';
import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { LayerId } from '../services/layer/layer.store';

@Component({
  selector: 'app-ol-map-feature-select',
  template: '',
  styles: [''],
  standalone: false,
})
export class OlMapFeatureSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public layers: LayerId[] = [];
  @Input() public toggle = false;
  @Input() public disabled = false;
  @Input() public selectedFeatures: Feature[] = [];
  @Output() public field = new EventEmitter<Field>();
  @Output() public event = new EventEmitter<SelectEvent>();

  private featureSelectSubscription = new Subscription();

  constructor(private mapService: OlMapService) {}

  public ngOnInit() {
    this._enableOnInitHack();

    this.featureSelectSubscription = this.mapService.mapReady$
      .pipe(
        switchMap(() => this._getFieldSelect$()),
        tap(() => {
          this.disabled ? this.mapService.disableSelectInteraction() : this.mapService.enableSelectInteraction();
        })
      )
      .subscribe((event: SelectEvent) => {
        const field = event.selected[0] ? event.selected[0].get('field') : null;
        this.event.emit(event);
        this.field.emit(field);
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.mapService.mapComponent) {
      if (changes['disabled']) {
        changes['disabled'].currentValue ? this.mapService.disableSelectInteraction() : this.mapService.enableSelectInteraction();
      }
    }
  }

  public ngOnDestroy() {
    this.featureSelectSubscription.unsubscribe();
    this.mapService.disableSelectInteraction();
  }

  private _getFieldSelect$() {
    return this.mapService.addSelectInteraction(this.toggle, this.layers, this.selectedFeatures).pipe(filter((event) => !!event));
  }

  /**
   * In some cases, when this component is recreated right after being destroyed,
   * disableSelectInteraction from OnDestroy is called before enableSelectInteraction in components.
   * This setTimeout is a workaround to ensure that enableSelectInteraction is called after disableSelectInteraction.
   * ! Should figure out a better approach. (only an issue when navigating from hotspots -> vra)
   */
  private _enableOnInitHack() {
    setTimeout(() => {
      this.disabled ? this.mapService.disableSelectInteraction() : this.mapService.enableSelectInteraction();
    }, 500);
  }
}
