import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[attr.disabled]': 'disabled || null',
  },
  standalone: false,
})
export class FabComponent {
  @Input() public mini = false;
  @Input() public icon = '';
  @Input() public disabled: boolean | undefined | null = false;
  @Input() public size = 'md';
  @Input() public theme = 'primary';

  public get isLarge() {
    return this.size === 'lg';
  }

  public get isMedium() {
    return this.size === 'md';
  }

  public get isSmall() {
    return this.size === 'sm';
  }
}
