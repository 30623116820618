import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FeedBackStateService } from '@app/state/services/feed-back/feed-back-state.service';
import { Observable } from 'rxjs';
import { ShortCutKey } from './cm-shortcuts.const';

@Directive({
  selector: '[appShortcutKey]',
  standalone: false,
})
/*
This directive is not to be used for now.
When a new business strategy for this is available, it will be reactivated in some form
*/
export class ShortcutKeyDirective {
  @Output() public shortcutPressed = new EventEmitter<KeyboardEvent>();
  @Input() public appShortcutKey!: ShortCutKey;
  @Input() public condition = true;

  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public readonly isFeedbackOpen$: Observable<boolean> = this.feedbackStateService.isFeedBackOpen$;

  public constructor(private feedbackStateService: FeedBackStateService) {}

  @HostListener('document:keydown', ['$event'])
  public handleKeyboardEvent(e: KeyboardEvent) {
    // if (eventIsShortCut(e, this.appShortcutKey) && this.condition && this.feedBackIsClosed) {
    //   this.shortcutPressed.emit(e);
    // }
  }
}
