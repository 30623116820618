import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SideDrawerFooterComponent } from '@app/shared/side-drawer/side-drawer-footer/side-drawer-footer.component';

@Component({
  selector: 'app-side-drawer-footer-button',
  templateUrl: './side-drawer-footer-button.component.html',
  styleUrls: ['./side-drawer-footer-button.component.scss'],
  standalone: false,
})
export class SideDrawerFooterButtonComponent {
  @Output() public clicked = new EventEmitter<any>();
  @Input() public disabled?: boolean;
  @Input() public buttonType = 'Primary';

  constructor(private sideDrawerFooter: SideDrawerFooterComponent) {
    if (!this.sideDrawerFooter) {
      throw new Error('Side drawer footer button must be used inside a side drawer footer');
    }
  }

  public onClick(action: any) {
    this.clicked.emit(action);
  }
}
