import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GrowthStageDay } from '@app/core/repositories/growthstage/growthstage.interface';
import { IsNotIntegerValidator } from '@app/helpers/validators/forms/is-not-integer.validator';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-growthstage-correction-dialog',
  templateUrl: './growthstage-correction-dialog.component.html',
  styleUrls: ['./growthstage-correction-dialog.component.scss'],
  standalone: false,
})
export class GrowthstageCorrectionDialogComponent implements OnInit {
  public updatedGrowthStageDay?: GrowthStageDay;
  public dialogFormGroup?: UntypedFormGroup;

  public startDate: Date = new Date();
  public minDate?: Date | null;
  public maxDate?: Date | null;
  public focusDate?: Date | null;
  public useCalander?: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<GrowthstageCorrectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private dialogData: { growthStageDay: GrowthStageDay; minDate: DateTime | null; maxDate: DateTime | null; focusDate: DateTime | null }
  ) {}

  public ngOnInit(): void {
    this.minDate = this.dialogData.minDate?.toJSDate();

    this.maxDate = this.dialogData.maxDate?.toJSDate();

    this.focusDate = this.dialogData.focusDate?.toJSDate();

    if (this.dialogData.growthStageDay) {
      this.useCalander = false;
      this.startDate = DateTime.fromISO(this.dialogData.growthStageDay.date!).toJSDate();
    } else {
      this.useCalander = true;

      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      this.startDate = this.focusDate;
    }

    if (!this.dialogData.growthStageDay) {
      this.updatedGrowthStageDay = {
        dataSource: null,

        date: null,
        growthStage: null,
        temperature: null,
        temperatureSum: null,
      };
    } else {
      this.updatedGrowthStageDay = this.dialogData.growthStageDay;
    }

    this.initFormGroup();
  }

  public onDateChange(date: string) {
    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.updatedGrowthStageDay.date = date;
  }

  public onSaveClicked(): void {
    if (this.updatedGrowthStageDay) this.updatedGrowthStageDay.growthStage = this.dialogFormGroup?.controls['growthStage'].value;
    this.dialogRef.close(this.updatedGrowthStageDay);
  }

  public onCancelClicked(): void {
    this.dialogRef.close();
  }

  private initFormGroup(): void {
    this.dialogFormGroup = this.formBuilder.group({
      growthStage: [
        this.updatedGrowthStageDay?.growthStage,
        [IsNotIntegerValidator, Validators.required, Validators.min(0), Validators.max(75)],
      ],
    });
  }
}
