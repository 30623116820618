import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { OperationTypes } from '@app/core/enums/operation-types.enum';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { BehaviorSubject, debounceTime, distinctUntilChanged, first, map, merge, switchMap, tap } from 'rxjs';
import { ProductFilterService, ProductMenuDataItem, ProductSubType } from './product-filter.service';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss'],
  standalone: false,
})
export class ProductFilterComponent implements OnInit, OnDestroy {
  @ViewChildren(MatMenuTrigger) menuTriggers?: QueryList<MatMenuTrigger>;
  protected openSubMenuTrigger: MatMenuTrigger | null = null;

  protected menuData$ = this._productFilterService.operationTypesAndProducts$.pipe(
    map((operationTypesAndProducts) => this._productFilterService.mapToMenuData(operationTypesAndProducts))
  );

  public selectedProduct$ = this._productFilterService.filterProduct$.pipe(
    map((product) => {
      const groups = this.formGroup?.get('groups')?.value;
      if (product.length === 0 || !groups.length) {
        return 'farm-tasks-overview.filter-state.none';
      }
      if (product.length === 1) {
        return product[0].label;
      }
      // check if all products are enabled
      const productsInFilter = groups.reduce((total: number, group: any) => total + group.products.length, 0);
      const allEnabled = productsInFilter === product.length;

      if (!allEnabled) {
        return 'farm-tasks-overview.filter-state.some';
      }
      return 'farm-tasks-overview.filter-state.all';
    })
  );

  protected noproducts$ = new BehaviorSubject<boolean>(true);

  protected formGroup?: FormGroup;
  protected hoveredItem: number | null = null;

  private _subs = new SubscriptionArray();

  constructor(private _productFilterService: ProductFilterService) {}

  ngOnInit(): void {
    this._subs.add(
      this.menuData$
        .pipe(
          tap((menuData) => {
            this.formGroup = this._productFilterService.createGroups(menuData);
            this.selectSelectedTypesAndProducts(menuData);
          }),
          switchMap(() => {
            return merge(
              this.subscribeToFormChanges(),
              this._productFilterService.selectAllproductsSubject.pipe(
                distinctUntilChanged(),
                first(),
                tap((isSelectAll) => {
                  if (isSelectAll) {
                    this.selectAllTypesAndProducts();
                  } else {
                    this.onClearClick();
                  }
                })
              ),
              this._productFilterService.selectSpecificProductSubject.pipe(
                distinctUntilChanged(),
                tap((data) => {
                  if (data) {
                    const { operationType, productIds } = data;
                    //? For some reason, despite being an array, productIds is always a single-element array
                    this.selectOperationOrProduct(operationType, productIds?.[0]);
                  }
                })
              )
            );
          })
        )
        .subscribe(() => {
          this.updateAnySelectedFlag();
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  get groups(): FormArray {
    return this.formGroup?.get('groups') as FormArray;
  }

  get productCount(): number {
    const groupsArray = this.formGroup!.get('groups') as FormArray;

    // Sum up the number of products in each group's `products` array
    let totalProducts = 0;

    groupsArray.controls.forEach((groupControl) => {
      const productsArray = groupControl.get('products') as FormArray;
      if (productsArray) {
        totalProducts += productsArray.length;
      }
    });

    return totalProducts;
  }

  protected onMenuStateChange(isOpen: boolean) {
    this._productFilterService.isMenuOpenSubject.next(isOpen);
  }

  protected foldOutProducts(event: Event, trigger: MatMenuTrigger) {
    // Should only work for touch-screens, so ignore click events.
    if (event.type === 'click') {
      return;
    }
    event.stopPropagation();
    if (this.openSubMenuTrigger === trigger) {
      this.closeSubMenu();
      return;
    }
    setTimeout(() => trigger.openMenu());
    this.openSubMenuTrigger = trigger;
  }

  protected onClearClick() {
    this.groups.controls.forEach((group) => {
      // Deselect the type
      const typeGroup = group.get('type') as FormGroup;
      typeGroup.patchValue(
        {
          selected: false,
          partiallySelected: false,
        },
        { emitEvent: false }
      );

      const productArray = group.get('products') as FormArray;
      productArray.controls.forEach((product) => {
        product.patchValue({ selected: false }, { emitEvent: false });
      });
    });

    this.closeSubMenu();
    this.formGroup?.patchValue({ anySelected: false }, { emitEvent: true });
  }

  protected onSelectAllClick() {
    this.selectAllTypesAndProducts();
  }

  protected getProductFormArray(control: AbstractControl): AbstractControl[] {
    const productFormArray = control.get('products') as FormArray;
    return productFormArray ? productFormArray.controls : [];
  }

  protected toggleTypeAndProduct(type: any, products: any[], event: Event, trigger: MatMenuTrigger) {
    event.stopPropagation();

    // Find the group containing this type
    const groupIndex = this.groups.controls.findIndex((group) => group.get('type')?.get('id')?.value === type.id);
    if (groupIndex === -1) return;

    const group = this.groups.controls[groupIndex];
    const typeGroup = group.get('type') as FormGroup;
    const productArray = group.get('products') as FormArray;

    // Get current selected state
    const isCurrentlySelected = typeGroup.get('selected')?.value;
    const newSelectedState = !isCurrentlySelected;

    // First update type selection
    typeGroup.patchValue(
      {
        selected: newSelectedState,
        partiallySelected: false,
      },
      { emitEvent: true }
    );

    // Then update all products
    productArray.controls.forEach((product) => {
      product.patchValue({ selected: newSelectedState }, { emitEvent: false });
    });

    // Emit a single event after all updates are done
    typeGroup.updateValueAndValidity({ emitEvent: true });

    // Handle menu
    if (products.length > 0) {
      setTimeout(() => {
        if (trigger && trigger.menuOpen === false) {
          trigger.openMenu();
        }
      }, 50);
      this.openSubMenuTrigger = trigger;
    }

    this.updateAnySelectedFlag();
  }

  protected toggleProduct(product: any, event: Event) {
    event.stopPropagation();

    // Find the group containing this product
    const groupIndex = this.groups.controls.findIndex((group) => {
      const productArray = group.get('products') as FormArray;
      return productArray.controls.some(
        (control) => control.get('id')?.value === product.id && control.get('productTypeId')?.value === product.productTypeId
      );
    });

    if (groupIndex === -1) return;

    const group = this.groups.controls[groupIndex];
    const typeGroup = group.get('type') as FormGroup;
    const productArray = group.get('products') as FormArray;

    // Find and toggle the specific product
    const productControl = productArray.controls.find((control) => control.get('id')?.value === product.id);
    if (!productControl) return;

    // Toggle product selection
    const newSelectedState = !productControl.get('selected')?.value;
    productControl.patchValue({ selected: newSelectedState }), { emitEvent: false };

    this._productFilterService.updateParentTypeStatus(typeGroup, productArray);
    this.updateAnySelectedFlag();
  }

  private subscribeToFormChanges() {
    let isFirstEmission = true; // Flag to track the first emission

    return this.formGroup!.valueChanges.pipe(
      debounceTime(25),
      tap(() => {
        this.noproducts$.next(this.groups.controls.length === 0);
      }),
      map(() => {
        // Extract selected products
        return this.groups.controls
          .flatMap((group) => {
            const products = group.get('products') as FormArray;
            return products.controls.filter((product) => product.get('selected')?.value);
          })
          .map((selectedProduct) => selectedProduct.get('product')?.value);
      }),
      tap((selectedProduct) => {
        if (isFirstEmission && selectedProduct.length === 0) {
          isFirstEmission = false; // Skip the first empty emission
          return;
        }
        isFirstEmission = false; // Reset the flag after the first emission
        this._productFilterService.setSelectedProducts(selectedProduct);
      })
    );
  }

  private selectSelectedTypesAndProducts(items: ProductMenuDataItem[]) {
    const groupedByType = items.reduce(
      (acc, item) => {
        const enabledProducts = item.products.filter((product) => product.enabled);
        if (enabledProducts.length > 0) {
          acc[item.type] = acc[item.type] || [];
          acc[item.type].push(...enabledProducts);
        }
        return acc;
      },
      {} as Record<OperationTypes, ProductSubType[]>
    );

    setTimeout(() => {
      Object.entries(groupedByType).forEach(([operationType, products]) => {
        const allProductsEnabled = products.length === items.find((item) => item.type === +operationType)?.products.length;

        if (allProductsEnabled) {
          this.selectOperationOrProduct(+operationType);
        } else {
          products.forEach((p) => {
            this.selectOperationOrProduct(+operationType, p.id);
          });
        }
      });
    }, 0);
  }

  private selectAllTypesAndProducts() {
    // Get the groups FormArray
    const groupsArray = this.groups;

    // Iterate through each group
    groupsArray.controls.forEach((group) => {
      // Select the type
      const typeGroup = group.get('type') as FormGroup;
      typeGroup.patchValue(
        {
          selected: true,
          partiallySelected: false,
        },
        { emitEvent: false }
      );

      // Select all products
      const productArray = group.get('products') as FormArray;
      productArray.controls.forEach((product) => {
        product.patchValue({ selected: true }, { emitEvent: false });
      });
    });

    // Update the anySelected flag
    this.formGroup?.get('anySelected')?.setValue(true, { emitEvent: true });
  }

  // Selects a specific operationType or Product
  private selectOperationOrProduct(operationTypeId: number, productId?: number) {
    const groupIndex = this.groups.controls.findIndex((group) => group.get('type')?.get('id')?.value === operationTypeId);

    if (groupIndex === -1) return;

    const group = this.groups.controls[groupIndex];
    const typeGroup = group.get('type') as FormGroup;
    const productArray = group.get('products') as FormArray;

    if (productId !== undefined) {
      // Select the specific sub-product type
      const ProductControl = productArray.controls.find((control) => control.get('id')?.value === productId);
      if (ProductControl) {
        ProductControl.patchValue({ selected: true }, { emitEvent: true });
        typeGroup.patchValue({ selected: false, partiallySelected: true }, { emitEvent: true });
      }
    } else {
      // Select the entire operationtype
      typeGroup.patchValue({ selected: true, partiallySelected: false }, { emitEvent: true });
      productArray.controls.forEach((product) => product.patchValue({ selected: true }, { emitEvent: true }));
    }

    // Update anySelected flag
    this.updateAnySelectedFlag();
  }

  private updateAnySelectedFlag() {
    const groupsArray = this.groups;
    let anySelected = false;

    // Check if any type or product is selected
    for (const group of groupsArray.controls) {
      const typeSelected = group.get('type')?.get('selected')?.value;
      if (typeSelected) {
        anySelected = true;
        break;
      }

      const productArray = group.get('products') as FormArray;
      const anyProductSelected = productArray.controls.some((product) => product.get('selected')?.value);
      if (anyProductSelected) {
        anySelected = true;
        break;
      }
    }

    // Update the form
    this.formGroup?.get('anySelected')?.setValue(anySelected, { emitEvent: false });
  }

  private closeSubMenu() {
    this.openSubMenuTrigger?.closeMenu();
    this.openSubMenuTrigger = null;
  }
}
