import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '@app/core/types/nullable.type';
import { isNullOrUndefined } from '@app/shared/utils/utils';

@Pipe({
  name: 'sort',
  standalone: false,
})
export class SortPipe implements PipeTransform {
  public transform<T>(array: Nullable<T[]>, key?: keyof T, direction: 'asc' | 'desc' = 'asc'): Nullable<T[]> {
    if (isNullOrUndefined(array)) return array;

    if (!Array.isArray(array)) throw new Error('Input value must be an array');

    if (!key) return [...array].sort();

    return [...array].sort((a, b) => {
      const aValue = a?.[key];
      const bValue = b?.[key];

      if (isNullOrUndefined(aValue) || isNullOrUndefined(bValue)) return 0;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        return direction === 'asc' ? aValue - bValue : bValue - aValue;
      } else return 0;
    });
  }
}
