import { Directive, InjectionToken } from '@angular/core';

/**
 * Injection token that can be used to reference instances of `SelectPrefix`. It serves as
 * alternative token to the actual `SelectPrefix` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const SELECT_PREFIX = new InjectionToken<SelectPrefixDirective>('SelectPrefix');

/**
 * Handle to grab custom prefix to form field
 */
@Directive({
  selector: 'app-select-prefix',
  providers: [{ provide: SELECT_PREFIX, useExisting: SelectPrefixDirective }],
  standalone: false,
})
export class SelectPrefixDirective {}
