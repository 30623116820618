import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { DataConnectionTypes } from '@app/core/data-connections/data-connection-types.enum';
import { DataConnectionsService } from '@app/core/data-connections/data-connections.service';
import { DataConnectionState } from '@app/core/enums/data-connection-state.enum';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { DataConnectionType } from '@app/core/interfaces/data-connection-type.interface';
import { LanguageService } from '@app/core/language/language.service';
import { LogService } from '@app/core/log/log.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { GridDataSource } from '@app/helpers/ui-helpers/grid-data-source';
import { NavigationService } from '@app/layouts/navigation/navigation.service';
import { AddEditSettingModalComponent } from '@app/settings/data-connections/add-edit-setting-modal/add-edit-setting-modal.component';
import { DeleteSettingModalComponent } from '@app/settings/data-connections/delete-setting-modal/delete-setting-modal.component';
import { DatamanagementStateService } from '@app/state/services/data-management/datamanagement-state.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { AgrirouterErrorCodeService } from './agrirouter-error-code.service';
import { SelectJdOrganizationDialogComponent } from './select-jd-organization-dialog/select-jd-organization-dialog.component';

interface GridData {
  dataConnectionSetting: DataConnectionSettingDTO;
  dataConnectionType: DataConnectionType;
  dataConnectionState: DataConnectionState;
}

@Component({
  selector: 'app-data-connections',
  templateUrl: './data-connections.component.html',
  styleUrls: ['./data-connections.component.scss'],
  standalone: false,
})
export class DataConnectionsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) public paginator!: MatPaginator;
  @ViewChild('filter', { static: true }) public filter!: ElementRef;

  public isLoading = true;
  public numberOfSettings!: number;
  public dataConnectionState = DataConnectionState;
  public displayedColumns = ['settingName', 'settingType', 'status', 'edit', 'delete'];
  public filterColumnNames = ['dataConnectionSetting.name', 'dataConnectionType.name'];

  protected dataConnectionTypes = DataConnectionTypes;

  public addEditDialogRef!: MatDialogRef<AddEditSettingModalComponent>;
  public deleteDialogRef!: MatDialogRef<DeleteSettingModalComponent>;
  public dataConnectionsGridDataSource!: GridDataSource<GridData>;
  private gridData!: GridData[];
  private dataChange!: BehaviorSubject<GridData[]>;
  private subscriptions = new Subscription();

  constructor(
    private dataConnectionsService: DataConnectionsService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private agrirouterErrorCodeService: AgrirouterErrorCodeService,
    private router: Router,
    private navigationService: NavigationService,
    private languageService: LanguageService,
    private datamanagementStateService: DatamanagementStateService,
    private mdDialog: MatDialog,
    private logService: LogService
  ) {}

  public async ngOnInit() {
    this.isLoading = true;
    this.dataChange = new BehaviorSubject<GridData[]>([]);

    this.gridData = [];
    this.dataConnectionsGridDataSource = new GridDataSource(this.dataChange, this.paginator, this.filterColumnNames);
    this.numberOfSettings = 0;
    this.initSettings();
    this.initTypes();
    this.initSubscriptions();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initSettings() {
    this.datamanagementStateService.dataConnectionSettings$
      .pipe(
        first(),
        filter((dataConnectionSettings) => dataConnectionSettings.length === 0),
        switchMap(() => this.dataConnectionsService.getSettings())
      )
      .subscribe((dataConnectionSettings) => {
        this.datamanagementStateService.dataConnectionStates = dataConnectionSettings.map((dataConnectionSetting) => {
          return { dataConnectionId: dataConnectionSetting.id, dataConnectionState: DataConnectionState.UNTESTED };
        });
        this.datamanagementStateService.dataConnectionSettings = dataConnectionSettings;
      });
  }

  private initTypes() {
    this.datamanagementStateService.dataConnectionTypes$
      .pipe(
        first(),
        filter((dataConnectionTypes) => dataConnectionTypes.length === 0),
        switchMap(() => this.dataConnectionsService.getTypes())
      )
      .subscribe((dataConnectionTypes) => {
        this.datamanagementStateService.dataConnectionTypes = dataConnectionTypes;
      });
  }

  private initSubscriptions() {
    this.subscriptions.add(
      this.datamanagementStateService.dataConnectionSettings$
        .pipe(
          filter((dataConnectionSettings) => dataConnectionSettings.some((setting) => setting.dataIsMissing)),
          map((dataConnectionSettings) =>
            dataConnectionSettings.find((setting) => setting.connectionTypeId === DataConnectionTypes.JohnDeere)
          )
        )
        .subscribe((setting) => {
          if (setting === undefined) return;
          this.mdDialog.open(SelectJdOrganizationDialogComponent, {
            data: setting,
            disableClose: true,
          });
        })
    );

    this.subscriptions.add(
      combineLatest([
        this.datamanagementStateService.dataConnectionSettings$,
        this.datamanagementStateService.dataConnectionTypes$,
        this.datamanagementStateService.dataConnectionStates$,
      ])
        .pipe(
          tap(([dataConnectionSettings, dataConnectionTypes, dataConnectionStates]) => {
            if (!dataConnectionSettings.length && !dataConnectionTypes.length && !dataConnectionStates.length) this.isLoading = false;
          }),
          filter(
            ([dataConnectionSettings, dataConnectionTypes, dataConnectionStates]) =>
              dataConnectionSettings.length > 0 && dataConnectionTypes.length > 0 && dataConnectionStates.length > 0
          )
        )
        .subscribe(([dataConnectionSettings, dataConnectionTypes, dataConnectionStates]) => {
          this.onSettingsAndTypesAndStatesLoaded(dataConnectionSettings, dataConnectionTypes, dataConnectionStates);
        })
    );

    this.subscriptions.add(
      this.dataConnectionsService.refreshBehaviourSubject.subscribe((result) => {
        if (result) {
          this.dataConnectionsService.refreshBehaviourSubject.next(false);
          this.reload().catch((err) => this.logService.logError(err));
        }
      })
    );

    this.subscriptions.add(
      this.route.queryParams.subscribe((params) => {
        const error = params['error'];
        const callbackresult = params['callbackresult'];
        const farmIds = params['farmIds'];
        const currentLanguage = params['currentLanguage'];
        const harvestYear = params['harvestYear'];
        if (error && error.length > 0) {
          const showNotification = () => {
            const errorText = this.agrirouterErrorCodeService.getErrorOrInfoText(error);

            if (errorText) {
              switch (errorText.type) {
                case 'info':
                  this.notificationService.showInfo(errorText.text, 4999);
                  break;
                case 'success':
                  this.notificationService.showSuccess(errorText.text, 4999);
                  break;
                default:
                  this.notificationService.showError(errorText.text, 9999);
                  break;
              }
            }
          };
          if (currentLanguage && this.languageService.currentLanguage.shortKey !== JSON.parse(currentLanguage)) {
            this.languageService.languageChanged.pipe(first()).subscribe(showNotification);
            this.languageService.setLanguage(JSON.parse(currentLanguage));
          } else {
            showNotification();
          }
        }
        if (callbackresult && callbackresult.length > 0) {
          this.notificationService.showError(
            this.languageService.getText('main.settings.dataConnections.johnDeere.onBoarding.accountAlreadyUsed'),
            15000
          );
        }
        void this.router.navigate(['/settings/connections'], {
          queryParams: {
            farmIds: farmIds,
            currentLanguage: currentLanguage,
            harvestYear: harvestYear,
          },
        });
      })
    );
  }

  public getSettingState(setting: DataConnectionSettingDTO, type: DataConnectionType) {
    const index = this.gridData.findIndex((x) => x.dataConnectionSetting.id === setting.id);

    if (index > -1) {
      this.gridData[index].dataConnectionState = DataConnectionState.TESTING;
      this.dataChange.next(this.gridData);
    }

    this.datamanagementStateService.dataConnectionSettings$
      .pipe(
        first(),
        map((settings) => settings.find((connectionSetting) => connectionSetting.id === setting.id)),

        switchMap((connectionSetting) => this.dataConnectionsService.validateConnection(connectionSetting!, type))
      )
      .subscribe((valid: boolean) => {
        this.datamanagementStateService.updateDataConnectionStates({
          dataConnectionId: setting.id,
          dataConnectionState: valid ? DataConnectionState.SUCCESS : DataConnectionState.FAILED,
        });
      });
  }

  //////// Event handlers /////////
  public onSearchTermChanged(term: string): void {
    if (!this.dataConnectionsGridDataSource) {
      return;
    }
    this.dataConnectionsGridDataSource.filter = term;
  }

  public onDeleteSettingClicked(setting: {
    dataConnectionSetting: DataConnectionSettingDTO;
    dataConnectionType: DataConnectionType;
  }): void {
    this.deleteDialogRef = this.mdDialog.open(DeleteSettingModalComponent);
    this.deleteDialogRef.componentInstance.dialogRef = this.deleteDialogRef;
    this.deleteDialogRef
      .afterClosed()
      .pipe(
        filter((shouldDelete: boolean) => !!shouldDelete),
        switchMap(() => this.dataConnectionsService.deleteSetting(setting.dataConnectionSetting))
      )
      .subscribe(() => {
        this.dataConnectionsService.clearDataConnectionsSettings();
        this.notificationService.showDeleted('main.dataConnection');
        this.datamanagementStateService.deleteDataConnection(setting.dataConnectionSetting);
        this.datamanagementStateService.deleteDataConnectionState({
          dataConnectionId: setting.dataConnectionSetting.id,

          // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          dataConnectionState: undefined,
        });

        this.refreshList();
        this.isLoading = false;
      });
  }

  public onAddSettingClicked(): void {
    this.addEditDialogRef = this.mdDialog.open(AddEditSettingModalComponent);
    this.addEditDialogRef.componentInstance.dialogRef = this.addEditDialogRef;

    this.mdDialog.afterAllClosed.pipe(first()).subscribe(() => {
      this.refreshList();
    });
    this.mdDialog.afterAllClosed.subscribe().unsubscribe();
  }

  public onEditSettingClicked(setting: { dataConnectionSetting: DataConnectionSettingDTO; dataConnectionType: DataConnectionType }) {
    if (setting.dataConnectionSetting.connectionTypeId === DataConnectionTypes.JohnDeere && !setting.dataConnectionSetting.additionalInfo) {
      this.mdDialog.open(SelectJdOrganizationDialogComponent, {
        data: setting.dataConnectionSetting,
        disableClose: true,
      });
      return;
    }

    this.addEditDialogRef = this.mdDialog.open(AddEditSettingModalComponent, {
      data: setting.dataConnectionSetting,
      disableClose: true,
    });
    this.addEditDialogRef.componentInstance.dialogRef = this.addEditDialogRef;
    this.addEditDialogRef.disableClose = true;
  }

  public isJDConnection(connectionType: DataConnectionTypes) {
    return connectionType === DataConnectionTypes.JohnDeere;
  }

  private onSettingsAndTypesAndStatesLoaded(
    settings: DataConnectionSettingDTO[],
    types: DataConnectionType[],
    states: { dataConnectionId: number; dataConnectionState: DataConnectionState }[]
  ) {
    this.gridData = [];

    // for each setting, set matching type
    settings.forEach((setting) => {
      const type = types.find((x) => x.id === setting.connectionTypeId);
      const state = states?.find((x) => x.dataConnectionId === setting.id);

      if (type) {
        // matching type was found
        this.gridData.push({
          dataConnectionSetting: setting,
          dataConnectionType: type,

          // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          dataConnectionState: state.dataConnectionState,
        });
      } else {
        // matching type was not found, create empty type
        this.gridData.push({
          dataConnectionSetting: setting,
          dataConnectionType: {
            id: 0,
            name: '',
            description: '',
            pingUrl: '',
          },
          dataConnectionState: DataConnectionState.UNTESTED,
        });
      }
    });

    this.numberOfSettings = this.gridData.length;
    this.dataChange.next(this.gridData);
    this.isLoading = false;
  }

  public refreshList() {
    this.subscriptions.unsubscribe();
    this.ngOnInit().catch(() => this.logService.logError('error refreshing data connections list'));
  }

  public async reload() {
    this.dataConnectionsService.clearDataConnectionsSettings();
    this.datamanagementStateService.dataConnectionSettings = [];
    this.datamanagementStateService.dataConnectionTypes = [];
    this.datamanagementStateService.dataConnectionStates = [];
    await this.router.navigateByUrl('', { skipLocationChange: true }).catch(() => console.error('error reloading page'));
    return this.router.navigate(['/settings/connections']);
  }
}
