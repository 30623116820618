import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-side-drawer-wrapper',
  templateUrl: './side-drawer-wrapper.component.html',
  styleUrls: ['./side-drawer-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SideDrawerWrapperComponent {}
