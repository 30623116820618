<!-- LOADING STATE DETERMINER -->
<ng-container *ngIf="isInitialized$ | async">
  <ng-container *ngIf="showLoading$ | async; else contentTemplate">
    <div class="loading-state">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  </ng-container>
</ng-container>

<!-- TABLE CONTENT-->
<ng-template #contentTemplate>
  <ng-container *ngIf="hasEntries$ | async; else noEntriesTemplate">
    <div class="backdrop-blur-y"></div>
    <div class="backdrop-blur-x"></div>
    <div class="table-container" #tableContainer @tableFade>
      <cdk-virtual-scroll-viewport itemSize="63">
        <table mat-table [dataSource]="dataSource" class="farm-tasks-table">
          <!-- Field Column -->
          <ng-container matColumnDef="field" sticky>
            <th mat-header-cell *matHeaderCellDef>
              <div class="sort-selector">
                <app-overview-sort-select></app-overview-sort-select>
              </div>
            </th>
            <td mat-cell *matCellDef="let row">
              <app-minimal-field-card [fieldEntry]="row.fieldEntry"></app-minimal-field-card>
            </td>
          </ng-container>

          <!-- Dynamic Date Columns -->
          <ng-container *ngFor="let date of uniqueDates; let i = index; trackBy: trackByDate" [matColumnDef]="date.date">
            <th mat-header-cell *matHeaderCellDef [attr.data-date]="date">
              <div class="date-header" [ngClass]="date.class">
                <ng-container *ngIf="isFirstDateOfMonth(date.date, i)">
                  <div class="month">{{ date.date | dateFormat: 'MMMM ´yy' }}</div>
                </ng-container>
                <div
                  class="date"
                  [ngClass]="{
                    'last-date': isLastDateOfMonth(date.date, i),
                    'first-date': isFirstDateOfMonth(date.date, i),
                    'is-today': isToday(date.date),
                  }"
                  [matMenuTriggerFor]="dateMenu"
                  [matMenuTriggerData]="{ date: date.date }"
                >
                  {{ date.date | dateFormat: 'd MMM' }}
                </div>
              </div>
            </th>

            <td mat-cell *matCellDef="let row" [ngClass]="{ 'is-today': isToday(date.date) }">
              <div class="task-container">
                <ng-container *ngIf="row.tasksByDate[date.date]?.length; else emptyCell">
                  <div class="task-group" *ngIf="row.tasksByDate[date.date]?.length">
                    <app-minimal-task-card [taskEntries]="row.tasksByDate[date.date]"></app-minimal-task-card>
                  </div>
                </ng-container>

                <ng-template #emptyCell>
                  <app-minimal-empty-card></app-minimal-empty-card>
                </ng-template>
              </div>
            </td>
          </ng-container>

          <!-- Header and Row Definitions -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <div class="table-rows-container">
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </div>
        </table>
      </cdk-virtual-scroll-viewport>

      <!-- Context Menu -->
      <mat-menu #dateMenu="matMenu">
        <ng-template matMenuContent let-date="date">
          <button mat-menu-item (click)="addDatesAfter(date, 1)" [disabled]="!wouldAddDatesAfter(date, 1)">
            <span>{{ 'farm-tasks-overview.context-menu.add-1-after' | translate }}</span>
          </button>
          <button mat-menu-item (click)="addDatesAfter(date, 3)" [disabled]="!wouldAddDatesAfter(date, 3)">
            <span>{{ 'farm-tasks-overview.context-menu.add-3-after' | translate }}</span>
          </button>
          <button mat-menu-item (click)="addDatesAfter(date, 7)" [disabled]="!wouldAddDatesAfter(date, 7)">
            <span>{{ 'farm-tasks-overview.context-menu.add-7-after' | translate }}</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="addDatesBefore(date, 1)" [disabled]="!wouldAddDatesBefore(date, 1)">
            <span>{{ 'farm-tasks-overview.context-menu.add-1-before' | translate }}</span>
          </button>
          <button mat-menu-item (click)="addDatesBefore(date, 3)" [disabled]="!wouldAddDatesBefore(date, 3)">
            <span>{{ 'farm-tasks-overview.context-menu.add-3-before' | translate }}</span>
          </button>
          <button mat-menu-item (click)="addDatesBefore(date, 7)" [disabled]="!wouldAddDatesBefore(date, 7)">
            <span>{{ 'farm-tasks-overview.context-menu.add-7-before' | translate }}</span>
          </button>
        </ng-template>
      </mat-menu>
    </div>
  </ng-container>
</ng-template>

<!-- NO ENTRIES TEMPLATE -->
<ng-template #noEntriesTemplate>
  <div class="empty-state" @tableFade>
    <div class="empty-state__icon">
      <mat-icon class="material-symbols-outlined">assignment</mat-icon>
    </div>
    <h3 class="empty-state__title">{{ 'farm-tasks-overview.empty-state.title' | translate }}</h3>
    <p class="empty-state__message">
      {{ 'farm-tasks-overview.empty-state.description' | translate }}
    </p>
  </div>
</ng-template>

<!-- LOADING TEMPLATE -->
<ng-template #loadingTemplate>
  <div class="loading-state">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
</ng-template>
