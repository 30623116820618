import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-side-drawer-info-hover',
  templateUrl: './side-drawer-info-hover.component.html',
  styleUrls: ['./side-drawer-info-hover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SideDrawerInfoHoverComponent {
  public isInfoVisible = false;
  @Input() public buttonText = '';
}
