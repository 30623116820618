import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { InputErrorComponent } from '@app/shared/input/input-error/input-error.component';
import { DecimalService } from '../pipes/decimal-separator/decimal.service';
import { InputErrorStateMatcher } from './input-error-state-matcher.class';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class InputComponent implements ControlValueAccessor, AfterContentInit {
  @Input() public placeholder?: string;
  @Input() public type: 'text' | 'number' | 'password' | 'email' = 'text';
  @Input() public readonly = false;
  @Input() public simple = false;
  @Input() public step?: number;
  @Input() public min?: number;
  @Input() public max?: number;
  @Input() public localize = false;
  @Input() public textAlign = 'left';
  @Input() public matchInstantly = false;
  @Input() public autocomplete = true;
  @Input() public name?: string;
  @Input() public disabled = false;
  @Input() public testId?: string;

  // @ts-ignore - TS2564 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  @Input() public maxlength: number;
  //eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public blur = new EventEmitter<void>();

  private _errorMsgs?: QueryList<InputErrorComponent>;
  public get errorMsgs(): QueryList<InputErrorComponent> | undefined {
    return this._errorMsgs;
  }

  @ContentChildren(InputErrorComponent)
  public set errorMsgs(v: QueryList<InputErrorComponent> | undefined) {
    this._errorMsgs = v;
    this.changeDetectorRef.detectChanges();
  }

  public touched = false;
  public value = '';
  public errorStateMatcher: ErrorStateMatcher | null = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private decimalService: DecimalService
  ) {}

  public ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.matchInstantly) this.errorStateMatcher = new InputErrorStateMatcher(this.errorMsgs, true);
      else this.errorStateMatcher = new InputErrorStateMatcher(this.errorMsgs, false);
    });
  }

  public propagateChange: any = (_: any) => {};

  public onTouched: any = (_: any) => {};

  public onChange(val: string) {
    this.value = val;

    let parsedResult: number | string | null = val;

    if (this.localize) parsedResult = this.decimalService.toNumber(val);

    this.propagateChange(parsedResult);
  }

  public onBlur() {
    this.onTouched();
    this.blur.emit();
  }

  public writeValue(obj: any): void {
    const isNumber = this.type === 'number' && typeof obj === 'number';
    const isText = this.type === 'text' && obj;

    if (this.localize && (isText || isNumber)) this.value = this.decimalService.toLocaleDecimal(obj).toLocaleString();
    else this.value = obj;

    this.changeDetectorRef.markForCheck();
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    const self = this;

    this.onTouched = (arg: any) => {
      self.touched = true;
      fn(arg);
      this.changeDetectorRef.detectChanges();
    };
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }
}
