import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]',
  standalone: false,
})
export class NumbersOnlyDirective {
  // Allow only numbers from 0-9
  private regex: RegExp = new RegExp(/^[0-9]+$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home, delete, left and right arrow keys
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'ArrowLeft', 'ArrowRight'];

  @Input() public maxLength?: number;
  @Input() public min?: number;
  @Input() public max?: number;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    // Allow special keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    // Let copy and paste events do their thing
    if (event.ctrlKey && (event.key === 'v' || event.key === 'c')) {
      return;
    }

    this.handleInput(event.key, event);
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent) {
    this.handleInput(event?.clipboardData?.getData('text'), event);
  }

  private handleInput(input: string | undefined, event: ClipboardEvent | KeyboardEvent) {
    const current = this.el.nativeElement.value;
    let next: string | undefined = current?.concat(input);

    // Replaces next with input if everything is selected
    if (window.getSelection) {
      const selected = window.getSelection()?.toString();

      if (selected === current) {
        next = input;
      }
    }

    if ((next && !String(next).match(this.regex)) || (this.maxLength && next!.length > this.maxLength)) {
      event.preventDefault();
    } else {
      if (+next! > this.max!) {
        event.preventDefault();
        this.el.nativeElement.value = this.max?.toString();
      } else if (+next! < this.min!) {
        event.preventDefault();
        this.el.nativeElement.value = this.min?.toString();
      }
    }
  }
}
