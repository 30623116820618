import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { SideDrawerComponent } from '@app/shared/side-drawer/side-drawer.component';

export enum SideDrawerToolbarCloseEventTypes {
  Cancel = 'cancel',
  CallToAction = 'call-to-action',
}

export interface SideDrawerToolbarCloseEvent {
  type: SideDrawerToolbarCloseEventTypes;
}

@Component({
  selector: 'app-side-drawer-toolbar',
  templateUrl: './side-drawer-toolbar.component.html',
  styleUrls: ['./side-drawer-toolbar.component.scss'],
  standalone: false,
})
export class SideDrawerToolbarComponent {
  @Input() public title = '';
  @Input() public actionButtonText = '';
  @Input() public cancelIconName = 'Luk';
  @Input() public showCallToAction = true;
  @Output() public action = new EventEmitter<SideDrawerToolbarCloseEvent>();

  /**
   *
   */
  constructor(private injector: Injector) {}

  public callToAction() {
    this.action.emit({
      type: SideDrawerToolbarCloseEventTypes.CallToAction,
    });
  }

  public cancel() {
    this.action.emit({
      type: SideDrawerToolbarCloseEventTypes.Cancel,
    });

    let sideDrawerComponent = null;
    try {
      sideDrawerComponent = this.injector.get(SideDrawerComponent); // check if viewContent of SideDrawer
    } catch (error) {}

    if (sideDrawerComponent) {
      sideDrawerComponent.close();
    }
  }
}
