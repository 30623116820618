import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';

@Component({
  selector: 'app-hotspot-subtype-item',
  templateUrl: './hotspot-subtype-item.component.html',
  styleUrls: ['./hotspot-subtype-item.component.scss'],
  standalone: false,
})
export class HotspotSubtypeItemComponent {
  @Input() public field!: Field;
  @Input() public canRemove = false;
  @Input() public canAdd = false;
  @Output() public onRemove = new EventEmitter<{ field: Field }>();
  @Output() public onAdd = new EventEmitter<{ field: Field }>();

  constructor() {}

  public remove = () => this.onRemove.emit({ field: this.field });
  public add = () => this.onAdd.emit({ field: this.field });
}
