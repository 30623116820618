import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-nav-toggle-button',
  templateUrl: './side-nav-toggle-button.component.html',
  styleUrls: ['./side-nav-toggle-button.component.scss'],
  standalone: false,
})
export class SideNavToggleButtonComponent implements OnInit, AfterViewInit {
  private isOpen!: boolean;
  public isMenuOpen!: boolean;

  constructor(public router: Router) {}

  public ngOnInit() {
    this.isOpen = false;
  }

  public ngAfterViewInit(): void {
    this.router.events.subscribe((event: any) => {
      setTimeout(() => {
        this.isMenuOpen = false;
      });
    });
  }

  public onMenuBtnClicked = () => (this.isMenuOpen = !this.isOpen);

  public onMenuOverlayClicked = () => (this.isMenuOpen = false);
}
