import { Component, OnDestroy, OnInit } from '@angular/core';
import { Month } from '@app/core/enums/month.enum';
import { filterNullish } from '@app/shared/operators';
import { NdviStateService } from '@app/state/services/ndvi/ndvi-state.service';
import { DateTime } from 'luxon';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { SatelliteImageDate } from '../interfaces/ndvi-image-date.interface';

@Component({
  selector: 'app-ndvi-date-picker',
  templateUrl: './ndvi-date-picker.component.html',
  styleUrls: ['./ndvi-date-picker.component.scss'],
  standalone: false,
})
export class NdviDatePickerComponent implements OnInit, OnDestroy {
  public readonly selectedDate$: Observable<DateTime | null> = this.ndviStateService.sateliteImageDate$;

  public availableImageDates?: SatelliteImageDate[] | undefined = [];
  public currentDate: DateTime | null = DateTime.fromObject({ year: 2001, month: Month.January, day: 1 });
  public currentIndex!: number;

  private subscriptions: Subscription[] = [];

  constructor(private ndviStateService: NdviStateService) {}

  public ngOnInit() {
    this.subscriptions.push(
      combineLatest([
        this.ndviStateService.ndviImageDates$.pipe(filterNullish()),
        this.ndviStateService.sateliteImageDate$.pipe(filterNullish()),
      ]).subscribe(([dates, date]) => {
        this.availableImageDates = dates?.sort(this.sortDates);
        this.currentDate = date;
        this.currentIndex = this.getDateIndex();
      })
    );
  }

  private sortDates(a: SatelliteImageDate | null, b: SatelliteImageDate | null) {
    if (!a || !b) return 0;
    // Dateformat 2018-05-30T00:00:00Z
    if (a.date < b.date) {
      return -1;
    } else if (a.date > b.date) {
      return 1;
    } else {
      return 0;
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public previousDate() {
    if (this.availableImageDates && this.currentIndex >= 1) {
      this.ndviStateService.sateliteImageDate = this.availableImageDates[this.currentIndex - 1].date;
    }
  }

  public nextDate() {
    if (this.availableImageDates && this.currentIndex < this.availableImageDates.length - 1) {
      this.ndviStateService.sateliteImageDate = this.availableImageDates[this.currentIndex + 1].date;
    }
  }

  private getDateIndex(): number {
    if (!this.availableImageDates) return -1;
    return this.availableImageDates.findIndex((img) => {
      if (!this.currentDate) return false;
      return img.date.hasSame(this.currentDate, 'day');
    });
  }
}
