import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { DateFormatPipe } from '@app/shared/pipes/date-format/date-format.pipe';
import { DecimalService } from '@app/shared/pipes/decimal-separator/decimal.service';
import { GrowthRegulationService } from '../../growth-regulation.service';
import { getScoreColor } from '../../helpers/score-color.helper';
import { getSoilType } from '../../helpers/soil-type.helper';
import { GrowthRegulation } from '../../interfaces/growth-regulation.interface';
import { FieldInfoElement } from './field-info-element/field-info-element.interface';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { WaterBalanceDialogComponent } from '@app/shared/water-balance/dialog/water-balance-dialog.component';

@Component({
  selector: 'app-field-info-content',
  templateUrl: './field-info-content.component.html',
  styleUrls: ['./field-info-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FieldInfoContentComponent implements OnChanges {
  @Input() public field!: Field;
  @Input() public growthRegulation!: GrowthRegulation;
  public tableData: FieldInfoElement[] = [];

  // to use MapLayerId enum in template
  public mapLayerId = MapLayerId;

  constructor(
    private lang: LanguageService,
    private decimalService: DecimalService,
    private growtRegulationService: GrowthRegulationService,
    private dialogService: DialogService
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    if (changes && changes.growthRegulation) {
      // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      this.setupTableData(changes.growthRegulation.currentValue);
    }
  }

  public getBackgroundColor(score: number) {
    return getScoreColor(score);
  }

  public getColor(score: number) {
    const color = this.getBackgroundColor(score);
    return color === '#F9E067' ? 'black' : 'white';
  }

  public getSoilType(soilTypeId: number) {
    return getSoilType(soilTypeId);
  }

  public hasScore(score: number) {
    return !(score === undefined || score === null);
  }

  public get isRye() {
    return this.growthRegulation.directorateCropNormNumber === 14 || this.growthRegulation.directorateCropNormNumber === 15;
  }

  public get isWheat() {
    return this.growthRegulation.directorateCropNormNumber === 11 || this.growthRegulation.directorateCropNormNumber === 13;
  }

  private setupTableData(gr: GrowthRegulation) {
    this.tableData = [];
    const datePipe = new DateFormatPipe(this.lang);
    const decPipe = new DecimalPipe(this.lang.currentLanguage.shortKey);
    this.tableData.push({
      subject: 'main.growthRegulation.soilType',
      name: getSoilType(gr.jb),

      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      score: gr.jbScore,
      info: {
        headline: 'main.growthRegulation.soilType',
        body: 'main.growthRegulation.table.soil.body',
        table: [
          { name: 'main.growthRegulation.table.soil.low', value: '-1' },
          { name: 'main.growthRegulation.table.soil.medium', value: '0' },
          { name: 'main.growthRegulation.table.soil.high', value: '1' },
        ],
      },
    });
    this.tableData.push({
      subject: 'main.growthRegulation.variety',

      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      name: gr.varietyNormName,

      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      score: gr.sortScore,
      info: {
        headline: 'main.growthRegulation.variety',
        body: 'main.growthRegulation.table.variety.body',
      },
    });
    this.tableData.push({
      subject: 'main.growthRegulation.seedingDate',

      // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      name: datePipe.transform(gr.sowingDate, null),

      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      score: gr.sowingDateScore,
      info: {
        headline: 'main.growthRegulation.seedingDate',
        body: 'main.growthRegulation.table.seedingDate.body',
      },
    });
    this.tableData.push({
      subject: 'main.growthRegulation.ndvi.subject',
      name: 'main.growthRegulation.ndvi.name',

      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      score: gr.ndviScore,
      info: {
        headline: 'main.growthRegulation.ndvi.subject',
        body: 'main.growthRegulation.table.ndvi.body',
      },
    });
    this.tableData.push({
      subject: 'main.growthRegulation.nNormDiffScore',
      name: this.decimalService.toLocaleString(decPipe.transform(gr.nNormDiff, '1.0-0')) + ' N/ha',

      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      score: gr.nNormDiffScore,
      info: {
        headline: 'main.growthRegulation.nNormDiffScore',
        body: 'main.growthRegulation.table.nNormDiffScore.body',
        table: [
          { name: 'main.growthRegulation.table.nNormDiffScore.low', value: '-1' },
          { name: 'main.growthRegulation.table.nNormDiffScore.medium', value: '0' },
          { name: 'main.growthRegulation.table.nNormDiffScore.high', value: '1' },
        ],
      },
    });
    this.tableData.push({
      subject: 'main.growthRegulation.nBeforeAprilScore',
      name: this.decimalService.toLocaleString(decPipe.transform(gr.nBeforeApril, '1.0-0')) + ' N/ha',

      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      score: gr.nBeforeAprilScore,
      info: {
        headline: 'main.growthRegulation.nBeforeAprilScore',
        body: 'main.growthRegulation.table.nBeforeAprilScore.body',
        table: [
          { name: 'main.growthRegulation.table.nBeforeAprilScore.low', value: '-1' },
          { name: 'main.growthRegulation.table.nBeforeAprilScore.medium', value: '0' },
          { name: 'main.growthRegulation.table.nBeforeAprilScore.high', value: '1' },
        ],
      },
    });
    this.tableData.push({
      subject: 'main.growthRegulation.aftereffectsNScore',
      name: this.decimalService.toLocaleString(decPipe.transform(gr.aftereffectsN, '1.0-0')) + ' N/ha',

      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      score: gr.aftereffectsNScore,
      info: {
        headline: 'main.growthRegulation.aftereffectsNScore',
        body: 'main.growthRegulation.table.aftereffectsNScore.body',
        table: [
          { name: 'main.growthRegulation.table.aftereffectsNScore.low', value: '-1' },
          { name: 'main.growthRegulation.table.aftereffectsNScore.medium', value: '0' },
          { name: 'main.growthRegulation.table.aftereffectsNScore.high', value: '1' },
        ],
      },
    });
  }

  public openWaterBalanceDialog() {
    this.dialogService.openCustomDialog(WaterBalanceDialogComponent, { maxWidth: '90vw', data: this.field });
  }
}
