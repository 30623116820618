import { Pipe, PipeTransform } from '@angular/core';
import { UserType } from './user-type.enum';

@Pipe({
  name: 'userTypeToString',
  standalone: false,
})
export class UserTypePipe implements PipeTransform {
  public transform(userType: UserType): string {
    return `common.userTypes.${UserType[userType]}`;
  }
}
