import { Injectable, OnDestroy } from '@angular/core';
import { FieldEntryStore } from '@app/farm-tasks-overview/services/state/field-entry-state-store/field-entry.store';
import { latest } from '@app/shared/constants/rxjs-constants';
import { BehaviorSubject, combineLatest, filter, map, shareReplay, Subject, takeUntil } from 'rxjs';
import { ProductSubType } from '../product-filter/product-filter.service';

@Injectable({
  providedIn: 'root',
})
export class MachineEventFilterService implements OnDestroy {
  constructor(private _ftoStore: FieldEntryStore) {
    this.setupMachineEventUpdates();
  }

  public isMenuOpenSubject = new BehaviorSubject<boolean>(false);
  public isMenuOpen$ = this.isMenuOpenSubject.asObservable().pipe(shareReplay(latest));

  private _pendingMachineEvents = new Set<ProductSubType>();
  private _machineEventUpdates$ = new Subject<Set<ProductSubType>>();
  private _destroy$ = new Subject<void>();
  private _updateVersion = 1;
  private _priorUpdateVersion = -1;

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public setSelectedMachineEvents(machineEvents: ProductSubType[]): void {
    // Add new products to pending set
    machineEvents.forEach((machineEvent) => this._pendingMachineEvents.add(machineEvent));

    // Emit the current state of pending products
    this._machineEventUpdates$.next(this._pendingMachineEvents);
    this._updateVersion++;
  }

  private setupMachineEventUpdates(): void {
    combineLatest([this.isMenuOpen$, this._machineEventUpdates$])
      .pipe(
        takeUntil(this._destroy$),
        filter(([isMenuOpen]) => !isMenuOpen),
        map(([isMenuOpen, updates]) => updates)
      )
      .subscribe((machineEvents) => {
        if (this._priorUpdateVersion === this._updateVersion) {
          return;
        }
        this._priorUpdateVersion = this._updateVersion;
        // Commit the accumulated changes to the store
        this._ftoStore.store.update((state) => ({
          ...state,
          machineEvents: Array.from(machineEvents),
        }));

        // Clear pending products after commit
        this._pendingMachineEvents.clear();
      });
  }
}
