import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-rain-chart-info',
  templateUrl: './rain-chart-info.component.html',
  styleUrls: ['./rain-chart-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RainChartInfoComponent {
  constructor(private dialogRef: MatDialogRef<RainChartInfoComponent>) {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
