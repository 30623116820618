import { Pipe, PipeTransform } from '@angular/core';
import { DatamanagementService } from '@app/core/datamanagement/datamanagement.service';
import { ClaasField } from '@app/core/interfaces/claas-field.interface';
import { Field } from '@app/core/interfaces/field.interface';

@Pipe({
  name: 'transferStatus',
  standalone: false,
})
export class TransferStatusPipe implements PipeTransform {
  constructor(private datamanagementService: DatamanagementService) {}

  public transform(value: Field, externalFields: ClaasField[]): string {
    if (!value) {
      return '';
    }

    return this.datamanagementService.getFieldTransferStatus(value, externalFields);
  }
}
