import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-soil-sample-table',
  templateUrl: './soil-sample-table.component.html',
  styleUrls: ['./soil-sample-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SoilSampleTableComponent {}
