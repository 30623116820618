import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AlertComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public snackBarData: any,
    private matSnackBarRef: MatSnackBarRef<NotificationComponent>
  ) {}

  public dismiss(): void {
    this.matSnackBarRef.dismiss();
  }
}
