import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { CoarseFodderDataSource } from '@app/core/economy/enum/coarseFodderDataSource.enum';
import { CoarseFodderColumn, CoarseFodderEconomyDataSource } from '@app/core/economy/interface/coarsefodder';
import { NotificationService } from '@app/core/notification/notification.service';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { IsInteger, IsValueBetweenZeroAndMax } from '@app/shared/directives/validation/form-validators';
import { filterNullish } from '@app/shared/operators';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { ColumnId } from '../grid/events/cellId';
import { DataSourceChangeEvent } from '../grid/events/DataSourceChangeEvent';
import { EditStateEvent, EditStateStatus } from '../grid/events/EditStateEvent';
import { Source } from '../grid/sourceUtil';
import { ScoreDialogComponent } from '../score-dialog/score-dialog.component';
import { EconomyStateService } from './../economy-state.service';
import { CellChangeEvent } from './../grid/events/CellChangeEvent';

@Component({
  selector: 'app-economy-table',
  templateUrl: './economy-table.component.html',
  styleUrls: ['./economy-table.component.scss'],
  standalone: false,
})
export class EconomyTableComponent implements OnInit, OnDestroy {
  private readonly MAX_CELL_VALUE = 10000000;
  public isValueBetweenZeroAndMax = IsValueBetweenZeroAndMax(this.MAX_CELL_VALUE);
  public isInteger = IsInteger();
  public fieldScoreDialogOpen = false;
  public DISPLAYED_COLUMNS: string[] = [
    'name',
    'fieldScore',
    'productionPrice',
    'yield',
    'unitCost',
    'machineCosts',
    'fieldscoreCost',
    'landLease',
    'otherCosts',
    'OtherIncome',
    'totalCosts',
  ];

  public get cellError$(): Observable<CellError> {
    return this.cellError.asObservable();
  }

  public get editable$(): Observable<boolean> {
    return this.editable.asObservable();
  }

  public loading$ = this.economyStateService.loading$;
  public dataRows: CoarseFodderColumn[] = [];
  public isFieldScoreEnabled$ = this.economyStateService.useFieldScore$;
  private cellError = new Subject<CellError>();
  private editable = new BehaviorSubject<boolean>(true);
  private subscriptions = new Subscription();
  private harvestYear!: number;
  private useFieldScore = false;
  private sorting!: Sort;
  private isFieldScoreEnabled = false;

  public readonly harvestYear$ = this.harvestYearStateService.harvestYear$;

  public ColumnKey = ColumnId;
  constructor(
    private economyStateService: EconomyStateService,
    private dialogService: DialogService,
    private changeDetection: ChangeDetectorRef,
    private harvestYearStateService: HarvestYearStateService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit() {
    this.subscriptions.add(
      this.economyStateService.useFieldScore$.subscribe((isFieldScoreEnabled) => {
        this.isFieldScoreEnabled = isFieldScoreEnabled;
        this.dataRows.forEach((coarseFodderColumn: CoarseFodderColumn) => (coarseFodderColumn.isFieldScoreEnabled = isFieldScoreEnabled));
        if (isFieldScoreEnabled) {
          this.DISPLAYED_COLUMNS = [
            'name',
            'fieldScore',
            'productionPrice',
            'yield',
            'unitCost',
            'machineCosts',
            'fieldscoreCost',
            'landLease',
            'otherCosts',
            'OtherIncome',
            'totalCosts',
          ];
        } else {
          this.DISPLAYED_COLUMNS = [
            'name',
            'fieldScore',
            'productionPrice',
            'yield',
            'unitCost',
            'machineCosts',
            'landLease',
            'otherCosts',
            'OtherIncome',
            'totalCosts',
          ];
        }
      })
    );
    this.subscriptions.add(
      this.economyStateService.coarseFodderColumn$.subscribe((coarseFooderColumns) => {
        this.dataRows = coarseFooderColumns.map((c) => new CoarseFodderColumn(c, this.isFieldScoreEnabled));
        if (this.sorting) {
          this.sortData(this.sorting);
        }
        this.economyStateService.loading = false;
        this.changeDetection.detectChanges();
      })
    );
    this.subscriptions.add(
      this.harvestYear$.pipe(filterNullish()).subscribe((harvestYear) => {
        if (this.harvestYear !== harvestYear) {
          this.economyStateService.hydrateCoarsefodderColumns(harvestYear);
          this.harvestYear = harvestYear;
        }
      })
    );
    this.subscriptions.add(
      this.economyStateService.useFieldScore$.subscribe((useFieldScore) => {
        this.useFieldScore = useFieldScore;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onEditChange(changeEvent: CellChangeEvent, fieldId: number, columnId: number) {
    const value = (changeEvent.value ?? 0).toString().length > 0 ? +(changeEvent.value ?? 0) : undefined;
    let rehydrateCoarsefoodder = false;
    const targetColumn = this.dataRows.find((t) => t.id === fieldId);

    if (!targetColumn) {
      return;
    }

    switch (columnId) {
      case ColumnId.productionPrice:
        break;
      case ColumnId.yield:
        if (targetColumn.yield === value) {
          return;
        }

        targetColumn.yield = value;

        targetColumn.yieldDataSource = CoarseFodderDataSource.Manual;

        targetColumn.sources = [
          ...targetColumn.sources.filter(
            (source) => !(source.dataSource === CoarseFodderDataSource.Manual && source.coarseFodderEconomyColumn === Source.Yield)
          ),
          {
            ...targetColumn.sources.find(
              (source) => source.dataSource === CoarseFodderDataSource.Manual && source.coarseFodderEconomyColumn === Source.Yield
            ),

            dataSourceValue: value,
          } as CoarseFodderEconomyDataSource,
        ];
        break;
      case ColumnId.unitCost:
        if (targetColumn.unitCosts === value) {
          return;
        }

        targetColumn.unitCosts = value;

        targetColumn.unitCostsDataSource = CoarseFodderDataSource.Manual;

        targetColumn.sources = [
          ...targetColumn.sources.filter(
            (source) => !(source.dataSource === CoarseFodderDataSource.Manual && source.coarseFodderEconomyColumn === Source.UnitCosts)
          ),
          {
            ...targetColumn.sources.find(
              (source) => source.dataSource === CoarseFodderDataSource.Manual && source.coarseFodderEconomyColumn === Source.UnitCosts
            ),

            dataSourceValue: value,
          } as CoarseFodderEconomyDataSource,
        ];
        break;
      case ColumnId.machineCosts:
        rehydrateCoarsefoodder = this.useFieldScore;

        targetColumn.machineCosts = value;

        targetColumn.machineAndLaborCostsDataSource = CoarseFodderDataSource.Manual;

        targetColumn.sources = [
          ...targetColumn.sources.filter(
            (source) =>
              !(source.dataSource === CoarseFodderDataSource.Manual && source.coarseFodderEconomyColumn === Source.MachineAndLaborCosts)
          ),
          {
            ...targetColumn.sources.find(
              (source) =>
                source.dataSource === CoarseFodderDataSource.Manual && source.coarseFodderEconomyColumn === Source.MachineAndLaborCosts
            ),

            dataSourceValue: value,
          } as CoarseFodderEconomyDataSource,
        ];
        break;
      case ColumnId.landLease:
        targetColumn.landLease = value;
        break;
      case ColumnId.otherCosts:
        targetColumn.otherCosts = value;
        break;
      case ColumnId.otherIncome:
        targetColumn.otherIncome = value;
        break;
    }

    this.economyStateService.saveCoarseFooderColumn(this.harvestYear, targetColumn, rehydrateCoarsefoodder);
  }

  public onSourceChange(sourceChangeEvent: DataSourceChangeEvent, fieldId: number, columnId: number) {
    const targetColumn = this.dataRows.find((t) => t.id === fieldId);
    if (!targetColumn) {
      return;
    }
    switch (columnId) {
      case ColumnId.productionPrice:
        break;
      case ColumnId.yield:
        targetColumn.yield = sourceChangeEvent.sourceValue;

        targetColumn.yieldDataSource = sourceChangeEvent.source;
        break;
      case ColumnId.unitCost:
        targetColumn.unitCosts = sourceChangeEvent.sourceValue;

        targetColumn.unitCostsDataSource = sourceChangeEvent.source;
        break;
      case ColumnId.machineCosts:
        targetColumn.machineCosts = sourceChangeEvent.sourceValue;

        targetColumn.machineAndLaborCostsDataSource = sourceChangeEvent.source;
        break;
      case ColumnId.fieldscoreCost:
        break;
      case ColumnId.landLease:
        break;
      case ColumnId.otherCosts:
        break;
      case ColumnId.otherIncome:
        break;
    }

    this.economyStateService.saveCoarseFooderColumn(this.harvestYear, targetColumn);
  }

  public onEditStateChange(editStateEvent: EditStateEvent, fieldId: number, columnId: number) {
    this.cellError.next({ fieldId, columnId });
    this.editable.next(editStateEvent.status !== EditStateStatus.Errored);
  }

  /**
   Custom sorting for each column in the table
   */
  public sortData(sort: Sort) {
    this.sorting = sort;
    const data = this.dataRows.slice();
    if (!sort.active || sort.direction === '') {
      this.dataRows = data;
      return;
    }
    this.dataRows = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return CompareHelper.compare(a.cropName, b.cropName, isAsc);
        case 'fieldScore':
          return CompareHelper.compare(a.hasFieldScore, b.hasFieldScore, isAsc);
        case 'productionPrice':
          return CompareHelper.compare(a.productionPrice, b.productionPrice, isAsc);
        case 'yield':
          return CompareHelper.compare(a.yield, b.yield, isAsc);
        case 'unitCost':
          return CompareHelper.compare(a.unitCosts, b.unitCosts, isAsc);
        case 'machineCosts':
          return CompareHelper.compare(a.machineCosts, b.machineCosts, isAsc);
        case 'landLease':
          return CompareHelper.compare(a.landLease, b.landLease, isAsc);
        case 'otherCosts':
          return CompareHelper.compare(a.otherCosts, b.otherCosts, isAsc);
        case 'OtherIncome':
          return CompareHelper.compare(a.otherIncome, b.otherIncome, isAsc);
        case 'totalCosts':
          return CompareHelper.compare(a.totalCosts, b.totalCosts, isAsc);
        default:
          return 0;
      }
    });
  }

  public onScoreDialogClick($event: any, element: any) {
    if (element.machineCosts === undefined || element.machineCosts === null) {
      this.notificationService.showWarning('main.economy.table.warnings.fieldScoreRequiresMachineCost');
      return;
    }

    if (this.fieldScoreDialogOpen) {
      return;
    }
    this.fieldScoreDialogOpen = true;
    const fieldId = element.fieldYearId;
    this.subscriptions.add(
      this.economyStateService
        .getFieldScore(this.harvestYear, fieldId, element.farmId)
        .pipe(
          first(),
          switchMap((fieldScoreData) =>
            this.dialogService
              .openMd(ScoreDialogComponent, {
                data: {
                  averageCostPreCorrection: fieldScoreData.machineCosts,
                  fieldName: element.fieldNumber + (element.fieldName?.length > 0 ? `(${element.fieldName})` : ''),
                  area: fieldScoreData.area,
                  fieldDistance: fieldScoreData.distance,
                  transportationCosts: fieldScoreData.transportCosts,
                  correction: fieldScoreData.avgFieldCapacity / 100,
                  manualCorrection: fieldScoreData.sizeFormCorrection ?? 3,
                },
              })
              .afterClosed()
              .pipe(
                tap(() => (this.fieldScoreDialogOpen = false)),
                filter((dialogResult) => !!dialogResult),
                switchMap((result) =>
                  this.economyStateService.saveFieldScore(
                    this.harvestYear,
                    fieldId,
                    result.transportationCost,
                    result.fieldDistance,
                    result.manualCorrection,
                    element.farmId
                  )
                )
              )
          )
        )
        .subscribe(() => {
          this.economyStateService.hydrateCoarsefodderColumns(this.harvestYear);
        })
    );
    $event.stopPropagation();
  }

  public deleteFieldScore(coarseFodderColumn: CoarseFodderColumn, event: MouseEvent) {
    const data = {
      title: 'main.economy.deleteFieldScoreDialog.title',
      text: 'main.economy.deleteFieldScoreDialog.text',
      cancelText: 'main.economy.deleteFieldScoreDialog.cancel',
      confirmText: 'main.economy.deleteFieldScoreDialog.confirm',
    };

    event.stopPropagation();
    this.dialogService.openConfirmDialog(data).onDialogConfirmed?.(() => {
      this.economyStateService.deleteFieldScore(coarseFodderColumn.farmId, this.harvestYear, coarseFodderColumn.fieldYearId);
    });
  }

  public getSourcesForElement(sources: any[], columnId: any) {
    switch (columnId) {
      case ColumnId.productionPrice:
        break;
      case ColumnId.yield:
        return sources.filter((source) => source.coarseFodderEconomyColumn === Source.Yield);
        break;
      case ColumnId.unitCost:
        return sources.filter((source) => source.coarseFodderEconomyColumn === Source.UnitCosts);
        break;
      case ColumnId.machineCosts:
        return sources.filter((source) => source.coarseFodderEconomyColumn === Source.MachineAndLaborCosts);
        break;
      case ColumnId.landLease:
        break;
      case ColumnId.otherCosts:
        break;
      case ColumnId.otherIncome:
        break;
    }
    return [];
  }
}

class CellError {
  public fieldId!: number;
  public columnId!: number;
}
