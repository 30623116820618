import { Directive, Input, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { random } from 'lodash-es';
import { SkeletonComponent } from './skeleton.component';

@Directive({
  selector: '[skeleton]',
  standalone: false,
})
export class SkeletonDirective {
  @Input('skeleton') isLoading: boolean | null = true;
  @Input('skeletonCount') count = 1;
  @Input('skeletonWidth') width = '100%';
  @Input('skeletonHeight') height = '32px';
  @Input('skeletonBottomMargin') bottomMargin?: string;
  @Input('skeletonClassName') className?: string;

  constructor(
    private tpl: TemplateRef<unknown>,
    private vcr: ViewContainerRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isLoading']) {
      this.vcr.clear();

      if (changes['isLoading'].currentValue) {
        Array.from({ length: this.count }).forEach(() => {
          const ref = this.vcr.createComponent(SkeletonComponent);

          Object.assign(ref.instance, {
            width: this.width === 'rand' ? `${random(30, 90)}%` : this.width,
            height: this.height,
            className: this.className,
            bottomMargin: this.bottomMargin,
          });
        });
      } else {
        this.vcr.createEmbeddedView(this.tpl);
      }
    }
  }
}
