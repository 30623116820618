import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { isNullOrUndefined } from '../utils/utils';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AccordionComponent implements OnChanges {
  @Input() public boldTitle = '';
  @Input() public normalTitle = '';
  @Input() public templateRef!: TemplateRef<any>;
  @Input() public titleTemplateRef!: TemplateRef<any>;
  @Input() public isExpanded = { expanded: false };
  @Input() public elHeight = '48px';
  @Input() public isDisabled: boolean | undefined | null = false;
  @Input() public isAxel = false;

  // @ts-ignore - TS2564 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  @ViewChild('accordion', { static: true }) public accordion: MatExpansionPanel;

  public ngOnChanges(changes: SimpleChanges) {
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    if (changes.isExpanded && !isNullOrUndefined(changes.isExpanded.currentValue)) {
      // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      this.isExpanded = changes.isExpanded.currentValue;
      this.isExpanded.expanded ? this.open() : this.close();
    }
  }

  public get isOpen() {
    return this.accordion.expanded;
  }

  public open() {
    setTimeout(() => {
      this.accordion.open();
    });
  }

  public close() {
    this.accordion.close();
  }
}
