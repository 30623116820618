import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixedDecimals',
  standalone: false,
})
export class FixedDecimalsPipe implements PipeTransform {
  public transform(value: number, decimals: number = 0): number {
    return +value?.toFixed(decimals);
  }
}
