import { Component } from '@angular/core';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { InfoDialogContentComponent } from './info-dialog-content/info-dialog-content.component';

@Component({
  selector: 'app-insurance-info',
  templateUrl: './insurance-info.component.html',
  styleUrls: ['./insurance-info.component.scss'],
  standalone: false,
})
export class InsuranceInfoComponent {
  constructor(private dialogService: DialogService) {}

  public showInfoDialog() {
    this.dialogService.openInfoDialog(InfoDialogContentComponent);
  }
}
