import { Component } from '@angular/core';
// Angular material
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '@app/core/language/language.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { first, map } from 'rxjs/operators';
import { ChangeCrop } from '../change-crop-modal/change-crop-modal-events.enum';

@Component({
  selector: 'app-delete-crop-task',
  templateUrl: './DeleteCropTask.component.html',
  styleUrls: ['./DeleteCropTask.component.scss'],
  standalone: false,
})
export class DeleteCropTaskComponent {
  constructor(
    private languageService: LanguageService | null,
    private dialogRef: MatDialogRef<DeleteCropTaskComponent> | null,
    private harvestYearStateService: HarvestYearStateService | null
  ) {}

  public onCancelClicked(event: any): void {
    this.dialogRef!.close(ChangeCrop.Cancel);
  }

  public onConfirmClicked(event: any): void {
    this.dialogRef!.close(ChangeCrop.DeleteTasks);
  }

  public get translateDescription() {
    const { data } = this.dialogRef!._containerInstance._config;
    const { crops, name, number } = data;

    const cropName = crops[0].cropName;
    return this.harvestYearStateService!.harvestYear$.pipe(
      first(),
      map((_) =>
        this.languageService!.getText('main.fieldAdministration.createField.areYouSureDeleteTaskForCrop', {
          fieldCrop: cropName,
          fieldName: !!name ? `${number} ${name}` : number,
        })
      )
    );
  }
}
