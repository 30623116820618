import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DirtyCheckService } from '@app/core/dirty-check/dirty-check.service';
import { ScreenSize } from '@app/core/enums/screen-size.enum';
import { FarmService } from '@app/core/farm/farm.service';
import { Farm } from '@app/core/interfaces/farm.interface';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { StorageService } from '@app/core/storage/storage.service';
import { filterSelectableFarms } from '@app/helpers/farms/filter-selectable-farms';
import { VraDirtyCheckService } from '@app/map/features/vra/guards/vra-dirty-check.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { cmShortcuts } from '@app/shared/directives/shortcut-key/cm-shortcuts.const';
import { FarmPickerStateService } from '@app/state/services/farm-picker/farm-picker-state.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { WindowStateService } from '@app/state/services/window/window-state.service';
import isEqual from 'lodash-es/isEqual';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { defaultIfEmpty, filter, first, map, take, withLatestFrom } from 'rxjs/operators';
import { FarmPickerStorageKeys } from '../farm-picker-storage-keys.enum';
import { UserType } from './../../../../core/repositories/user/user-type.enum';
import { FarmPickerSearchService } from './farm-picker-search-service/farm-picker-search.service';

@Component({
  selector: 'app-farm-picker-modal',
  templateUrl: './farm-picker-modal.component.html',
  styleUrls: ['farm-picker-modal.component.scss'],
  standalone: false,
})
export class FarmPickerModalComponent implements OnInit, OnDestroy {
  // local vars
  public userType: UserType | null = null;
  public isAddFarmOpen = false;
  public initialSelect = false;
  public searchValue = '';
  public initialSearchValue = '';
  public selectedFarms: Farm[] = [];
  public selectableFarms: Farm[] = [];
  public isLoading = false;
  public farmGridCols = 4;
  public showCreateButton = false;
  public subscriptions: Subscription[] = [];
  public shortcuts = cmShortcuts;
  private farms: Farm[] = [];
  private readonly READ_RIGHTS_WARNING_DURATION = 15000;

  constructor(
    private farmService: FarmService,
    private farmPickerStateService: FarmPickerStateService,
    private farmStateService: FarmStateService,
    private farmPickerSearchService: FarmPickerSearchService,
    private storageService: StorageService,
    private dialogService: DialogService,
    private dirtyCheckService: DirtyCheckService,
    private languageService: LanguageService,
    private router: Router,
    private notificationService: NotificationService,
    private harvestYearStateService: HarvestYearStateService,
    private windowStateService: WindowStateService,
    private dirtyCheckServiceVra: VraDirtyCheckService
  ) {}

  public ngOnInit() {
    this.subscriptions.push(this.farmStateService.userType$.subscribe((userType) => this.onUserTypeChange(userType!)));
    // get usertype from api
    if (!this.isConsultant()) {
      this.isLoading = true;
    }
    // set up listeners for state changes
    this.initSubscriptions();
    // init selectable farms
    this.selectableFarms = this.farms.filter((_farm) => !this.selectedFarms.find((selected) => selected.id === _farm.id));
    this.initSearch();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public isFarmSelected(farm: Farm) {
    return this.selectedFarms.includes(farm);
  }

  /**
   * Event handler for user clicking on a farm
   * @param farm Clicked farms
   */
  public onFarmClicked(farm: Farm) {
    if (this.isAddFarmOpen) {
      return;
    }
    if (this.selectedFarms.includes(farm)) {
      // remove from selected farms
      this.selectedFarms = this.selectedFarms.filter((selectedFarm) => selectedFarm !== farm);
    } else {
      // add to selected farms
      this.selectedFarms = [...this.selectedFarms, farm];
    }

    this.selectableFarms = this.filterSelectedFarms();
  }

  public onConfirmFarmsClicked() {
    this.dirtyCheckServiceVra
      .checkDirtyAndAct()
      .pipe(defaultIfEmpty(true), first())
      .subscribe((isNotDirtyOrUserAccepted) => {
        if (isNotDirtyOrUserAccepted) {
          this.farmStateService.selectedFarms$.pipe(first()).subscribe((farms) => {
            isEqual(farms, this.selectedFarms)
              ? this.farmPickerStateService.closeFarmPickerModal()
              : this.dirtyCheckService.isAppDirty
                ? this.showConfirmDialog()
                : this.selectFarms();
          });
        }
      });
  }

  public onCancelFarmsClicked() {
    this.selectedFarms = [];
    this.farmPickerStateService.closeFarmPickerModal();
  }

  /**
   * Shows the form to add a new farm.
   */
  public onAddFarmClicked() {
    this.farmPickerStateService.openAddFarmModal();
  }

  public isConsultant = () => {
    return (
      this.userType === UserType.Consultant ||
      this.userType === UserType.Teacher ||
      this.userType === UserType.PrivateConsultant ||
      this.userType === UserType.Company
    );
  };

  public onFarmsChange(farms: Farm[] | null) {
    if (!farms) {
      return;
    }
    this.farms = farms;
    this.selectableFarms = this.filterSelectedFarms();
    this.isLoading = false;
  }

  public onSelectedFarmsChange(selectedFarms: Farm[]) {
    this.selectedFarms = selectedFarms;
    this.initialSelect = selectedFarms.length === 0;
    this.selectableFarms = this.filterSelectedFarms();
  }

  public onUserTypeChange(userType: UserType) {
    this.userType = userType;
    this.showCreateButton = !this.isConsultant();
  }

  public onScreenSizeChange(screenSize: ScreenSize) {
    switch (screenSize) {
      case ScreenSize.XS:
      case ScreenSize.S:
        this.farmGridCols = 1;
        break;
      case ScreenSize.M:
        this.farmGridCols = 2;
        break;
      case ScreenSize.L:
        this.farmGridCols = 3;
        break;
      case ScreenSize.XL:
        this.farmGridCols = 4;
    }
  }

  public toggleNoFarmsClass() {
    return !!this.selectedFarms.length && !!this.selectableFarms.length && !this.isLoading;
  }

  public onSearchValueChange(searchString: string) {
    this.isLoading = true;
    this.farmPickerSearchService.lastSearch = searchString;
    if (!this.isConsultant()) {
      // Normal users can just search in their own farms
      this.searchValue = searchString;
      this.selectableFarms = filterSelectableFarms(this.farms, this.selectedFarms, searchString);
      this.isLoading = false;
    } else {
      // Consultants request a number of farms directly from the backend.
      // Short search strings reset the search
      if (searchString.length < 3) {
        this.consultantSearch('', []);
      } else {
        this.farmService
          .searchFarms(searchString)
          .pipe(first())
          .subscribe((farms: Farm[]) => {
            this.consultantSearch(searchString, farms);
          });
      }
    }
  }

  public get hasZeroFarms$(): Observable<boolean | null> {
    return this.farmStateService.farms$.pipe(map((farms) => farms && farms.length === 0));
  }

  private selectFarms() {
    this.showWarningIfSelectedFarmsContainsReadOnly();
    this.farmStateService.selectedFarms = this.selectedFarms;
    this.storageService.saveToStorage(FarmPickerStorageKeys.StoredFarmsKey, this.selectedFarms);
    this.farmPickerStateService.closeFarmPickerModal();
    this.goToCultivationJournal();
  }

  private showConfirmDialog() {
    this.dialogService.openDirtyCheckDialog().subscribe((action) => {
      if (action && action.isConfirmed) {
        this.dirtyCheckService.clearAllStates();
        this.selectFarms();
      } else {
        this.farmPickerStateService.closeFarmPickerModal();
      }
    });
  }

  private goToCultivationJournal() {
    this.farmStateService.selectedFarms$
      .pipe(take(1), withLatestFrom(this.harvestYearStateService.harvestYear$))
      .subscribe(([farms, harvestYear]) => {
        void this.router.navigate(['/map/cultivation-journal'], {
          queryParams: {
            harvestYear: harvestYear,
            farmIds: JSON.stringify(farms.map((f) => f.id)),
            currentLanguage: JSON.stringify(this.languageService.currentLanguage.shortKey),
          },
        });
      });
  }

  private filterSelectedFarms() {
    return this.isConsultant()
      ? this.farms.filter((_farm) => !this.selectedFarms.find((selected) => selected.id === _farm.id))
      : filterSelectableFarms(this.farms, this.selectedFarms, this.searchValue);
  }

  private consultantSearch(searchString: string, farms: Farm[]) {
    this.searchValue = searchString;
    this.farmStateService.farmSearchTerm = searchString;
    this.farmStateService.farms = farms;
    this.isLoading = false;
  }

  /**
   * Init all subscriptions listeners for component.
   */
  private initSubscriptions(): void {
    this.subscriptions.push(
      this.farmStateService.farms$.pipe(filter((farms) => !!farms)).subscribe((farms) => this.onFarmsChange(farms)),
      this.farmStateService.selectedFarms$.subscribe((selectedFarms) => this.onSelectedFarmsChange(selectedFarms)),
      this.farmPickerStateService.isAddFarmOpen$.subscribe((isAddFarmModalOpen) => (this.isAddFarmOpen = isAddFarmModalOpen)),
      this.windowStateService.screenSize$.subscribe((screenSize) => this.onScreenSizeChange(screenSize))
    );
  }

  private initSearch() {
    this.initialSearchValue = this.farmPickerSearchService.lastSearch;
  }

  private showWarningIfSelectedFarmsContainsReadOnly() {
    if (this.selectedFarms.some((f) => !f.hasWriteAccess)) {
      this.notificationService.showWarning('messages.common.farmWithReadPermission', this.READ_RIGHTS_WARNING_DURATION);
    }
  }
}
