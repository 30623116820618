import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { AsAppliedMetadataHelper } from '@app/helpers/as-applied-metadata-helper.ts';
import { MetadataTask } from '@app/map/features/field-analysis/features/as-applied/metadata-task.class';
import { DateTime } from 'luxon';
import { combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, first, map, startWith, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-meta-data-child-form',
  templateUrl: './meta-data-child-form.component.html',
  styleUrls: ['./meta-data-child-form.component.scss'],
  standalone: false,
})
export class MetaDataChildFormComponent implements OnInit, OnDestroy {
  @Input() public form!: FormGroup;
  @Input() public selectableFields$!: Observable<Field[]>;
  @Input() public selectableTasks$!: Observable<MetadataTask[]>;
  @Input() public maxDate!: Date;
  @Input() public isEditMode = false;
  @Output() public onRemoveChild = new EventEmitter();
  @Output() public onPointToggle = new EventEmitter();
  @Output() public onFieldNumberChange = new EventEmitter();

  public units: string[] = ['kg/ha', 'l/ha', 'ton/ha'];
  public selectableTasksGroupedByOperationTypeGroup$!: Observable<OperationTypeGroupTaskCollection[]>;
  public sortedSelectableTasks$!: Observable<MetadataTaskWithText[]>;
  private subscriptions = new Subscription();
  private _selectedField = new ReplaySubject<Field>(1);
  private originalFieldNumber: any;
  private originalMetadataTask: any;
  private possibleTaskId: number | undefined;
  private originalDate?: string | number | Date;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.possibleTaskId = this.form.get('taskId')?.value;
    this.originalFieldNumber = this.form.get('fieldNumber')?.value;
    this.originalMetadataTask = this.form.get('metadataTask')?.value;
    this.originalDate = this.form.get('appliedDate')?.value;

    if (!this.isEditMode) {
      this.subscriptions.add(
        // Detect if the form values revert to the original values
        this.form.valueChanges.subscribe((changes) => this.handleFormValueChanges(changes))
      );

      // Group and filter tasks based on selected field and language
      this.selectableTasksGroupedByOperationTypeGroup$ = combineLatest([this.selectableTasks$, this.selectedField$]).pipe(
        map(([tasks, selectedField]) => {
          return tasks.filter((task) => task.fieldId === selectedField?.id || (this.possibleTaskId && task.taskId === this.possibleTaskId));
        }),
        map((tasks) => AsAppliedMetadataHelper.groupAndSortTasks(tasks, this.languageService))
      );
      this.sortedSelectableTasks$ = this.selectableTasksGroupedByOperationTypeGroup$.pipe(map((st) => st.flatMap((task) => task.tasks)));

      const fieldNumberControl = this.form.get('fieldNumber');
      this.subscriptions.add(
        fieldNumberControl?.valueChanges
          .pipe(
            startWith(fieldNumberControl.value),
            distinctUntilChanged(),
            withLatestFrom(this.selectableFields$),
            map(([selectedFieldNumber, selectableFields]) => selectableFields.find((field) => field.number === selectedFieldNumber))
          )
          .subscribe((selectedField) => this.updateSelectedFieldAndMetadataTaskControl(selectedField))
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private get selectedField$() {
    return this._selectedField.asObservable().pipe(distinctUntilChanged());
  }

  private updateSelectedFieldAndMetadataTaskControl(selectedField?: Field) {
    const metadataTaskControl = this.form.get('metadataTask');
    if (selectedField) {
      this._selectedField.next(selectedField);
    }

    if (this.possibleTaskId && metadataTaskControl) {
      this.selectableTasksGroupedByOperationTypeGroup$
        .pipe(
          first(),
          map((tasksOperationTypeGroups) => tasksOperationTypeGroups.flatMap((o) => o.tasks)),
          map((tasks) => tasks.find((task) => task.taskId === this.possibleTaskId))
        )
        .subscribe((matchingTask) => {
          metadataTaskControl.setValue(matchingTask || null, { emitEvent: false });
        });
    }

    if (selectedField && metadataTaskControl?.disabled) {
      metadataTaskControl.enable();
    } else if (!selectedField && metadataTaskControl?.enabled) {
      metadataTaskControl.disable();
    }
  }

  private handleFormValueChanges(changes: any): void {
    const jsDate = new Date(this.originalDate as string);
    const appliedJsDate = new Date(changes.appliedDate);
    const sameDay = DateTime.fromJSDate(jsDate).hasSame(DateTime.fromJSDate(appliedJsDate), 'day');
    const sameFieldNumber = changes.fieldNumber === this.originalFieldNumber;
    const bothFalsey = !changes.metadataTask && !this.originalMetadataTask;
    const isTheSameTask = changes.metadataTask === this.originalMetadataTask;

    if (sameFieldNumber && sameDay && (bothFalsey || isTheSameTask)) {
      this.form.markAsPristine();
    }

    if (changes.fieldNumber) {
      this.onFieldNumberChange.emit();
    }
  }

  public togglePoints() {
    this.onPointToggle.emit(this.form);
  }

  public removeChildClicked() {
    this.onRemoveChild.emit(this.form);
  }

  compareTasks(task1: MetadataTaskWithText | null, task2: MetadataTaskWithText | null): boolean {
    return task1 && task2 ? task1.taskId === task2.taskId : task1 === task2;
  }
}
export interface OperationTypeGroupTaskCollection {
  operationTypeGroupName: string;
  tasks: MetadataTaskWithText[];
}

export interface MetadataTaskWithText extends MetadataTask {
  text: string;
}
