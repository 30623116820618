import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-link-alert',
  templateUrl: './link-alert.component.html',
  styleUrls: ['./link-alert.component.scss'],
  standalone: false,
})
export class LinkAlertComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public snackBarData: any,
    private _snackRef: MatSnackBarRef<LinkAlertComponent>
  ) {}

  public dismiss() {
    this._snackRef.dismiss();
  }
}
