import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reminder-window',
  templateUrl: './reminder-window.component.html',
  styleUrls: ['./reminder-window.component.scss'],
  standalone: false,
})
export class ReminderWindowComponent {
  constructor(private dialogRef: MatDialogRef<ReminderWindowComponent>) {}

  public onClose() {
    this.dialogRef.close();
  }
}
