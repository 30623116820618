<div class="task-card-container">
  <mat-card
    appearance="outlined"
    class="task-card full-card"
    *ngIf="!isMultipleTasks"
    (mouseenter)="onMouseEnterMultipleCards()"
    (mouseleave)="onMouseLeaveMultipleCards(true)"
    (click)="onMultipleCards()"
  >
    <div class="task-card-content">
      <div class="operation-type-chip" [ngStyle]="{ 'background-color': operationTypeColor }">
        <span class="title">{{ chipTitle | truncate: [12, '..'] }}</span>
        <span *ngIf="firstTaskMultipleProducts" class="additional-task-chip"> +{{ additionalProductCount }} </span>
      </div>
      <div class="task-complete-chip" [ngStyle]="{ 'background-color': operationStatusColor }">
        <span>{{ operationStatusString | translate }}</span>
      </div>
    </div>
  </mat-card>

  <div class="multiple-tasks-card-container" *ngIf="isMultipleTasks">
    <mat-card
      appearance="outlined"
      class="task-card split-card"
      (mouseenter)="onMouseEnterMultipleCards()"
      (mouseleave)="onMouseLeaveMultipleCards(true)"
      (click)="onMultipleCards()"
    >
      <div class="task-card-split-content">
        <div class="top-card">
          <div class="operation-type-chip-short" [ngStyle]="{ 'background-color': operationTypeColor }">
            <span class="title">{{ chipTitle | translate | truncate: [10, '..'] }}</span>
          </div>
          <mat-icon class="material-symbols-outlined small-status" [ngStyle]="{ color: operationStatusColor }">{{
            firstTask.status === 0 ? 'pending' : 'check_circle'
          }}</mat-icon>
        </div>
      </div>
    </mat-card>
    <mat-card
      appearance="outlined"
      class="task-card split-card"
      (mouseenter)="onMouseEnterMultipleCards()"
      (mouseleave)="onMouseLeaveMultipleCards(true)"
      (click)="onMultipleCards()"
    >
      <div class="task-card-split-content">
        <div class="bottom-card">
          <div class="more-tasks">
            <span class="title"> +{{ additionalTaskCount }} {{ 'main.overview.task-card.tasks' | translate }}</span>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>

<div
  class="multiple-cards-container"
  [@fadeInOut]
  *ngIf="hoverMultipleCards"
  (mouseleave)="onMouseLeaveMultipleCards()"
  (click)="onMultipleCards()"
>
  <ng-container *ngFor="let task of additionalTasks">
    <app-task-details-card [taskEntry]="task" (clickedOutside)="onMouseLeaveMultipleCards()"> </app-task-details-card>
  </ng-container>
</div>
