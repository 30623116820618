import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { Farm } from '@app/core/interfaces/farm.interface';
import { SecureUrlService } from '@app/core/secure-url/secure-url.service';
import { first } from 'rxjs/operators';
import { FarmPickerModalEditFarmComponent } from '../edit/edit.component';

@Component({
  selector: 'app-farm-card',
  templateUrl: './farm-card.component.html',
  styleUrls: ['farm-card.component.scss'],
  standalone: false,
})
export class FarmCardComponent implements OnInit {
  public farmLogoUrl: SafeUrl;

  @Input() public farm!: Farm;
  @Input() public isSelected!: Boolean;

  constructor(
    private secureUrlService: SecureUrlService,
    private sanitizer: DomSanitizer,
    private matDialog: MatDialog,
    private endpointService: EndpointsService
  ) {
    this.farmLogoUrl = this.sanitizer.bypassSecurityTrustStyle(''); // Initialize here with empty string to avoid null request
  }

  public ngOnInit(): void {
    this.loadImage();
  }

  public loadImage(): void {
    if (this.farm.imageUrl) {
      const maxWidth = 300;
      const maxHeight = 300;

      this.secureUrlService
        .get(this.getFarmImageUrl(this.farm.imageUrl, maxWidth, maxHeight))
        .pipe(first())
        .subscribe(
          (url) => {
            this.farmLogoUrl = this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
          },
          (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        );
    } else {
      this.farmLogoUrl = this.sanitizer.bypassSecurityTrustStyle(`url(${'~../../../../assets/images/farm-picker/lf.svg'})`);
    }
  }

  private getFarmImageUrl(farmImageUrl: string, maxWidth: number, maxHeight: number) {
    return `${this.endpointService.foApi}${farmImageUrl}/raw?maxWidth=${maxWidth}&maxHeight=${maxHeight}`;
  }

  public onEditFarmClicked($event: Event): void {
    $event.stopPropagation();
    this.matDialog.open(FarmPickerModalEditFarmComponent, {
      data: {
        farm: this.farm,
      },
    });
  }
}
