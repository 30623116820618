import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { GrowthRegulation } from '../interfaces/growth-regulation.interface';

@Component({
  selector: 'app-field-info',
  templateUrl: './field-info.component.html',
  styleUrls: ['./field-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FieldInfoComponent {
  @Input() public field?: Field;
  @Input() public growthRegulation?: GrowthRegulation;
  @Input() public isGrowthRegulationsEmpty = true;

  public harvestYear$ = this.harvestYearStateService.harvestYear$;

  constructor(private harvestYearStateService: HarvestYearStateService) {}
}
