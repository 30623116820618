import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Field } from '@app/core/interfaces/field.interface';

@Component({
  selector: 'app-tasks-crop-filter',
  templateUrl: './tasks-crop-filter.component.html',
  styleUrls: ['./tasks-crop-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TasksCropFilterComponent implements OnInit {
  @Input() public field!: Field;
  @Output() public cropIdSelect = new EventEmitter<number>();

  private selectedCrop?: Crop | null;

  constructor() {}

  public ngOnInit() {
    this.selectedCrop = this.getSelectedCrop() as Crop;
  }

  public isSelectedCrop(crop: Crop | null) {
    return !!crop && !!this.selectedCrop && crop.id === this.selectedCrop.id;
  }

  public selectCrop(crop: Crop | null) {
    this.selectedCrop = crop;

    this.cropIdSelect.emit(crop ? crop.id : undefined);
  }

  private getSelectedCrop() {
    if (!this.field.crops || !this.field.crops.length) {
      return null;
    }
    return this.field.crops.reduce((prev, curr) => (prev.successionNo < curr.successionNo ? prev : curr));
  }
}
