<div class="top-section" #topSection>
  <app-overview-harvest-year-selector
    [start]="HarvestYear.HARVEST_YEAR_START"
    [end]="HarvestYear.HARVEST_YEAR_END"
  ></app-overview-harvest-year-selector>
  @if (isStoreReady$ | async) {
    <app-overview-crop-filter #cropFilter class="cropFilter"></app-overview-crop-filter>
    <app-product-filter #productFilter class="productFilter"></app-product-filter>
    <app-overview-machine-event-filter #machineEventFilter class="machineEventFilter"></app-overview-machine-event-filter>
  }
</div>

<app-task-overview-tour></app-task-overview-tour>
<app-farm-tasks-overview-table #tableOverview class="tableOverview"></app-farm-tasks-overview-table>
