import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-basis-layer-information-dialog',
  templateUrl: './basis-layer-information-dialog.component.html',
  styleUrls: ['./basis-layer-information-dialog.component.scss'],
  standalone: false,
})
export class BasisLayerInformationDialogComponent {
  constructor(private dialogRef: MatDialogRef<BasisLayerInformationDialogComponent>) {}

  public close() {
    this.dialogRef.close();
  }
}
