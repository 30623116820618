import { Component } from '@angular/core';
// Angular material
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-setting-modal',
  templateUrl: './delete-setting-modal.component.html',
  styleUrls: ['./delete-setting-modal.component.scss'],
  standalone: false,
})
export class DeleteSettingModalComponent {
  public dialogRef!: MatDialogRef<DeleteSettingModalComponent>;

  public onCancelClicked(event: any): void {
    this.dialogRef.close(false);
  }

  public onConfirmClicked(event: any): void {
    this.dialogRef.close(true);
  }
}
