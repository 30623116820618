import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
  standalone: false,
})
export class PartnersComponent implements OnInit {
  public year?: number;
  constructor(public dialogRef: MatDialogRef<PartnersComponent>) {}

  public ngOnInit() {
    this.year = DateTime.now().year;
  }

  public close() {
    this.dialogRef.close();
  }
}
